import { Component, HostListener } from '@angular/core';
import { AbstractFormInterface } from '../interfaces/abstract-form.interface';

@Component({ template: '' })
export class AbstractFormComponent implements AbstractFormInterface {
  preventUnsavedForm = false;
  formTouched = false;

  @HostListener('window:beforeunload')
  formUnsavedGuard() {
    if (this.preventUnsavedForm && this.formTouched) {
      return false;
    }
    return;
  }
}
