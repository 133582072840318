<p-calendar [(ngModel)]="value" [inline]="true" dateFormat="dd/mm/yy" selectionMode="range"></p-calendar>

<div class="action-wrapper">
  <jm-button [class]="'red-border-button'" (clicked)="clear()">
    {{ 'button.clear' | translate }}
  </jm-button>
  <jm-button [disabled]="!isDateRangeEnabled()" [class]="'red-button'" (clicked)="apply()">
    {{ 'button.apply' | translate }}
  </jm-button>
</div>
