<div class="background-home-page"></div>
<div class="login" #loginIntro>
  <div class="login__wrapper">
    <div class="login__form form">
      <div class="intro">
        <div class="welcome-message">{{ 'login.welcome' | translate }}</div>
        <div class="intro-message">{{ 'login.intro' | translate }}</div>
      </div>

      <form *ngIf="form" class="quick-form form" (keyup.enter)="login()">
        <quick-form-controls [form]="form" [config]="formConfig"> </quick-form-controls>
      </form>

      <div class="actions">
        <div class="action-button">
          <jm-button
            [dataTestSelector]="'login'"
            [textCenter]="true"
            [loading]="loading"
            [class]="'red-button full-width'"
            (clicked)="login()"
          >
            {{ 'login.login-text' | translate }}
          </jm-button>
        </div>

        <div class="actions__button">
          <jm-button
            [dataTestSelector]="'mymueller-login'"
            [class]="'red-border-button full-width'"
            [textCenter]="true"
            (clicked)="loginMyMueller()"
          >
            {{ 'auth.mymueller-sso.actions.sign-in' | translate }}
          </jm-button>
        </div>
      </div>

      <p class="password-reset" (click)="navigateTo('/auth/forgot-password')">
        {{ 'login.password-reset.forget' | translate }}
        <span class="forget"> {{ 'login.password-reset.password' | translate }}? </span>
      </p>
    </div>
    <div class="login__ilustration">
      <img src="./assets/images/jm-white.svg" alt="jm-white" class="jm-white-logo" />
    </div>
  </div>
</div>
