import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PreviewDocumentDTO } from 'src/app/modules/media-gallery/interfaces/preview-document.interface';
import { ControlConfig } from '../../../models/control.config';
import { environment } from 'src/environments/environment';
import { downloadAFile, isSet } from 'src/app/util/util';

@Component({
  selector: 'quick-form-upload-multipart',
  templateUrl: './upload-multipart.component.html',
  styleUrls: ['./upload-multipart.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UploadMultipartComponent),
    },
  ],
})
export class UploadMultipartComponent implements ControlValueAccessor {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Input()
  canUserEditFile = true;

  @Output()
  valueChanged = new EventEmitter<File>();

  @ViewChild('fileUpload', { static: false }) fileUpload?: ElementRef;

  value: PreviewDocumentDTO | null = null;
  isDisabled = false;

  get isValueDefined(): boolean {
    return Boolean(this.value?.filename) || Boolean(this.value?.fileSize);
  }

  get fileAcceptFormat(): string {
    return this.config.meta?.fileAcceptFormat;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  onFileChange(event: Event | null) {
    const files = (event?.target as HTMLInputElement).files;
    if (!isSet(files)) {
      return;
    }

    const file = files[0];
    this.propagateChange(file);
    this.valueChanged.emit(file);
    this.addAttachment(file);
  }

  removeFile(): void {
    this.writeValue(undefined);
    this.propagateChange(undefined);
    this.valueChanged.emit(undefined);
  }

  download(): void {
    if (!this.value?.url || !this.value.filename || !this.value.extension) {
      return;
    }
    const fileUrl = `${environment.rootUrl}${this.value.url}`;
    downloadAFile(fileUrl, this.value.filename, this.value.extension);
  }

  //eslint-disable-next-line
  private propagateChange = (value: any) => {};

  //eslint-disable-next-line
  private propagateTouched = () => {};

  private addAttachment(file: File): void {
    const fileReader: FileReader = new FileReader();

    const attachmentsEmit: PreviewDocumentDTO = {
      filename: null,
      data: null,
      filetype: null,
    };

    attachmentsEmit.filename = file.name;
    attachmentsEmit.filetype = file.type;

    fileReader.onload = () => {
      attachmentsEmit.fileSize = file.size;
      this.writeValue(attachmentsEmit);
    };

    fileReader.readAsDataURL(file);
    return;
  }
}
