import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { ResponseData } from '../../abstract/interface/response-data';
import averageEfficiency from './gql/average-efficiency.graphql';
import { AverageEfficiencyDTO } from './interfaces/average-efficiency';
import { AverageEfficiencyRequest } from './interfaces/average-efficiency.request';

@Injectable({
  providedIn: 'root',
})
export class AverageEfficiencyService {
  private readonly apollo = inject(Apollo);
  private readonly snackbarService = inject(SnackBarService);

  getData(request: AverageEfficiencyRequest) {
    return this.apollo
      .query({
        query: averageEfficiency,
        variables: {
          input: request,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackbarService.error('widgets.error.average-efficiency');
        }),
        map((response: ApolloQueryResult<ResponseData<AverageEfficiencyDTO>>) => response.data.averageEfficiencyChart)
      );
  }
}
