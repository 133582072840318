import { get } from 'lodash-es';

import { Component } from '@angular/core';

import { ObjectLiteral } from '../../../../../util/object-literal';
import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'td[jm-tag-cell]',
  templateUrl: './jm-tag-cell.component.html',
})
export class JmTagCellComponent extends AbstractCellComponent {
  get tagConditional(): ObjectLiteral | undefined {
    return this.column?.meta?.tagClassConditional?.find((f: any) => f.value === this.cellData[this.columnField]);
  }

  get value() {
    return get(this.cellData, this.columnField) || '-';
  }
}
