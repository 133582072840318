import { TourService } from 'ngx-ui-tour-md-menu';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { Logout } from 'src/app/app.action';
import { CreateDashboardModalComponent } from 'src/app/core/custom-dashboard/create-dashboard/create-dashboard-modal/create-dashboard-modal.component';
import { loadPrivateDashboards, loadPublicDashboards } from 'src/app/core/custom-dashboard/store/dashboard.actions';
import { selectIsSettingsSideBarOpened } from 'src/app/store/application.selector';
import { OperatorEnum } from 'src/app/util/operator.enum';
import { checkEnvironmentPermission } from 'src/app/util/util';

import { Component, EventEmitter, HostListener, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Event, NavigationEnd, Router } from '@angular/router';
import {
  AuthenticationService,
  GetUserInfo,
  JMPermissions,
  LoginService,
  RequestBackendVersion,
  ResponseScheduledNotifications,
  selectNotification,
  selectUserName,
  selectUserPermissions,
  SetUserInfo,
} from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ReleaseNotes, ReleaseNotesInfoDialogComponent, ReleaseNotesService, ReleaseNotesSubscriptionService } from '@release-notes';
import { User } from '@user';

import { IUserNotification } from '../../interfaces/user-notification.interface';
import { LocalStorageService } from '../../local-storage.service';
import * as ApplicationActions from '../../store/application.actions';
import { navBarLinks } from './navbar.links';
import { IAppLinksDTO } from './navbar.model';
import { DeviceService } from 'src/app/modules/device/device.service';

@UntilDestroy()
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  private readonly deviceService = inject(DeviceService);
  private readonly releaseNotesService = inject(ReleaseNotesService);
  private readonly releaseNotesSubscriptionService = inject(ReleaseNotesSubscriptionService);

  @ViewChild('dashboardMenuTrigger') dashboardMenuTrigger!: MatMenuTrigger;

  @Output()
  mobileMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  hideLogo = false;

  userPermissions$: Observable<Array<JMPermissions> | any> = this.store$.select(selectUserPermissions);
  loginName$: Observable<string | any> = this.store$.select(selectUserName);
  userNotification$: Observable<IUserNotification | any> = this.store$.select(selectNotification);
  isSettingsSideBarOpened$: Observable<boolean> = this.store$.select(selectIsSettingsSideBarOpened);
  readonly isLoggedIn$ = this.authenticationService.isLoggedIn();
  impersonateUserToken$: Observable<string | null> = this.localStorageService.impersonateUserToken$;
  isClassNotificationActive$: Observable<boolean> = combineLatest([this.userNotification$]).pipe(
    map(([userNotification]) => {
      return Boolean(userNotification);
    })
  );

  navBarLinksUpdated: Array<IAppLinksDTO> | any;
  isMobileMenuOpened = false;
  footerLinks: Array<{ key: string; url: string; targetBlank: boolean }> = [
    {
      key: 'imprint',
      url: 'https://www.mueller-frick.com/en/imprint',
      targetBlank: true,
    },
    {
      key: 'contact',
      url: '/#/auth/support',
      targetBlank: false,
    },
  ];
  innerWidth = -1;
  selectedMenu = '';
  userPermissions!: Array<JMPermissions>;
  isMobile = false;

  get permissions(): typeof JMPermissions {
    return JMPermissions;
  }

  get operator(): typeof OperatorEnum {
    return OperatorEnum;
  }

  get routerUrl(): string {
    return this.router.url;
  }

  get isMenuIconDisplayed(): boolean {
    const url: string = this.router.url;
    return url.includes('dashboard') && !url.includes('/dashboard/view') && !url.includes('/dashboard/edit');
  }

  private prevInnerWidth = -1;
  private stepTourId = -1;
  private stepTourAnchorId: string | undefined;

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly store$: Store<any>,
    private readonly loginService: LoginService,
    private readonly tourService: TourService,
    private readonly localStorageService: LocalStorageService,
    private readonly dialog: MatDialog
  ) {
    this.setRouterEventsListener();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.prevInnerWidth = this.innerWidth;
    this.innerWidth = window.innerWidth;

    if (this.prevInnerWidth !== this.innerWidth) {
      this.handleSideBarVisibility();
    }

    if (this.innerWidth < 900) {
      this.dashboardMenuTrigger?.closeMenu();
    }
  }

  ngOnInit() {
    this.checkUserIsLoggedIn();
    this.setNavBarLinks();

    this.setTourStep();

    this.innerWidth = window.innerWidth;
    this.prevInnerWidth = window.innerWidth;

    this.handleSideBarVisibility();

    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  checkUserIsLoggedIn(): void {
    this.isLoggedIn$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((_: boolean) => {
      this.store$.dispatch(new GetUserInfo());
      this.subscribeToNewUserNotifications();
      this.checkIfThereIsActiveNote();
      this.subscribeToReleaseSubscription();
      this.loadDashboards();
    });
  }

  trackByIndex(index: number, _: unknown): number {
    return index;
  }

  logout(): void {
    this.store$.dispatch(new SetUserInfo({ user: new User() }));
    this.authenticationService.logOut();
    this.clearLocalStorage();
    this.handleSideBarVisibility();
    this.closeMobileMenu();
    this.router.navigate(['/auth/login']);
  }

  navigateToHome(): void {
    this.handleSideBarVisibility();
    this.closeMobileMenu();
    this.router.navigate(['/']);
  }

  navigateTo(link: IAppLinksDTO): void {
    if (this.innerWidth < 900 && link.subMenu) {
      this.toggleSubmenu(link.label);
      return;
    }

    if (link.disabled) {
      return;
    }

    this.handleSideBarVisibility();
    this.closeMobileMenu();
    this.router.navigate(['/' + link.path]);

    if (this.stepTourId && this.stepTourAnchorId === link.anchorId) {
      this.tourService.startAt(this.stepTourId.toString());
    }
  }

  toggleSubmenu(menu: string): void {
    if (this.selectedMenu === menu) {
      this.selectedMenu = '';
      return;
    }

    this.selectedMenu = menu;
  }

  toggleSettingsSidebar(): void {
    this.store$.dispatch(ApplicationActions.toggleSettingsSidebar());
  }

  toggleMobileMenu(): void {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;
    this.mobileMenuOpen.emit(this.isMobileMenuOpened);
  }

  checkEnvironmentPermission(environments: Array<string> | any): boolean {
    return checkEnvironmentPermission(environments);
  }

  stopImpersonating(): void {
    this.localStorageService.clearImpersonateUserToken();
    location.replace(`${location.origin}/#/admin/users`);
    location.reload();
  }

  onCreateDashboard() {
    this.dialog.open(CreateDashboardModalComponent, { width: '500px', autoFocus: false });
  }

  openExternalLink(url: string): void {
    window.open(url, '_blank');
  }

  private setNavBarLinks(): void {
    this.userPermissions$
      .pipe(
        filter((userPermissions) => Boolean(userPermissions.length)),
        untilDestroyed(this)
      )
      .subscribe((userPermissions) => {
        this.navBarLinksUpdated = navBarLinks.map((navBarLink) => {
          if (navBarLink.label === 'home') {
            return navBarLink;
          }

          this.userPermissions = userPermissions;

          const allPermissions = userPermissions.filter((permission: JMPermissions) => navBarLink.permissions?.includes(permission));

          return {
            ...navBarLink,
            disabled: !allPermissions.length || !this.checkEnvironmentPermission(navBarLink?.environments),
          };
        });
      });
  }

  private setTourStep(): void {
    this.tourService.stepShow$.pipe(first(Boolean), untilDestroyed(this)).subscribe((stepChangeParams) => {
      const step = stepChangeParams.step;
      this.stepTourId = Number(step.stepId || 0) + 1;
      this.stepTourAnchorId = step.anchorId;
    });
  }

  private clearLocalStorage(): void {
    this.store$.dispatch(new Logout());
    localStorage.clear();
    this.store$.dispatch(new RequestBackendVersion());
  }

  private subscribeToNewUserNotifications(): void {
    this.loginService
      .fetchNewUserNotification()
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(({ data }) => {
        if (data && data.userNotification) {
          this.store$.dispatch(
            new ResponseScheduledNotifications({
              userNotification: {
                id: data?.userNotification?.id,
                message: data?.userNotification?.message,
              },
            })
          );
        }
      });
  }

  private subscribeToReleaseSubscription(): void {
    this.releaseNotesSubscriptionService
      .newReleaseNotes()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        if (!response) {
          return;
        }
        const note = new ReleaseNotes().loadModel(response);
        this.openReleaseNotesInfoDialog(note);
      });
  }

  private checkIfThereIsActiveNote(): void {
    this.releaseNotesService
      .getActive(true)
      .pipe(untilDestroyed(this))
      .subscribe((note) => {
        if (!note) {
          return;
        }
        this.openReleaseNotesInfoDialog(note);
      });
  }

  private handleSideBarVisibility(): void {
    if (this.innerWidth >= 900) {
      this.closeMobileMenu();
    }

    this.isSettingsSideBarOpened$.pipe(take(1), untilDestroyed(this)).subscribe((isSettingsSideBarOpened) => {
      if (isSettingsSideBarOpened && this.innerWidth < 1365) {
        this.store$.dispatch(ApplicationActions.closeSettingsSidebar());
        return;
      }

      if (!isSettingsSideBarOpened && this.innerWidth >= 1366) {
        this.store$.dispatch(ApplicationActions.showSettingsSidebar());
      }
    });
  }

  private closeMobileMenu(): void {
    this.isMobileMenuOpened = false;
    this.mobileMenuOpen.emit(this.isMobileMenuOpened);
  }

  private setRouterEventsListener(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.selectedMenu = '';
        this.closeMobileMenu();
      });
  }

  private openReleaseNotesInfoDialog(note: ReleaseNotes): void {
    const dialogRef = this.dialog.open(ReleaseNotesInfoDialogComponent, {
      data: note,
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(() => {
          if (!note.version) {
            return EMPTY;
          }
          return this.releaseNotesService.markReleaseNotesSeen(note.version);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private loadDashboards(): void {
    this.store$.dispatch(loadPublicDashboards());
    this.store$.dispatch(loadPrivateDashboards());
  }
}
