import pkg from 'package.json';
import { map, Observable, of, switchMap } from 'rxjs';

import { RequestBackendVersion, selectBackendVersion } from '@auth';
import { AuthenticationService } from '@auth';

import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { ILink } from '../../interfaces/link.interface';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotes, ReleaseNotesInfoDialogComponent, ReleaseNotesService } from '@release-notes';
import { isSet } from 'src/app/util/util';
import { SearchCriteriaDTO } from '@jm-table';
import { SortOrder } from 'src/app/util/search/sort-order.enum';

@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  private readonly authenticationService = inject(AuthenticationService);
  private readonly dialog = inject(MatDialog);
  private readonly releaseNotesService = inject(ReleaseNotesService);
  private readonly router = inject(Router);
  private readonly store$ = inject(Store);

  backendVersion$: Observable<string> = this.store$.select(selectBackendVersion);
  frontendVersion: string = pkg.version;
  isLogged = false;

  links: Array<ILink> = [
    {
      key: 'imprint',
      url: 'https://www.mueller-frick.com/en/imprint',
      targetBlank: true,
    },
    {
      key: 'contact',
      url: '/auth/support',
      targetBlank: false,
    },
  ];

  ngOnInit(): void {
    this.store$.dispatch(new RequestBackendVersion());
    this.handleLinks();
  }

  handleLinks(): void {
    this.authenticationService
      .isLoggedIn()
      .pipe(untilDestroyed(this))
      .subscribe((loggedIn: boolean) => {
        this.isLogged = loggedIn;
        if (!loggedIn) {
          this.links = this.links.filter((link: ILink) => link.key !== 'contact');
        }
      });
  }

  trackByIndex(index: number, _: unknown): number {
    return index;
  }

  navigateLink(link: ILink): void {
    if (link.targetBlank) {
      window.open(link.url);

      return;
    }

    this.router.navigate([link.url]);
  }

  openReleaseNotesDialog(): void {
    if (!this.isLogged) {
      return;
    }

    this.fetchAndHandleReleaseNotes()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (note: ReleaseNotes | null) => {
          if (!note) {
            return;
          }
          this.dialog.open(ReleaseNotesInfoDialogComponent, { data: note });
        },
      });
  }

  private fetchAndHandleReleaseNotes(): Observable<ReleaseNotes | null> {
    return this.releaseNotesService.getActive().pipe(
      switchMap((note: ReleaseNotes | null) => {
        if (!isSet(note)) {
          const searchCriteria: SearchCriteriaDTO = {
            pagination: {
              limit: 1,
              offset: 0,
            },
            sortBy: {
              columnName: 'endDate',
              direction: SortOrder.DESC,
            },
          };

          return this.releaseNotesService.getAll(searchCriteria).pipe(
            map((value) => {
              if (!isSet(value.records[0])) {
                return null;
              }

              return value.records[0];
            })
          );
        }

        return of(note);
      })
    );
  }
}
