import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { DowntimeByArticleDTO } from './interfaces';
import { DowntimeByArticle } from './models';

@Injectable({
  providedIn: 'root',
})
export class DowntimeByArticleService extends AbstractCRUDService<DowntimeByArticle, DowntimeByArticleDTO> {
  override model = DowntimeByArticle;

  getData(input: PerformanceInput): Observable<Array<DowntimeByArticle>> {
    const query = gql`
      query DowntimeByArticle($input: OEEInput!) {
        downtimeByArticle(input: $input) {
          articleGuid
          articleName
          duration
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<DowntimeByArticleDTO>>>) => {
          return response.data.downtimeByArticle.map((data) => this.loadModel(data));
        })
      );
  }
}
