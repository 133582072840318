import { Component, Input } from '@angular/core';

import { CellType } from '../../enum/cell-type.enum';
import { CellConfig } from '../../models/cells.config';

@Component({
  selector: 'jm-row-expansion',
  templateUrl: './jm-row-expansion.component.html',
  styles: `
  :host {
    width: 100%;
  }`,
})
export class JmRowExpansionComponent {
  @Input() column: CellConfig | null = null;
  @Input() rowData: any;

  readonly cellType = CellType;
}
