import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { QualityDTO } from './interfaces';
import { Quality } from './models';

@Injectable({
  providedIn: 'root',
})
export class QualityService extends AbstractCRUDService<Quality, QualityDTO> {
  getQuality(input: PerformanceInput): Observable<number> {
    const query = gql`
      query overallOEEQuality($input: OEEInput!) {
        overallOEEQuality(input: $input)
      }
    `;

    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response) => {
          return response.data['overallOEEQuality'];
        })
      );
  }
}
