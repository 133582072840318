<div class="background-home-page"></div>
<div class="auth-card">
  <div class="auth-card__wrapper" [ngClass]="{ loading }">
    <div class="auth-card__form form">
      <div class="intro" [ngClass]="titleClass">
        <div class="auth-card-welcome-message">
          <ng-content select="[title]"></ng-content>
        </div>
      </div>

      <ng-content *ngIf="!loading"></ng-content>
      <app-loader class="jm-loader" *ngIf="loading"></app-loader>
    </div>
    <div class="auth-card__ilustration">
      <img src="./assets/images/jm-white.svg" alt="jm-white" class="jm-white-logo" />
    </div>
  </div>
</div>
