import { ObjectLiteral } from '../object-literal';
import { DateTimeFormatStyle } from './format-style.enum';

const date = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const time = {
  hour: 'numeric',
  minute: 'numeric',
  hourCycle: 'h23',
};

const dateTime = {
  ...date,
  ...time,
};

const dateTimeWithSeconds = {
  ...dateTime,
  second: 'numeric',
};

const timeWithSeconds = {
  ...time,
  second: 'numeric',
};

export const dateStyleToOptionsMaper = new Map<DateTimeFormatStyle, ObjectLiteral>([
  [DateTimeFormatStyle.date, date],
  [DateTimeFormatStyle.dateTime, dateTime],
  [DateTimeFormatStyle.dateTimeWithSeconds, dateTimeWithSeconds],
  [DateTimeFormatStyle.time, time],
  [DateTimeFormatStyle.timeWithSeconds, timeWithSeconds],
]);
