import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sb-string-input',
  templateUrl: './sb-string-input.component.html',
  styleUrls: ['../input.scss'],
})
export class SbStringInputComponent {
  @ViewChild('inputField') inputField?: ElementRef;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() nonEditable = false;
  @Input() icon = '';
  @Input() label = '';
  @Input() emptyLabel = false;
  @Input() required = false;
  @Input() invalid = false;
  @Input() readonly = false;
  @Input() type = '';
  @Input() className = '';
  @Input() imageClassName = '';
  @Input() value: string | number | Date | null | undefined = null;
  @Input() errorMessage = '';
  @Input() maxLength: number | null = null;
  @Input() iconLeftPosition = false;
  @Input()
  set setFocusToInputField(flag: boolean) {
    if (flag) {
      setTimeout(() => {
        this.inputField?.nativeElement.focus();
      }, 10);
    }
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() setActive: EventEmitter<void> = new EventEmitter<void>();

  valueChange(param: string): void {
    this.onValueChange.emit(param);
  }

  clickInputWrapper(): void {
    if (this.readonly) {
      this.onClick.emit();
    }
    this.setActive.emit();
  }
}
