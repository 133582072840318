import { AlertConfiguration } from '../model';

export const notificationConfigurationStateName = 'popupConfiguration';

export const initialAlertConfiguration: AlertConfiguration = {
  machineSpeedAlertDelay: null,
  machineEfficiencyAlertDelay: null,
  articleSpeedAlertDelay: null,
  articleEfficiencyAlertDelay: null,
  locationEfficiencyAlertDelay: null,
  machineStoppedAlert: false,
  machineStoppedAlertReasons: [],
  orderRunoutAlert: null,
  muconAlertDelay: null,
};

export interface AlertConfigurationState {
  alertConfiguration: AlertConfiguration;
  alertConfigurationLoader: boolean;
  submittingLoading: boolean;
}

export const initialConfigurationState: AlertConfigurationState = {
  alertConfiguration: initialAlertConfiguration,
  alertConfigurationLoader: true,
  submittingLoading: false,
};

export interface NotificationConfigurationState {
  alertConfiguration: AlertConfigurationState;
}

export const initialNotificationConfigurationState: NotificationConfigurationState = {
  alertConfiguration: initialConfigurationState,
};
