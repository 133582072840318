<div class="performance-title">
  {{ title | translate }} <span class="measure-unit">({{ measureUnit }})</span>
</div>
<div class="performance-threshold-wrapper">
  <div class="input-value slider-box">
    <div *ngIf="showValueSign">{{ valueSign }}</div>
    <input [attr.id]="performanceTreshold?.poor" type="number" [(ngModel)]="type === 'stops' ? 100 - value : value" readonly />
  </div>
  <div class="slider slider-box">
    <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChangeEnd)="onUserChangeEnd($event)"></ngx-slider>
  </div>
  <div class="input-value slider-box">
    <div *ngIf="showHighValueSign">{{ highValueSign }}</div>
    <input [attr.id]="performanceTreshold?.good" type="number" [(ngModel)]="type === 'stops' ? 100 - highValue : highValue" readonly />
  </div>
</div>
