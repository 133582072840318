<div class="quick-form-control">
  <div class="input-wrapper number" [ngClass]="config.wrapperCssClass">
    <div class="number-header">
      <label class="label" *ngIf="config.label">{{ config.label | translate }} <span *ngIf="config?.validators?.required">*</span></label>
      <span class="description" *ngIf="config?.meta?.description">({{ config.meta?.description }})</span>
      <span class="description" *ngIf="config?.meta?.unit">({{ config.meta?.unit }})</span>
    </div>

    <input
      type="number"
      [id]="config.getDisplayId()"
      [name]="config.getDisplayId()"
      [(ngModel)]="value"
      placeholder="{{ config.placeholder | translate }}"
      [appControlAttributes]="config.attributes"
      (input)="onValueChanged()"
      (blur)="onBlur()"
      [disabled]="isDisabled || control.disabled"
    />
  </div>
  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
