<div class="dialog-wrapper" [ngClass]="config.className">
  <svg-icon class="close-icon" alt="close-icon" (click)="closeDialog()" *ngIf="!config.hideCloseIcon" [src]="'./assets/images/close.svg'">
  </svg-icon>
  <h1 class="title">{{ config.title }}</h1>

  <div class="message-wrapper" *ngIf="config.message">
    <p [innerHtml]="config.message"></p>
  </div>

  <div class="buttons" *ngIf="!config.button.hide">
    <jm-button *ngIf="config.button.confirmation as confirmation" [class]="'red-button'" (clicked)="onConfirmClick()">
      {{ confirmation | translate }}
    </jm-button>
    <jm-button *ngIf="config.button.cancel as cancel" [class]="'red-border-button'" (clicked)="closeDialog()">
      {{ cancel | translate }}
    </jm-button>
  </div>
</div>
