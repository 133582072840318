import { Model } from 'ngx-super-model';
import { secondsToHMS } from 'src/app/util/util';

import { DowntimeByArticleDTO } from '../interfaces';

export class DowntimeByArticle extends Model<DowntimeByArticleDTO> {
  articleGuid: string | null = null;
  articleName: string | null = null;
  duration: number | null = null;

  hours(): number {
    if (!this.duration) {
      return 0;
    }
    const hours = this.duration / 3600;
    return hours;
  }

  secondsToHMS(): string {
    return secondsToHMS(this.duration);
  }
}
