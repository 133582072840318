import 'chartjs-adapter-moment';

import { ChartConfiguration } from 'chart.js';

import { commonZoomConfig } from '../core/plugins/zoom.plugin';

export const chartConfig: ChartConfiguration = {
  type: 'line',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
      intersect: false,
    },
    parsing: {
      xAxisKey: 'timestamp',
      yAxisKey: 'value',
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataIndex } = context;
            const {
              myCustomData: { unit },
              data,
            } = context.dataset as any;

            return `${data[dataIndex].value} ${unit}`;
          },
        },
      },
      zoom: {
        ...commonZoomConfig,
      },
    },
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'DD/MM/YYYY HH:mm:ss',
          displayFormats: {
            second: 'DD/MM/YYYY HH:mm:ss',
            minute: 'DD/MM/YYYY HH:mm',
            hour: 'DD/MM/YYYY HH:mm',
            day: 'DD/MM/YYYY',
            month: 'MM/YYYY',
            year: 'YYYY',
          },
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: '',
          font: {
            size: 10,
            weight: 'bold',
          },
        },
      },
    },
  },
  plugins: [
    {
      id: 'customLegend',
      afterUpdate(chart: any) {
        chart.legend.legendItems.forEach(function (legendItem: any) {
          legendItem.lineDash = [];
          legendItem.strokeStyle = 'rgba(0,0,0,0)';
        });
      },
    },
  ],
};
