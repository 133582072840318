import { LocationService } from '@locations';
import { MachineService, MachineStopService } from '@machines';
import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

import { WidgetTypeEnum } from '../core/enums/widget-type.enum';

export const averageStopsFormConfig: Array<ControlConfigDTO> = [
  {
    id: 'id',
    type: ControlTypeEnum.Hidden,
  },
  {
    id: 'name',
    label: 'widgets.controls.widget-name',
    type: ControlTypeEnum.InputText,
    value: 'Average stops',
    validators: {
      required: true,
      maxLength: 30,
    },
  },
  {
    id: 'type',
    type: ControlTypeEnum.Hidden,
    value: WidgetTypeEnum.AverageStops,
  },
  {
    id: 'location',
    label: 'dashboards.labels.location',
    type: ControlTypeEnum.Dropdown,
    placeholder: 'dashboards.labels.location',
    validators: {
      required: true,
    },
    meta: {
      valueAsObject: true,
      virtualScroll: true,
      lazyLoad: true,
      lookupService: LocationService,
    },
  },
  {
    id: 'machine',
    label: 'machines.title',
    type: ControlTypeEnum.Dropdown,
    placeholder: 'machines.title',
    validators: {
      required: true,
    },
    meta: {
      valueAsObject: true,
      dependentOn: {
        controlId: 'location',
        filterBy: 'locationId',
      },
      virtualScroll: true,
      lazyLoad: true,
      lookupService: MachineService,
    },
  },
  {
    id: 'stopReasons',
    label: 'machines.machine-status',
    type: ControlTypeEnum.Multiselect,
    placeholder: 'machines.machine-status',
    validators: {
      required: true,
    },
    meta: {
      dependentOn: {
        controlId: 'machine',
        filterBy: 'machineGuid',
      },
      virtualScroll: true,
      lazyLoad: true,
      lookupService: MachineStopService,
    },
  },
];
