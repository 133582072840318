import { Model } from 'ngx-super-model';
import { LicenseDTO } from 'src/app/core/license/interfaces/licenses.interface';
import { UserDTO } from 'src/app/core/user/interfaces/user.interface';

import { LocationDTO } from '@locations';

import { CompanyDTO } from '../interfaces/company.interface';

export class Company extends Model<CompanyDTO> {
  id: number | null = null;
  name: string | null = null;
  locations: Array<LocationDTO> = [];
  licenses: Array<LicenseDTO> = [];
  noUsers: number | null = null;
  users: Array<UserDTO> | null = null;
  admins: Array<UserDTO> | null = null;
  createdAt: string | null = null;
  updatedAt: string | null = null;
  noLocations: number | null = null;
  oxyonId: number | null = null;
}
