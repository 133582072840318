import { Model } from 'ngx-super-model';

import { ProductionDetailsDTO } from '../interfaces';

export class ProductionDetails extends Model<ProductionDetailsDTO> {
  machineGuid: string | null = null;
  machineName: string | null = null;
  machineAlias: string | null = null;
  articleGuid: string | null = null;
  articleName: string | null = null;
  machineTypeId: string | null = null;
  machineTypeName: string | null = null;
  lastFinished: string | null = null;
  stopStatus: string | null = null;
  downtime: number | null = null;
}
