import { ObserveResizeService } from 'angular-container-media-query';
import { filter } from 'rxjs/operators';
import { deleteWidget, updateWidget } from 'src/app/core/custom-dashboard/store/dashboard.actions';
import { selectDashboardMode } from 'src/app/core/custom-dashboard/store/dashboard.selector';
import { DashboardMode } from 'src/app/core/custom-dashboard/store/dashboard.state';
import { zoomChanged } from 'src/app/core/widgets/store/dashboard.actions';
import { DialogComponent } from 'src/app/layout';
import { DialogConfigDTO } from 'src/app/layout/dialog/dialog.interface';
import { IAppLinksDTO } from 'src/app/layout/navbar/navbar.model';
import { FormModeEnum } from 'src/app/modules/quick-forms/enums/form-mode';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { ZoomActionEnum } from '../../../enums/zoom-action.enum';
import { WidgetFactory } from '../../../factories/widget.factory';
import { WidgetDTO } from '../../../interfaces/widget.interface';
import { Widget } from '../../../models/widgets/widget.model';
import { AbstractResizableComponent } from '../../../ui/abstract-resizeable.component';
import { WidgetFormModalComponent } from '../../../ui/add-widget-modal/widget-form-modal.component';

@UntilDestroy()
@Component({
  selector: 'widget-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends AbstractResizableComponent implements OnInit {
  @ViewChild('optionMenuTrigger') optionMenuTrigger?: MatMenuTrigger;

  @Input()
  widget: Widget = new Widget();

  @Input()
  isLive = false;

  @Output()
  download = new EventEmitter<void>();

  menuOptions: Array<IAppLinksDTO> = [
    {
      label: 'edit',
      icon: 'edit.svg',
      disabled: false,
    },
    {
      label: 'delete',
      icon: 'bin.svg',
      disabled: false,
    },
  ];

  dashboardMode: DashboardMode = 'view';

  constructor(
    resize: ObserveResizeService,
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly store$: Store
  ) {
    super(resize, elementRef, changeDetector);
  }

  ngOnInit(): void {
    this.loadDashboardMode();
  }

  loadDashboardMode(): void {
    this.store$
      .select(selectDashboardMode)
      .pipe(untilDestroyed(this))
      .subscribe((mode: DashboardMode) => {
        this.dashboardMode = mode;
      });
  }

  onMenuAction(event: any, action: any): void {
    event.stopPropagation();

    this.optionMenuTrigger?.closeMenu();

    if (action.label === 'delete') {
      this.delete();
      return;
    }

    if (action.label === 'edit') {
      this.edit();
      return;
    }
  }

  delete(): void {
    const dialogConfig: DialogConfigDTO = {
      title: this.translateService.instant('widgets.actions.deleteConfirmTitle'),
      message: this.translateService.instant('widgets.actions.deleteConfirmMessage') + '?',
    };

    const dialogRef = this.dialog.open(DialogComponent, {
      data: dialogConfig,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.store$.dispatch(deleteWidget({ id: this.widget.id }));
      });
  }

  edit() {
    const dialogRef = this.dialog.open(WidgetFormModalComponent, {
      width: '1200px',
      autoFocus: false,
      data: {
        formMode: FormModeEnum.edit,
        widget: this.widget,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((widgetForm: ObjectLiteral) => {
        if (!widgetForm) {
          return;
        }

        const { id, name, type, ...config } = widgetForm;

        const widgetDTO: WidgetDTO = {
          id,
          name,
          type,
          config,
        };

        const widget = WidgetFactory.fromObjectLiteral(widgetDTO);

        this.store$.dispatch(updateWidget({ widget }));
      });
  }

  onZoomIn(): void {
    this.store$.dispatch(zoomChanged({ widgetId: this.widget.id, action: ZoomActionEnum.in }));
  }

  onZoomOut(): void {
    this.store$.dispatch(zoomChanged({ widgetId: this.widget.id, action: ZoomActionEnum.out }));
  }

  isZoomEnabled(): boolean {
    return this.widget.zoom.enabled;
  }
}
