import { AngularSvgIconModule } from 'angular-svg-icon';
import { downloadAFile } from 'src/app/util/util';
import { environment } from 'src/environments/environment';

import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JmButtonComponent } from '@app-ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { ReleaseNotes } from '../../models/release-notes.model';

@Component({
  selector: 'app-release-notes-info-dialog',
  standalone: true,
  imports: [AngularSvgIconModule, JmButtonComponent, TranslateModule],
  templateUrl: './release-notes-info-dialog.component.html',
  styleUrls: ['./release-notes-info-dialog.component.scss'],
})
export class ReleaseNotesInfoDialogComponent {
  private readonly dialogRef = inject(MatDialogRef<ReleaseNotesInfoDialogComponent>);
  private readonly router = inject(Router);
  private readonly translateService = inject(TranslateService);

  note: ReleaseNotes = new ReleaseNotes();

  get title(): string {
    return `${this.translateService.instant('admin.links.release-notes')} ${this.note.version}`;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: ReleaseNotes) {
    this.note = data;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  download(): void {
    const link = `${environment.rootUrl}${this.note.url}`;
    downloadAFile(link, this.title, 'pdf');
  }

  goToVersionHistory(): void {
    this.router.navigateByUrl('release-notes');
    this.closeDialog();
  }
}
