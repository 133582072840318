import { Routes } from '@angular/router';

import { PublicReleaseNotesComponent } from '../components/public-release-notes/public-release-notes.component';
import { JMPermissions, PermissionGuard } from '@auth';

export const PUBLIC_RELEASE_NOTES_ROUTES: Routes = [
  {
    path: '',
    component: PublicReleaseNotesComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('../components/table/release-notes-table.component').then((c) => c.ReleaseNotesTableComponent),
      },
      {
        path: 'new',
        canActivate: [PermissionGuard],
        data: {
          authorities: [JMPermissions.ReleaseNotesManageAny],
        },
        loadComponent: () => import('../components/details/release-notes-details.component').then((c) => c.ReleaseNotesDetailsComponent),
      },
      {
        path: ':version',
        loadComponent: () => import('../components/details/release-notes-details.component').then((c) => c.ReleaseNotesDetailsComponent),
      },
    ],
  },
];
