<div class="jm-column-filter">
  <form class="quick-form form">
    <div class="form__fieldset form__input filter-dropdown">
      <quick-form-dropdown
        [config]="dropdownConfig[0]"
        [control]="getControl(dropdownConfig[0].id)"
        [formControl]="getControl(dropdownConfig[0].id)"
        (valueChanged)="onValueChanged($event)"
        (focused)="onFocusToggle($event)"
      />
    </div>
  </form>
</div>
<div class="action-wrapper">
  <jm-button [disabled]="!isValueSet(value)" [class]="'red-border-button'" (clicked)="clear()">
    {{ 'button.clear' | translate }}
  </jm-button>
  <jm-button [disabled]="!isValueSet(value)" [class]="'red-button'" (clicked)="apply()">
    {{ 'button.apply' | translate }}
  </jm-button>
</div>
