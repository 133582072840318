import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AbstractCRUDService } from 'src/app/core/abstract-crud.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetAllOptionsDTO } from '@jm-table';
import { ActiveLicenses } from '@licenses';
import { camelCaseToWords } from '@util';

import { SearchCriteriaDTO } from '../../modules/jm-table/interfaces/search-criteria.interface';
import { LookupResult, LookupService } from '../../modules/quick-forms';
import { ResponseData } from '../abstract/interface/response-data';
import { listItemsToLookupResult } from '../helpers/lookup-mapper.helper';
import activeLicensesQuery from './gql/active-licenses.query.graphql';
import deleteMutation from './gql/delete.graphql';
import oneQuery from './gql/one.graphql';
import getAll from './gql/get-all.graphql';
import { CompanyDTO } from './interfaces/company.interface';
import { Company } from './models/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends AbstractCRUDService<Company, CompanyDTO> implements LookupService {
  override modelName = 'company';
  override modelNamePlural = 'companies';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  override model = Company;

  override readonly fields: string = `
    id
    name
    noUsers
    locations {
      id
      address
      name
      lat
      lon
    }
    users {
      id
      name
      email
    }
    admins {
      id
      name
      email
    }
    noLocations
    oxyonId
  `;

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<Company>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<CompanyDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<CompanyDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override create(model: any, options?: any): Observable<Company> {
    const mutation = gql`
      ${this.createQuery}
    `;

    return this.apollo.mutate<CompanyDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('companies.error.create');
      }),
      tap(() => {
        this.snackBarService.success('companies.success.create');
      }),
      map((response: ApolloQueryResult<{ addCompany: CompanyDTO }>) => {
        return this.loadModel(response.data['addCompany']);
      })
    );
  }

  override update(model: any, options?: any): Observable<any> {
    const mutation = gql`
      ${this.updateQuery}
    `;

    return this.apollo.mutate<CompanyDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('companies.error.update');
      }),
      tap(() => {
        this.snackBarService.success('companies.success.update');
      }),
      map((response: ApolloQueryResult<{ updateCompany: CompanyDTO }>) => {
        const mutation = `updateCompany`;
        return response.data[mutation];
      })
    );
  }

  protected override get updateQuery(): string {
    return `
     mutation updateCompany($id: Int!, $name: String!, $locations: [CompanyLocationInput!], $oxyonId: Int) {
        updateCompany(id: $id, name: $name, locations: $locations, oxyonId: $oxyonId) {
          ${this.fields}
        }
      }
    `;
  }

  protected override get createQuery(): string {
    return `
    mutation addCompany($name: String!, $locations: [CompanyLocationInput!], $oxyonId: Int) {
      addCompany(name: $name, locations: $locations, oxyonId: $oxyonId) {
        ${this.fields}
      }
    }
    `;
  }

  getSuggestions(searchCriteria: SearchCriteriaDTO): Observable<ListResultDTO<LookupResult>> {
    // ToDo
    // remove this when backend implement this feature
    delete searchCriteria.filterBy;
    return this.getAll(searchCriteria).pipe(
      map((value) => {
        return {
          records: listItemsToLookupResult(value.records),
          pageDetails: value.pageDetails,
        };
      })
    );
  }

  getActiveLicenses(companyId: number): Observable<ActiveLicenses> {
    return this.apollo.query({ query: activeLicensesQuery, variables: { companyId } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('companies.error.get-active-licenses');
      }),
      map((response: ApolloQueryResult<ResponseData<CompanyDTO>>) => {
        return response.data.company.activeLicenses || [];
      })
    );
  }
}
