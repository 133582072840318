<div class="configuration-dialog-wrapper" [ngClass]="{ 'machine-stopped-alert': fieldName === 'machineStoppedAlert' }">
  <svg-icon class="close-icon" alt="close-icon" (click)="closeDialog()" [src]="'./assets/images/close.svg'"> </svg-icon>
  <h1 class="title">{{ 'notifications.table-rows.' + fieldName | translate }}</h1>
  <ng-container *ngIf="fieldName !== 'machineStoppedAlert'">
    <ng-container *ngIf="type === 'number'">
      <p class="input-label">{{ 'notifications.minutes-before-triggering' | translate }}</p>
      <app-sb-number-input [hideArrows]="true" [value]="value" (onValueChange)="setValue($event)"></app-sb-number-input>
    </ng-container>
    <div class="checked-wrapper" *ngIf="innerWidth < 768">
      <p class="checked-title">{{ 'global.enable-disable' | translate }}</p>
      <app-sb-slide-toggle [value]="checked" (toggleChange)="toggleAlertConfiguration($event)"></app-sb-slide-toggle>
    </div>
  </ng-container>
  <ng-container *ngIf="fieldName === 'machineStoppedAlert'">
    <div class="form__fieldset stop-reason">
      <label class="label">
        {{ 'dashboards.labels.stop-reason' | translate }}
      </label>
      <div class="stop-reason-wrapper">
        <div class="reason" *ngFor="let reason of stopReasons">
          <app-sb-checkbox
            [checked]="valueArray.includes(reason.value) || false"
            [label]="reason.label"
            (onChangeCheckboxValue)="changeCheckboxValue($event, reason)"
          ></app-sb-checkbox>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="buttons">
    <jm-button [class]="'red-button'" (clicked)="onConfirmClick()">
      {{ 'button.apply' | translate }}
    </jm-button>
    <jm-button [class]="'red-border-button'" (clicked)="closeDialog()">
      {{ 'button.cancel' | translate }}
    </jm-button>
  </div>
</div>
