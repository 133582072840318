import { GridsterItem } from 'angular-gridster2';
import { gql } from 'apollo-angular';
import { upperFirst } from 'lodash-es';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { User, UserDTO } from '@user';

import { EntityNotFound } from '../../errors/not-found.error';
import { AbstractCRUDService } from '../abstract-crud.service';
import { ResponseData } from '../abstract/interface/response-data';
import { WidgetTypeEnum } from '../widgets/core';
import deleteMutation from './gql/delete.graphql';
import oneQuery from './gql/one.graphql';
import dashboardShareableWithUsers from './gql/shareable-users.graphql';
import updateDashboardShares from './gql/update-dashboard-share.graphql';
import { applyGlobalConfig } from './helpers/widget-config.helper';
import { DashboardCreateDTO } from './interfaces/create-dashboard.interface';
import { DashboardDTO } from './interfaces/dashboard.interface';
import { ShareDashboardRequestDTO } from './interfaces/input-share-dashboard.interface';
import { UsersWithAccessDTO } from './interfaces/users-with-access.interface';
import { WidgetConfigLimitsDTO } from './interfaces/widget-config-limits.interface';
import { Dashboard } from './model/dashboard.model';
import getAll from './gql/getAll.graphql';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends AbstractCRUDService<Dashboard, DashboardDTO> {
  override modelName = 'dashboard';
  override modelNamePlural = 'dashboards';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  private widgetsConfigLimits: WidgetConfigLimitsDTO = {};

  override model = Dashboard;

  override readonly fields: string = `
      guid
      name
      widgets
      createdAt
      updatedAt
      user {
        id
        name
        email
      }
      usersWithAccess {
        dashboardUserRole
        user {
          name
          id
          email
        }
        dashboard {
          guid
          name
        }
      }
      dashboardUserRole
  `;

  override getAll(): Observable<ListResultDTO<Dashboard>> {
    return this.apollo.query<{ dashboards: ListResultDTO<DashboardDTO> }>({ query: getAll }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: this.modelNamePlural }));
      }),
      map((response: ApolloQueryResult<{ dashboards: ListResultDTO<DashboardDTO> }>) => {
        const records = response.data.dashboards.records.map(this.loadModel);

        return {
          records,
          pageDetails: null,
        };
      })
    );
  }

  override getByGuid(guid: string, options?: ObjectLiteral): Observable<Dashboard> {
    const query = this.oneQuery;

    return this.apollo.query<ListResultDTO<DashboardDTO>>({ query, variables: { guid, ...options } }).pipe(
      catchAllErrors((error) => {
        if (!(error instanceof EntityNotFound)) {
          this.snackBarService.error('dashboard.error.get-one');
        }
      }),
      map((response: ApolloQueryResult<{ dashboard: DashboardDTO; widgetConfigLimits: WidgetConfigLimitsDTO }>) => {
        const { dashboard, widgetConfigLimits } = response.data;
        this.widgetsConfigLimits = widgetConfigLimits;
        dashboard.widgets = applyGlobalConfig(dashboard.widgets, widgetConfigLimits);
        return this.loadModel(response.data.dashboard);
      })
    );
  }

  override create(dashboard: DashboardCreateDTO): Observable<Dashboard> {
    const mutation = gql`
      ${this.createQuery}
    `;

    const modelName = upperFirst(this.modelName);

    return this.apollo.mutate<DashboardDTO>({ mutation, variables: { input: dashboard } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('dashboard.error.create');
      }),
      tap(() => {
        this.snackBarService.success('dashboard.success.create');
      }),
      map((response: ApolloQueryResult<ResponseData<DashboardDTO>>) => {
        const mutation = `create${modelName}`;
        return this.loadModel(response.data[mutation]);
      })
    );
  }

  override update(dashboard: Dashboard, options?: any): Observable<Dashboard> {
    const mutation = gql`
      ${this.updateQuery}
    `;

    const modelName = upperFirst(this.modelName);

    const dashboardForSave = dashboard.forSave();

    return this.apollo.mutate<DashboardDTO>({ mutation, variables: { input: dashboardForSave, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('dashboard.error.update');
      }),
      tap(() => {
        this.snackBarService.success('dashboard.success.update');
      }),
      map((response: ApolloQueryResult<ResponseData<DashboardDTO>>) => {
        const mutation = `update${modelName}`;
        return this.loadModel(response.data[mutation]);
      })
    );
  }

  getSharedDashboard(): Observable<ListResultDTO<Dashboard>> {
    const query = gql`
      query sharedDashboards {
        sharedDashboards {
          records {
            ${this.fields}
          }
          ${this.pageDetails}
        }
      }
    `;

    return this.apollo.query<ListResultDTO<DashboardDTO>>({ query, fetchPolicy: 'no-cache' }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('dashboard.error.get-all');
      }),
      map((response: ApolloQueryResult<{ sharedDashboards: ListResultDTO<DashboardDTO> }>) => {
        const records = response.data['sharedDashboards'].records.map(this.loadModel);
        return {
          ...response.data['sharedDashboards'],
          records,
        };
      })
    );
  }

  updateDashboardShares(input: ShareDashboardRequestDTO): Observable<Array<UsersWithAccessDTO>> {
    return this.apollo.mutate<DashboardDTO>({ mutation: updateDashboardShares, variables: { input } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('dashboard.error.change-role');
      }),
      map((response: ApolloQueryResult<ResponseData<Array<UsersWithAccessDTO>>>) => response.data.updateDashboardShares)
    );
  }

  getWidgetConfigLimits(widgetType: WidgetTypeEnum): GridsterItem | null {
    return this.widgetsConfigLimits[widgetType] || null;
  }

  getShareableUsers(guid: string): Observable<ListResultDTO<User>> {
    return this.apollo.query({ query: dashboardShareableWithUsers, variables: { guid } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('dashboard.shareable-users.error.get-all'));
      }),
      map((response: ApolloQueryResult<{ dashboardShareableWithUsers: Array<UserDTO> }>) => {
        const records = response.data.dashboardShareableWithUsers.map((user) => new User().loadModel(user));

        return {
          records,
          pageDetails: null,
        };
      })
    );
  }
}
