import { Component } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';
import { get } from 'lodash';

@Component({
  selector: 'td[jm-date-time-cell]',
  templateUrl: './jm-date-time-cell.component.html',
})
export class JmDateTimeCellComponent extends AbstractCellComponent {
  get cellDataValue(): string {
    return get(this.cellData, this.columnField);
  }
}
