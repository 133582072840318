export enum CHART_COLORS {
  babyBlue = '#00aaee',
  blue = '#0685C5',
  darkBlue = '#002266',
  darkGrey = '#929EA7',
  darkYellow = '#F9B022',
  grey = '#C3CED6',
  green = '#80c953',
  orange = '#EB9D27',
  red = '#ef4123',
  yellow = '#f6ca30',
}
