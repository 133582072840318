import { AngularSvgIconModule } from 'angular-svg-icon';
import { DialogComponent } from 'src/app/layout';
import { DialogConfigDTO } from 'src/app/layout/dialog/dialog.interface';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { LoaderComponent } from '@app-ui';
import { ProgressBarComponent } from 'src/app/shared/modules/progress-bar/progress-bar.component';
import { ProgressBar } from 'src/app/shared/modules/progress-bar/progress-bar.interface';
import { PercentagePipe } from '@util';
import { getColorByValueForPercentage } from 'src/app/util/util';

import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { QualityDTO } from './interfaces';

const imports = [
  CommonModule,
  LoaderComponent,
  ProgressBarComponent,
  BoxModule,
  TranslateModule,
  PercentagePipe,
  AngularSvgIconModule,
  DialogComponent,
  MatDialogModule,
  EmptyBlockModule,
];

@Component({
  selector: 'widget-quality',
  standalone: true,
  imports: imports,
  templateUrl: './quality.component.html',
  styleUrls: ['./quality.component.scss'],
})
export class QualityComponent {
  private readonly dialog = inject(MatDialog);
  private readonly translateService = inject(TranslateService);

  @Input() loader = false;
  @Input() data: QualityDTO | null = null;
  @Input()
  error: string | null = null;

  get valueInPercentage(): number {
    const value = this.data?.value || 0;
    return value * 100;
  }

  get progressBar(): ProgressBar {
    return {
      type: 'secondary',
      value: this.valueInPercentage,
      class: getColorByValueForPercentage(this.valueInPercentage),
      striped: false,
      animate: false,
    };
  }

  onIconClick(): void {
    const dialogConfig: DialogConfigDTO = {
      title: this.translateService.instant('performance-dashboard.quality.title'),
      message: this.translateService.instant('performance-dashboard.quality.description'),
      hideCloseIcon: true,
      className: ['left-align'],
      button: {
        cancel: this.translateService.instant('actions.close'),
      },
    };

    this.dialog.open(DialogComponent, {
      data: dialogConfig,
      width: '700px',
    });
  }
}
