import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[afterValueChanged]',
})
export class AfterValueChangedDirective {
  @Input() valueChangeDelay = 5;

  @Output() afterValueChanged: EventEmitter<any> = new EventEmitter<any>();

  // eslint-disable-next-line rxjs/finnish
  private readonly stream: Subject<any> = new Subject<any>();

  constructor() {
    this.stream
      .pipe(debounceTime(this.valueChangeDelay), untilDestroyed(this))
      .subscribe((value: any) => this.afterValueChanged.next(value));
  }

  @HostListener('input', ['$event'])
  onValueChange(value: any): void {
    this.stream.next(value);
  }
}
