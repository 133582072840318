import { OperatorEnum } from 'src/app/util/operator.enum';
import { JMPermissions } from '../enums/permission.enum';

export function checkPermissions(
  userPermissions: Array<JMPermissions>,
  requiredPermissions: Array<JMPermissions>,
  operator: OperatorEnum = OperatorEnum.AND
): boolean {
  if (operator === OperatorEnum.OR) {
    return requiredPermissions.some((permission) => userPermissions?.includes(permission));
  }

  return requiredPermissions.every((permission) => userPermissions?.includes(permission));
}
