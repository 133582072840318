import { Model } from 'ngx-super-model';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { WidgetTypeEnum } from '../../enums/widget-type.enum';
import { WidgetMetaDTO } from '../../interfaces/widget-meta.interface';
import { WidgetDTO } from '../../interfaces/widget.interface';
import { GridsterConfig } from '../gridster-config.model';

export class Widget extends Model<WidgetDTO> {
  id = '';
  name = '';
  type: WidgetTypeEnum | null = null;
  gridsterConfig = new GridsterConfig();
  config: ObjectLiteral = {};
  meta?: WidgetMetaDTO = {};
  actions: {
    edit?: [];
    view?: [];
  } = {};

  zoom = {
    enabled: false,
  };

  live?: boolean;
  download?: boolean;

  override loadModel(input: WidgetDTO): this {
    const { gridsterConfig, ...widgetConfig } = input;

    super.loadModel(widgetConfig);

    if (gridsterConfig) {
      this.gridsterConfig.loadModel(gridsterConfig);
    }

    return this;
  }
}
