import { TutorialComponent } from 'src/app/layout';
import { AuthGuard, GuestGuard } from '@auth';

import { Routes } from '@angular/router';

import { LoginComponent, PasswordResetComponent, UserProfileComponent } from './components';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CallbackComponent } from './mymueller-sso/callback/callback.component';
import { SSOGuard } from './mymueller-sso/guard/sso.guard.service';
import { FeedbackFormComponent } from 'src/app/core/feedback/feedback-form/feedback-form.component';
import { MainComponent } from '../main/main.component';

export const authRoutes: Routes = [
  {
    path: 'auth',
    component: MainComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'password-reset',
        component: PasswordResetComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'activate/:token',
        component: ActivateAccountComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'password-reset/:token',
        component: PasswordResetComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'mymueller-sso/callback',
        component: CallbackComponent,
        canActivate: [SSOGuard],
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      { path: 'tutorials', component: TutorialComponent, canActivate: [AuthGuard] },
      { path: 'support', component: FeedbackFormComponent, canActivate: [AuthGuard] },
    ],
  },
];
