import { Component, HostListener, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { PushNotificationsService } from '@push-notifications';

import { GetUserInfo } from './auth/store/login.actions';
import { ReloadDialogComponent } from './layout/reload-dialog/reload-dialog.component';
import { DeviceService } from './modules/device/device.service';
import { isNewVersion } from './util/pwa';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  private readonly deviceService = inject(DeviceService);
  private readonly pushNotificationsService = inject(PushNotificationsService);
  private readonly store$ = inject(Store);
  private readonly swUpdateService = inject(SwUpdate);
  private readonly dialog = inject(MatDialog);

  constructor() {
    this.initPushNotifications();
    this.watchServiceWorkerUpdate();
  }

  watchServiceWorkerUpdate(): void {
    this.swUpdateService.versionUpdates.subscribe((event: VersionEvent) => {
      if (event.type === 'VERSION_READY') {
        if (!isNewVersion(event)) {
          return;
        }

        this.dialog.open(ReloadDialogComponent, {
          data: {
            message: 'reload-page.message',
          },
        });
      }
    });
  }

  ngOnInit(): void {
    this.deviceService.updateDeviceInfo(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { currentTarget: { innerWidth: any } }) {
    this.deviceService.updateDeviceInfo(event.currentTarget.innerWidth);
  }

  initPushNotifications(): void {
    this.pushNotificationsService.init().subscribe(() => {
      this.store$.dispatch(new GetUserInfo());
    });
  }
}
