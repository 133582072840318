import { uniq } from 'lodash-es';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { SensorConfigurationDTO } from '@sensor';
import { roundToDecimals } from '@util';
import { flattenDeep } from 'lodash-es';

import { HistoricalReadingsDTO } from '../interfaces/historical-sensor.interface';

export function mapYAxisValuesToKeys(
  sensorConfigurations: Array<SensorConfigurationDTO>,
  readings: Array<HistoricalReadingsDTO>
): ObjectLiteral {
  const sensorConfigurationKeys = uniq(sensorConfigurations.map((value) => value.unit));
  const sensorValues = readings.map((reading) => reading.values.map((value) => value.value));

  const flattenedSensorValues = flattenDeep(sensorValues);

  const yKeys: ObjectLiteral = {};

  sensorConfigurationKeys?.forEach((configKey, index) => {
    const yAxisKey = `y${index !== 0 ? index + 1 : ''}`;

    yKeys[yAxisKey] = {
      type: 'linear',
      display: true,
      position: index === 0 ? 'left' : 'right',
      title: {
        display: true,
        text: configKey,
        font: {
          size: 12,
        },
        ticks: {
          color: 'transparent',
        },
      },
      suggestedMin: Math.min(...flattenedSensorValues),
      suggestedMax: Math.max(...flattenedSensorValues),
    };
  });

  return yKeys;
}

export function mapSensorConfiguration(sensorConfigurations: Array<SensorConfigurationDTO>): Array<SensorConfigurationDTO> {
  const sensorConfigurationUnitKeys = uniq(sensorConfigurations.map((value) => value.unit));

  return sensorConfigurations.map((config) => {
    const keyIndex = sensorConfigurationUnitKeys.findIndex((key) => config.unit === key);

    return {
      ...config,
      yAxisKey: `y${keyIndex !== 0 ? keyIndex + 1 : ''}`,
    };
  });
}

export function dataToHistoricalSensorChart(
  readings: Array<HistoricalReadingsDTO>,
  sensorConfigurations: Array<SensorConfigurationDTO>
): ObjectLiteral {
  const sensorConfiguredIds = sensorConfigurations.map((configuration) => configuration.sensorId);
  const datasets = readings
    .filter((value) => sensorConfiguredIds.includes(value.sensorId))
    .map((value) => ({
      data: value.values.map((data) => {
        return {
          ...data,
          value: roundToDecimals(data.value, 2),
        };
      }),
      pointRadius: 0,
      ...getYAxisConfiguration(value, sensorConfigurations),
    }));

  return {
    datasets,
  };
}

function getYAxisConfiguration(value: HistoricalReadingsDTO, sensorConfigurations: Array<SensorConfigurationDTO>): ObjectLiteral {
  const sensorConfig = sensorConfigurations.find((sensorConfiguration) => sensorConfiguration.sensorId === value.sensorId);

  const baseConfig = {
    label: sensorConfig?.label,
    yAxisID: sensorConfig?.yAxisKey,
    myCustomData: {
      unit: sensorConfig?.unit,
    },
  };

  if (sensorConfig?.yAxisKey !== 'y') {
    return {
      ...baseConfig,
      borderDash: [5, 5],
    };
  }

  return baseConfig;
}
