import { Model } from 'ngx-super-model';
import { CompanyDTO } from 'src/app/core/company/interfaces/company.interface';
import { MachineDTO } from 'src/app/core/machines/interfaces/machine.interface.model';
import { IAccessKey, LocationStatusEnum } from 'src/app/main/admin/models';

import { LocationDTO } from '../interfaces';
import { HolidayDTO } from '../interfaces/holidays';
import { WorkingHoursDTO } from '../interfaces/working-hours';

export class Location extends Model<LocationDTO> {
  id?: number | null = null;
  company?: CompanyDTO | null = null;
  lat?: number | null = null;
  lon?: number | null = null;
  address?: string | null = null;
  status?: LocationStatusEnum | null = null;
  name: string | null = null;
  noActiveMachines?: number | null = null;
  timezone?: string | null = null;
  accessKeys?: Array<IAccessKey> = [];
  machines?: Array<MachineDTO> = [];
  lastHeartbeat?: string | null = null;
  companyName?: string | null = null;
  createdAt?: string | null = null;
  isActive?: boolean | null = null;
  companyId?: string | null = null;
  noUsers?: number | null = null;
  lastHeartbeatIpAddress?: string | null = null;
  lastHeartbeatMconVersion?: string | null = null;
  energyPricePerKwh?: number | null = null;
  currencyCode?: string | null = null;
  co2GramsPerKwh?: number | null = null;
  workingHours: Array<WorkingHoursDTO> = [];
  holidays: Array<HolidayDTO> = [];
}
