import { isString } from 'lodash-es';
import { Model } from 'ngx-super-model';
import { UserDTO } from 'src/app/core/user/interfaces/user.interface';

import { ArticleContentDTO } from '../interfaces/article-content.interface';
import { ContentData } from './content-data.model';

export class ArticleContent extends Model<ArticleContentDTO> {
  id: number | null = null;
  createdBy?: UserDTO;
  createdAt? = '';
  data: ContentData = new ContentData();
  version: number | null = null;

  override loadModel(input: ArticleContentDTO): this {
    super.loadModel(input);

    if (isString(input.data)) {
      const data = JSON.parse(input.data as string);
      this.data = new ContentData().loadModel(data);
      return this;
    }

    this.data = new ContentData().loadModel(input.data);
    return this;
  }
}
