import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import productionDetails from './gql/query.graphql';
import { ProductionDetailsDTO, ProductionDetailsRequest } from './interfaces';
import { ProductionDetails } from './models';

@Injectable({
  providedIn: 'root',
})
export class ProductionDetailsService extends AbstractCRUDService<ProductionDetails, ProductionDetailsDTO> {
  override model = ProductionDetails;

  getData(request: ProductionDetailsRequest): Observable<Array<ProductionDetails>> {
    return this.apollo
      .query<any>({
        query: productionDetails,
        variables: {
          input: request,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<ProductionDetailsDTO>>>) => {
          const records = response.data.productionDetails.map(this.loadModel);
          return records;
        })
      );
  }
}
