import { MachineCategoryDTO } from '../core/machines/models/machine-category.model';
import { SearchCriteria } from '../modules/jm-table/models/search-criteria.config';

export const applicationStateName = 'applicationState';

export interface ApplicationState {
  isSettingsSideBarOpened: boolean;
  activeDashboardId: string | null;
  machineCategories: Array<MachineCategoryDTO>;
  tableFilters: { [key: string]: SearchCriteria };
}

export const initialApplicationState: ApplicationState = {
  isSettingsSideBarOpened: false,
  activeDashboardId: null,
  machineCategories: [],
  tableFilters: {},
};
