import { uniq } from 'lodash-es';
import { filter } from 'rxjs';
import { FormModeEnum } from 'src/app/modules/quick-forms/enums/form-mode';
import { QuickFormError } from 'src/app/modules/quick-forms/errors/forms.error';
import { notNull } from 'src/app/util/rxjs/rxjs.hellper';
import { isSet } from 'src/app/util/util';
import { v4 as uuidv4 } from 'uuid';

import { Component, Inject, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { selectActiveLicenses } from '@auth';
import { SearchCriteria } from '@jm-table';
import { checkLicenses } from '@licenses';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ControlConfig, ControlConfigDTO, createControls, findConfig, loadFormConfig, LookupResult } from '@quick-form';
import { SensorService, SensorType } from '@sensor';
import { sensorConfigurationToLookup } from '@widgets';

import { widgetsConfig } from '../../../config';
import { WidgetTypeEnum } from '../../enums/widget-type.enum';
import { WidgetGlobalConfig } from '../../interfaces/widgets-global-config.interface';
import { Widget } from '../../models/widgets/widget.model';

@UntilDestroy()
@Component({
  selector: 'app-widget-form-modal',
  templateUrl: './widget-form-modal.component.html',
  styleUrls: ['./widget-form-modal.component.scss'],
})
export class WidgetFormModalComponent implements OnInit {
  private readonly fb = inject(FormBuilder);
  private readonly dialogRef = inject(MatDialogRef<WidgetFormModalComponent>);
  private readonly sensorService = inject(SensorService);
  private readonly store$ = inject(Store);

  widgetFormConfig: Array<ControlConfig> = [];
  widgetForm!: FormGroup;
  selectedWidget: WidgetGlobalConfig | null = null;
  formMode: FormModeEnum = FormModeEnum.create;

  sensorWidgetOptions: Array<LookupResult> = [];
  sensorDropdownLoading = false;

  private _sensorWidgetOptionsCopy: Array<LookupResult> = [];

  readonly widgetType = WidgetTypeEnum;

  widgetsConfig: Array<WidgetGlobalConfig> = [];

  constructor(@Inject(MAT_DIALOG_DATA) readonly data: { widget: Widget; formMode: FormModeEnum }) {}

  ngOnInit(): void {
    this.checkHasUserSensorPermission();
    const { widget } = this.data;

    this.formMode = this.data.formMode;

    if (widget && widget.type) {
      this.onWidgetSelect(widget.type, { name: widget.name, type: widget.type, id: widget.id, ...widget.config });

      if (widget.type === WidgetTypeEnum.HistoricalSensor || widget.type === WidgetTypeEnum.RealtimeSensor) {
        const { machine } = widget.config;

        this.getSensorConfigurationOptions(machine.id);
      }

      return;
    }

    this.onWidgetSelect(this.widgetsConfig[0].type);
  }

  onAddWidget(): void {
    if (this.widgetForm.invalid) {
      this.widgetForm.markAllAsTouched();
      return;
    }

    this.dialogRef.close({ ...this.widgetForm.getRawValue() });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onWidgetSelect(widgetType: WidgetTypeEnum | string, model?: any) {
    this.selectedWidget = this.widgetsConfig.find((widget) => widget.type === widgetType) || null;

    if (this.selectedWidget?.formConfig) {
      this.setupWidgetForm(this.selectedWidget.formConfig, model);
    }
  }

  getButtonText(): string {
    if (this.formMode === FormModeEnum.create) {
      return 'widgets.actions.add-widget';
    }

    return 'widgets.actions.update';
  }

  getTitle(): string {
    if (this.formMode === FormModeEnum.create) {
      return 'widgets.actions.select-widget';
    }

    return 'widgets.actions.update-widget';
  }

  getConfig(id: string): ControlConfig {
    const config = findConfig(this.widgetFormConfig, id);

    if (!config) {
      throw new QuickFormError('Cant find form config');
    }

    return config;
  }

  getFormControl(controlId: string): FormControl {
    return this.widgetForm?.get(controlId) as FormControl;
  }

  private setupWidgetForm(widgetFormConfig: Array<ControlConfigDTO>, model?: any) {
    this.widgetFormConfig = loadFormConfig(widgetFormConfig, model);
    this.widgetForm = this.fb.group(createControls(this.widgetFormConfig));

    if (this.formMode === FormModeEnum.create) {
      this.widgetForm.get('id')?.setValue(uuidv4());
    }

    this.watchMachineFormChanges();
    this.watchSensorFormChanges();
  }

  private watchMachineFormChanges(): void {
    this.widgetForm
      .get('machine')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((machine: LookupResult) => {
        if (!isSet(machine)) {
          return;
        }
        if (this.selectedWidget?.type === WidgetTypeEnum.RealtimeSensor || this.selectedWidget?.type === WidgetTypeEnum.HistoricalSensor) {
          this.getSensorConfigurationOptions(machine.id);
          return;
        }
        this.sensorWidgetOptions = [];
      });
  }

  private getSensorConfigurationOptions(machineGuid: string): void {
    const searchCriteria = new SearchCriteria();

    searchCriteria.filterBy = { machineGuid };
    if (this.selectedWidget?.type === WidgetTypeEnum.HistoricalSensor) {
      searchCriteria.filterBy = {
        ...searchCriteria.filterBy,
        type: SensorType.CURRENT_VALUE,
      };
    }
    this.sensorDropdownLoading = true;

    this.sensorService
      .getAll(searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.sensorDropdownLoading = false;
          const options: Array<LookupResult> = sensorConfigurationToLookup(response.records);
          this.sensorWidgetOptions = options;
          this._sensorWidgetOptionsCopy = options;
          this.widgetForm.get('sensorId')?.enable();
        },
        error: () => (this.sensorDropdownLoading = false),
      });
  }

  private watchSensorFormChanges(): void {
    this.widgetForm
      .get('sensorId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((sensors: Array<LookupResult>) => {
        if (this.selectedWidget?.type === WidgetTypeEnum.HistoricalSensor) {
          const uniqUnitOptions = uniq(sensors?.map((value) => value.additionalField));
          if (uniqUnitOptions.length === 2) {
            this.sensorWidgetOptions = this.sensorWidgetOptions.filter((option) => uniqUnitOptions.includes(option.additionalField));
            return;
          }
          this.sensorWidgetOptions = this._sensorWidgetOptionsCopy;
        }
      });
  }

  private checkHasUserSensorPermission(): void {
    this.store$.pipe(select(selectActiveLicenses), filter(notNull), untilDestroyed(this)).subscribe((activeLicenses) => {
      this.widgetsConfig = widgetsConfig.filter((config) => {
        const licenses = config.licenses ?? [];
        return checkLicenses(activeLicenses, licenses);
      });
    });
  }
}
