import { Observable } from 'rxjs';
import { createControls, loadFormConfig } from 'src/app/modules/quick-forms';
import { ControlConfig } from 'src/app/modules/quick-forms/models/control.config';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';

import { LoginActionType, SetNewPassword, SuccessfullyForgotPassword, UserLogin } from '../../store/login.actions';
import { selectPasswordResetLoader } from '../../store/login.selector';
import { formConfig as passwordConfig } from '../shared/password-form-config';

@UntilDestroy()
@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit {
  token: string | null = null;
  loader$!: Observable<boolean>;

  form!: FormGroup;
  formConfig: Array<ControlConfig> = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<any>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly actionsSubject$: ActionsSubject
  ) {}

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    this.loader$ = this.store$.pipe(select(selectPasswordResetLoader));

    this.formConfig = loadFormConfig(passwordConfig);
    this.form = this.formBuilder.group(createControls(this.formConfig));

    this.watchPasswordResetSuccessfull();
  }

  watchPasswordResetSuccessfull(): void {
    this.actionsSubject$
      .pipe(ofType(LoginActionType.SUCCESSFULLY_FORGOT_PASSWORD), untilDestroyed(this))
      .subscribe((action: SuccessfullyForgotPassword) => {
        const password = this.form.get('password')?.value;
        const email = action.payload.user.email;
        this.loggIn(email, password);
      });
  }

  submit(): void {
    this.setPassword();
  }

  setPassword(): void {
    const password: string = this.form.get('password')?.value;
    this.store$.dispatch(new SetNewPassword({ password, resetToken: this.token }));
  }

  loggIn(email: string, password: string) {
    this.store$.dispatch(new UserLogin({ email, password }));
  }
}
