export enum WidgetTypeEnum {
  Custom = 'custom',
  AverageSpeed = 'averageSpeed',
  AverageEfficiency = 'averageEfficiency',
  AverageStops = 'averageStops',
  EfficiencyPerShift = 'efficiencyPerShift',
  SensorData = 'sensorData',
  RealtimeSensor = 'realTimeSensor',
  HistoricalSensor = 'historicalSensor',
}
