import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { isSet } from 'src/app/util/util';

import { AsyncPipe, NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JmButtonComponent, LoaderComponent } from '@app-ui';
import { GetUserInfo } from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  EuIds,
  PushNotificationConfiguration,
  PushNotificationConfigurationService,
  PushNotificationsPermission,
  PushNotificationsService,
} from '@push-notifications';
import { AbstractFormConfigComponent, createControls, loadFormConfig, QuickFormsModule } from '@quick-form';

import { pushNoTificationsConfiguration } from './form-config';

@UntilDestroy()
@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, QuickFormsModule, TranslateModule, LoaderComponent, NgIf, AsyncPipe, JmButtonComponent],
})
export class PushNotificationsComponent extends AbstractFormConfigComponent implements OnInit {
  private readonly pushConfigurationService = inject(PushNotificationConfigurationService);
  private readonly pushNotificationsService = inject(PushNotificationsService);
  private readonly snackBarService = inject(SnackBarService);
  private readonly store$ = inject(Store);

  @Input()
  set userNotificationEuids(data: Array<EuIds> | null) {
    this.osUserId = localStorage.getItem('osUserId');

    if (!data) {
      return;
    }

    const euids: Array<string> = data.map((value: EuIds) => value.euid);
    this.alreadyInRegistered = isSet(this.osUserId) ? euids.includes(this.osUserId) : false;
  }

  @Output() setOnePushNotification$: EventEmitter<boolean> = new EventEmitter<boolean>();

  alreadyInRegistered = false;
  configuration = new PushNotificationConfiguration();
  pushPermissions = PushNotificationsPermission;
  loading = false;
  updating = false;
  osUserId: string | null = '';
  permission?: string;
  hasPermission?: boolean;

  ngOnInit(): void {
    this.loadConfiguration();
    this.loadPermissions();
  }

  initFrom(model: PushNotificationConfiguration): void {
    this.formConfig = loadFormConfig(pushNoTificationsConfiguration, model);
    this.form = this.formBuilder.group(createControls(this.formConfig));
  }

  loadPermissions(): void {
    this.pushNotificationsService
      .isLoaded()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.permission = this.pushNotificationsService.getNativePermission();
      });
  }

  private loadConfiguration(): void {
    this.pushConfigurationService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (configuration) => {
          this.configuration = configuration;
          this.initFrom(configuration);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  registerDevice(): void {
    // handles OneSignal being blocked by an ad blocker
    if (typeof window.OneSignal !== 'function') {
      this.snackBarService.error('notifications.push-notifications.disable-adblocker');
      return;
    }

    if (this.permission !== PushNotificationsPermission.granted) {
      this.pushNotificationsService
        .showNativePrompt()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.permission = this.pushNotificationsService.getNativePermission();
          const osUserId = this.pushNotificationsService.getUserId();

          if (isSet(osUserId)) {
            this.osUserId = osUserId;
          }
        });
      return;
    }

    this.pushNotificationsService
      .checkAndSendToApiIfUserIdExist()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.store$.dispatch(new GetUserInfo());
      });
  }

  save(enabled: boolean): void {
    if (!isSet(this.osUserId)) {
      return;
    }

    this.updating = true;
    this.pushConfigurationService
      .update(enabled)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (configuration) => {
          this.configuration = configuration;
          this.updating = false;
        },
        error: () => {
          this.updating = false;
        },
      });
  }

  /**
   * Button register should be visible if enabled on BE and
   * missing osUserId or not alreadyRegistered or push notifications permission is "default"
   */
  get registerButtonVisible(): boolean {
    const isPushEnabled = this.configuration?.defaultNotification;

    if (!isSet(isPushEnabled) || !isPushEnabled) {
      return false;
    }

    if (this.permission === PushNotificationsPermission.denied) {
      return false;
    }

    if (this.permission === PushNotificationsPermission.default || !this.alreadyInRegistered || !isSet(this.osUserId)) {
      return true;
    }

    return false;
  }
}
