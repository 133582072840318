<div class="alert-dialog">
  <div class="alert-dialog__header">
    <svg-icon class="jm-logo" [src]="'./assets/images/jm-white.svg'"></svg-icon>
  </div>
  <div class="alert-dialog__body">
    <h3 class="title">{{ 'reload-page.title' | translate }}</h3>
    <p class="message" [innerHTML]="data.message | translate"></p>
    <jm-button [class]="'red-button'" [suffixIcon]="'reload'" (clicked)="reload()">
      {{ 'actions.reload-page' | translate }}
    </jm-button>
  </div>
</div>
