<div class="quick-form-control">
  <div class="input-wrapper google-places">
    <label class="label" *ngIf="config.label">{{ config.label | translate }} <span *ngIf="config.validators?.required">*</span></label>
    <input
      type="text"
      [id]="config.getDisplayId()"
      [placeholder]="'admin.new-company.placeholder.location' | translate"
      (blur)="onBlur()"
      (input)="onValueChanged($event)"
      [value]="autoCompleteValue"
      [disabled]="isDisabled || control.disabled"
      #addressRef
    />
  </div>
  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
