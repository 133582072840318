import { inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { QuickFormError } from '../errors/forms.error';
import { findConfig } from '../helpers/form.helper';
import { ControlConfig } from '../models/control.config';

export class AbstractFormConfigComponent {
  protected readonly formBuilder = inject(FormBuilder);

  form: FormGroup | null = null;
  formConfig: Array<ControlConfig> = [];

  getConfig(id: string): ControlConfig {
    const config = findConfig(this.formConfig, id);

    if (!config) {
      throw new QuickFormError('Cant find form config');
    }

    return config;
  }

  getFormControl(controlId: string): FormControl {
    return this.form?.get(controlId) as FormControl;
  }
}
