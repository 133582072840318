import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';

import { Injectable } from '@angular/core';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { EnergyConsumedByArticleDTO } from './interfaces';
import { EnergyConsumedByArticle } from './models';

@Injectable({
  providedIn: 'root',
})
export class EnergyConsumedByArticleService extends AbstractCRUDService<EnergyConsumedByArticle, EnergyConsumedByArticleDTO> {
  getData(input: SearchCriteriaDTO): Observable<Array<EnergyConsumedByArticleDTO>> {
    const query = gql`
      query ArticleEnergyConsumptionData($input: EnergyConsumptionInput!) {
        energyConsumedByArticles(input: $input) {
          articleEnergyConsumptionData {
            articleName
            articleGuid
            totalEnergyUsedKwh
            co2Equivalent
          }
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response) => {
          return response.data['energyConsumedByArticles']['articleEnergyConsumptionData'];
        })
      );
  }
}
