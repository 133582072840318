import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { CustomPeriod } from 'src/app/util/period/custom-period-input.model';
import { Period } from 'src/app/util/period/period.interface';

import { Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { selectDashboardPeriod } from '../../custom-dashboard/store/dashboard.selector';
import { PeriodDTO, periodToBucketSize, statePeriodToStartEndDate } from '../core';
import { FooterTag } from '../core/interfaces/footer-tag.interface';
import { TagTypeEnum } from '../core/interfaces/tags.interface';
import { AverageStopsWidget } from './average-stops.model';
import { chartConfig } from './chart-config';
import { AverageStopsRequest } from './interface/average-stops.request';
import { dataToChartMapper } from './mappers/data-to-chart.mapper';
import { AverageStopsService } from './services/average-stops.service';
import { MachineSubscriptionsService } from './services/machine-subscriptions.service';
import { mapMachinesStatuses } from '../../machines/helpers';

@UntilDestroy()
@Component({
  selector: 'widget-average-stop',
  templateUrl: './average-stop.component.html',
  styleUrls: ['./average-stop.component.scss'],
})
export class AverageStopComponent implements OnInit {
  private readonly store$ = inject(Store);
  private readonly widgetService = inject(AverageStopsService);
  private readonly widgetSubscriptionsService = inject(MachineSubscriptionsService);

  @Input() widget: AverageStopsWidget = new AverageStopsWidget();
  @Input()
  dashboardId: string | undefined | null = null;

  value: number | null = null;
  percentage = 0;
  chartConfig: ChartConfiguration = cloneDeep(chartConfig);
  loading = false;

  data: ObjectLiteral | null = null;
  error = false;
  errorDescription: string | null = null;
  noData = false;

  tags: Array<FooterTag> = [];
  private period!: Period;

  private customPeriod: CustomPeriod | null = null;

  get isRealTimePeriodSelected(): boolean {
    if (
      this.period.type === PeriodTypeEnum.PERIOD_TODAY ||
      this.period.type === PeriodTypeEnum.PERIOD_LAST_HOUR ||
      this.period.type === PeriodTypeEnum.PERIOD_LAST_24_HOURS ||
      this.period.type === PeriodTypeEnum.PERIOD_LAST_TWO_DAYS ||
      this.period.type === PeriodTypeEnum.PERIOD_CURRENT_MONTH
    ) {
      return true;
    }

    if (this.period.type === PeriodTypeEnum.CUSTOM_RANGE) {
      const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(this.period);

      return moment().isBetween(mappedPeriod.startDate, mappedPeriod.endDate);
    }

    return false;
  }

  ngOnInit(): void {
    this.watchPeriodChange();
    this.watchMachineStatusChanged();
    this.populateTags();
  }

  populateTags(): void {
    const location = this.widget.config.location;
    const machine = this.widget.config.machine;

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: machine.name,
      },
    ];
  }

  private watchPeriodChange(): void {
    if (!this.dashboardId) {
      return;
    }
    this.store$.pipe(select(selectDashboardPeriod(this.dashboardId)), untilDestroyed(this)).subscribe((state: Period) => {
      const { type, customPeriod } = state;
      this.period = state;
      this.customPeriod = this.period.type === PeriodTypeEnum.CUSTOM_RANGE ? customPeriod : null;

      const period = {
        type,
        customPeriod: this.customPeriod,
      };

      this.loadData(period);
    });
  }

  private watchMachineStatusChanged(): void {
    this.widgetSubscriptionsService
      .machineStatusUpdated()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (machineStatus) => {
          const selectedMachine = this.widget.config.machine;

          if (machineStatus.guid === selectedMachine.id && this.realTimePeriodSelected()) {
            this.disableChartAnimations();

            const period = {
              type: this.period.type,
              customPeriod: this.customPeriod,
            };

            this.loadData(period, false);
          }
        },
      });
  }

  private loadData(period: Period, loader: boolean | null = null): void {
    const { machine } = this.widget.config;

    this.loading = loader !== null ? loader : true;
    this.noData = false;
    this.error = false;

    const stopTypes = mapMachinesStatuses(this.widget.config.stopReasons);
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(period);
    const bucketSize = periodToBucketSize(mappedPeriod);

    const request: AverageStopsRequest = {
      ...mappedPeriod,
      machineGuids: [machine.id],
      bucketSize,
      stopTypes,
    };

    this.widgetService
      .getData(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (averageStops) => {
          this.loading = false;
          if (averageStops.buckets.length === 0) {
            this.noData = true;
            return;
          }

          this.value = averageStops.count;

          const chartData = dataToChartMapper(stopTypes, averageStops.buckets, mappedPeriod);

          this.chartConfig.data = chartData;
          this.data = chartData;
        },
        error: (error: Error) => {
          this.loading = false;
          this.error = true;

          if (error.message === 'Invalid location') {
            this.errorDescription = 'global.errors.location-not-found-widget';
          }
        },
      });
  }

  private realTimePeriodSelected(): boolean {
    if (this.period.type === PeriodTypeEnum.PERIOD_LAST_MONTH || this.period.type === PeriodTypeEnum.PERIOD_YESTERDAY) {
      return false;
    }

    return true;
  }

  private disableChartAnimations(): void {
    const options = this.chartConfig?.options;

    if (options?.animation) {
      options.animation.duration = 0;
    }
  }
}
