<div class="notification" data-test="scheduled-notification">
  <div class="notification__holder">
    <div class="notification__message">
      <svg-icon class="user-icon" [src]="'./assets/images/notification.svg'"></svg-icon>
      <span class="message">{{ userNotification?.message }}</span>
    </div>
    <div class="notification__action">
      <jm-button
        [class]="'red-button close-button'"
        (clicked)="closeNotification.emit(userNotification?.id)"
        [dataTestSelector]="'close-scheduled-notification-button'"
      >
        {{ 'actions.close' | translate }}
      </jm-button>
    </div>
  </div>
</div>
