import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { LookupResult } from 'src/app/modules/quick-forms';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import { MachineCategory, MachineCategoryDTO } from '../models/machine-category.model';
import machineCategoriesQuery from './gql/machine-category.query.graphql';

import deleteMutation from './gql/delete.graphql';
import machineCategories from './gql/basic-machine-category.graphql';

@Injectable({
  providedIn: 'root',
})
export class MachineCategoryService extends AbstractCRUDService<MachineCategory, MachineCategoryDTO> {
  override modelName = 'machineCategory';
  override modelNamePlural = 'machineCategories';

  protected override deleteMutation = deleteMutation;

  override model = MachineCategory;

  override readonly fields: string = `
    id
    name
    goodEfficiency
    stopsGood
    speedGood
    poorEfficiency
    stopsPoor
    speedPoor
    energyConsumptionKw
  `;

  override getAll(): Observable<ListResultDTO<MachineCategory>> {
    return this.apollo.query<any>({ query: machineCategoriesQuery }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('machine-category.error.get-all');
      }),
      map((response: ApolloQueryResult<ResponseData<Array<MachineCategoryDTO>>>) => {
        return {
          records: response.data.machineCategories.map((category) => this.loadModel(category)),
          pageDetails: null,
        };
      })
    );
  }

  override create(model: any): Observable<MachineCategory> {
    const { name, goodEfficiency, stopsGood, speedGood, poorEfficiency, stopsPoor, speedPoor, energyConsumptionKw } = model;
    const mutation = gql`
      mutation addMachineCategory(
        $name: String!
        $goodEfficiency: Float!
        $stopsGood: Int!
        $speedGood: Int!
        $poorEfficiency: Float!
        $stopsPoor: Int!
        $speedPoor: Int!
        $energyConsumptionKw: Float

      ) {
        addMachineCategory(
          name: $name
          goodEfficiency: $goodEfficiency
          stopsGood: $stopsGood
          speedGood: $speedGood
          poorEfficiency: $poorEfficiency
          stopsPoor: $stopsPoor
          speedPoor: $speedPoor
          energyConsumptionKw: $energyConsumptionKw
        ) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: { name, goodEfficiency, stopsGood, speedGood, poorEfficiency, stopsPoor, speedPoor, energyConsumptionKw },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('machine-category.error.create');
        }),
        map((response: ApolloQueryResult<ResponseData<MachineCategoryDTO>>) => this.loadModel(response.data.addMachineCategory))
      );
  }

  override update(model: any): Observable<MachineCategory> {
    const mutation = gql`
      mutation updateMachineCategory(
        $id: Int!
        $name: String!
        $goodEfficiency: Float!
        $stopsGood: Int!
        $speedGood: Int!
        $poorEfficiency: Float!
        $stopsPoor: Int!
        $speedPoor: Int!
        $energyConsumptionKw: Float
      ) {
        updateMachineCategory(
          id: $id
          name: $name
          goodEfficiency: $goodEfficiency
          stopsGood: $stopsGood
          speedGood: $speedGood
          poorEfficiency: $poorEfficiency
          stopsPoor: $stopsPoor
          speedPoor: $speedPoor
          energyConsumptionKw: $energyConsumptionKw
        ) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          ...model,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('machine-category.error.update');
        }),
        tap(() => {
          this.snackBarService.success('machine-category.success.update');
        }),
        map((response: ApolloQueryResult<ResponseData<MachineCategoryDTO>>) => this.loadModel(response.data.updateMachineCategory))
      );
  }

  getSuggestions(): Observable<ListResultDTO<LookupResult>> {
    return this.apollo.query({ query: machineCategories }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('machine-category.error.get-all');
      }),
      map((response: ApolloQueryResult<ResponseData<Array<MachineCategoryDTO>>>) => {
        const records = response.data.machineCategories.map((category) => this.loadModel(category));
        return {
          pageDetails: null,
          records: listItemsToLookupResult(records),
        };
      })
    );
  }
}
