import { Injectable, inject } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { Observable, map, tap } from 'rxjs';
import { FetchResult } from '@apollo/client';
import { Store } from '@ngrx/store';

import { LiveUpdateOrdersPerMachine } from 'src/app/main/dashboard/store/dashboard.action';
import orderQueueUpdated from './queries/order-queue-updated.graphql';
import orderStatusSub from './queries/order-status-updated.graphql';
import { OrderQueueUpdatedDTO } from './interfaces/order-queeue-updated.interface';
import { mapFromQueueUpdated } from './mappers/from-queue-updated.mappler';

@Injectable({
  providedIn: 'root',
})
export class OrderSubscriptionsService {
  private readonly apollo = inject(Apollo);
  private readonly store$ = inject(Store);

  orderStatusUpdated(orderGuid: string): Observable<any> {
    return this.apollo
      .subscribe({
        query: orderStatusSub,
        variables: { orderGuid },
      })
      .pipe(map((result: FetchResult<any>) => result.data?.orderStatusUpdated));
  }

  orderQueueUpdated(machineGuid: string) {
    return this.apollo
      .subscribe({
        query: orderQueueUpdated,
        variables: { machineGuid },
      })
      .pipe(
        tap((result: any) => {
          const orders: Array<OrderQueueUpdatedDTO> = result.data.orderQueueUpdated;
          if (!orders) {
            return;
          }

          const mappedOrders = mapFromQueueUpdated(orders);

          this.store$.dispatch(
            new LiveUpdateOrdersPerMachine({
              orders: mappedOrders,
            })
          );
        })
      );
  }
}
