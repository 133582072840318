import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { PerformanceDetailsInput } from '../performance-details';
import { SustainabilityDetailsDTO } from './interfaces';
import { SustainabilityDetails } from './models';

@Injectable({
  providedIn: 'root',
})
export class SustainabilityDetailsService extends AbstractCRUDService<SustainabilityDetails, SustainabilityDetailsDTO> {
  override model = SustainabilityDetails;

  override fields = `
    machineGuid
    machineName
    machineAlias
    articleGuid
    articleName
    lastFinished
    targetTime
    actualTime
    targetEnergy
    actualEnergy
    diffEnergy
    co2Produced
  `;

  getData(input: PerformanceDetailsInput): Observable<Array<SustainabilityDetails>> {
    const query = gql`
      query SustainabilityDetails($input: SustainabilityDetailsInput!) {
        sustainabilityDetails(input: $input) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<SustainabilityDetailsDTO>>>) =>
          response.data.sustainabilityDetails.map((item: SustainabilityDetailsDTO) => this.loadModel(item))
        )
      );
  }
}
