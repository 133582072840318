@if(!tableConfig.reorderColumns){
<th class="toggle-row-cell hidden-mobile" [class.empty-space]="!rowExpanded">
  <div class="cell"></div>
</th>
}

<ng-template ngFor let-column [ngForOf]="columns" [ngForTrackBy]="trackByIndex">
  @if(column.display){
  <th
    [class.hidden-mobile]="!tableConfig.reorderColumns"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <ng-container *ngTemplateOutlet="cellHeader; context: { column: column }"> </ng-container>
  </th>
  }
</ng-template>

<th class="hidden-tablet hidden-desktop mobile-header" [attr.colspan]="mobileDefaultCellsColSpan">
  <ng-container *ngTemplateOutlet="cellHeader; context: { column: activeMobileCell }"> </ng-container>
  <div *ngIf="!tableConfig.reorderColumns" class="arrow-wrapper" [matMenuTriggerFor]="optionMenu" #optionMenuTrigger="matMenuTrigger">
    <svg-icon class="icon-arrow asc" [src]="'./assets/images/arrow-down.svg'"></svg-icon>
  </div>
</th>

<ng-template #cellHeader let-column="column">
  <div class="cell" [ngClass]="column?.cssClass" [pSortableColumn]="column.sortingField">
    {{ column.title || '' | translate }} <ng-container *ngIf="column.unit && unit">({{ unit }})</ng-container>
    @if(column.sortable){
    <div class="sorting-icons">
      <svg-icon
        class="sort-icon-arrow asc"
        [class.active]="sortOrder === ASC && sortField === column.sortingField"
        [src]="'./assets/images/sorting-arrow.svg'"
      ></svg-icon>
      <svg-icon
        class="sort-icon-arrow desc"
        [class.active]="sortOrder === DESC && sortField === column.sortingField"
        [src]="'./assets/images/sorting-arrow.svg'"
      ></svg-icon>
    </div>
    } @if(column.filter){
    <jm-column-filter [column]="column" />
    }
  </div>
</ng-template>

<mat-menu #optionMenu="matMenu" class="columns-selection" xPosition="before">
  <ng-template matMenuContent>
    <div class="option" *ngFor="let column of columns" (click)="onMenuAction(column)">
      <span class="label">{{ column.title || '' | translate }}</span>
    </div>
  </ng-template>
</mat-menu>
