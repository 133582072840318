import { shareReplay } from 'rxjs';
import { UserGroupEnum } from 'src/app/enum';
import { isSet } from 'src/app/util/util';

import { Directive, inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { selectUserGroup } from '../../store/login.selector';

@UntilDestroy()
@Directive({
  selector: '[appHasRole]',
  standalone: true,
})
export class RoleDirective implements OnChanges {
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly store$ = inject(Store);

  @Input()
  appHasRole: UserGroupEnum | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (isSet(changes.appHasRole)) {
      const requiredRole = changes.appHasRole?.currentValue;

      this.store$
        .select(selectUserGroup)
        .pipe(shareReplay(1), untilDestroyed(this))
        .subscribe((userGroup) => {
          this.updateView(userGroup, requiredRole);
        });
    }
  }

  private updateView(userGroup: UserGroupEnum | undefined, requiredRole: UserGroupEnum | null): void {
    if (!userGroup || !requiredRole || userGroup !== requiredRole) {
      this.viewContainer.clear();
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
