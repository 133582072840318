<div class="widget widget--average-speed">
  <widget-header [widget]="widget"></widget-header>

  <widget-content [error]="error" [errorDescription]="errorDescription" [noData]="noData">
    <main class="widget__main">
      <ng-container *ngIf="!loading; else loader">
        <div class="value">
          <span *appIsSet="value" class="highlight">{{ value }} {{ unit }}</span>
        </div>
        <div class="graph">
          <jm-chart [chartConfig]="chartConfig" [id]="widget.id"></jm-chart>
        </div>
      </ng-container>
    </main>
  </widget-content>

  <widget-footer [tags]="tags"></widget-footer>
</div>

<ng-template #loader> <app-loader class="jm-loader" [centered]="true"></app-loader></ng-template>
