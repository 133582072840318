import { ChartData } from 'chart.js';
import { PeriodDTO } from '../../core/interfaces/period.interface';
import { stackedBarChartColors } from '../../core/mappers/stacked-bar-chart-collors';
import { mapLabels } from '../../core/helpers/chart.helper';
import { ShiftDTO } from '../interface/shift.interface';
import { EfficiencyPerShiftDTO } from '../interface/efficiency-per-shift';
import { roundToDecimals } from '@util';

export function dataToChartMapper(backendData: Array<EfficiencyPerShiftDTO>, period: PeriodDTO): ChartData {
  const templates = [
    {
      id: 'SHIFT_A',
      label: 'A',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_B',
      label: 'B',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_C',
      label: 'C',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_D',
      label: 'D',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_E',
      label: 'E',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_F',
      label: 'F',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_G',
      label: 'G',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_H',
      label: 'H',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_N',
      label: 'N',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_1',
      label: '1',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_2',
      label: '2',
      data: [],
      backgroundColor: '',
    },
    {
      id: 'SHIFT_3',
      label: '3',
      data: [],
      backgroundColor: '',
    },
  ];

  const labels = mapLabels(backendData, period);

  const initialTemplates = templates.map((template, index: number) => {
    return {
      ...template,
      data: new Array(labels.length).fill(0),
      borderColor: stackedBarChartColors[index],
      backgroundColor: stackedBarChartColors[index],
      yAxisID: 'Y',
    };
  });

  backendData.forEach((item, itemIndex) => {
    item.shifts.forEach((shift: ShiftDTO) => {
      const template = initialTemplates.find((stopTemplate) => stopTemplate.label === shift.shift);

      if (template) {
        template.data[itemIndex] = convertToPercentage(shift.value);
      }
    });
  });

  return {
    labels,
    datasets: initialTemplates,
  };
}

function convertToPercentage(value: number): number | null {
  value *= 100;
  return roundToDecimals(value, 2);
}
