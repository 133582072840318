import { Injectable, inject } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { isSet } from '@util';
import { IMachineStatusDto } from '@machines';

import { ResponseData } from '../../../abstract/interface/response-data';
import machineStatusUpdated from './../gql/machine-status-updated.graphql';

@Injectable({
  providedIn: 'root',
})
export class MachineSubscriptionsService {
  private readonly apollo = inject(Apollo);

  machineStatusUpdated(): Observable<IMachineStatusDto> {
    return this.apollo
      .subscribe<ResponseData<IMachineStatusDto>>({
        query: machineStatusUpdated,
      })
      .pipe(
        map((response) => response.data?.machineStatusUpdated),
        filter((machineStatus: IMachineStatusDto | null | undefined): machineStatus is IMachineStatusDto => isSet(machineStatus))
      );
  }
}
