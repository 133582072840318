<div class="cell" [ngClass]="column?.cssClass">
  <ng-container *ngIf="column?.meta?.arrayType === cellArrayType.STRING">
    <ng-template ngFor let-data [ngForOf]="cellData[columnField]" let-i="index">
      {{ data[column?.meta?.arrayKey] }}
      <ng-container *ngIf="i + 1 < cellData[columnField]?.length">,</ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="column?.meta?.arrayType === cellArrayType.IMAGE">
    <img
      imageNotFound
      *ngFor="let image of getImages()"
      [src]="environmentRootUrl + image?.urls?.thumbnail"
      (click)="preview(image, cellData[columnField]); $event.stopPropagation()"
    />
    <div class="more-images" *ngIf="cellData[columnField].length > 3">
      <svg-icon [src]="'./assets/images/plus.svg'" alt="plus"></svg-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="cellData[columnField]?.length === 0">-</ng-container>
</div>

<div class="extra-column hidden-tablet hidden-desktop">
  <ng-content></ng-content>
</div>
