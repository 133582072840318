import { ControlConfigDTO } from 'src/app/modules/quick-forms/interfaces/control-config.interface';
import { TicketTypeEnum } from '../enum/ticket-type.enum';
import { ControlTypeEnum } from '@quick-form';

export const formConfig: Array<ControlConfigDTO> = [
  {
    id: 'type',
    label: 'global.category',
    type: ControlTypeEnum.Dropdown,
    placeholder: 'global.choose-category',
    validators: {
      required: true,
    },
    meta: {
      options: [
        {
          id: TicketTypeEnum.FEEDBACK,
          name: TicketTypeEnum.FEEDBACK,
        },
        {
          id: TicketTypeEnum.SUPPORT,
          name: TicketTypeEnum.SUPPORT,
        },
      ],
    },
  },
  {
    id: 'message',
    label: 'global.message',
    placeholder: 'global.type',
    type: ControlTypeEnum.TextArea,
    validators: {
      required: true,
      maxLength: 1000,
    },
  },
];
