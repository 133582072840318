import { Component } from '@angular/core';
import { MACHINE_PLATFORM } from '@machines';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-order-name]',
  templateUrl: './jm-order-name.component.html',
  styleUrls: ['./jm-order-name.component.scss'],
})
export class JmOrderNameComponent extends AbstractCellComponent {
  readonly machinePlatform = MACHINE_PLATFORM;
}
