import { Pipe, PipeTransform } from '@angular/core';
import { valueFallback } from 'src/app/util/util';
@Pipe({
  name: 'value',
  standalone: true,
})
export class ValuePipe implements PipeTransform {
  transform(value: any): any {
    return valueFallback(value);
  }
}
