import { isArray, isEmpty, set } from 'lodash-es';
import { FilterMetadata } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { Pagination } from 'src/app/util/search/pagination.interface';
import { SortBy } from 'src/app/util/search/sort-by.interface';
import { SortOrder } from 'src/app/util/search/sort-order.enum';
import { isSet } from 'src/app/util/util';

import { CustomLazyLoadEvent } from '../interfaces/lazy-load-event';
import { CellConfig } from '../models';
import { SearchCriteria } from '../models/search-criteria.config';
import { TableConfig } from '../models/table.config';
import { PrimeNGFilters } from '../types/filters.type';

export function filtersToSearchCriteria(event: TableLazyLoadEvent, tableConfig?: TableConfig): SearchCriteria {
  const searchCriteria = new SearchCriteria();

  delete searchCriteria.searchQuery;
  delete searchCriteria.filterBy;

  if (tableConfig?.search.show && event.globalFilter) {
    searchCriteria.searchQuery = {
      name: event.globalFilter,
    };
  }

  if (tableConfig?.filtering === true && isSet(event.filters)) {
    const filters = filterUndefinedValues(event.filters);

    searchCriteria.filterBy = mapFilters(filters);
  }

  if (event.sortField) {
    searchCriteria.sortBy = mapSort(event);
  }

  searchCriteria.pagination = mapPagination(event);

  return searchCriteria;
}

export function mapFilters(filters: PrimeNGFilters): ObjectLiteral {
  return Object.entries(filters).reduce((accumulator, [key, value]: [string, FilterMetadata | Array<FilterMetadata>]) => {
    if (key === 'global') {
      return accumulator;
    }

    let filter: FilterMetadata = {};
    if (isArray(value)) {
      filter = (value as Array<FilterMetadata>)[0];
    } else {
      filter = value as FilterMetadata;
    }

    if (isSet(filter) && isArray(filter.value) && filter.value.length === 0) {
      return accumulator;
    }

    if (!isEmpty(filter) && isSet(filter?.value)) {
      accumulator[key] = filter.value;
    }

    return accumulator;
  }, {} as ObjectLiteral);
}

export function mapSort(event: TableLazyLoadEvent): SortBy {
  return {
    columnName: event.sortField,
    direction: event.sortOrder === -1 ? SortOrder.DESC : SortOrder.ASC,
  };
}

export function mapPagination(event: TableLazyLoadEvent): Pagination {
  return {
    limit: event.rows ?? 0,
    offset: event.first ?? 0,
  };
}

export function queryParamsToSearchCriteria(queryParams: string, tableConfig: TableConfig): SearchCriteria {
  const searchCriteria = new SearchCriteria();
  const params = new URLSearchParams(queryParams);

  for (const paramKey of params.keys()) {
    let value: any = params.get(paramKey);

    if (value === 'true') {
      value = true;
    }

    if (value === 'false') {
      value = false;
    }

    if (paramKey === 'pagination.limit' || paramKey === 'pagination.offset') {
      value = Number(value);
    }

    set(searchCriteria, paramKey, value);
  }

  if (searchCriteria.filterBy) {
    searchCriteria.filterBy = mapValuesFromQueryParams(searchCriteria.filterBy, tableConfig.cellsConfig);
  }

  return searchCriteria;
}

function mapValuesFromQueryParams(filterBy: ObjectLiteral, cells: Array<CellConfig>): ObjectLiteral {
  const mappedObj: ObjectLiteral = {};

  Object.entries(filterBy).forEach(([key, value]) => {
    const config = cells.find((config) => config.field === key);

    const valueType = config?.filter?.valueType;

    if (valueType === 'number') {
      mappedObj[key] = Number(value);
      return;
    }

    mappedObj[key] = value;
  });

  return mappedObj;
}

export function searchCriteriaToFilters(searchCriteria: SearchCriteria, tableConfig: TableConfig): CustomLazyLoadEvent {
  const event: CustomLazyLoadEvent = {};

  const pagination = searchCriteria.pagination;
  if (pagination) {
    event.first = pagination.offset;
    event.rows = pagination.limit;
  }

  if (searchCriteria.sortBy) {
    event.sortField = searchCriteria.sortBy.columnName;
    event.sortOrder = searchCriteria.sortBy.direction === SortOrder.ASC ? 1 : -1;
  }

  if (searchCriteria.filterBy) {
    const filters = tableConfig.getFilters();
    event.filters = filterByToPrimengFilters(filters, searchCriteria.filterBy);
  }

  if (searchCriteria.searchQuery?.name) {
    event.globalFilter = searchCriteria.searchQuery.name;
    event.filters = {
      ...event.filters,
      global: { value: searchCriteria.searchQuery.name, matchMode: 'contains' },
    };
  }

  return event;
}

export function filterByToPrimengFilters(tableFilters: Array<string>, filters: ObjectLiteral) {
  return tableFilters.reduce((accumulator: ObjectLiteral, filterKey: string) => {
    const value = isSet(filters[filterKey]) ? filters[filterKey] : null;
    accumulator[filterKey] = [{ value: value, matchMode: 'startsWith', operator: 'and' }];
    return accumulator;
  }, {});
}

function filterUndefinedValues(filters: { [s: string]: FilterMetadata | Array<FilterMetadata> | undefined }): PrimeNGFilters {
  const result: PrimeNGFilters = {};

  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined && value !== null) {
      result[key] = value;
    }
  }

  return result;
}
