<header class="widget__header">
  <div class="title-section">
    <svg-icon class="option-icon drag-handler" [src]="'./assets/images/drag.svg'"></svg-icon>
    @if(widget.live || isLive){
    <span class="label">
      {{ 'global.live' | translate }}
      <svg-icon class="live-icon" [src]="'./assets/images/live.svg'"></svg-icon>
    </span>
    }
    <h5 class="widget__title">{{ widget.name }}</h5>
  </div>

  @if(widget.download){
  <div class="options download" (click)="download.emit()">
    <svg-icon class="option-icon" [src]="'./assets/images/download.svg'"></svg-icon>
  </div>
  } @if(isZoomEnabled()){
  <app-zoom-buttons class="gridster-item-content" (zoomIn)="onZoomIn()" (zoomOut)="onZoomOut()"></app-zoom-buttons>
  } @if(dashboardMode === 'edit'){
  <div class="options gridster-item-content" [matMenuTriggerFor]="optionMenu" #optionMenuTrigger="matMenuTrigger">
    <svg-icon class="options-icon" [src]="'./assets/images/3-dots.svg'"></svg-icon>
  </div>
  }
</header>

<mat-menu #optionMenu="matMenu" class="widgets-dashboard-menu" xPosition="before">
  <ng-template matMenuContent>
    <div class="option" *ngFor="let option of menuOptions" (click)="onMenuAction($event, option)">
      <svg-icon class="option-icon" [src]="'./assets/images/' + option.icon"></svg-icon>
      <span class="label">{{ 'actions.' + option.label | translate }}</span>
    </div>
  </ng-template>
</mat-menu>
