import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { OverallOeeDTO } from './interfaces';
import { OverallOee } from './models';

@Injectable({
  providedIn: 'root',
})
export class OverallOeeService extends AbstractCRUDService<OverallOee, OverallOeeDTO> {
  override model = OverallOee;

  getData(input: PerformanceInput): Observable<OverallOee> {
    const query = gql`
      query LocationOverallOEE($input: OEEInput!) {
        locationOverallOEE(input: $input) {
          availability
          overallOEE
          performance
          quality
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response) => {
          return this.loadModel(response.data['locationOverallOEE']);
        })
      );
  }
}
