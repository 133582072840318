<div
  class="notice"
  [class.notice--info]="type === 'info'"
  [class.notice--success]="type === 'success'"
  [class.notice--warning]="type === 'warning'"
  [class.notice--error]="type === 'error'"
>
  <p class="notice__description" *ngIf="description" [innerHtml]="description"></p>
  <svg-icon class="notice__icon" [src]="'./assets/images/notice/' + type + '.svg'"></svg-icon>
</div>
