import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { JmButtonComponent } from '@app-ui';
import { TranslateModule } from '@ngx-translate/core';
import { upperFirst } from 'lodash-es';
import { camelCaseToWords } from 'src/app/util/util';

@Component({
  selector: 'app-entity-not-found',
  templateUrl: './entity-not-found.component.html',
  styleUrls: ['../not-found/not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JmButtonComponent, TranslateModule],
})
export class EntityNotFoundComponent {
  private readonly location = inject(Location);
  private readonly router = inject(Router);

  entity?: string = 'Entity';

  constructor() {
    const modelName = this.router.getCurrentNavigation()?.extras.state?.modelName;

    if (modelName) {
      this.entity = upperFirst(camelCaseToWords(modelName));
    }
  }

  back(): void {
    this.location.back();
  }
}
