import { Model } from 'ngx-super-model';

import { v4 as uuidv4 } from 'uuid';

import { PerformanceDetailsDTO } from '../interfaces';

export class PerformanceDetails extends Model<PerformanceDetailsDTO> {
  actualTime: number | null = null;
  articleName: string | null = null;
  cost: number | null = null;
  diffTime: number | null = null;
  lastFinished: string | null = null;
  profit: number | null = null;
  quantityInMeters: number | null = null;
  revenue: number | null = null;
  targetTime: number | null = null;
  id: string = uuidv4();
}
