<app-box [loading]="loader">
  <ng-container header>
    {{ title | translate }}
  </ng-container>

  <ng-container content>
    <div class="graph" *ngIf="!error">
      <jm-chart [chartConfig]="chartConfig" [id]="id" *ngIf="!loader"></jm-chart>
    </div>
    <app-empty-block
      *ngIf="error"
      [title]="'global.errors.error' | translate"
      [image]="'./assets/images/machines-error.svg'"
      [description]="'global.errors.could-not-fetch-data' | translate"
    >
    </app-empty-block>
  </ng-container>
</app-box>
