export enum ControlTypeEnum {
  GooglePlaces = 'google-places',
  RadioButton = 'radio-button',
  InputText = 'input-text',
  InputNumber = 'input-number',
  Dropdown = 'dropdown',
  Multiselect = 'multiselect',
  Checkbox = 'checkbox',
  ReadOnlyCheckbox = 'readonly-checkbox',
  TextArea = 'textarea',
  Repeater = 'repeater',
  Hidden = 'hidden',
  Group = 'group',
  PDatePicker = 'p-datepicker',
  ReadOnly = 'readonly',
  Upload = 'upload',
  UploadMultipart = 'upload-multipart',
  PatternFile = 'pattern-file',
  ColorPicker = 'color-picker',
  SlideToggle = 'slide-toggle',
}
