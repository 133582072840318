import { Model } from 'ngx-super-model';
import { MachineStatus } from 'src/app/core/machines/enums/machine-status.enum';
import { secondsToHMS } from 'src/app/util/util';

import { Seconds } from '../../core/types/bucket.type';
import { DownTimeByStopReasonDTO } from '../interfaces/downtime-by-stop-reasons.interface';

export class DownTimeByStopReason extends Model<DownTimeByStopReasonDTO> {
  stopStatus: MachineStatus | null = null;
  duration: Seconds | null = null;

  toHours(): number {
    if (!this.duration) {
      return 0;
    }
    const hours = this.duration / 3600;
    return hours;
  }

  secondsToHMS(): string {
    return secondsToHMS(this.duration);
  }
}
