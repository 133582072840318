import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';

import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ControlTypeEnum } from '../../../enums/control-type.enum';
import { LookupResult } from '../../../interfaces/lookup-result.interface';
import { ControlConfig } from '../../../models/control.config';
import { AbstractDropdownComponent } from '../abstract/abstract-dropdown.component';

@UntilDestroy()
@Component({
  selector: 'quick-form-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./../dropdown/dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    },
  ],
})
export class MultiselectComponent extends AbstractDropdownComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<Array<LookupResult> | null>();

  value: Array<LookupResult> | null = null;

  readonly multiSelectVisibleItems = 5;

  selectAllConfig: ControlConfig = new ControlConfig().loadModel({
    id: 'select-all',
    type: ControlTypeEnum.Checkbox,
    label: 'Select all',
  });

  selectAll = false;

  writeValue(value: Array<LookupResult> | null): void {
    if (typeof value !== 'undefined') {
      this.value = value;

      this.selectAll = this.isAllSelected(value, this.options);
      return;
    }

    this.value = null;
  }

  onChange(values: Array<LookupResult>): void {
    this.selectAll = (this.value as Array<any>)?.length === this.options.length;
    this.value = values;
    this.propagateChange(values);
    this.valueChanged.emit(values);
  }

  clearValue(): void {
    this.value = null;
    this.updateDropdownPosition();
  }

  protected override getByLookupService(concatToExistingOptions = false): void {
    this.loading = true;
    this.lookupService
      ?.getSuggestions(this.searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe((result: ListResultDTO<LookupResult>) => {
        const { pageDetails, records } = result;

        this.pageDetails = pageDetails;
        if (concatToExistingOptions) {
          this.options = this.options.concat(...records);
        } else {
          this.options = [...records];
        }

        this.loading = false;

        this.selectAll = this.isAllSelected(this.value, this.options);
      });
  }

  onSelectAll(checked: boolean) {
    this.value = checked ? [...this.options] : [];
    this.selectAll = checked;
    this.propagateChange(this.value);
    this.valueChanged.emit(this.value);
    this.updateDropdownPosition();
  }

  compareFn = (item: LookupResult, value: LookupResult | null): boolean => {
    if (value === null) {
      return false;
    }

    return item.id === value.id;
  };

  /**
   * Workaround
   * With option appendTo="body", dropdown panel does not update after selected values are changed.
   * Recomendation: Try ng-select@9.1 in new angular app with appendTo body to be sure if it's us or them.
   */
  updateDropdownPosition(): void {
    setTimeout(() => {
      this.select?.dropdownPanel?.adjustPosition();
    }, 10);
  }

  isAllSelected(value: Array<LookupResult> | null, options: Array<LookupResult> = []): boolean {
    return value?.length === options.length;
  }
}
