import { Model } from 'ngx-super-model';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { ControlConfigDTO } from '../interfaces/control-config.interface';
import { TourConfigDTO } from '../interfaces/tour-config.interface';
import { FormValidators } from '../interfaces/validators.interace';
import { ControlTypeEnum } from '../enums/control-type.enum';
import { QuickFormError } from '../errors/forms.error';
import { isSet } from 'src/app/util/util';
import { LabelPosition } from '../types/label-position.type';

export class ControlConfig extends Model<ControlConfigDTO> {
  id = '';
  label? = '';
  labelPosition: LabelPosition = 'before';
  type = '';
  value: any = '';
  disabled = false;
  visible = true;
  validators?: FormValidators = {};
  meta?: ObjectLiteral = {};
  attributes?: ObjectLiteral = {};
  placeholder = '';
  cssClass?: string = '';
  wrapperCssClass?: string = '';
  take?: string | null = null;
  tour?: TourConfigDTO | null = null;
  children: Array<any> | null = null;
  index: number | null = null;

  isLeaf(): boolean {
    return this.type !== 'repeater' && this.type !== 'group';
  }

  getChildren(): Array<ControlConfig> {
    if (!this.children) {
      throw new QuickFormError('Children array is undefined');
    }

    if (this.type === ControlTypeEnum.Repeater) {
      return this.meta?.initialConfig as Array<ControlConfig>;
    }

    return this.children;
  }

  getDisplayId(): string {
    if (!isSet(this.index)) {
      return this.id;
    }

    return this.id + '-' + this.index;
  }

  getAnchorId(): string {
    if (!isSet(this.index)) {
      return this.tour?.anchor || '';
    }

    return this.tour?.anchor + '-' + this.index;
  }

  hasAnchorId(): boolean {
    return isSet(this.tour?.anchor);
  }
}
