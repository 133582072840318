import { GraphQLError } from 'graphql';
import { OperatorFunction, pipe, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isNotFoundError } from 'src/app/errors/helpers/error.helper';
import { EntityNotFound } from 'src/app/errors/not-found.error';

import { ApolloQueryResult } from '@apollo/client/core';

function successWithError(): OperatorFunction<any, any> {
  return map((result: ApolloQueryResult<any>) => {
    if (result.data?.errors) {
      throw new Error(result.data.errors[0]);
    }

    if (result.errors) {
      handleGraphQLErrors(result.errors);
    }

    return result;
  });
}
/**
 * Creates operator that catches timeout, and errors on response 200
 * @param callback invoked if error is catched
 * @returns pipe operator
 */
export function catchAllErrors(callback?: (error?: any) => void) {
  return pipe(
    successWithError(),
    catchError((error) => {
      if (typeof callback === 'function') {
        callback(error);
      }

      return throwError(() => error);
    })
  );
}

function handleGraphQLErrors(errors: ReadonlyArray<GraphQLError>): void {
  if (isNotFoundError(errors[0])) {
    throw new EntityNotFound(errors[0].message);
  }

  const { message, nodes, source, positions, path, originalError, extensions } = errors[0];

  throw new GraphQLError(message, nodes, source, positions, path, originalError, extensions);
}
