import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserNotification } from 'src/app/interfaces/user-notification.interface';
@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent {
  @Input() userNotification: IUserNotification | null = null;
  @Output() closeNotification = new EventEmitter<number>();
}
