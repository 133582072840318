import { GraphQLError } from 'graphql';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { ApolloError } from '@apollo/client/core';

import { GraphQlErrorCodesEnum } from '../enum/graphql-error-codes';

export function isNotFoundError(error: ObjectLiteral): boolean {
  return error.extensions.code === GraphQlErrorCodesEnum.ENTITY_NOT_FOUND;
}

export function isGraphQlError(error: ObjectLiteral): boolean {
  return error instanceof GraphQLError;
}

export function isBadUserInputError(error: ObjectLiteral): boolean {
  return error.extensions?.code === GraphQlErrorCodesEnum.BAD_USER_INPUT;
}

export function isValidationFailedError(error: ObjectLiteral): boolean {
  return error.extensions?.code === GraphQlErrorCodesEnum.GRAPHQL_VALIDATION_FAILED;
}

export function isApolloError(error: ObjectLiteral): boolean {
  return error instanceof ApolloError;
}
