<div *ngIf="config.type === tagTypeEnum.basic" class="widget-tag" [ngClass]="config.type">
  <svg-icon *ngIf="config.icon" class="widget-tag__icon" [src]="'./assets/images/' + config.icon + '.svg'"></svg-icon>
  <span class="widget-tag__name">{{ config.text }}</span>
</div>

<div
  *ngIf="config.type === tagTypeEnum.list"
  class="widget-tag widget-tag--hoverable gridster-item-content"
  [ngClass]="config.type"
  #tagListMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="tagList"
>
  <svg-icon *ngIf="config.icon" class="widget-tag__icon" [src]="'./assets/images/' + config.icon + '.svg'"></svg-icon>
  <span class="widget-tag__name">{{ config.text }}</span>

  <span *ngIf="config.children?.length" class="badge widget-tag__badge">{{ config.children?.length }}</span>
</div>

<mat-menu #tagList="matMenu" class="taglist-menu">
  <ng-template matMenuContent>
    <ul class="widget-tag__list">
      <li class="widget-tag__list-item" *ngFor="let item of config.children">{{ item.text }}</li>
    </ul>
  </ng-template>
</mat-menu>
