<div class="image-preview">
  <div *ngIf="previewDocuments.length" class="arrow-wrapper arrow-left" (click)="showPreviousImage()">
    <svg-icon class="arrow" [src]="'./assets/images/arrow-down.svg'"></svg-icon>
  </div>
  <pinch-zoom #pinch [limitPan]="true" zoomControlPosition="right-bottom" style="background-color: #ffffff !important">
    <img [src]="image" [width]="width" [height]="height" class="image" />
  </pinch-zoom>
  <div *ngIf="previewDocuments.length" class="arrow-wrapper arrow-line-right" (click)="showNextImage()">
    <svg-icon class="arrow" [src]="'./assets/images/arrow-down.svg'"></svg-icon>
  </div>
  <div class="close-holder" (click)="closeDialog()">
    <img src="./assets/images/close-white.svg" alt="close" class="close-icon" />
  </div>
  <div class="image-name" *ngIf="name">
    {{ name }}
  </div>
</div>
