import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentage',
  standalone: true,
})
export class PercentagePipe implements PipeTransform {
  transform(value: number | null | undefined, arg?: string): string {
    if (!value && value !== 0) {
      if (!arg) {
        return 'N/A';
      }
      return arg;
    }

    const percentage = value * 100;
    return `${percentage.toFixed(2)}%`;
  }
}
