import { Selector, createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchCriteria } from '../modules/jm-table/models/search-criteria.config';

import { ApplicationState, applicationStateName } from './application.state';

const selectApplicationState = createFeatureSelector<ApplicationState>(applicationStateName);

export const selectIsSettingsSideBarOpened: Selector<object, boolean> = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.isSettingsSideBarOpened
);

export const selectActiveDashboard: Selector<object, string | null> = createSelector(
  selectApplicationState,
  (state: ApplicationState) => state.activeDashboardId
);

export const getTableFilters = (tableId: string) =>
  createSelector(selectApplicationState, (state: ApplicationState): SearchCriteria | null => state.tableFilters[tableId] || null);
