import { Component } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'td[jm-order-progress-cell]',
  templateUrl: './jm-order-progress-cell.component.html',
})
export class JmOrderProgressCellComponent extends AbstractCellComponent {}
