<div class="table-global-filter-wrapper">
  <form class="quick-form form" *ngIf="form" id="table-global-filter">
    <quick-form-controls [form]="form" [config]="formConfig"> </quick-form-controls>
  </form>

  <div class="action-wrapper">
    <jm-button [class]="'red-button'" (clicked)="apply()" [suffixIcon]="'check'">
      {{ 'button.apply' | translate }}
    </jm-button>
    <jm-button [class]="'red-border-button'" (clicked)="cancel.emit()">
      {{ 'button.cancel' | translate }}
    </jm-button>
  </div>
</div>
