import { LookupResult } from '@quick-form';
import { SensorConfiguration } from '@sensor';
import { isSet } from '@util';

import { RealtimeSensor } from '../model';

export function sensorConfigurationToLookup(data: Array<SensorConfiguration>): Array<LookupResult> {
  const options: Array<LookupResult> = data.map((record) => {
    const option = {
      id: `${record.sensorId}`,
      name: `${record.label}`,
      additionalField: '',
    };

    if (isSet(record.unit)) {
      option.name = `${record.label} (${record.unit})`;
      option.additionalField = record.unit;
    }
    return option;
  });

  return options;
}

export function sensorDataToLookup(data: Array<RealtimeSensor>): Array<LookupResult> {
  const options: Array<LookupResult> = data.map((record) => {
    const { label, unit } = record.sensorConfiguration;

    const option = {
      id: `${record.sensorId}`,
      name: `${label}`,
      additionalField: '',
    };

    if (isSet(unit)) {
      option.name = `${label} (${unit})`;
      option.additionalField = unit;
    }
    return option;
  });

  return options;
}
