import { CellConfig } from 'src/app/modules/jm-table/models/cells.config';

import { Component, Input } from '@angular/core';

import { TableConfig } from '../../models';

@Component({
  selector: 'app-abstract-row',
  template: '',
})
export class AbstractRowComponent {
  @Input()
  columns: Array<CellConfig> = [];
  @Input()
  rowExpanded = false;
  @Input()
  rowIndex = 0;
  @Input()
  tableConfig: TableConfig = new TableConfig();

  trackByIndex(index: number, _: unknown): number {
    return index;
  }
}
