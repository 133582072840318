import { AngularSvgIconModule } from 'angular-svg-icon';
import { get } from 'lodash';
import { OrderProgressBarComponent } from 'src/app/shared/modules/order-progress-bar/order-progress-bar.component';
import { CustomDatePipe } from 'src/app/util/datetime/custom-date.pipe';
import { DateTimeFormatStyle } from 'src/app/util/datetime/format-style.enum';

import { LowerCasePipe, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MachineStatus } from '../../enums/machine-status.enum';
import { MachineStatsDTO } from '../../interfaces/machine-stats.interface';
import { MachineStatisticColumnComponent } from '../machine-statistic-column/machine-statistic-column.component';
import { MachineStatusComponent } from '../machine-status/machine-status.component';
import { statisticColumns } from './card.settings';
import { MachineProperty } from 'src/app/main/dashboard/constants/machine-property.constant';
import { isSet } from '@util';

@Component({
  selector: 'app-machine-card',
  standalone: true,
  imports: [
    AngularSvgIconModule,
    CustomDatePipe,
    LowerCasePipe,
    MachineStatisticColumnComponent,
    MachineStatusComponent,
    NgIf,
    NgFor,
    OrderProgressBarComponent,
    TranslateModule,
  ],
  templateUrl: './machine-card.component.html',
  styleUrls: ['./machine-card.component.scss'],
})
export class MachineCardComponent {
  @Input() data!: MachineStatsDTO;
  @Input() embedded = false;
  @Output() cardClick = new EventEmitter<void>();

  @HostListener('click', ['$event'])
  onClick() {
    this.cardClick.emit();
  }

  readonly machineCurrentStatus = MachineStatus;
  readonly dateStyle = DateTimeFormatStyle;
  readonly statisticColumns = statisticColumns;

  getValue(field: string): number | string {
    if (field === MachineProperty.Efficiency) {
      if (isSet(this.data.efficiency)) {
        return `${(this.data.efficiency * 100).toFixed(2)} %`;
      }

      return get(this.data, field);
    }

    return get(this.data, field);
  }
}
