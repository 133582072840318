import { pick } from 'lodash-es';
import { LocalStorageService } from 'src/app/local-storage.service';
import { isSet } from 'src/app/util/util';

import { Action, ActionReducer } from '@ngrx/store';

import { DashboardPersistentState } from './store/dashboard.state';

export function storageDashboardMetaReducer<DashboardState, A extends Action = Action>(
  saveKeys: Array<string>,
  localStorageKey: string,
  storageService: LocalStorageService
) {
  let onInit = true;
  return function (reducer: ActionReducer<DashboardState, A>) {
    return function (state: DashboardState, action: A): DashboardState {
      const nextState: DashboardState = reducer(state, action);
      if (onInit) {
        onInit = false;
        const rawState: DashboardPersistentState = storageService.getSavedState(localStorageKey) as DashboardPersistentState;
        if (isSet(rawState)) {
          return {
            ...state,
            ...rawState,
          };
        }
        return nextState;
      }
      const stateToSave = pick(nextState, saveKeys);
      storageService.setSavedState(stateToSave, localStorageKey);

      return nextState;
    };
  };
}
