<div class="textarea-wrapper">
  <label class="label" *ngIf="label"
    >{{ label | translate }} <span *ngIf="required">*</span
    ><span class="error-message" *ngIf="errorMessage">{{ errorMessage | translate }}</span></label
  >
  <div class="empty-label" *ngIf="emptyLabel"></div>
  <textarea
    class="{{ className }}"
    type="text"
    placeholder="{{ placeholder | translate }}"
    [(ngModel)]="value"
    [ngClass]="{ 'not-editable': nonEditable, invalid: invalid, readonly: readonly }"
    [disabled]="disabled"
    [readonly]="readonly"
    [maxLength]="maxLength || 1024"
    (afterValueChanged)="valueChange($event.target.value)"
  ></textarea>
  <div class="max-length" *ngIf="maxLength">
    {{ value ? value.toString().length : 0 }}/{{ maxLength }} {{ 'global.characters' | translate }}
  </div>
</div>
