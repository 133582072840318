import { Model } from 'ngx-super-model';

import { SensorConfigurationDTO } from '@sensor';

import { HistoricalReadingsDTO, HistoricalSensorDTO } from '../interfaces/historical-sensor.interface';

export class HistoricalSensor extends Model<HistoricalSensorDTO> {
  sensorConfigurations: Array<SensorConfigurationDTO> = [];
  readings: Array<HistoricalReadingsDTO> = [];
}
