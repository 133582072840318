<tour-step-template>
  <ng-template let-step="step">
    <mat-card
      (click)="$event.stopPropagation()"
      [style.width]="step.stepDimensions?.width"
      [style.min-width]="step.stepDimensions?.minWidth"
      [style.max-width]="step.stepDimensions?.maxWidth"
    >
      @if(step.title){
      <div class="tour-tile">
        {{ step.title }}
      </div>
      }

      <div class="content" [innerHTML]="step.content"></div>

      <div class="actions-wrapper">
        <div class="button prev" [class.disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
          <svg-icon class="icon-arrow left" [src]="'./assets/images/arrow-border.svg'"></svg-icon>
        </div>
        @if (tourService.hasNext(step) && !step.nextOnAnchorClick) {
        <div class="button next" (click)="tourService.next()">
          <svg-icon class="icon-arrow right" [src]="'./assets/images/arrow-border.svg'"></svg-icon>
        </div>
        }
        <div class="end" (click)="end()">
          {{ 'global.end' | translate }}
        </div>
      </div>
    </mat-card>
  </ng-template>
</tour-step-template>
