import { OperatorEnum } from 'src/app/util/operator.enum';
import { LicenseEnum } from '../enums/license.enum';

export function checkLicenses(
  userLicenses: Array<string>,
  requiredLicenses: Array<LicenseEnum>,
  operator: OperatorEnum = OperatorEnum.AND
): boolean {
  if (operator === OperatorEnum.OR) {
    return requiredLicenses.some((permission) => userLicenses?.includes(permission));
  }

  return requiredLicenses.every((permission) => userLicenses?.includes(permission));
}
