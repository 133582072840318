import { Model } from 'ngx-super-model';

import { AlertPriority, AlertSeverity, AlertType } from '../enums';
import { AlertDTO } from '../interfaces/alert.interface';

export class Alert extends Model<AlertDTO> {
  id: number | null = null;
  message: string | null = null;
  severity: AlertSeverity | null = null;
  priority: AlertPriority | null = null;
  createdAt: string | null = null;
  readAt: string | null = null;
  type: AlertType | null = null;
  typeId?: string | null = null;
}
