import { GridsterModule } from 'angular-gridster2';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartComponent } from 'src/app/layout/chart/chart.component';
import { ModalLayoutModule } from 'src/app/layout/partials/modal-layout/modal-layout.module';
import { NoticeComponent } from 'src/app/shared/components/notice/notice.component';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { RoundNumberPipe } from 'src/app/shared/pipes/round-number.pipe';
import { SharedModule } from 'src/app/shared/shared.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { LoaderComponent, ZoomButtonsComponent } from '@app-ui';
import { LegacyComponentsModule } from '@legacy-components';
import { TranslateModule } from '@ngx-translate/core';
import { QuickFormsModule } from '@quick-form';
import { PercentagePipe } from '@util';

import { AverageEfficiencyComponent } from './average-efficiency/average-efficiency.component';
import { AverageSpeedComponent } from './average-speed/average-speed.component';
import { AverageStopComponent } from './average-stop/average-stop.component';
import { ContentComponent } from './core/layout/main/content/content.component';
import { FooterComponent } from './core/layout/main/footer/footer.component';
import { HeaderComponent } from './core/layout/main/header/header.component';
import { TagComponent } from './core/layout/main/tag/tag/tag.component';
import { WidgetFormModalComponent } from './core/ui/add-widget-modal/widget-form-modal.component';
import { WidgetSelectComponent } from './core/ui/add-widget-modal/widget-select/widget-select.component';
import { WidgetComponent } from './core/ui/widget-factory.component';
import { EfficiencyPerShiftComponent } from './efficiency-per-shift/efficiency-per-shift.component';
import { HistoricalSensorComponent } from './historical-sensor/historical-sensor.component';
import { RealtimeSensorConfigurationComponent } from './realtime-sensor/realtime-sensor-configuration/realtime-sensor-configuration.component';
import { RealtimeSensorComponent } from './realtime-sensor/realtime-sensor.component';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [
    WidgetComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    WidgetFormModalComponent,
    TagComponent,
    AverageSpeedComponent,
    AverageEfficiencyComponent,
    AverageStopComponent,
    WidgetSelectComponent,
    EfficiencyPerShiftComponent,
    RealtimeSensorComponent,
    RealtimeSensorConfigurationComponent,
    HistoricalSensorComponent,
  ],
  imports: [
    CommonModule,
    GridsterModule,
    TranslateModule,
    LegacyComponentsModule,
    SharedModule,
    ModalLayoutModule,
    QuickFormsModule,
    AngularSvgIconModule,
    MatMenuModule,
    LoaderComponent,
    EmptyBlockModule,
    ZoomButtonsComponent,
    ChartComponent,
    RoundNumberPipe,
    NoticeComponent,
    PercentagePipe,
    DragDropModule,
    MatGridListModule,
  ],
  exports: [WidgetComponent],
})
export class WidgetModule {}
