import { Model } from 'ngx-super-model';

import { ReleaseNotesStatus } from '../enums/status.enum';
import { ReleaseNotesDTO } from '../interfaces/release-notes.interface';

export class ReleaseNotes extends Model<ReleaseNotesDTO> {
  version: string | null = null;
  message: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  status: ReleaseNotesStatus | null = null;
  // ToDo => this must be file: File
  name: string | null = null;
  url: string | null = null;
  type: string | null = null;
  extension: string | null = null;
  size: number | null = null;
}
