@if(showFrick || showComez){
<h3 class="main-title">{{ 'machines.controls.machineStoppedAlert' | translate }}</h3>
<div class="stopped-control">
  <div class="stopped-control__header">
    <h5 class="stopped-title">{{ 'dashboards.labels.stop-reason' | translate }}</h5>
    <div class="toggle">
      <app-sb-slide-toggle
        [value]="control.value.checked"
        (toggleChange)="onStopAlertToggle('machineStoppedAlert', $event)"
        [disabled]="control.disabled"
      ></app-sb-slide-toggle>
    </div>
  </div>
  <div class="stopped-control__data">
    @if(showFrick){
    <div class="stopped-status">
      <div class="stopped-status__title">
        {{ 'machines.controls.weaving' | translate }}
      </div>
      <div class="stopped-status__alert">
        <div class="item" *ngFor="let control of formConfigFrickStatus">
          <app-sb-checkbox
            [checked]="this.formFrickStatus?.get(control.id)?.value"
            (onChangeCheckboxValue)="onStatusToggle(control.id, $event, 'FRICK')"
            [label]="control.label || '' | translate"
          ></app-sb-checkbox>
        </div>
      </div>
    </div>
    } @if(showComez){
    <div class="stopped-status">
      <div class="stopped-status__title">
        {{ 'machines.controls.knitting' | translate }}
      </div>
      <div class="stopped-status__alert">
        <div class="item" *ngFor="let control of formConfigComezStatus">
          <app-sb-checkbox
            [checked]="this.formComezStatus?.get(control.id)?.value"
            (onChangeCheckboxValue)="onStatusToggle(control.id, $event, 'COMEZ')"
            [label]="control.label || '' | translate"
          ></app-sb-checkbox>
        </div>
      </div>
    </div>
    }
  </div>
</div>
}
