import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';

import { Component, Input, OnInit, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { selectDashboardPeriod } from '../../custom-dashboard/store/dashboard.selector';

import { FooterTag } from '../core/interfaces/footer-tag.interface';
import { Widget } from '../core/models/widgets/widget.model';
import { speedChartConfig } from './chart-config';
import { dataToAverageSpeedChart } from './mappers/data-to-chart.mappers';
import { AverageSpeedService } from './average-speed.service';
import { PeriodDTO } from '../core/interfaces/period.interface';
import { periodToBucketSize, statePeriodToStartEndDate } from '../core/mappers/period-mapper';
import { SingleMachineRequest } from '../core/interfaces/chart-request.interface';
import { Period } from 'src/app/util/period/period.interface';
import { TagTypeEnum } from '../core/interfaces/tags.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UNIT } from './average-speed.constants';

@UntilDestroy()
@Component({
  selector: 'widget-average-speed',
  templateUrl: './average-speed.component.html',
  styleUrls: ['./average-speed.component.scss'],
})
export class AverageSpeedComponent implements OnInit {
  @Input() widget: Widget = new Widget();
  @Input()
  dashboardId: string | undefined | null = null;

  value = 0;
  percentage = 0;
  chartConfig: ChartConfiguration = cloneDeep(speedChartConfig);
  loading = true;
  tags: Array<FooterTag> = [];
  error = false;
  errorDescription: string | null = null;
  noData = false;

  readonly unit = UNIT;

  private readonly store$ = inject(Store);
  private readonly averageSpeedService = inject(AverageSpeedService);

  ngOnInit() {
    if (!this.dashboardId) {
      return;
    }
    this.store$.pipe(select(selectDashboardPeriod(this.dashboardId)), untilDestroyed(this)).subscribe((period: Period) => {
      this.loadData(period);
    });

    this.populateTags();
  }

  private populateTags() {
    const location = this.widget.config.location;
    const machine = this.widget.config.machine;

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: machine.name,
      },
    ];
  }

  private loadData(period: Period): void {
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(period);
    const bucketSize = periodToBucketSize(mappedPeriod);

    this.noData = false;
    this.error = false;
    this.loading = true;

    const request: SingleMachineRequest = {
      startDate: mappedPeriod.startDate,
      endDate: mappedPeriod.endDate,
      machineGuid: this.widget.config.machine.id,
      bucketSize,
    };

    this.averageSpeedService
      .getData(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (response.buckets.length === 0) {
            this.noData = true;
            return;
          }
          this.value = response.average;
          this.chartConfig.data = dataToAverageSpeedChart(response.buckets, mappedPeriod);
          this.loading = false;
        },
        error: (error: Error) => {
          this.loading = false;
          this.error = true;

          if (error.message === 'Invalid location') {
            this.errorDescription = 'global.errors.location-not-found-widget';
          }
        },
      });
  }
}
