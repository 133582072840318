import { CellType } from 'src/app/modules/jm-table/enum/cell-type.enum';
import { CellConfigDTO } from 'src/app/modules/jm-table/interfaces/cell-config.interface';
import { DateTimeFormatStyle } from 'src/app/util/datetime/format-style.enum';

export const performanceDetailsCellConfig: Array<CellConfigDTO> = [
  {
    type: CellType.STRING,
    field: 'articleName',
    title: 'performance-dashboard.performance-details.table.columns.articleName',
    cssClass: ['small'],
    sortingField: 'articleName',
    sortable: true,
  },
  {
    type: CellType.DATE_TIME,
    field: 'lastFinished',
    title: 'performance-dashboard.performance-details.table.columns.lastFinished',
    cssClass: ['small'],
    sortingField: 'lastFinished',
    sortable: true,
    meta: {
      dateFormat: DateTimeFormatStyle.date,
      hiddenMobile: true,
    },
  },
  {
    type: CellType.NUMBER,
    field: 'quantityInMeters',
    title: 'performance-dashboard.performance-details.table.columns.quantityInMeters',
    cssClass: ['small'],
    sortingField: 'quantityInMeters',
    sortable: true,
    meta: {
      roundNumber: 0,
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'targetTime',
    title: 'performance-dashboard.performance-details.table.columns.targetTime',
    cssClass: ['small'],
    sortingField: 'targetTime',
    sortable: true,
    meta: {
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'actualTime',
    title: 'performance-dashboard.performance-details.table.columns.actualTime',
    cssClass: ['small'],
    sortingField: 'actualTime',
    sortable: true,
    meta: {
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'diffTime',
    title: 'performance-dashboard.performance-details.table.columns.diffTime',
    cssClass: ['small'],
    sortingField: 'diffTime',
    sortable: true,
    meta: {
      hiddenMobile: true,
      hiddenTablet: true,
    },
  },
  {
    type: CellType.NUMBER,
    field: 'revenue',
    title: 'performance-dashboard.performance-details.table.columns.revenue',
    cssClass: ['small'],
    unit: true,
    sortingField: 'revenue',
    sortable: true,
    meta: {
      roundNumber: 0,
      hiddenMobile: true,
      hiddenTablet: true,
    },
  },
  {
    type: CellType.NUMBER,
    field: 'cost',
    title: 'performance-dashboard.performance-details.table.columns.cost',
    cssClass: ['small'],
    unit: true,
    sortingField: 'cost',
    sortable: true,
    meta: {
      roundNumber: 0,
      hiddenMobile: true,
      hiddenTablet: true,
    },
  },
  {
    type: CellType.NUMBER,
    field: 'profit',
    title: 'performance-dashboard.performance-details.table.columns.profit',
    cssClass: ['small'],
    unit: true,
    sortingField: 'profit',
    sortable: true,
    meta: {
      roundNumber: 0,
      hiddenMobile: true,
      hiddenTablet: true,
    },
  },
];
