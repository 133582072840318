import { UserGroupEnum } from 'src/app/enum';

import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { LoginState, loginStateName } from './login.state';
import { IUserNotification } from 'src/app/interfaces/user-notification.interface';
import { JMPermissions } from '@auth';
import { PushNotificationDevicesDTO, User } from '@user';

const selectLoginSelector = createFeatureSelector<LoginState>(loginStateName);

const selectLoginState: Selector<object, LoginState> = createSelector(selectLoginSelector, (state: LoginState) => state);

export const selectUserPermissions: Selector<object, Array<JMPermissions>> = createSelector(
  selectLoginState,
  (state: LoginState) => state.user.permissions
);

export const selectUserInfo: Selector<object, User> = createSelector(selectLoginState, (state: LoginState) => state.user);

export const selectUserName: Selector<object, string | null> = createSelector(selectLoginState, (state: LoginState) => state.user.name);

export const selectUserEmail: Selector<object, string | null> = createSelector(selectLoginState, (state: LoginState) => state.user.email);

export const selectUserNotificationEuids: Selector<object, Array<PushNotificationDevicesDTO>> = createSelector(
  selectLoginState,
  (state: LoginState) => state.user?.pushNotificationDevices || []
);

export const selectUserGroup: Selector<object, UserGroupEnum | undefined> = createSelector(
  selectLoginState,
  (state: LoginState) => state.user?.group?.id
);

export const selectUserCompanyId: Selector<object, number | undefined> = createSelector(
  selectLoginState,
  (state: LoginState) => state.user?.company?.id
);

export const selectActiveLicenses: Selector<object, Array<string> | null> = createSelector(
  selectLoginState,
  (state: LoginState) => state.user.company?.activeLicenses.map((license) => license.type) || null
);

export const selectBackendVersion: Selector<object, string> = createSelector(
  selectLoginState,
  (state: LoginState) => state.backendVersion || ''
);

export const selectPasswordResetLoader: Selector<object, boolean> = createSelector(
  selectLoginState,
  (state: LoginState) => state.passwordResetLoader
);

export const selectNotification: Selector<object, IUserNotification | null> = createSelector(
  selectLoginState,
  (state: LoginState) => state.notification
);

export const selectLoginLoader: Selector<object, boolean> = createSelector(selectLoginState, (state: LoginState) => state.loginLoader);
