import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';

import { PerformanceDTO } from './interfaces';
import { Performance } from './models';
import { ApolloQueryResult } from '@apollo/client';
import { ResponseData } from '../../abstract/interface/response-data';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService extends AbstractCRUDService<Performance, PerformanceDTO> {
  override model = Performance;

  getPerformance(input: PerformanceInput): Observable<Performance> {
    const query = gql`
      query locationPerformance($input: OEEInput!) {
        locationPerformance(input: $input)
      }
    `;

    return this.apollo
      .query<any>({ query, variables: { input } })
      .pipe(map((response: ApolloQueryResult<ResponseData<number>>) => this.loadModel({ value: response.data.locationPerformance })));
  }
}
