import { COMEZ_STATUS, FRICK_STATUS, machineComezStatusColorMapper, machineFrickStatusColorMapper } from '@machines';

export const chartTemplate = [
  {
    id: FRICK_STATUS.STOP_NO,
    label: 'Running',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_NO),
  },
  {
    id: FRICK_STATUS.STOP_REEDCOVER,
    label: 'Reed cover',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_REEDCOVER),
  },
  {
    id: FRICK_STATUS.STOP_END_OF_ORDER,
    label: 'End of order',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_END_OF_ORDER),
  },
  {
    id: FRICK_STATUS.STOP_BINDER,
    label: 'Binder',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_BINDER),
  },
  {
    id: FRICK_STATUS.STOP_WEFT,
    label: 'Weft',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_WEFT),
  },
  {
    id: FRICK_STATUS.STOP_FABRIC_OVERROLL,
    label: 'Fabric overroll',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_FABRIC_OVERROLL),
  },
  {
    id: FRICK_STATUS.STOP_EXTERNAL_STOP,
    label: 'External stop',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_EXTERNAL_STOP),
  },
  {
    id: FRICK_STATUS.STOP_WARP,
    label: 'Warp',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_WARP),
  },
  {
    id: FRICK_STATUS.STOP_OTHER,
    label: 'Other',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_OTHER),
  },
  {
    id: FRICK_STATUS.STOP_OFFLINE,
    label: 'Offline',
    data: [],
    backgroundColor: machineFrickStatusColorMapper.get(FRICK_STATUS.STOP_OFFLINE),
  },
  {
    id: COMEZ_STATUS.INIT_START,
    label: 'Start',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.INIT_START),
  },
  {
    id: COMEZ_STATUS.INIT_INITIALIZATION_IN_PROGRESS,
    label: 'Initialization in progress',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.INIT_INITIALIZATION_IN_PROGRESS),
  },
  {
    id: COMEZ_STATUS.INIT_INIT,
    label: 'Init',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.INIT_INIT),
  },
  {
    id: COMEZ_STATUS.SETUP_CONFIGURE_MACHINE,
    label: 'Configure machine',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_CONFIGURE_MACHINE),
  },
  {
    id: COMEZ_STATUS.SETUP_LOAD_CHECK_PATTERN,
    label: 'Load check pattern',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_LOAD_CHECK_PATTERN),
  },
  {
    id: COMEZ_STATUS.SETUP_EXECUTE_HOME,
    label: 'Execute home',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_EXECUTE_HOME),
  },
  {
    id: COMEZ_STATUS.SETUP_WAITING_FOR_FIRST_LINE_RECALL,
    label: 'Waiting for first line recall',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_WAITING_FOR_FIRST_LINE_RECALL),
  },
  {
    id: COMEZ_STATUS.SETUP_OK,
    label: 'OK',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_OK),
  },
  {
    id: COMEZ_STATUS.SETUP_INCORRECT_ANGLES,
    label: 'Incorrect angles',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.SETUP_INCORRECT_ANGLES),
  },
  {
    id: COMEZ_STATUS.ACTIVATION_LOADING_PATTERN,
    label: 'Loading pattern',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ACTIVATION_LOADING_PATTERN),
  },
  {
    id: COMEZ_STATUS.ACTIVE_PATTERN_EXECUTING,
    label: 'Pattern executing',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ACTIVE_PATTERN_EXECUTING),
  },
  {
    id: COMEZ_STATUS.ACTIVE_CHECK_PATTERN,
    label: 'Check pattern',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ACTIVE_CHECK_PATTERN),
  },
  {
    id: COMEZ_STATUS.ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS,
    label: 'First line recall in progress',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS),
  },
  {
    id: COMEZ_STATUS.ERROR_FILE,
    label: 'File',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_FILE),
  },
  {
    id: COMEZ_STATUS.ERROR_INTERNAL,
    label: 'Internal',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_INTERNAL),
  },
  {
    id: COMEZ_STATUS.ERROR_I_O,
    label: 'I/0',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_I_O),
  },
  {
    id: COMEZ_STATUS.ERROR_CAN,
    label: 'Offline',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_CAN),
  },
  {
    id: COMEZ_STATUS.ERROR_ACTUATORS,
    label: 'Actuators',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_ACTUATORS),
  },
  {
    id: COMEZ_STATUS.ERROR_OPERATION,
    label: 'Operation',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_OPERATION),
  },
  {
    id: COMEZ_STATUS.ERROR_DEVICE,
    label: 'Device',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_DEVICE),
  },
  {
    id: COMEZ_STATUS.ERROR_AUTOMATIC_CYCLE,
    label: 'Automatic cycle',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_AUTOMATIC_CYCLE),
  },
  {
    id: COMEZ_STATUS.ERROR_INVERTER,
    label: 'Inverter',
    data: [],
    backgroundColor: machineComezStatusColorMapper.get(COMEZ_STATUS.ERROR_INVERTER),
  },
];
