import { ObjectLiteral } from 'ngx-super-model/types/object-literal';
import { map, Observable, tap } from 'rxjs';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { camelCaseToWords, isSet } from 'src/app/util/util';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ReleaseNotesInputDTO } from '../interfaces/release-notes-input.interface';
import { ReleaseNotesDTO } from '../interfaces/release-notes.interface';
import { ReleaseNotes } from '../models/release-notes.model';
import activeReleaseNotes from './queries/active-release-notes.graphql';
import createReleaseNotes from './queries/create-release-notes.graphql';
import deleteReleaseNotes from './queries/delete-release-notes.graphql';
import markReleaseNotesSeen from './queries/mark-release-note.graphql';
import releaseNotes from './queries/release-notes.graphql';
import updateReleaseNotes from './queries/update-release-notes.graphql';
import { GetAllOptionsDTO, ListResultDTO, SearchCriteriaDTO } from '@jm-table';
import getAll from './queries/get-all.graphql';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesService extends AbstractCRUDService<ReleaseNotes, ReleaseNotesDTO> {
  override modelName = 'releaseNotes';
  override modelNamePlural = 'releaseNotesList';

  override model = ReleaseNotes;

  override readonly fields: string = `
    version
    message
    startDate
    endDate
    status
    name
    url
    type
    extension
    size
  `;

  getByVersion(version: string): Observable<ReleaseNotes> {
    return this.apollo
      .query<ReleaseNotesDTO>({
        query: releaseNotes,
        variables: {
          version,
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'release-notes.error.get-one');
        }),
        map((response: ApolloQueryResult<ReleaseNotesDTO>) => new ReleaseNotes().loadModel((response.data as ObjectLiteral).releaseNotes))
      );
  }

  deleteByVersion(version: string): Observable<ReleaseNotes> {
    return this.apollo
      .mutate<ReleaseNotesDTO>({
        mutation: deleteReleaseNotes,
        variables: {
          version,
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'release-notes.error.delete');
        }),
        tap(() => {
          this.snackBarService.success('release-notes.success.delete');
        }),
        map((response: ApolloQueryResult<ReleaseNotesDTO>) =>
          new ReleaseNotes().loadModel((response.data as ObjectLiteral).deleteReleaseNotes)
        )
      );
  }

  markReleaseNotesSeen(version: string): Observable<ReleaseNotes> {
    return this.apollo
      .mutate<ReleaseNotesDTO>({
        mutation: markReleaseNotesSeen,
        variables: {
          version,
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'release-notes.error.mark-seen');
        }),
        map((response: ApolloQueryResult<ReleaseNotesDTO>) =>
          new ReleaseNotes().loadModel((response.data as ObjectLiteral).markReleaseNotesSeen)
        )
      );
  }

  getActive(filterSeen?: boolean): Observable<ReleaseNotes | null> {
    return this.apollo
      .query<ReleaseNotesDTO>({
        query: activeReleaseNotes,
        variables: {
          filterSeen,
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'release-notes.error.get-one');
        }),
        map((response: ApolloQueryResult<ReleaseNotesDTO>) => {
          const note = (response.data as ObjectLiteral).activeReleaseNotes;
          if (!isSet(note)) {
            return null;
          }
          return new ReleaseNotes().loadModel(note);
        })
      );
  }

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<ReleaseNotes>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<ReleaseNotesDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<ReleaseNotesDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override create(input: ReleaseNotesInputDTO): Observable<ReleaseNotes> {
    return this.apollo.mutate<ReleaseNotesDTO>({ mutation: createReleaseNotes, variables: { input } }).pipe(
      catchAllErrors((error) => {
        this.handleMessage(error, this.translateService.instant('global.errors.create', { modelName: 'Release notes' }));
      }),
      tap(() => {
        this.snackBarService.success(this.translateService.instant('global.success.create', { modelName: 'Release notes' }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ReleaseNotesDTO }>) => {
        return this.loadModel(response.data['createReleaseNotes']);
      })
    );
  }

  override update(input: ReleaseNotesInputDTO): Observable<ReleaseNotes> {
    return this.apollo.mutate<ReleaseNotesDTO>({ mutation: updateReleaseNotes, variables: { input } }).pipe(
      catchAllErrors((error) => {
        this.handleMessage(error, this.translateService.instant('global.errors.update', { modelName: 'Release notes' }));
      }),
      tap(() => {
        this.snackBarService.success(this.translateService.instant('global.success.update', { modelName: 'Release notes' }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ReleaseNotesDTO }>) => {
        return this.loadModel(response.data['updateReleaseNotes']);
      })
    );
  }
}
