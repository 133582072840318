export enum TableEnum {
  articles = 'articles',
  articlesHistory = 'articlesHistory',
  auditLogs = 'auditLogs',
  companies = 'companies',
  licenses = 'licenses',
  locations = 'locations',
  machines = 'machines',
  mconVersions = 'mconVersions',
  notifications = 'notifications',
  oeeByMachineCategory = 'oeeByMachineCategory',
  oeeByMachine = 'oeeByMachine',
  orders = 'orders',
  performanceDetails = 'performanceDetails',
  productionDetails = 'productionDetails',
  rawEvents = 'rawEvents',
  releaseNotes = 'releaseNotes',
  rootTemplates = 'rootTemplates',
  savedTemplates = 'savedTemplates',
  scheduledNotifications = 'scheduledNotifications',
  sustainabilityDetails = 'sustainabilityDetails',
  users = 'users',
  sensors = 'sensors',
}
