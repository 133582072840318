<app-box [loading]="loader">
  <ng-container header>
    {{ 'performance-dashboard.performance.title' | translate }}
    <svg-icon (click)="onIconClick()" class="question-icon" [src]="'./assets/images/question-mark.svg'"></svg-icon>
  </ng-container>

  <ng-container content>
    <div class="data-wrapper" *ngIf="!loader">
      <ng-container *ngIf="!error">
        <span class="value">{{ data?.value | percentage }}</span>
        <app-progress-bar [progressBar]="progressBar"></app-progress-bar>
      </ng-container>

      <app-empty-block
        *ngIf="error"
        [title]="'global.errors.error' | translate"
        [image]="'./assets/images/machines-error.svg'"
        [description]="'global.errors.could-not-fetch-data' | translate"
      >
      </app-empty-block>
    </div>
  </ng-container>
</app-box>
