import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appControlAttributes]',
})
export class AttributesDirective implements OnInit {
  @Input() appControlAttributes: any;
  constructor(private readonly el: ElementRef) {}

  ignoreAttributes = ['class', 'mask', 'date', 'tooltip'];

  ngOnInit() {
    Object.entries(this.appControlAttributes).forEach(([key, value]) => {
      if (key === 'autofocus') {
        this.el.nativeElement.focus();
        return;
      }

      if (value && !this.isAttributeIgnored(key)) {
        this.el.nativeElement.setAttribute(key, value);
      }
    });
  }

  private isAttributeIgnored(name: string) {
    let ignoreAttribute = false;
    this.ignoreAttributes.forEach((attribute) => {
      if (attribute === name) {
        ignoreAttribute = true;
      }
    });
    return ignoreAttribute;
  }
}
