export enum JMPermissions {
  ModuleDashboard = 'MODULE_DASHBOARD',
  ModuleLibrary = 'MODULE_LIBRARY',
  ModuleAdmin = 'MODULE_ADMIN',
  ModuleAnalytics = 'MODULE_ANALYTICS',

  AnalyticsViewAny = 'ANALYTICS_VIEW_ANY',
  AnalyticsViewOwn = 'ANALYTICS_VIEW_OWN',
  AnalyticsAdmin = 'ANALYTICS_ADMIN',
  AnalyticsUser = 'ANALYTICS_USER',

  CompanyViewAny = 'COMPANY_VIEW_ANY',
  CompanyViewOwn = 'COMPANY_VIEW_OWN',
  CompanyManageAny = 'COMPANY_MANAGE_ANY',
  CompanyManageOwn = 'COMPANY_MANAGE_OWN',

  LocationViewAny = 'LOCATION_VIEW_ANY',
  LocationViewOwn = 'LOCATION_VIEW_OWN',
  LocationManageAny = 'LOCATION_MANAGE_ANY',
  LocationManageOwn = 'LOCATION_MANAGE_OWN',

  UserViewAny = 'USER_VIEW_ANY',
  UserViewOwn = 'USER_VIEW_OWN',
  UserManageAny = 'USER_MANAGE_ANY',
  UserManageOwn = 'USER_MANAGE_OWN',

  MachineCategoriesManageAny = 'MACHINE_CATEGORIES_MANAGE_ANY',
  MachineCategoriesManageOwn = 'MACHINE_CATEGORIES_MANAGE_OWN',

  MachineTypesManageAny = 'MACHINE_TYPES_MANAGE_ANY',
  MachineTypesManageOwn = 'MACHINE_TYPES_MANAGE_OWN',

  ArticleViewAny = 'ARTICLE_VIEW_ANY',
  ArticleViewOwn = 'ARTICLE_VIEW_OWN',
  ArticleManageAny = 'ARTICLE_MANAGE_ANY',
  ArticleManageOwn = 'ARTICLE_MANAGE_OWN',

  OrderViewAny = 'ORDER_VIEW_ANY',
  OrderViewOwn = 'ORDER_VIEW_OWN',
  OrderManageAny = 'ORDER_MANAGE_ANY',
  OrderManageOwn = 'ORDER_MANAGE_OWN',

  OrderSendAny = 'ORDER_SEND_ANY',
  OrderSendOwn = 'ORDER_SEND_OWN',

  UserImpersonateAny = 'USER_IMPERSONATE_ANY',

  LicenseManageAny = 'LICENSE_MANAGE_ANY',
  LicenseViewAny = 'LICENSE_VIEW_ANY',
  LicenseViewOwn = 'LICENSE_VIEW_OWN',
  LicenseAllocateAny = 'LICENSE_ALLOCATE_ANY',
  LicenseAllocateOwn = 'LICENSE_ALLOCATE_OWN',

  LicenseEditor = 'LICENSE_EDITOR',
  LicenseOrderManagement = 'LICENSE_ORDER_MANAGEMENT',
  LicenseBasic = 'LICENSE_BASIC',

  ArticleRootTemplateManageAny = 'ARTICLE_ROOT_TEMPLATE_MANAGE_ANY',

  AuditLogViewAny = 'AUDIT_LOG_VIEW_ANY',
  AuditLogViewOwn = 'AUDIT_LOG_VIEW_OWN',

  MconVersionsManage = 'MCON_VERSIONS_MANAGE',

  EventViewAny = 'EVENT_VIEW_ANY',

  UserNotificationsManageAny = 'USER_NOTIFICATIONS_MANAGE_ANY',
  UserNotificationsViewAny = 'USER_NOTIFICATIONS_VIEW_ANY',

  ReleaseNotesManageAny = 'RELEASE_NOTES_MANAGE_ANY',
  ReleaseNotesViewAny = 'RELEASE_NOTES_VIEW_ANY',

  SensorConfigurationViewAny = 'SENSOR_CONFIGURATION_VIEW_ANY',
  SensorConfigurationViewOwn = 'SENSOR_CONFIGURATION_VIEW_OWN',
  SensorConfigurationManageAny = 'SENSOR_CONFIGURATION_MANAGE_ANY',
  SensorConfigurationManageOwn = 'SENSOR_CONFIGURATION_MANAGE_OWN',

  DashboardViewAny = 'DASHBOARD_VIEW_ANY',
  DashboardViewOwn = 'DASHBOARD_VIEW_OWN',
  DashboardManageAny = 'DASHBOARD_MANAGE_ANY',
  DashboardManageOwn = 'DASHBOARD_MANAGE_OWN',

  AllPermission = 'ALL_PERMISSIONS',
}
