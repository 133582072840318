@if(rowExpanded){
<td jm-toggle-cell [expanded]="expanded" [rowData]="rowData" class="toggle-row-cell hidden-mobile"></td>
} @if(!rowExpanded && !tableConfig.reorderColumns){
<td class="toggle-row-cell empty-space hidden-mobile"></td>

}
<ng-template ngFor let-column [ngForOf]="columns" [ngForTrackBy]="trackByIndex" let-i="index" let-last="last">
  @if(column.display){
  <td
    jm-number-cell
    *ngIf="column.type === cellType.NUMBER"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-date-time-cell
    *ngIf="column.type === cellType.DATE_TIME"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-string-cell
    *ngIf="column.type === cellType.STRING"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-relation-cell
    *ngIf="column.type === cellType.RELATION"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-data-translation-cell
    *ngIf="column.type === cellType.DATA_TRANSLATION"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-array-cell
    *ngIf="column.type === cellType.ARRAY"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-assigned-cell
    *ngIf="column.type === cellType.ASSIGNED"
    [assigned]="rowData[column.assigned]"
    [total]="rowData[column.total]"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-order-progress-cell
    *ngIf="column.type === cellType.ORDER_PROGRESS"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  ></td>
  <td
    jm-time-cell
    *ngIf="column.type === cellType.TIME"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td
    jm-oee-cell
    *ngIf="column.type === cellType.OEE"
    [cellData]="rowData"
    [column]="column"
    [ngClass]="column?.meta?.columnClass"
    [class.hidden-mobile]="column?.meta?.hiddenMobile"
    [class.hidden-tablet]="column?.meta?.hiddenTablet"
    [class.hidden-desktop]="column?.meta?.hiddenDesktop"
  >
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td jm-tag-cell *ngIf="column?.type === cellType.TAG" [cellData]="rowData" [column]="column">
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td jm-order-name *ngIf="column?.type === cellType.ORDER_NAME" [cellData]="rowData" [column]="column">
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  <td jm-machine-status *ngIf="column?.type === cellType.MACHINE_STATUS" [cellData]="rowData" [column]="column">
    <jm-extra-mobile-cell
      *ngIf="!!getExtraMobileCell(column?.meta?.extraMobileField)"
      [rowData]="rowData"
      [column]="getExtraMobileCell(column?.meta?.extraMobileField)"
    ></jm-extra-mobile-cell>
  </td>
  }
  <div
    class="more-options"
    *ngIf="last && deleteButton?.active"
    [class.disabled]="!rowData[deleteButton.keyForDisabling]"
    (click)="onMoreOptions($event, rowData)"
    tooltipPosition="top"
    [pTooltip]="deleteButton.tooltip || '' | translate"
  >
    <svg-icon class="option-icon" [src]="'./assets/images/' + (deleteButton.icon || 'bin') + '.svg'"></svg-icon>
  </div>
  <div
    class="more-options"
    *ngIf="last && dequeueButton?.active && rowData[dequeueButton.keyForDisabling]"
    (click)="onMoreOptions($event, rowData)"
    tooltipPosition="top"
    [pTooltip]="'orders.dequeue-order' | translate"
  >
    <svg-icon class="option-icon" [src]="'./assets/images/dequeue.svg'"></svg-icon>
  </div>
  <div
    class="more-options"
    *ngIf="last && moreOptions.length > 0"
    [tourAnchor]="'moreOptions-' + rowIndex"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="optionMenu"
    #optionsMenuTrigger="matMenuTrigger"
    [matMenuTriggerData]="{
      moreOptions: moreOptions
    }"
  >
    <svg-icon class="option-icon" [src]="'./assets/images/3-dots.svg'"></svg-icon>
  </div>
</ng-template>

<mat-menu #optionMenu="matMenu" xPosition="before" class="jm-table-action-menu">
  <ng-template matMenuContent let-options="moreOptions" let-i="index">
    <ng-template #defaultTemplate>
      <div class="option" *ngFor="let option of options" (click)="optionsSelected.emit({option, data:rowData})">
        <svg-icon class="option-icon" [src]="'./assets/images/' + option.icon"></svg-icon>
        <span class="label">{{ 'actions.' + option.label | translate }}</span>
      </div>
    </ng-template>
    <ng-container [ngTemplateOutlet]="matMenuTemplate || defaultTemplate" [ngTemplateOutletContext]="{ $implicit: rowData }">
    </ng-container>
  </ng-template>
</mat-menu>
