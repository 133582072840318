import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlConfig } from '../../../models/control.config';
import { isSet } from 'src/app/util/util';

@Component({
  selector: 'quick-form-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorPickerComponent),
    },
  ],
})
export class ColorPickerComponent implements ControlValueAccessor {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Input()
  checked = false;

  @Output()
  valueChanged = new EventEmitter<string>();

  value = '';

  readonly colorFormat = 'hex';
  readonly colorPickerControls = 'no-alpha';

  onColorChange(value: string | null): void {
    if (!isSet(value) || value?.length === 0) {
      return;
    }
    this.control.markAsTouched();
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  onValueChanged(event: Event) {
    const value = (event.target as HTMLInputElement).value || null;

    if (!isSet(value)) {
      return;
    }

    this.control.markAsTouched();
    this.writeValue(value);
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  // eslint-disable-next-line
  private propagateChange = (value: any) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
