import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from 'src/app/core/user/interfaces/user.interface';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetAllOptionsDTO } from '@jm-table';
import { MACHINE_PLATFORM } from '@machines';
import { camelCaseToWords, isSet } from '@util';

import { LookupResult, LookupService } from '../../modules/quick-forms';
import { AbstractCRUDService } from '../abstract-crud.service';
import { listItemsToLookupResult } from '../helpers/lookup-mapper.helper';
import deleteMutation from './gql/delete.graphql';
import getAll from './gql/get-all.graphql';
import oneQuery from './gql/one.graphql';
import userPermissionToPlatform from './gql/userPermissionToPlatform.graphql';
import { User } from './models/user.model';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractCRUDService<User, UserDTO> implements LookupService {
  override modelName = 'user';
  override modelNamePlural = 'users';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  override model = User;

  override readonly fields: string = `
    id
    name
    email
    company {
      id
      name
    }
    group {
      id
      name
    }
    permissions
    isAdmin
    isActive
    validFrom
    validUntil
    locations {
      id
      name
    }
    createdAt
    updatedAt
    lastSignIn {
      timestamp
      ipAddress
      country
    }
  `;

  impersonateUser(id: number): Observable<{ token: string }> {
    const input = {
      id,
    };

    const mutation = gql`
      mutation impersonateUser($input: ImpersonateUserInput!) {
        impersonateUser(input: $input) {
          token
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          input,
        },
      })
      .pipe(map((response: any) => response.data['impersonateUser']));
  }

  getUserPermissionToPlatform(): Observable<Array<MACHINE_PLATFORM>> {
    return this.apollo.query<UserDTO>({ query: userPermissionToPlatform }).pipe(
      catchAllErrors((error: ObjectLiteral) => {
        this.handleMessage(error, 'users.error.permission');
      }),
      map((response: ApolloQueryResult<{ [key: string]: UserDTO }>) => {
        const uniquePlatforms: Set<MACHINE_PLATFORM> = new Set();

        response.data.me.company.locations.forEach((location) => {
          location?.machines?.forEach((machine) => {
            if (isSet(machine.platform)) {
              uniquePlatforms.add(machine.platform as MACHINE_PLATFORM);
            }
          });
        });

        return Array.from(uniquePlatforms);
      })
    );
  }

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<User>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<UserDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<UserDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override create(model: any, options?: any): Observable<any> {
    const mutation = gql`
      ${this.createQuery}
    `;

    return this.apollo.mutate<UserDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors((error: ObjectLiteral) => {
        this.handleMessage(error, 'users.error.create');
      }),
      map((response: ApolloQueryResult<{ [key: string]: UserDTO }>) => {
        this.snackBarService.success('users.success.create');
        const mutation = `addUser`;
        return response.data[mutation];
      })
    );
  }

  override update(model: any, options?: any): Observable<any> {
    const mutation = gql`
      ${this.updateQuery}
    `;

    return this.apollo.mutate<UserDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors((error: ObjectLiteral) => {
        this.handleMessage(error, 'users.error.update');
      }),
      map((response: ApolloQueryResult<{ [key: string]: UserDTO }>) => {
        this.snackBarService.success('users.success.update');
        const mutation = `updateUser`;
        return this.loadModel(response.data[mutation]);
      })
    );
  }

  protected override get updateQuery(): string {
    return `
      mutation updateUser(
        $id: Int!
        $name: String!
        $userGroupId: ID!
        $isAdmin: Boolean!
        $validFrom: Date!
        $validUntil: Date
        $locationIds: [Int!]!
      ) {
        updateUser(
          id: $id
          name: $name
          userGroupId: $userGroupId
          isAdmin: $isAdmin
          validFrom: $validFrom
          validUntil: $validUntil
          locationIds: $locationIds
        ) {
          ${this.fields}
        }
      }
    `;
  }

  protected override get createQuery(): string {
    return `
      mutation addUser(
        $email: String!
        $name: String!
        $userGroupId: ID!
        $isAdmin: Boolean!
        $validFrom: Date!
        $validUntil: Date
        $locationIds: [Int!]!
        $companyId: Int
      ) {
        addUser(
          email: $email
          name: $name
          userGroupId: $userGroupId
          isAdmin: $isAdmin
          validFrom: $validFrom
          validUntil: $validUntil
          locationIds: $locationIds
          companyId: $companyId
        ) {
          ${this.fields}
        }
      }
    `;
  }

  getSuggestions(searchCriteria: SearchCriteriaDTO): Observable<ListResultDTO<LookupResult>> {
    return this.getAll(searchCriteria).pipe(
      map((value) => {
        return {
          records: listItemsToLookupResult(value.records),
          pageDetails: value.pageDetails,
        };
      })
    );
  }
}
