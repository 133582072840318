import { UserGroupEnum } from 'src/app/enum';
import { environment } from 'src/environments/environment';
import { MediaGalleryFolder } from '../interfaces/media-gallery-folder.interface';
import { PreviewDocumentDTO } from '../interfaces/preview-document.interface';

import { ImageFolder } from '../store/media-gallery.state';

export function filterFoldersByUserGroup(userGroup: UserGroupEnum | undefined, mediaFolder: ImageFolder): Array<MediaGalleryFolder> {
  if (userGroup === UserGroupEnum.COMPANY_ADMIN) {
    return mediaFolder.companyFolders.filter((value) => Boolean(value.id));
  } else if (userGroup === UserGroupEnum.JM_ADMIN) {
    return mediaFolder.adminFolders?.filter((value) => Boolean(value.id)) ?? [];
  }
  return [];
}

export function resolveFileSource(documentAttachment: PreviewDocumentDTO): any {
  if (documentAttachment?.urls) {
    return `${environment.rootUrl}${documentAttachment.urls.original}`;
  }

  return documentAttachment.data;
}
