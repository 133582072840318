import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { isSet } from 'src/app/util/util';

/**
 * Directive used to display element if the passed value is not null or undefined
 */
@Directive({ selector: '[appIsSet]' })
export class IsSetDirective {
  constructor(private readonly templateRef: TemplateRef<any>, private readonly viewContainer: ViewContainerRef) {}

  @Input() set appIsSet(value: unknown) {
    this.viewContainer.clear();

    if (isSet(value)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
