<div class="jm-loader" [ngClass]="sizingCssClass" [class.invert]="invert" [class.jm-loader--centered]="centered">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
