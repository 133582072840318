import { AngularSvgIconModule } from 'angular-svg-icon';
import { getImageDimensions } from 'src/app/util/util';
import { environment } from 'src/environments/environment';

import { NgIf } from '@angular/common';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PreviewDocumentDTO } from '../../../modules/media-gallery/interfaces/preview-document.interface';
import { PinchZoomComponent } from './pinch-zoom/pinch-zoom.component';

const imports = [AngularSvgIconModule, NgIf, PinchZoomComponent];

@Component({
  selector: 'app-image-preview',
  templateUrl: 'image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  standalone: true,
  imports,
})
export class ImagePreviewComponent {
  @ViewChild('pinch') pinchZoom?: PinchZoomComponent;
  @Input()
  set imageProps(data: {
    image: string;
    width: number;
    height: number;
    name: string;
    previewDocuments: Array<PreviewDocumentDTO>;
    activeDocumentId: string;
  }) {
    this.image = data?.image;
    this.width = this.checkWidthAndHeight(Number(data?.width));
    this.height = this.checkWidthAndHeight(Number(data?.height));
    this.name = data?.name;
    this.previewDocuments = data?.previewDocuments || [];
    this.activeDocumentId = data?.activeDocumentId;
  }
  image: string;
  width: number;
  height: number;
  name?: string;
  previewDocuments: Array<PreviewDocumentDTO> = [];
  private activeDocumentId?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: {
      image: string;
      width: number;
      height: number;
      previewDocuments: Array<PreviewDocumentDTO>;
      activeDocumentId: string;
    },
    private readonly dialogRef: MatDialogRef<ImagePreviewComponent>
  ) {
    this.image = data?.image;
    this.width = this.checkWidthAndHeight(Number(data?.width));
    this.height = this.checkWidthAndHeight(Number(data?.height));
    this.previewDocuments = data?.previewDocuments || [];
    this.activeDocumentId = data?.activeDocumentId;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showPreviousImage(): void {
    const currentActiveImageIndex: number = this.previewDocuments.findIndex((value) => value.id === this.activeDocumentId);

    const newActiveImageIndex: number = currentActiveImageIndex > 0 ? currentActiveImageIndex - 1 : this.previewDocuments.length - 1;

    this.setImage(newActiveImageIndex);
  }

  showNextImage(): void {
    const currentActiveImageIndex: number = this.previewDocuments.findIndex((value) => value.id === this.activeDocumentId);

    const newActiveImageIndex: number = currentActiveImageIndex < this.previewDocuments.length - 1 ? currentActiveImageIndex + 1 : 0;

    this.setImage(newActiveImageIndex);
  }

  private checkWidthAndHeight(value: number): number {
    if (value > 350 && value < 1500) {
      return value;
    } else if (value <= 350) {
      return 350;
    } else {
      return 1500;
    }
  }

  private setImage(imageIndex: number): void {
    this.activeDocumentId = this.previewDocuments[imageIndex].id as any;
    const image: any = this.previewDocuments[imageIndex]?.urls
      ? this.getDocumentUrl(this.previewDocuments[imageIndex].urls?.original)
      : this.previewDocuments[imageIndex].data;

    this.getImageDimensions(image).then((value) => {
      this.image = image;
      this.height = value.height;
      this.width = value.width;
    });
  }

  private getDocumentUrl(image: any): string {
    return `${environment.rootUrl}${image}`;
  }

  private getImageDimensions(url: string): Promise<{ height: number, width: number }> {
    return getImageDimensions(url);
  }
}
