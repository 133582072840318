import { ChartData } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { Model } from 'ngx-super-model';
import { v4 as uuidv4 } from 'uuid';

import { realtimeSensorChartConfig } from '../chart-config';
import { RealtimeSensorChartConfigDTO } from '../interfaces/chart-config.interface';
import { dataToRealtimeSensorChart } from '../mappers/data-to-chart.mapper';
import { RealtimeSensorChart } from './real-time-sensor-chart.model';

export class RealtimeSensorChartConfig extends Model<RealtimeSensorChartConfigDTO> {
  show = false;
  config = cloneDeep(realtimeSensorChartConfig);
  data: ChartData | null = null;
  id = uuidv4();
  loader = true;
  response: Array<RealtimeSensorChart> = [];

  setChartData(unit: string | null | undefined, color: string | undefined): void {
    this.config.data = dataToRealtimeSensorChart(this.response, unit, color);
    this.data = dataToRealtimeSensorChart(this.response, unit, color);
  }

  setLoader(loader: boolean): void {
    this.loader = loader;
  }

  toggle(): void {
    this.show = !this.show;
  }

  setResponse(response: Array<RealtimeSensorChart>): void {
    this.response = response;
  }

  hasChartData(): boolean {
    return this.response.length > 0;
  }
}
