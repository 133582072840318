import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-sb-checkbox',
  templateUrl: './sb-checkbox.component.html',
  styleUrls: ['./sb-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbCheckboxComponent {
  @Input() checked = false;
  @Input() label = '';
  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChangeCheckboxValue: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();

  changeCheckboxValue(event: MatCheckboxChange): void {
    this.onChangeCheckboxValue.emit(event);
  }
}
