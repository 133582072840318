import { Directive, ElementRef, inject, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[imageNotFound]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(error)': 'onError($event)',
    '[src]': 'currentSrc',
  },
  standalone: true,
})
export class ImageNotFoundDirective {
  private readonly element = inject(ElementRef);

  @Input()
  set src(src: string) {
    this.isFallback = false;

    if (src.slice(-4) === 'null') {
      this.currentSrc = this.fallbackImg;
      return;
    }

    this.isFallback = false;
    this.currentSrc = src;
  }

  currentSrc?: string;

  readonly fallbackImg: string = './assets/images/fallback.svg';

  private isFallback = false;

  onError(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.isFallback) {
      this.element.nativeElement.classList.add('image-not-found');
      this.isFallback = true;
      this.currentSrc = this.fallbackImg;
    }
  }
}
