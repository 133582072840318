import { AngularSvgIconModule } from 'angular-svg-icon';
import { DialogComponent } from 'src/app/layout';
import { DialogConfigDTO } from 'src/app/layout/dialog/dialog.interface';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { PercentageBoxComponent } from 'src/app/shared/modules/percentage-box/percentage-box.component';
import { PercentagePipe } from '@util';

import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { OverallOee } from './models';

const imports = [
  CommonModule,
  BoxModule,
  TranslateModule,
  PercentageBoxComponent,
  MatDialogModule,
  AngularSvgIconModule,
  DialogComponent,
  PercentagePipe,
  EmptyBlockModule,
];

@Component({
  selector: 'widget-overall-oee',
  standalone: true,
  imports,
  templateUrl: './overall-oee.component.html',
  styleUrls: ['./overall-oee.component.scss'],
})
export class OverallOeeComponent {
  private readonly dialog = inject(MatDialog);
  private readonly translateService = inject(TranslateService);

  @Input() loader = false;
  @Input() data: OverallOee | null = null;
  @Input()
  error: string | null = null;

  onIconClick(): void {
    const dialogConfig: DialogConfigDTO = {
      title: this.translateService.instant('performance-dashboard.overall-oee.title'),
      message: this.translateService.instant('performance-dashboard.overall-oee.description'),
      hideCloseIcon: true,
      className: ['left-align'],
      button: {
        cancel: this.translateService.instant('actions.close'),
      },
    };

    this.dialog.open(DialogComponent, {
      data: dialogConfig,
      width: '700px',
    });
  }
}
