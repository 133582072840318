import { LicenseEnum } from '../license/enums/license.enum';
import { AverageEfficiencyComponent } from './average-efficiency/average-efficiency.component';
import { averageEfficiencyFormConfig } from './average-efficiency/form-config';
import { AverageSpeedComponent } from './average-speed/average-speed.component';
import { averageSpeedFormConfig } from './average-speed/form-config';
import { AverageStopComponent } from './average-stop/average-stop.component';
import { averageStopsFormConfig } from './average-stop/form-config';
import { WidgetTypeEnum } from './core/enums/widget-type.enum';
import { WidgetGlobalConfig } from './core/interfaces/widgets-global-config.interface';
import { EfficiencyPerShiftComponent } from './efficiency-per-shift/efficiency-per-shift.component';
import { efficiencyPerShiftFormConfig } from './efficiency-per-shift/form-config';
import { historicalSensorFormConfig } from './historical-sensor/form-config';
import { HistoricalSensorComponent } from './historical-sensor/historical-sensor.component';
import { realTimeSensorFormConfig } from './realtime-sensor/form-config';
import { RealtimeSensorComponent } from './realtime-sensor/realtime-sensor.component';

export const widgetsConfig: Array<WidgetGlobalConfig> = [
  {
    type: WidgetTypeEnum.AverageSpeed,
    name: 'widgets.global.names.averageSpeed',
    component: AverageSpeedComponent,
    formConfig: averageSpeedFormConfig,
  },
  {
    type: WidgetTypeEnum.AverageEfficiency,
    name: 'widgets.global.names.averageEfficiency',
    component: AverageEfficiencyComponent,
    formConfig: averageEfficiencyFormConfig,
  },
  {
    type: WidgetTypeEnum.AverageStops,
    name: 'widgets.global.names.averageStops',
    component: AverageStopComponent,
    formConfig: averageStopsFormConfig,
  },
  {
    type: WidgetTypeEnum.EfficiencyPerShift,
    name: 'widgets.global.names.efficiencyPerShift',
    component: EfficiencyPerShiftComponent,
    formConfig: efficiencyPerShiftFormConfig,
  },
  {
    type: WidgetTypeEnum.RealtimeSensor,
    name: 'widgets.global.names.realTimeSensor',
    component: RealtimeSensorComponent,
    formConfig: realTimeSensorFormConfig,
    info: 'sensor.widget.real-time.info',
    licenses: [LicenseEnum.Sensor],
  },
  {
    type: WidgetTypeEnum.HistoricalSensor,
    name: 'widgets.global.names.historicalSensor',
    component: HistoricalSensorComponent,
    formConfig: historicalSensorFormConfig,
    info: 'sensor.widget.historical.info',
    licenses: [LicenseEnum.Sensor],
  },
];
