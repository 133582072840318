import * as Sentry from '@sentry/angular';

import {
  ActiveLicensesUpdated,
  LoginAction,
  LoginActionType,
  ResponseBackendVersion,
  ResponseScheduledNotifications,
  SetUserInfo,
} from './login.actions';
import { initialLoginState, LoginState } from './login.state';

export function loginReducer(oldState: LoginState = initialLoginState, action: LoginAction): LoginState {
  switch (action.type) {
    case LoginActionType.SET_USER_INFO: {
      const { user } = (action as SetUserInfo).payload;

      if (user?.id && user?.email) {
        Sentry.setUser({ name: user.name, id: user.id, company: user.company, email: user?.email });
      }

      return {
        ...oldState,
        user,
      };
    }

    case LoginActionType.RESPONSE_BACKEND_VERSION: {
      const { version } = (action as ResponseBackendVersion).payload;

      const state = {
        ...oldState,
        backendVersion: version,
      };

      return state;
    }

    case LoginActionType.USER_LOGIN: {
      const state = {
        ...oldState,
        loginLoader: true,
      };

      return state;
    }

    case LoginActionType.LOGIN_SUCCESS:
    case LoginActionType.LOGIN_ERROR: {
      const state = {
        ...oldState,
        loginLoader: false,
      };

      return state;
    }

    case LoginActionType.FORGOT_PASSWORD:
    case LoginActionType.SET_NEW_PASSWORD: {
      const state = {
        ...oldState,
        passwordResetLoader: true,
      };

      return state;
    }

    case LoginActionType.SUCCESSFULLY_FORGOT_PASSWORD:
    case LoginActionType.ERROR_NEW_PASSWORD: {
      const state = {
        ...oldState,
        passwordResetLoader: false,
      };

      return state;
    }

    case LoginActionType.RESPONSE_SCHEDULED_NOTIFICATIONS: {
      const { userNotification } = (action as ResponseScheduledNotifications).payload;

      const state = {
        ...oldState,
        notification: userNotification,
      };

      return state;
    }

    case LoginActionType.ACTIVE_LICENSES_UPDATED: {
      const { activeLicenses } = (action as ActiveLicensesUpdated).payload;

      const user = oldState.user;

      if (oldState.user.company) {
        user.company = {
          ...oldState.user.company,
          activeLicenses,
        };
      }

      const state = {
        ...oldState,
        user,
      };

      return state;
    }

    default:
      return oldState;
  }
}
