import { FileType } from 'src/app/main/library/enums/file-type.enum';
import { resolveFileSource } from 'src/app/modules/media-gallery/helper/media-gallery.helper';
import { PreviewDocumentDTO } from 'src/app/modules/media-gallery/interfaces/preview-document.interface';
import { ImagePreviewComponent } from 'src/app/shared/modules/image-preview/image-preview.component';
import { getImageDimensions } from 'src/app/util/util';
import { environment } from 'src/environments/environment';

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CellArrayType } from '../../../enum/cell-array-type.enum';
import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  //eslint-disable-next-line
  selector: 'td[jm-array-cell]',
  templateUrl: './jm-array-cell.component.html',
})
export class JmArrayCellComponent extends AbstractCellComponent {
  readonly cellArrayType = CellArrayType;
  readonly environmentRootUrl: string = environment.rootUrl;

  constructor(private readonly dialog: MatDialog) {
    super();
  }

  preview(documentAttachment: PreviewDocumentDTO, previewDocuments: Array<PreviewDocumentDTO>, event?: PointerEvent): void {
    event?.stopPropagation();
    if (documentAttachment && documentAttachment.filetype?.includes(FileType.Image)) {
      const image: string = resolveFileSource(documentAttachment);

      getImageDimensions(image).then((value) => {
        this.dialog.open(ImagePreviewComponent, {
          panelClass: 'cdk-overlay-pane--imageviewer',
          data: {
            image,
            height: value.height,
            width: value.width,
            previewDocuments,
            activeDocumentId: documentAttachment?.id,
          },
        });
      });
    }
  }

  getImages(): Array<any> {
    return this.cellData[this.columnField].slice(0, 3);
  }
}
