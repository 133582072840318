import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { ProfitabilityInput } from '../../performance-dashboard/interfaces/profitability-input.interface';
import { OeeByMachineDTO } from './interfaces';
import { OeeByMachine } from './models';

@Injectable({
  providedIn: 'root',
})
export class OeeByMachineService extends AbstractCRUDService<OeeByMachine, OeeByMachineDTO> {
  override model = OeeByMachine;

  override fields = `
    OEE
    machineGuid
    machineName
  `;

  getData(input: ProfitabilityInput): Observable<Array<OeeByMachine>> {
    const query = gql`
     query oeeByMachine($input: OEEByMachineInput!) {
        oeeByMachine(input: $input) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<OeeByMachineDTO>>>) =>
          response.data.oeeByMachine.map((item: OeeByMachineDTO) => this.loadModel(item))
        )
      );
  }
}
