import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { ChartComponent } from 'src/app/layout/chart/chart.component';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { LoaderComponent } from '@app-ui';

import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { chartConfig } from './chart.config';
import { dataToChartMapper } from './mappers/data-to-chart.mapper';
import { DownTimeByStopReason } from './models';

const imports = [CommonModule, ChartComponent, BoxModule, LoaderComponent, TranslateModule, EmptyBlockModule];

@Component({
  selector: 'widget-downtime-by-stop-reason',
  templateUrl: 'downtime-by-stop-reason.component.html',
  styleUrls: ['./downtime-by-stop-reason.component.scss'],
  standalone: true,
  imports,
})
export class DownTimeByStopReasonComponent {
  @Input() title!: string;
  @Input() loader = false;

  @Input()
  set data(data: Array<DownTimeByStopReason>) {
    if (!data) {
      return;
    }
    this.setChartData(data);
  }
  @Input()
  error: string | null = null;

  translateService = inject(TranslateService);

  chartConfig: ChartConfiguration = cloneDeep(chartConfig);

  readonly id: string = `downtime-by-stop-reason-${uuidv4()}`;

  private setChartData(data: Array<DownTimeByStopReason>): void {
    const { datasets, labels } = dataToChartMapper(data);

    const translatedLabels = labels?.map((label) => this.translateService.instant('machines.status.' + (label as string)));

    this.chartConfig.data = {
      labels: translatedLabels,
      datasets,
    };
  }
}
