import { ApolloLink, DefaultContext } from '@apollo/client';

export function getTimeZoneLink() {
  return new ApolloLink((operation, forward) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    operation.setContext((context: DefaultContext) => {
      return {
        headers: {
          ...context.headers,
          'X-Timezone': userTimezone,
        },
      };
    });

    return forward(operation);
  });
}
