import { ColumnFilter } from 'primeng/table';
import { DeviceService } from 'src/app/modules/device/device.service';
import { CellFilterType } from 'src/app/modules/jm-table/enum/cell-filter-type.enum';
import { CellConfig } from 'src/app/modules/jm-table/models/cells.config';
import { DeviceDetectionService } from 'src/app/services/device-detection.service';
import { noop } from 'src/app/util/function.helper';
import { isSet } from 'src/app/util/util';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'jm-column-filter',
  templateUrl: './jm-column-filter.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JmColumnFilterComponent implements OnInit, AfterViewInit {
  private readonly deviceService = inject(DeviceService);
  private readonly deviceDetectionService = inject(DeviceDetectionService);

  @Input() column: CellConfig = new CellConfig();
  @ViewChild('columFilter') columFilter?: ColumnFilter;

  readonly cellFilterType = CellFilterType;

  shouldHideOnScroll = true;

  private isMobile = false;

  @HostListener('window:scroll')
  onWindowScroll() {
    if ((this.isMobile || this.deviceDetectionService.isTouchDevice()) && !this.shouldHideOnScroll) {
      return;
    }

    this.hideColumnFilter();
  }

  ngOnInit() {
    this.watchDeviceSize();
  }

  ngAfterViewInit(): void {
    if (isSet(this.columFilter) && this.deviceDetectionService.isTouchDevice()) {
      this.columFilter.bindDocumentResizeListener = noop;
    }
  }

  watchDeviceSize(): void {
    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isMobile: boolean) => {
          this.isMobile = isMobile;
        },
      });
  }

  hideColumnFilter(): void {
    if (!this.columFilter) {
      return;
    }

    this.columFilter.overlayVisible = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onValueChanged(filterCallback: (event: any) => void, event: any): void {
    this.hideColumnFilter();
    filterCallback(event);
  }

  onFocusToggle(focused: boolean): void {
    this.shouldHideOnScroll = !focused;
  }
}
