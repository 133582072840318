import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { PerformanceDetailsDTO, PerformanceDetailsInput } from './interfaces';
import { PerformanceDetails } from './models';

@Injectable({
  providedIn: 'root',
})
export class PerformanceDetailsService extends AbstractCRUDService<PerformanceDetails, PerformanceDetailsDTO> {
  override model = PerformanceDetails;

  override fields = `
    articleName
    lastFinished
    quantityInMeters
    targetTime
    actualTime
    diffTime
    revenue
    cost
    profit
  `;

  getData(input: PerformanceDetailsInput): Observable<Array<PerformanceDetails>> {
    const query = gql`
     query PerformanceDetails($input: PerformanceDetailsInput!) {
        performanceDetails(input: $input) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<any>) => {
          const records = response.data['performanceDetails'].map(this.loadModel);

          return records;
        })
      );
  }
}
