import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { isSet } from 'src/app/util/util';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { GetUserInfo, LoginActionType, selectUserInfo, SetUserInfo } from '@auth';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { User } from '@user';

import { ActiveLicenses } from './type/active-licenses';

@Injectable({ providedIn: 'root' })
export class LicenseGuard {
  private readonly store$ = inject(Store);
  private readonly router = inject(Router);
  private readonly actions$ = inject(Actions);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const licenses = route.data['requiredLicenses'];
    return this.checkLicense(licenses);
  }

  /**
   * Pseudocode:
   * 1. Get UserInfo:
   *   -- if set (angular navigation) -> return activeLicences
   *   -- if not set (browser navigation) -> dispatch GetUserInfo and wait for it to be set -> return activeLicences
   * 2. hasLicence:
   *   -- if yes -> resolve route
   *   -- if no -> return home
   */
  checkLicense(requiredLicenses: Array<string>): Observable<boolean> {
    const userInfo$ = this.store$.select(selectUserInfo).pipe(take(1));
    const setUserInfo$ = this.actions$.pipe(ofType(LoginActionType.SET_USER_INFO)).pipe(take(1));

    return userInfo$.pipe(
      tap((userInfo) => {
        if (!userInfo.company) {
          this.store$.dispatch(new GetUserInfo());
        }
      }),
      switchMap((userInfo: User) => {
        if (userInfo.company) {
          return of(userInfo.company?.activeLicenses);
        }

        return setUserInfo$.pipe(
          map((action: SetUserInfo) => {
            const { user } = action.payload;

            if (!isSet(user.company)) {
              return [];
            }

            return user.company.activeLicenses;
          })
        );
      }),
      map((activeLicenses: ActiveLicenses) => {
        const mappedLicences = activeLicenses.map((licence) => licence.type);
        return requiredLicenses.some((license) => mappedLicences.includes(license));
      }),
      tap((hasLicence: boolean) => {
        if (!hasLicence) {
          this.router.navigateByUrl('/');
        }
      })
    );
  }
}
