import { Model } from 'ngx-super-model';
import { isSet } from 'src/app/util/util';

import { TableEnum } from '../enum/table.enum';
import { CellMeta } from '../interfaces/cell-config.interface';
import { TableConfigDTO } from '../interfaces/table-config.interface';
import { TableMoreOptions } from '../interfaces/table-more-options.interface';
import { TableGlobalFilter } from './global-filter.model';
import { CellConfig } from './cells.config';

export class TableConfig extends Model<TableConfigDTO> {
  id: TableEnum | string = '';
  cellsConfig: Array<CellConfig> = [];
  permissions: Array<string> = [];
  cssClass: Array<string> = [];
  unit: string | null = null;
  footer = false;
  rowNavigationAvailable = false;
  rowExpanded = false;
  mobileRowExpanded = true;
  dataKey = 'id';
  customRowExpand = false;
  customSort = true;
  conditionalClasses?: TableConfigDTO['conditionalClasses'] = undefined;
  search = {
    show: false,
    placeholder: null,
    sticky: true,
  };
  filtering = false;
  moreOptions: Array<TableMoreOptions> = [];
  deleteButton = {
    active: false,
    keyForDisabling: '',
  };
  dequeueButton = {
    active: false,
    keyForDisabling: '',
  };
  meta?: CellMeta | null = null;
  updateUrl = true;
  scrollHeight = null;
  loadMoreOnScrollHeight = false;
  virtualScroll = false;
  autoLoad = true;
  reorderColumns = false;
  checkElementViewport = false;
  globalFilter = new TableGlobalFilter();
  restoreStore = false;

  getFilters(): Array<string> {
    const cellsWithFilters = this.cellsConfig.filter((cellConfig) => isSet(cellConfig.filter));

    if (isSet(this.globalFilter)) {
      const globalFilters = this.globalFilter.controls.map((control) => control.id);
      return cellsWithFilters.map((cellConfig) => cellConfig.field || '').concat(globalFilters);
    }

    return cellsWithFilters.map((cellConfig) => cellConfig.field || '');
  }
}
