<div class="input-dash-wrapper" [ngClass]="{ disabled: disabled }">
  <input
    *ngIf="!disabled; else dash"
    #dash
    #numberInput
    type="number"
    min="{{ min }}"
    max="{{ max }}"
    [(ngModel)]="checkedValue"
    [style.width]="styleWidth"
    (keyup)="valueChange($event)"
  />

  <span class="unit" (click)="focusOnNumberInput()">{{ unit }}</span>
</div>

<ng-template #dash>-&nbsp;</ng-template>
