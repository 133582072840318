import { get } from 'lodash-es';

import { Component } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-relation-cell]',
  templateUrl: './jm-relation-cell.component.html',
})
export class JmRelationCellComponent extends AbstractCellComponent {
  get cellDataValue(): string {
    return get(this.cellData, this.columnField);
  }
}
