import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { PageDetails } from 'src/app/modules/jm-table/interfaces/page-details.interface';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';
import { SearchCriteria } from 'src/app/modules/jm-table/models/search-criteria.config';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { hasProperty } from 'src/app/util/object.helper';
import { isSet } from 'src/app/util/util';

import { Component, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LookupResult } from '../../../interfaces/lookup-result.interface';
import { LookupService } from '../../../interfaces/lookup-service.interface';
import { ControlConfig } from '../../../models/control.config';

@UntilDestroy()
@Component({ template: '' })
export class AbstractDropdownComponent implements OnInit {
  @Input() config = new ControlConfig();
  @Input() control = new FormControl();

  @Input()
  loading = false;

  @Input()
  options: Array<any> = [];

  @Output() openInfoWindow = new EventEmitter<void>();
  @Output() focused = new EventEmitter<boolean>(false);
  @Output() search = new EventEmitter<SearchCriteriaDTO>();

  @ViewChild('select') select?: NgSelectComponent;

  isDisabled = false;
  placeholder: string | null = null;
  appendTo = 'body';
  lookupService: LookupService | null = null;
  translate = false;
  virtualScroll = false;
  lazyLoad = false;
  // eslint-disable-next-line rxjs/no-exposed-subjects
  readonly searchInput$ = new Subject<string>();

  /**
   * Dropdown can be dependent on other dropdown, when so add control id it depends on and property to filterBy on backend
   * NOTE dependent dropdown needst to be a sibling of the dropdown it depends on
   */
  dependentOn: { controlId: string; filterBy: string } | null = null;

  protected searchCriteria: SearchCriteriaDTO = new SearchCriteria();
  protected pageDetails: PageDetails | null = null;

  constructor(protected injector: Injector) {}

  ngOnInit(): void {
    this.setupConfiguration();

    const lookupService: LookupService = this.config.meta?.lookupService;
    const options: Array<LookupResult> = this.config.meta?.options;
    this.virtualScroll = this.config.meta?.virtualScroll;
    this.lazyLoad = this.config.meta?.lazyLoad;

    this.handleSearchInputSubscription();

    if (this.dependentOn) {
      this.handleDependentDropdown(this.dependentOn);
    }

    if (lookupService && typeof options === 'undefined') {
      this.initLookupService(lookupService);

      this.getByLookupService();

      return;
    }

    if (options) {
      this.options = options;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const dropdownPanel = this.select?.dropdownPanel;

    if (isSet(dropdownPanel)) {
      this.select?.dropdownPanel?.outsideClick.emit();
    }
  }

  setupConfiguration(): void {
    const meta = this.config.meta;

    if (isSet(meta) && hasProperty(meta, 'disabled')) {
      this.setDisable(this.config.disabled);
    }

    if (isSet(meta) && hasProperty(meta, 'appendTo')) {
      this.appendTo = this.config.meta?.appendTo;
    }

    if (isSet(meta) && hasProperty(meta, 'translate')) {
      this.translate = this.config.meta?.translate;
    }

    if (isSet(meta) && hasProperty(meta, 'dependentOn')) {
      this.dependentOn = this.config.meta?.dependentOn;
      if (!this.control.value) {
        this.setDisable(true);
      }
    }

    this.placeholder = this.config.placeholder || this.config.label || null;
  }

  protected handleSearchInputSubscription() {
    if (!this.config.meta?.lazyLoad) {
      return;
    }

    this.searchInput$
      .asObservable()
      .pipe(debounceTime(400), distinctUntilChanged(), untilDestroyed(this))
      .subscribe({
        next: (searchValue) => {
          this.onSearch(searchValue);
        },
      });
  }

  onSearch(searchInput: string | null): void {
    const searchCriteria = this.searchCriteria;

    if (searchCriteria.searchQuery) {
      searchCriteria.searchQuery.name = searchInput;
    }

    if (!isSet(this.config.meta?.lookupService)) {
      this.search.emit(searchCriteria);
      return;
    }

    this.getByLookupService();
  }

  setDisable(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  protected initLookupService(service: any): void {
    this.lookupService = this.injector.get<LookupService>(service);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  onOpenInfoWindow(event: MouseEvent): void {
    event.stopPropagation();
    this.openInfoWindow.emit();
  }

  onBlur(): void {
    this.propagateTouched();
    this.focused.emit(false);
  }

  onFocus() {
    this.focused.emit(true);
  }

  onScrollToEnd(): void {
    if (!this.pageDetails) {
      return;
    }

    if (this.pageDetails.isLast) {
      return;
    }
    const searchCriteria = this.searchCriteria;
    if (searchCriteria.pagination) {
      searchCriteria.pagination.offset = this.pageDetails.page * this.pageDetails.size;
    }
    this.getByLookupService(true);
  }

  protected getByLookupService(concatToExistingOptions = false): void {
    this.loading = true;
    this.lookupService
      ?.getSuggestions(this.searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: ListResultDTO<LookupResult>) => {
          this.loading = false;
          const { pageDetails, records } = result;

          this.pageDetails = pageDetails;
          if (concatToExistingOptions) {
            this.options = this.options.concat(...records);
          } else {
            this.options = [...records];
          }
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  protected handleDependentDropdown(dependentOn: { controlId: string; filterBy: string }): void {
    const parentControl = this.control?.parent?.get(dependentOn.controlId);

    if (parentControl?.value) {
      const rawValue = parentControl?.value?.id || parentControl?.value;
      this.setDisable(false);
      const filterBy: ObjectLiteral = {};
      const filterByProperty: string | undefined = dependentOn.filterBy || dependentOn.controlId;

      if (filterByProperty) {
        filterBy[filterByProperty] = rawValue;
      }

      const lookupService: LookupService = this.config.meta?.lookupService;
      const options: Array<LookupResult> = this.config.meta?.options;

      if (lookupService && typeof options === 'undefined') {
        this.searchCriteria = new SearchCriteria();
        this.searchCriteria.filterBy = filterBy;

        this.getByLookupService();
      }
    }

    this.control?.parent
      ?.get(dependentOn.controlId)
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.control.reset(null, { emitEvent: false });
        this.placeholder = this.config.placeholder;

        const rawValue = value?.id || value;
        if (!isSet(rawValue)) {
          this.setDisable(true);
          this.options = [];
          this.control.setErrors(null);
          return;
        }

        this.setDisable(false);

        const filterBy: ObjectLiteral = {};
        const filterByProperty: string | undefined = dependentOn.filterBy || dependentOn.controlId;

        if (filterByProperty) {
          filterBy[filterByProperty] = rawValue;
        }

        this.searchCriteria = new SearchCriteria();
        this.searchCriteria.filterBy = filterBy;

        this.getByLookupService();
      });
  }

  // eslint-disable-next-line
  protected propagateChange = (value: any | null) => {};

  // eslint-disable-next-line
  protected propagateTouched = () => {};
}
