import { Period } from 'src/app/util/period/period.interface';
import { isSet } from 'src/app/util/util';

import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import {
  CUSTOM_DASHBOARD_STORAGE_KEY,
  DashboardMode,
  DashboardState,
  initialPeriodState,
  widgetsDashboardStateName,
} from './dashboard.state';
import { Dashboard } from '../model/dashboard.model';

const selectApplicationState = createFeatureSelector<DashboardState>(widgetsDashboardStateName);

export const selectDashboardMode: Selector<object, DashboardMode> = createSelector(
  selectApplicationState,
  (state: DashboardState) => state.mode
);

export const selectDashboardPeriod = (id: string): Selector<object, Period> =>
  createSelector(selectApplicationState, (state: DashboardState) => {
    const key = `${CUSTOM_DASHBOARD_STORAGE_KEY}-${id}`;
    if (!isSet(state.filters[key])) {
      return initialPeriodState;
    }

    return state.filters[key].period;
  });

export const selectSharedDashboards: Selector<object, Array<Dashboard>> = createSelector(
  selectApplicationState,
  (state: DashboardState) => state.sharedDashboards
);

export const selectPrivateDashboards: Selector<object, Array<Dashboard>> = createSelector(
  selectApplicationState,
  (state: DashboardState) => state.privateDashboards
);
