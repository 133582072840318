/* eslint-disable */
/* Deprecated */

import { IMachineStatusDto } from 'src/app/core/machines/interfaces/machine-status.model';
import { OrderStatusUpdatedDTO } from 'src/app/core/orders/interfaces/order-status-updated.interface';
import { OrderDTO } from 'src/app/core/orders/interfaces/order.interface';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { IMachineDetails, LiveOrderUpdate } from 'src/app/main/dashboard/models';
import { PageDetails } from 'src/app/modules/jm-table/interfaces/page-details.interface';
import { PayloadAction } from 'src/app/shared/util';
import { SortBy } from 'src/app/util/search/sort-by.interface';
import { SortOrder } from 'src/app/util/search/sort-order.enum';

import { LocationDTO } from '@locations';
import { MachineShiftDTO, MachineStatus } from '@machines';
import { Action } from '@ngrx/store';
import { OrderView } from '@orders';
import { SensorReadingDTO } from '@sensor-reading';

import { MachineProperty } from '../constants/machine-property.constant';

export const enum DashboardActionType {
  RESPOND_MACHINES_OVERVIEW_PER_LOCATION = '[DASHBOARD MODULE] RESPOND_MACHINES_OVERVIEW_PER_LOCATION',

  REQUEST_DATA_PER_MACHINE = '[DASHBOARD MODULE] REQUEST_DATA_PER_MACHINE',
  RESPOND_DATA_PER_MACHINE = '[DASHBOARD MODULE] RESPOND_DATA_PER_MACHINE',
  ERROR_DATA_PER_MACHINE = '[DASHBOARD MODULE] ERROR_DATA_PER_MACHINE',

  SEND_MESSAGE_MACHINE = '[DASHBOARD MODULE] SEND_MESSAGE_MACHINE',

  SET_MACHINE_MACHINE_GUID = '[DASHBOARD MODULE] SET_MACHINE_MACHINE_GUID',
  SET_MACHINE_CATEGORY = '[DASHBOARD MODULE] SET_MACHINE_CATEGORY',

  REQUEST_LOCATION = '[DASHBOARD MODULE] REQUEST_LOCATION',
  RESPOND_LOCATION = '[DASHBOARD MODULE] RESPOND_LOCATION',

  REQUEST_TOTAL_STATISTIC_PER_LOCATION = '[DASHBOARD MODULE] REQUEST_TOTAL_STATISTIC_PER_LOCATION',
  RESPOND_TOTAL_STATISTIC_PER_LOCATION = '[DASHBOARD MODULE] RESPOND_TOTAL_STATISTIC_PER_LOCATION',
  REQUEST_MACHINE_DATA_PER_LOCATION = '[DASHBOARD MODULE] REQUEST_MACHINE_DATA_PER_LOCATION',
  RESPOND_MACHINE_DATA_PER_LOCATION = '[DASHBOARD MODULE] RESPOND_MACHINE_DATA_PER_LOCATION',

  UPDATE_SELECTED_LOCATION = '[DASHBOARD MODULE] UPDATE_SELECTED_LOCATION',

  UPDATE_DASHBOARDS_CUSTOM_PERIOD = '[DASHBOARD MODULE] UPDATE__DASHBOARDS_CUSTOM_PERIOD',
  UPDATE_DASHBOARDS_MACHINE_TYPE = '[DASHBOARD MODULE] UPDATE_DASHBOARDS_MACHINE_TYPE',
  UPDATE_SELECTED_LOCATION_BY_ID = '[DASHBOARD MODULE] UPDATE_SELECTED_LOCATION_BY_ID',
  UPDATE_SELECTED_PERIOD = '[DASHBOARD MODULE] UPDATE__SELECTED_PERIOD',
  UPDATE_SELECTED_SHIFT = '[DASHBOARD MODULE] UPDATE__SELECTED_SHIFT',
  UPDATE_DASHBOARD_OVERVIEW_SORT = '[DASHBOARD MODULE] UPDATE_DASHBOARD_OVERVIEW_SORT',
  UPDATE_DASHBOARDS_STOP_TYPES = '[DASHBOARD MODULE] UPDATE_DASHBOARDS_STOP_TYPES',

  DELETE_ORDER = '[DASHBOARD] DELETE_ORDER',
  DELETE_ORDER_SUCCESS = '[DASHBOARD] DELETE_ORDER_SUCCESS',
  ORDER_STATUS_UPDATED = '[DASHBOARD] ORDER_STATUS_UPDATED',

  REQUEST_SHIFTS = '[DASHBOARD] REQUEST_SHIFTS',
  UPDATE_SHIFTS = '[DASHBOARD] UPDATE_SHIFTS',
  SAVE_SHIFTS = '[DASHBOARD] SAVE_SHIFTS',
  SAVE_SHIFTS_SUCCESSFULLY = '[DASHBOARD] SAVE_SHIFTS_SUCCESSFULLY',

  REQUEST_ALL_EXCEPT_ORDERS_PER_LOCATION = '[DASHBOARD] REQUEST_ALL_EXCEPT_ORDERS_PER_LOCATION',

  UPDATE_MACHINE_STATUS = '[DASHBOARD] UPDATE_MACHINE_STATUS',

  UPDATE_MACHINE_ORDER_STATUS = '[DASHBOARD MODULE] UPDATE MACHINE ORDER STATUS',
  UPDATE_MACHINES_FILTERS = '[DASHBOARD MODULE] UPDATE MACHINE DETAILS FILTERS',
  UPDATE_MACHINES_SORT = '[DASHBOARD MODULE] UPDATE MACHINE SORT',
  DELETE_ORDER_FROM_LOCATION = '[DASHBOARD MODULE] DELETE_ORDER_FROM_LOCATION',

  TRIGGER_DASHBOARD_REQUEST = '[DASHBOARD MODULE] TRIGGER_DASHBOARD_REQUEST',

  FETCH_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] FETCH_ORDERS_PER_MACHINE',
  RESPONSE_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] RESPONSE_ORDERS_PER_MACHINE',
  ERROR_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] ERROR_ORDERS_PER_MACHINE',
  SUCCESSFULLY_MOVE_ORDER_PER_MACHINE = '[DASHBOARD MODULE] SUCCESSFULLY_MOVE_ORDER_PER_MACHINE',
  LIVE_UPDATE_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] LIVE_UPDATE_ORDERS_PER_MACHINE',

  DEQUEUE_ORDER_FROM_MACHINE = '[DASHBOARD MODULE] DEQUEUE_ORDER_FROM_MACHINE',
  ADD_FILTER_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] ADD_FILTER_ORDERS_PER_MACHINE',
  SORT_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] SORT_ORDERS_PER_MACHINE',
  LOAD_MORE_ORDERS_PER_MACHINE = '[DASHBOARD MODULE] LOAD_MORE_ORDERS_PER_MACHINE',
  MOVE_ORDER_PER_MACHINE = '[DASHBOARD MODULE] MOVE_ORDER_PER_MACHINE',

  LIVE_UPDATE_ORDER_PER_MACHINE = '[DASHBOARD MODULE] LIVE_UPDATE_ORDER_PER_MACHINE',
  UPDATE_LOCATION_INFO = '[DASHBOARD MODULE] UPDATE_LOCATION_INFO',

  INITIAL_LOAD_STATS_LOCATIONS = '[DASHBOARD MODULE] INITIAL_LOAD_STATS_LOCATIONS',

  UPDATE_SENSOR_READING_DATA = '[DASHBOARD MODULE] UPDATE_SENSOR_READING_DATA',

  TOGGLE_LOCATION_SETTING_CLEARABLE = '[DASHBOARD MODULE] TOGGLE_LOCATION_SETTING_CLEARABLE',
}

export class SetMachineGUID implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SET_MACHINE_MACHINE_GUID;

  constructor(public payload: { machineGUID: string | undefined }) {}
}

export class SetActiveMachineCategory implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SET_MACHINE_CATEGORY;

  constructor(public payload: { category: MachineProperty | undefined }) {}
}

export class RequestDataPerMachine implements Action {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_DATA_PER_MACHINE;
}

export class RespondDataPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.RESPOND_DATA_PER_MACHINE;

  constructor(public payload: { statisticData: IMachineDetails }) {}
}

export class ErrorDataPerMachine implements Action {
  readonly type: DashboardActionType = DashboardActionType.ERROR_DATA_PER_MACHINE;
}

export class SendMessageMachineDashboard implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SEND_MESSAGE_MACHINE;

  constructor(public payload: { message: string }) {}
}

export class RequestLocationsDashboard implements Action {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_LOCATION;
}

export class RespondLocationsDashboard implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.RESPOND_LOCATION;

  constructor(public payload: { locations: Array<LocationDTO> }) {}
}

export class UpdateDashboardSelectedLocation implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SELECTED_LOCATION;

  constructor(public payload: { location: LocationDTO }) {}
}

export class UpdateDashboardSelectedMachineCategory implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_DASHBOARDS_MACHINE_TYPE;

  constructor(public payload: { id: number | null }) {}
}
export class UpdateDashboardStopTypes implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_DASHBOARDS_STOP_TYPES;

  constructor(public payload: { stopTypes: Array<MachineStatus> }) {}
}

export class RequestTotalStatisticPerLocation implements Action {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_TOTAL_STATISTIC_PER_LOCATION;
}

export class RespondTotalStatisticPerLocation implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.RESPOND_TOTAL_STATISTIC_PER_LOCATION;

  constructor(public payload: { statisticData: any }) {}
}

export class RequestMachineDataPerLocation implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_MACHINE_DATA_PER_LOCATION;

  constructor(public payload: { featureName: string }) {}
}

export class RespondMachineDataPerLocation implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.RESPOND_MACHINE_DATA_PER_LOCATION;

  constructor(
    public payload: {
      data: {
        machineStats: IMachineDetails;
        machinesWithoutLicense: number;
      };
    }
  ) {}
}

export class UpdateDashboardCustomPeriod implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_DASHBOARDS_CUSTOM_PERIOD;

  constructor(public payload: { start: string | null; end: string | null }) {}
}

export class UpdateDashboardSelectedLocationById implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SELECTED_LOCATION_BY_ID;

  constructor(public payload: { id: number | null }) {}
}

export class UpdateDashboardSelectedPeriod implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SELECTED_PERIOD;

  constructor(public payload: { period: PeriodTypeEnum }) {}
}
export class UpdateDashboardSelectedShift implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SELECTED_SHIFT;

  constructor(public payload: { shiftId: string | null }) {}
}

export class DashboardDeleteOrderSuccess implements Action {
  readonly type: DashboardActionType = DashboardActionType.DELETE_ORDER_SUCCESS;
}

export class DashboardRequestShifts implements Action {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_SHIFTS;
}

export class DashboardUpdateShifts implements Action {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SHIFTS;

  constructor(public payload: { shifts: Array<MachineShiftDTO> }) {}
}

export class DashboardSaveShift implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SAVE_SHIFTS;

  constructor(public payload: { id: string }) {}
}

export class DashboardSaveShiftSuccessfully implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SAVE_SHIFTS_SUCCESSFULLY;

  constructor(public payload: { shiftId: string; alias: string }) {}
}

export class DashboardRequestAllExceptOrdersPerLocation implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.REQUEST_ALL_EXCEPT_ORDERS_PER_LOCATION;

  constructor(
    public payload: {
      locationID: number;
      featureName: string;
      newFilters?: any;
      showActiveMachinesForPeriod: boolean;
    }
  ) {}
}

export class DashboardUpdateMachineStatus implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_MACHINE_STATUS;

  constructor(public payload: { machineData: IMachineStatusDto }) {}
}

export class UpdateMachineOrderStatus implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_MACHINE_ORDER_STATUS;

  constructor(public payload: { liveOrder: LiveOrderUpdate }) {}
}

export class UpdateMachinesFilters implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_MACHINES_FILTERS;

  constructor(public payload: { showActiveMachinesForPeriod: boolean }) {}
}

export class UpdateMachinesSort implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_MACHINES_SORT;

  constructor(public payload: { sortBy: SortBy }) {}
}

export class TriggerDashboardRequest implements Action {
  readonly type: DashboardActionType = DashboardActionType.TRIGGER_DASHBOARD_REQUEST;
}

export class FetchOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.FETCH_ORDERS_PER_MACHINE;

  constructor(
    public payload: {
      featureName: OrderView;
      initialLoad?: boolean;
      resetData?: boolean;
    }
  ) {}
}

export class ResponseOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.RESPONSE_ORDERS_PER_MACHINE;

  constructor(
    public payload: {
      orders: Array<OrderDTO>;
      featureName: OrderView;
      initialLoad?: boolean;
      pageDetails: PageDetails;
    }
  ) {}
}

export class LiveUpdateOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.LIVE_UPDATE_ORDERS_PER_MACHINE;

  constructor(
    public payload: {
      orders: Array<Partial<OrderDTO>>;
    }
  ) {}
}

export class OrderStatusUpdated implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.ORDER_STATUS_UPDATED;

  constructor(public payload: { orderStatus: OrderStatusUpdatedDTO }) {}
}

export class ErrorOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.ERROR_ORDERS_PER_MACHINE;

  constructor(public payload: { featureName: OrderView }) {}
}

export class DequeueOrderFromMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.DEQUEUE_ORDER_FROM_MACHINE;

  constructor(public payload: { orderGuid: string | undefined; featureName: OrderView }) {}
}

export class AddFilterOrderPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.ADD_FILTER_ORDERS_PER_MACHINE;

  constructor(public payload: { filter: string }) {}
}

export class SortOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.SORT_ORDERS_PER_MACHINE;

  constructor(
    public payload: {
      featureName: OrderView;
      sortBy: string | null;
      sortOrder: SortOrder | null;
    }
  ) {}
}

export class LoadMoreOrdersPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.LOAD_MORE_ORDERS_PER_MACHINE;

  constructor(public payload: { featureName: OrderView }) {}
}

export class MoveOrderPerMachine implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.MOVE_ORDER_PER_MACHINE;

  constructor(public payload: { orderGuid: string; precedingOrderGuid: string }) {}
}

export class UpdateLocationInfo implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_LOCATION_INFO;

  constructor(public payload: { location: LocationDTO }) {}
}

export class InitialLoadStatsLocations implements Action {
  readonly type: DashboardActionType = DashboardActionType.INITIAL_LOAD_STATS_LOCATIONS;
}

export class UpdateSensorReadingData implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.UPDATE_SENSOR_READING_DATA;

  constructor(public payload: { sensorReading: SensorReadingDTO }) {}
}

export class ToggleLocationSettingClearable implements PayloadAction {
  readonly type: DashboardActionType = DashboardActionType.TOGGLE_LOCATION_SETTING_CLEARABLE;

  constructor(public payload: { clearable: boolean }) {}
}

export type DashboardAction =
  | SetMachineGUID
  | SetActiveMachineCategory
  | RespondDataPerMachine
  | ErrorDataPerMachine
  | SendMessageMachineDashboard
  | RequestLocationsDashboard
  | RespondLocationsDashboard
  | RequestTotalStatisticPerLocation
  | RespondTotalStatisticPerLocation
  | RequestMachineDataPerLocation
  | RespondMachineDataPerLocation
  | DashboardRequestShifts
  | DashboardUpdateShifts
  | DashboardSaveShiftSuccessfully
  | DashboardRequestAllExceptOrdersPerLocation
  | DashboardUpdateMachineStatus
  | UpdateMachineOrderStatus
  | TriggerDashboardRequest
  | FetchOrdersPerMachine
  | ResponseOrdersPerMachine
  | ErrorOrdersPerMachine
  | LiveUpdateOrdersPerMachine
  | AddFilterOrderPerMachine
  | SortOrdersPerMachine
  | LoadMoreOrdersPerMachine
  | MoveOrderPerMachine
  | UpdateLocationInfo
  | DequeueOrderFromMachine
  | InitialLoadStatsLocations
  | ToggleLocationSettingClearable
  | UpdateSensorReadingData;

export type DashboardLocalStorageActions =
  | UpdateDashboardSelectedLocation
  | UpdateDashboardSelectedLocationById
  | UpdateDashboardCustomPeriod
  | UpdateDashboardSelectedPeriod
  | UpdateDashboardSelectedMachineCategory
  | UpdateMachinesFilters
  | UpdateMachinesSort;
