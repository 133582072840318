import { Model } from 'ngx-super-model';

export interface MachineTypeDTO {
  id: number;
  name?: string;
}

export class MachineType extends Model<MachineTypeDTO> {
  id: number | null = null;
  name = '';
}
