import { Model } from 'ngx-super-model';

import { DialogConfigDTO } from './dialog.interface';

export class DialogConfig extends Model<DialogConfigDTO> {
  title = '';
  message?: string = '';
  hideCloseIcon?: boolean = false;
  className?: Array<string> = [];
  button = {
    hide: false,
    confirmation: 'actions.yes',
    cancel: 'actions.no',
  };
}
