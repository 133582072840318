import { FileType } from 'src/app/main/library/enums/file-type.enum';
import { PreviewDocumentDTO } from 'src/app/modules/media-gallery/interfaces/preview-document.interface';
import { downloadAFile } from 'src/app/util/util';
import { environment } from 'src/environments/environment';

import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-upload',
  templateUrl: './upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UploadComponent),
    },
  ],
})
export class UploadComponent implements ControlValueAccessor {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Input()
  canUserEditFile = true;

  @Output()
  valueChanged = new EventEmitter<PreviewDocumentDTO>();

  @ViewChild('fileUpload', { static: false }) fileUpload?: ElementRef;

  value: PreviewDocumentDTO | null = null;
  isDisabled = false;

  get isValueDefined(): boolean {
    return Boolean(this.value?.filename) || Boolean(this.value?.fileSize);
  }

  get fileAcceptFormat(): string {
    return this.config.meta?.fileAcceptFormat;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  onFileChange(target: any): void {
    if (!target || !target.files) {
      return;
    }

    [...target.files].forEach((file: File) => this.addAttachment(file));
  }

  removeFile(): void {
    this.writeValue(undefined);
    this.propagateChange(undefined);
    this.valueChanged.emit(undefined);
  }

  download(): void {
    if (!this.value?.url || !this.value.filename || !this.value.extension) {
      return;
    }
    const fileUrl = `${environment.rootUrl}${this.value.url}`;
    downloadAFile(fileUrl, this.value.filename, this.value.extension);
  }

  //eslint-disable-next-line
  private propagateChange = (value: any) => {};

  //eslint-disable-next-line
  private propagateTouched = () => {};

  private addAttachment(file: File): void {
    const fileReader: FileReader = new FileReader();

    const attachmentsEmit: PreviewDocumentDTO = {
      filename: null,
      data: null,
      filetype: null,
    };

    attachmentsEmit.filename = file.name;
    attachmentsEmit.filetype = file.type as FileType;

    fileReader.onload = () => {
      const data: string = fileReader.result as string;
      attachmentsEmit.data = data.split(';base64,')[1];
      attachmentsEmit.fileSize = file.size;
      this.writeValue(attachmentsEmit);
      this.propagateChange(attachmentsEmit);
      this.valueChanged.emit(attachmentsEmit);
    };

    fileReader.readAsDataURL(file);
    return;
  }
}
