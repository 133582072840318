<app-modal-layout (closeDialog)="onCloseDialog()" className="app-modal--create-dashboard">
  <span header>{{ 'dashboard.actions.create-dashboard' | translate }}</span>

  <div main>
    <quick-form [id]="'create-dashboard-modal'" [config]="formConfig" [form]="form"></quick-form>
  </div>

  <ng-container footer>
    <jm-button [class]="'green-button'" (clicked)="save()">
      {{ 'button.create' | translate }}
    </jm-button>
    <jm-button [class]="'red-border-button'" (clicked)="onCloseDialog()">
      {{ 'button.cancel' | translate }}
    </jm-button>
  </ng-container>
</app-modal-layout>
