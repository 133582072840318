import { TableEnum } from 'src/app/modules/jm-table/enum/table.enum';
import { loadTableCellConfig } from 'src/app/modules/jm-table/helpers/table.helper';
import { TableConfigDTO } from 'src/app/modules/jm-table/interfaces/table-config.interface';

import { productionDetailsCellConfig } from './cells.config';

export const productionDetailsTableConfig: TableConfigDTO = {
  id: TableEnum.productionDetails,
  cellsConfig: loadTableCellConfig(productionDetailsCellConfig),
  footer: false,
  rowExpanded: false,
  mobileRowExpanded: true,
  dataKey: 'articleGuid',
  customSort: true,
  search: {
    show: true,
    placeholder: 'actions.enter-article-name',
  },
  filtering: true,
  updateUrl: false,
  scrollHeight: '500px',
  autoLoad: false,
};
