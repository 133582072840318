export enum FRICK_STATUS {
  STOP_OFFLINE = 'STOP_OFFLINE',
  STOP_NO = 'STOP_NO',
  INVALID = 'INVALID',
  STOP_REEDCOVER = 'STOP_REEDCOVER',
  STOP_FABRIC_OVERROLL = 'STOP_FABRIC_OVERROLL',
  STOP_FABRIC_ROLLOVER = 'STOP_FABRIC_ROLLOVER',
  STOP_END_OF_ORDER = 'STOP_END_OF_ORDER',
  STOP_MANUAL = 'STOP_MANUAL',
  STOP_BINDER = 'STOP_BINDER',
  STOP_WARP = 'STOP_WARP',
  STOP_WEFT = 'STOP_WEFT',
  STOP_OTHER = 'STOP_OTHER',
  STOP_EXTERNAL_STOP = 'STOP_EXTERNAL_STOP',
}

export enum COMEZ_STATUS {
  INIT_START = 'INIT_START',
  INIT_INITIALIZATION_IN_PROGRESS = 'INIT_INITIALIZATION_IN_PROGRESS',
  INIT_INIT = 'INIT_INIT',
  SETUP_CONFIGURE_MACHINE = 'SETUP_CONFIGURE_MACHINE',
  SETUP_LOAD_CHECK_PATTERN = 'SETUP_LOAD_CHECK_PATTERN',
  SETUP_EXECUTE_HOME = 'SETUP_EXECUTE_HOME',
  SETUP_WAITING_FOR_FIRST_LINE_RECALL = 'SETUP_WAITING_FOR_FIRST_LINE_RECALL',
  SETUP_OK = 'SETUP_OK',
  SETUP_INCORRECT_ANGLES = 'SETUP_INCORRECT_ANGLES',
  ACTIVATION_LOADING_PATTERN = 'ACTIVATION_LOADING_PATTERN',
  ACTIVE_PATTERN_EXECUTING = 'ACTIVE_PATTERN_EXECUTING',
  ACTIVE_CHECK_PATTERN = 'ACTIVE_CHECK_PATTERN',
  ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS = 'ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS',
  ERROR_FILE = 'ERROR_FILE',
  ERROR_INTERNAL = 'ERROR_INTERNAL',
  ERROR_I_O = 'ERROR_I_O',
  ERROR_CAN = 'ERROR_CAN',
  ERROR_ACTUATORS = 'ERROR_ACTUATORS',
  ERROR_OPERATION = 'ERROR_OPERATION',
  ERROR_DEVICE = 'ERROR_DEVICE',
  ERROR_AUTOMATIC_CYCLE = 'ERROR_AUTOMATIC_CYCLE',
  ERROR_INVERTER = 'ERROR_INVERTER',
}

export enum FILTERED_COMEZ_STATUS {
  SETUP = 'SETUP',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR',
}

// Requirement by ticket https://kickstage.atlassian.net/browse/MYM-3734
export enum WIDGET_COMEZ_STATUS {
  ERROR_CAN = 'ERROR_CAN',
  ERROR_ACTUATORS = 'ERROR_ACTUATORS',
  ERROR_AUTOMATIC_CYCLE = 'ERROR_AUTOMATIC_CYCLE',
  ERROR_INVERTER = 'ERROR_INVERTER',
}

// Requirement by ticket https://kickstage.atlassian.net/browse/MYM-3734
export const WIDGET_MACHINE_STATUS = {
  ...FRICK_STATUS,
  ...WIDGET_COMEZ_STATUS,
};
export type WIDGET_MACHINE_STATUS = (typeof WIDGET_MACHINE_STATUS)[keyof typeof WIDGET_MACHINE_STATUS];

export const FILTERED_MACHINE_STATUS = {
  ...FRICK_STATUS,
  ...FILTERED_COMEZ_STATUS,
};
export type FILTERED_MACHINE_STATUS = (typeof FILTERED_MACHINE_STATUS)[keyof typeof FILTERED_MACHINE_STATUS];

export const MachineStatus = {
  ...FRICK_STATUS,
  ...COMEZ_STATUS,
};
export type MachineStatus = (typeof MachineStatus)[keyof typeof MachineStatus];
