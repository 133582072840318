
    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"productionDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProductionDetailsInput"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"productionDetails"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"machineGuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"machineName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"machineAlias"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"articleGuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"articleName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"machineTypeId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"machineTypeName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastFinished"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"stopStatus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"downtime"},"arguments":[],"directives":[]}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
