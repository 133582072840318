import { environment } from 'src/environments/environment';

export const pushNotificationConfig = {
  appId: environment.oneSignalAppID,
  safari_web_id: environment.oneSignalSafariWebID,
  notifyButton: {
    enable: false,
  },
  welcomeNotification: {
    disable: true,
  },
  allowLocalhostAsSecureOrigin: true,
  serviceWorkerParam: { scope: '/push/onesignal' },
};
