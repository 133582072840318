<app-auth-card>
  <ng-container title>
    {{ 'forgot-password.welcome' | translate }}
  </ng-container>

  <form *ngIf="form" class="quick-form form">
    <quick-form-controls [form]="form" [config]="formConfig"> </quick-form-controls>
  </form>

  <jm-button [class]="'red-button'" (clicked)="submit()" [disabled]="form.invalid">
    {{ 'forgot-password.button-send' | translate }}
  </jm-button>

  <div *ngIf="mailSent" class="action-message-wrapper">
    <p>
      {{ 'forgot-password.email-sent.action-message' | translate }}
    </p>
  </div>
  <p *ngIf="mailSent" class="action">
    {{ 'forgot-password.email-sent.message' | translate }}
    <span (click)="submit()" class="forget">{{ 'forgot-password.email-sent.action' | translate }}?</span>
  </p>
  <p class="action" (click)="navigateTo('/auth/login')">
    <a class="forget go-back">
      <!-- <i class="fa fa-angle-left"></i> -->
      <svg-icon class="user-icon" [src]="'./assets/images/chevron-left.svg'"></svg-icon>
      {{ 'forgot-password.go-back' | translate }}
    </a>
  </p>
</app-auth-card>
