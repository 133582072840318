import { Model } from 'ngx-super-model';
import { CompanyDTO } from 'src/app/core/company/interfaces/company.interface';

import { JMPermissions } from '@auth';
import { LocationDTO } from '@locations';

import { PushNotificationDevicesDTO } from '../interfaces/push-notification-devices.interface';
import { UserGroupDTO } from '../interfaces/user-group.interface';
import { UserDTO } from '../interfaces/user.interface';
import { LastSignIn } from './last-sign-in.model';

export class User extends Model<UserDTO> {
  id: number | null = null;
  isActive = false;
  name: string | null = null;
  email: string | null = null;
  company: CompanyDTO | null = null;
  group: UserGroupDTO | null = null;
  userGroupId: string | null = null;
  activeDate: string | null = null;
  endDate: string | null = null;
  locations: Array<LocationDTO> = [];
  isAdmin = false;
  companyId: string | null = null;
  permissions: Array<JMPermissions> = [];
  userRole?: string | null = null;
  userStatus?: string | null = null;
  createdAt?: string | null = null;
  companyName?: string | null = null;
  validFrom?: string | null = null;
  validUntil?: string | null = null;
  enabledValidUntil?: boolean | null = null;
  lastSignIn?: LastSignIn = new LastSignIn();
  pushNotificationDevices?: Array<PushNotificationDevicesDTO> = [];
}
