import { Component } from '@angular/core';
import { get } from 'lodash-es';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-string-cell]',
  templateUrl: './jm-string-cell.component.html',
})
export class JmStringCellComponent extends AbstractCellComponent {
  get tagConditional(): ObjectLiteral | undefined {
    return this.column?.meta?.tagClassConditional?.find((f: any) => f.value === this.cellData[this.columnField]);
  }

  get value() {
    return get(this.cellData, this.columnField) || '-';
  }
}
