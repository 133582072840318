import { Model } from 'ngx-super-model';

import { v4 as uuidv4 } from 'uuid';

import { SustainabilityDetailsDTO } from '../interfaces';

export class SustainabilityDetails extends Model<SustainabilityDetailsDTO> {
  actualEnergy: number | null = null;
  actualTime: number | null = null;
  articleGuid: string | null = null;
  articleName: string | null = null;
  co2Produced: number | null = null;
  diffEnergy: number | null = null;
  lastFinished: string | null = null;
  machineAlias: string | null = null;
  machineGuid: string | null = null;
  machineName: string | null = null;
  targetEnergy: number | null = null;
  targetTime: number | null = null;
  id: string = uuidv4();
}
