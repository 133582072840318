import { uniq } from 'lodash-es';

import { enumToOptions, LookupResult } from '@quick-form';

import {
  COMEZ_STATUS,
  FILTERED_COMEZ_STATUS,
  FILTERED_MACHINE_STATUS,
  FRICK_STATUS,
  MachineStatus,
  WIDGET_COMEZ_STATUS,
  WIDGET_MACHINE_STATUS,
} from '../enums/machine-status.enum';
import { MACHINE_PLATFORM } from '../enums/platform.enum';
import { COMEZ_ACTIVE, COMEZ_ERROR, COMEZ_SETUP } from '../settings';
import { Machine } from '../models/machine.model';

/**
 * Determines the available machine statuses based on the given array of machines.
 * If all machines belong to a specific platform, returns statuses specific to that platform.
 * If machines belong to different platforms, returns a combination of statuses.
 * @param machines Array of Machine objects representing machines.
 * @returns Array of LookupResult options representing available machine statuses.
 */
export function setAvailableMachineStatuses(machines: Array<Machine>): Array<LookupResult> {
  const comezMachines = machines?.filter((machine) => machine.platform === MACHINE_PLATFORM.COMEZ);
  const frickMachines = machines?.filter((machine) => machine.platform !== MACHINE_PLATFORM.COMEZ);

  if (frickMachines?.length === machines?.length) {
    return enumToOptions(FRICK_STATUS, 'machines.status');
  }

  if (comezMachines?.length === machines?.length) {
    return enumToOptions(FILTERED_COMEZ_STATUS, 'machines.status');
  }

  return enumToOptions(FILTERED_MACHINE_STATUS, 'machines.status');
}
/**
 * Determines the available machine statuses based on the given array of machines ONLY FOR AVERAGE STOPS WIDGET.
 * Requirement by ticket https://kickstage.atlassian.net/browse/MYM-3734
 * If all machines belong to a specific platform, returns statuses specific to that platform.
 * If machines belong to different platforms, returns a combination of statuses.
 * @param machines Array of Machine objects representing machines.
 * @returns Array of LookupResult options representing available machine statuses.
 */
export function setWidgetAvailableMachineStatuses(machines: Array<Machine>): Array<LookupResult> {
  const comezMachines = machines?.filter((machine) => machine.platform === MACHINE_PLATFORM.COMEZ);
  const frickMachines = machines?.filter((machine) => machine.platform !== MACHINE_PLATFORM.COMEZ);

  if (frickMachines?.length === machines?.length) {
    return enumToOptions(FRICK_STATUS, 'machines.status');
  }

  if (comezMachines?.length === machines?.length) {
    return enumToOptions(WIDGET_COMEZ_STATUS, 'machines.status');
  }

  return enumToOptions(WIDGET_MACHINE_STATUS, 'machines.status');
}

/**
 * Maps frontend status options (FILTERED_COMEZ_STATUS) to corresponding backend-compatible COMEZ status options.
 * @param options Array of LookupResult objects representing frontend status options.
 * @returns Array of MachineStatus options suitable for backend requests.
 */
export function mapMachinesStatuses(options: Array<LookupResult>): Array<MachineStatus> {
  return options.reduce((statuses: Array<MachineStatus>, option: LookupResult) => {
    if (option.id === FILTERED_COMEZ_STATUS.SETUP) {
      return statuses.concat(COMEZ_SETUP);
    }

    if (option.id === FILTERED_COMEZ_STATUS.ACTIVE) {
      return statuses.concat(COMEZ_ACTIVE);
    }

    if (option.id === FILTERED_COMEZ_STATUS.ERROR) {
      return statuses.concat(COMEZ_ERROR);
    }

    // If the option ID is not one of the predefined COMEZ statuses, add it to the statuses array
    statuses.push(option.id);

    return statuses;
  }, []);
}

export function filterMachinesStatuses(statuses: Array<MachineStatus>): Array<LookupResult> {
  const filtered: Array<FILTERED_MACHINE_STATUS> = [];

  statuses.forEach((status) => {
    if (COMEZ_SETUP.includes(status)) {
      filtered.push(FILTERED_COMEZ_STATUS.SETUP);
      return;
    }

    if (COMEZ_ACTIVE.includes(status)) {
      filtered.push(FILTERED_COMEZ_STATUS.ACTIVE);
      return;
    }
    if (COMEZ_ERROR.includes(status)) {
      filtered.push(FILTERED_COMEZ_STATUS.ERROR);
      return;
    }

    filtered.push(status as FRICK_STATUS);
  });

  const result = uniq(filtered).map((value) => {
    return { id: value, name: `${value}.machines.status` };
  });

  return result;
}

export function mapStopsDataForAverageStopsChart(statuses: Array<MachineStatus>): Array<MachineStatus> {
  const filtered: Array<MachineStatus> = [];

  statuses.forEach((status) => {
    if (COMEZ_SETUP.includes(status)) {
      return;
    }

    if (COMEZ_ACTIVE.includes(status)) {
      return;
    }
    if (COMEZ_ERROR.includes(status)) {
      filtered.push(
        ...[COMEZ_STATUS.ERROR_AUTOMATIC_CYCLE, COMEZ_STATUS.ERROR_INVERTER, COMEZ_STATUS.ERROR_CAN, COMEZ_STATUS.ERROR_ACTUATORS]
      );
      return;
    }

    filtered.push(status as FRICK_STATUS);
  });

  return uniq(filtered);
}
