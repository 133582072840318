import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { ChartComponent } from 'src/app/layout/chart/chart.component';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { isSet } from 'src/app/util/util';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { chartConfig } from './chart.config';
import { dataToChartMapper } from './mappers/data-to-chart.mapper';
import { Revenue } from './models';
import { LoaderComponent } from '@app-ui';

const imports = [CommonModule, ChartComponent, BoxModule, LoaderComponent, TranslateModule, EmptyBlockModule];

@Component({
  selector: 'widget-revenue',
  standalone: true,
  imports: imports,
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss'],
})
export class RevenueComponent {
  @Input()
  title!: string;
  @Input()
  loader = false;
  @Input()
  set data(data: Array<Revenue>) {
    if (!data) {
      return;
    }
    this.setChartData(data);
  }
  @Input()
  currency: string | null = null;
  @Input()
  error: string | null = null;

  chartConfig: ChartConfiguration = cloneDeep(chartConfig);

  readonly id: string = `profitability-${uuidv4()}`;

  private setChartData(data: Array<Revenue>): void {
    this.chartConfig.data = dataToChartMapper(data);
    this.updateYAxisTitle();
  }

  private updateYAxisTitle(): void {
    const options = this.chartConfig?.options as any;
    const scales = options?.scales as any;
    if (!isSet(scales?.y)) {
      return;
    }
    const yAxes = scales.y;

    if (!isSet(yAxes.title)) {
      return;
    }

    // if (scales && scales.y) {
    //   if (yAxes.title) {
    yAxes.title.display = true;
    yAxes.title.text = this.currency ?? '';

    // Update tooltip callback
    options.plugins.tooltip = {
      callbacks: {
        label: (context: any) => {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          const value = context.parsed.y.toFixed(2);
          label += value;
          if (this.currency) {
            label += ` ${this.currency}`;
          }
          return label;
        },
      },
    };
    //   }
    // }
  }
}
