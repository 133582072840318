import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { ChartComponent } from 'src/app/layout/chart/chart.component';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { chartConfig } from './chart.config';
import { dataToChartMapper } from './mappers/data-to-chart.mapper';
import { DowntimeByArticle } from './models';
import { LoaderComponent } from '@app-ui';

const imports = [CommonModule, ChartComponent, BoxModule, LoaderComponent, TranslateModule, EmptyBlockModule];

@Component({
  selector: 'widget-downtime-by-article',
  standalone: true,
  imports,
  templateUrl: './downtime-by-article.component.html',
  styleUrls: ['./downtime-by-article.component.scss'],
})
export class DowntimeByArticleComponent {
  @Input() title!: string;
  @Input() loader = false;
  @Input()
  set data(data: Array<DowntimeByArticle>) {
    if (!data) {
      return;
    }
    this.setChartData(data);
  }
  @Input()
  error: string | null = null;

  chartConfig: ChartConfiguration = cloneDeep(chartConfig);

  readonly id: string = `downtime-by-article-${uuidv4()}`;

  private setChartData(data: Array<DowntimeByArticle>): void {
    this.chartConfig.data = dataToChartMapper(data);
  }
}
