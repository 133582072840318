import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipModule } from 'primeng/tooltip';

import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { isSet } from '@util';

@Component({
  selector: 'app-machine-statistic-column',
  standalone: true,
  imports: [AngularSvgIconModule, TooltipModule, TranslateModule],
  templateUrl: './machine-statistic-column.component.html',
  styleUrls: ['./machine-statistic-column.component.scss'],
})
export class MachineStatisticColumnComponent {
  @Input() src!: string;
  @Input() tooltipText!: string;
  hasNoValue!: boolean;

  @Input()
  set value(value: string | number | undefined) {
    if (!isSet(value) || value === 0) {
      this.hasNoValue = true;
      this._value = 'N/A';
    } else {
      this.hasNoValue = false;
      this._value = String(value);
    }
  }
  get value(): string | number | undefined {
    return this._value;
  }

  private _value: string | number | undefined = undefined;
}
