<div class="number-header" (click)="setActive.emit()">
  <label class="label" *ngIf="label">{{ label | translate }} <span *ngIf="required">*</span> </label>
  <span class="description" *ngIf="description">({{ description }})</span>
</div>
<div class="input-wrapper" (click)="clickInputWrapper()">
  <button
    *ngIf="!nonEditable && !hideArrows"
    class="input-arrows minus"
    [ngClass]="{
      'disabled-arrow': disabled,
      'input-focused': isInputFocused,
      invalid: invalid
    }"
    [disabled]="disabled"
    type="button"
    (click)="decrease()"
  >
    <svg-icon [src]="'./assets/images/minus.svg'" alt="minus"></svg-icon>
  </button>
  <input
    type="number"
    [ngClass]="{
      'not-editable': nonEditable,
      invalid: invalid,
      readonly: readonly,
      'hide-arrows': hideArrows
    }"
    placeholder="{{ placeholder | translate }}"
    [(ngModel)]="value"
    min="{{ min }}"
    max="{{ max }}"
    (afterValueChanged)="valueChange($event.target.value)"
    (focus)="onFocus($event)"
    (blur)="onFocus()"
    [disabled]="disabled"
    [readonly]="readonly"
  />
  <button
    *ngIf="!nonEditable && !hideArrows"
    class="input-arrows plus"
    [ngClass]="{
      'disabled-arrow': disabled,
      'input-focused': isInputFocused,
      invalid: invalid
    }"
    [disabled]="disabled"
    type="button"
    (click)="increase()"
  >
    <svg-icon [src]="'./assets/images/plus.svg'" alt="plus"></svg-icon>
  </button>
</div>

<div class="validation-message" *ngIf="!!validationMessage && invalid">
  {{ validationMessage | translate }}
</div>
