import { catchError, EMPTY, exhaustMap, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { ListResultDTO } from '@jm-table';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { DashboardService } from '../dashboard.service';
import { Dashboard } from '../model/dashboard.model';
import * as DashboardActions from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  loadPublicDashboards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadPublicDashboards),
      exhaustMap(() =>
        this.dashboardService.getSharedDashboard().pipe(
          map((dashboards: ListResultDTO<Dashboard>) => DashboardActions.setPublicDashboards({ dashboards: dashboards.records })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  loadPrivateDashboards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.loadPrivateDashboards),
      exhaustMap(() =>
        this.dashboardService.getAll().pipe(
          map((dashboards: ListResultDTO<Dashboard>) => DashboardActions.setPrivateDashboards({ dashboards: dashboards.records })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly dashboardService: DashboardService) {}
}
