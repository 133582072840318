import { Component } from '@angular/core';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-data-translation-cell]',
  templateUrl: './jm-data-translation-cell.component.html',
})
export class JmDataTranslationCellComponent extends AbstractCellComponent {
  get tagConditional(): ObjectLiteral | undefined {
    return this.column?.meta?.tagClassConditional?.find((f: any) => f.value === this.cellData[this.columnField]);
  }
}
