import { FormUnsavedGuard } from 'src/app/modules/quick-forms/guards/form-unsaved-guard.service';

import { Routes } from '@angular/router';
import { AuthGuard } from '@auth';

import { MainComponent } from '../../main/main.component';
import { ConfigurationComponent } from './components';

export const notificationConfigurationRoute: Routes = [
  {
    path: 'configuration',
    component: MainComponent,
    children: [
      {
        path: '',
        component: ConfigurationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [FormUnsavedGuard],
        data: {
          navBarTitle: 'configuration',
        },
      },
    ],
  },
];
