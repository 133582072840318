<app-box [className]="'without-content-scroll'">
  <ng-container header>
    {{ 'performance-dashboard.production-details.title' | translate }}
  </ng-container>

  <ng-container content>
    <div class="table-wrapper">
      <jm-table
        *ngIf="!error"
        [tableConfig]="tableConfig"
        [loading]="loading"
        [data]="data"
        [tableSearchConfig]="tableSearchConfig"
        (lazyLoadChanged)="onLazyLoadChanged($event)"
      />

      @if(error && !loading){
      <div class="error-wrapper">
        <app-empty-block
          [title]="'global.errors.error' | translate"
          [image]="'./assets/images/machines-error.svg'"
          [description]="'global.errors.could-not-fetch-data' | translate"
        />
      </div>
      }
    </div>
  </ng-container>
</app-box>
