import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  private readonly router = inject(Router);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public notificationRef: MatSnackBarRef<SnackBarComponent>) {}

  getIconUrl() {
    return `./assets/images/alert-${this.data.severity.toLowerCase()}.svg`;
  }

  navigateTo(): void {
    this.router.navigate([this.data.link.url]);
  }

  onDismiss() {
    this.notificationRef.dismiss();
  }

  onReadMore() {
    this.router.navigate([`/notifications/${this.data.messageId}`]);
    this.notificationRef.dismiss();
  }
}
