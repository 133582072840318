import { gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';

import { Injectable } from '@angular/core';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { EnergyConsumedByMachinesDTO } from './interfaces';
import { EnergyConsumedByMachines } from './models';

@Injectable({
  providedIn: 'root',
})
export class EnergyConsumedByMachinesService extends AbstractCRUDService<EnergyConsumedByMachines, EnergyConsumedByMachinesDTO> {
  getData(input: SearchCriteriaDTO): Observable<Array<EnergyConsumedByMachinesDTO>> {
    const query = gql`
      query energyConsumedByMachines($input: EnergyConsumptionInput!) {
        energyConsumedByMachines(input: $input) {
          machineEnergyConsumptionData {
            machineName
            machineGuid
            totalEnergyUsedKwh
            co2Equivalent
          }
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response) => {
          return response.data['energyConsumedByMachines']['machineEnergyConsumptionData'];
        })
      );
  }
}
