import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-sb-slide-toggle',
  templateUrl: './sb-slide-toggle.component.html',
  styleUrls: ['./sb-slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbSlideToggleComponent {
  @Input() label = '';
  @Input() labelPosition: 'before' | 'after' = 'before';
  @Input() value: boolean | null | undefined = null;
  @Input() disabled = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter();

  toggleChanged(event: MatSlideToggleChange) {
    this.toggleChange.emit(event.checked);
  }
}
