import { ChartData } from 'chart.js';

import { MachineStatus } from '@machines';

import { PeriodDTO } from '../../core';
import { mapLabels } from '../../core/helpers/chart.helper';
import { AverageStopsItemDTO } from '../interface/average-stops.interface';
import { chartTemplate } from './chart-template';

export function dataToChartMapper(
  selectedStops: Array<MachineStatus>,
  backendData: Array<AverageStopsItemDTO>,
  period: PeriodDTO
): ChartData {
  const labels = mapLabels(backendData, period);

  const initialTemplates = chartTemplate.map((template) => {
    return {
      ...template,
      data: new Array(labels.length).fill(0),
    };
  });

  backendData.forEach((item, itemIndex) => {
    item.stops.forEach((stop) => {
      const template = initialTemplates.find((stopTemplate) => stopTemplate.id === stop.stopType);
      if (template) {
        template.data[itemIndex] = stop.value;
      }
    });
  });

  const selectedTemplates = initialTemplates.filter((template) => selectedStops.includes(template.id));

  return {
    labels,
    datasets: selectedTemplates,
  };
}
