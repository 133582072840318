<div class="header-bar {{ className }}" [class.header-bar--icon]="icon">
  <div class="header-bar__info-and-navigation">
    <svg-icon *ngIf="icon" class="header-bar__icon" [src]="'./assets/images/' + icon + '.svg'"></svg-icon>
    <div class="header-bar__title" *ngIf="title">
      {{ title }}
    </div>

    <ng-content select="[info]"></ng-content>
  </div>

  <div class="header-bar__actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
