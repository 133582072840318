<app-box [loading]="loader">
  <ng-container header>
    <div class="title-wrapper">
      <div class="title">
        {{ 'performance-dashboard.availability.title' | translate }}
        <svg-icon (click)="onIconClick()" class="question-icon" [src]="'./assets/images/question-mark.svg'"></svg-icon>
      </div>
      <app-percentage-box *ngIf="!error && !loader" [value]="data?.availability"></app-percentage-box>
    </div>
  </ng-container>

  <ng-container content>
    <ng-container *ngIf="!loader">
      <ng-container *ngIf="!error">
        <div class="content-row">
          <div class="content-column">{{ 'performance-dashboard.availability.planned-runtime' | translate }}</div>
          <div class="content-column data">{{ data?.plannedProductionTime | prettyDuration }}</div>
        </div>
        <div class="content-row">
          <div class="content-column">{{ 'performance-dashboard.availability.actual-runtime' | translate }}</div>
          <div class="content-column data">{{ data?.actualProductionTime | prettyDuration }}</div>
        </div>
        <div class="content-row">
          <div class="content-column">{{ 'performance-dashboard.availability.unplanned-downtime' | translate }}</div>
          <div class="content-column data">{{ data?.unplannedDowntime | prettyDuration }}</div>
        </div>
      </ng-container>
      <div class="error-wrapper" *ngIf="error">
        <app-empty-block [title]="'global.errors.error' | translate" [image]="'./assets/images/machines-error.svg'" [description]="error">
        </app-empty-block>
      </div>
    </ng-container>
  </ng-container>
</app-box>
