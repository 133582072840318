import { TableEnum } from 'src/app/modules/jm-table/enum/table.enum';
import { loadTableCellConfig } from 'src/app/modules/jm-table/helpers/table.helper';
import { TableConfigDTO } from 'src/app/modules/jm-table/interfaces/table-config.interface';

import { oeeByMachineCategoryCellConfig } from './cells.config';

export const oeeByMachineCategoryTableConfig: TableConfigDTO = {
  id: TableEnum.oeeByMachineCategory,
  cellsConfig: loadTableCellConfig(oeeByMachineCategoryCellConfig),
  cssClass: ['no-border'],
  footer: false,
  rowExpanded: false,
  mobileRowExpanded: false,
  dataKey: 'machineCategoryId',
  customSort: true,
  updateUrl: false,
  scrollHeight: '500px',
  autoLoad: false,
};
