import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { ResponseData } from '../../abstract/interface/response-data';
import multipleRawSensorReadingsChart from './gql/multiple-raw-sensor-readings-chart.graphql';
import { HistoricalSensorDTO } from './interfaces/historical-sensor.interface';
import { InputHistoricalSensor } from './interfaces/input-historical-sensor.interface';
import { HistoricalSensor } from './models/historical-sensor.model';

@Injectable({ providedIn: 'root' })
export class HistoricalSensorService {
  private readonly apollo = inject(Apollo);

  getData(input: InputHistoricalSensor): Observable<HistoricalSensor> {
    return this.apollo
      .query<HistoricalSensorDTO>({
        query: multipleRawSensorReadingsChart,
        variables: {
          input,
        },
      })
      .pipe(
        catchAllErrors(),
        map((response: ApolloQueryResult<ResponseData<HistoricalSensorDTO>>) => {
          return new HistoricalSensor().loadModel(response.data.multipleRawSensorReadingsChart);
        })
      );
  }
}
