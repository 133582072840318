import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxComponent } from './box.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@app-ui';

@NgModule({
  declarations: [BoxComponent],
  exports: [BoxComponent],
  imports: [CommonModule, TranslateModule, LoaderComponent],
})
export class BoxModule {}
