import { HeaderBarComponent } from 'src/app/layout/header-bar/header-bar.component';
import { notificationConfigurationReducer } from 'src/app/core/notification-configuration/store/notification-configuration.reducer';
import { notificationConfigurationStateName } from 'src/app/core/notification-configuration/store/notification-configuration.state';
import { MaterialSharedModule } from 'src/app/shared/material-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LegacyComponentsModule } from '@legacy-components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '@app-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import {
  AlertConfigurationComponent,
  AlertTableComponent,
  ConfigurationComponent,
  ConfigurationDialogComponent,
  PushNotificationsComponent,
} from './components';
import { MachineStoppedControlComponent } from './components/configuration/alert-configuration/alert-table/machine-stopped-control/machine-stopped-control.component';
import { notificationConfigurationRoute } from './notification-configuration.routing';
import { NotificationConfigurationEffects } from './store/notification-configuration.effect';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';

@NgModule({
  declarations: [
    AlertConfigurationComponent,
    AlertTableComponent,
    ConfigurationDialogComponent,
    ConfigurationComponent,
    MachineStoppedControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LegacyComponentsModule,
    SharedModule,
    RouterModule.forChild(notificationConfigurationRoute),
    StoreModule.forFeature(notificationConfigurationStateName, notificationConfigurationReducer),
    EffectsModule.forFeature([NotificationConfigurationEffects]),
    MaterialSharedModule,
    HeaderBarComponent,
    LoaderComponent,
    TimeAgoPipe,
    PushNotificationsComponent,
  ],
})
export class NotificationConfigurationModule {}
