<div class="push-notifications-wrapper">
  <div class="push-notifications-data">
    <ng-container *ngIf="!loading">
      <div class="notifications-settings">
        <div class="settings-title">
          {{ 'notifications.push-notifications.title' | translate }}
        </div>
        <div class="settings first-row">
          <div class="setting-name">
            {{ 'notifications.push-notifications.enable-disable' | translate }}
          </div>
          <div class="setting-value">
            <form *ngIf="form" [id]="'push-notification-configuration'" class="quick-form form" [formGroup]="form">
              <quick-form-slide-toggle
                [config]="getConfig('defaultNotification')"
                [formControl]="getFormControl('defaultNotification')"
                (valueChanged)="save($event)"
              ></quick-form-slide-toggle>
            </form>
          </div>
        </div>

        <div *ngIf="permission === pushPermissions.denied" class="settings">
          <div class="setting-name">
            <b>{{ 'notifications.push-notifications.permission-blocked' | translate }}</b>
          </div>
        </div>

        <div class="settings other-device" *ngIf="registerButtonVisible">
          <div class="setting-name">
            <p class="setting-name-text">{{ 'notifications.push-notifications.register-to-new-device' | translate }}</p>
          </div>
          <jm-button [class]="'red-button'" (clicked)="registerDevice()">
            {{ 'notifications.push-notifications.register-device' | translate }}
          </jm-button>
        </div>
      </div>
    </ng-container>
  </div>
  <app-loader class="jm-loader" *ngIf="loading"></app-loader>
</div>
