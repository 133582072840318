import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sb-text-box',
  templateUrl: './sb-text-box.component.html',
  styleUrls: ['./sb-text-box.component.scss'],
})
export class SbTextBoxComponent {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() nonEditable = false;
  @Input() label = '';
  @Input() emptyLabel = false;
  @Input() required = false;
  @Input() invalid = false;
  @Input() readonly = false;
  @Input() className = '';
  @Input() maxLength = 0;
  @Input() value: string | number | null | undefined = null;
  @Input() errorMessage = '';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();

  valueChange(param: string): void {
    this.onValueChange.emit(param);
  }
}
