import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

export const formConfig: Array<ControlConfigDTO> = [
  {
    id: 'password',
    label: 'auth.password',
    type: ControlTypeEnum.InputText,
    attributes: {
      type: 'password',
    },
    validators: {
      required: true,
      equalTo: {
        field: 'confirmPassword',
        message: 'Passwords must match',
      },
      password: true,
    },
  },
  {
    id: 'confirmPassword',
    label: 'auth.confirm-password',
    type: ControlTypeEnum.InputText,
    placeholder: 'auth.form.confirm-password',
    attributes: {
      type: 'password',
    },
    validators: {
      required: true,
      equalTo: {
        field: 'password',
        message: 'Passwords must match',
      },
      password: true,
    },
  },
];
