import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalLayoutModule } from 'src/app/layout/partials/modal-layout/modal-layout.module';
import { LegacyComponentsModule } from '@legacy-components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QuickFormsModule } from '@quick-form';

import { CreateDashboardModalComponent } from './create-dashboard-modal/create-dashboard-modal.component';

@NgModule({
  declarations: [CreateDashboardModalComponent],
  imports: [CommonModule, TranslateModule, AngularSvgIconModule, ModalLayoutModule, LegacyComponentsModule, QuickFormsModule],
  exports: [CreateDashboardModalComponent],
})
export class CreateDashboardModule {}
