export class CRUDError extends Error {
  override name = 'CRUD error';
  override message = '';

  constructor(message: string) {
    super(message);
    this.message = message;
    Object.setPrototypeOf(this, CRUDError.prototype);
  }
}
