import { SSOAutoLoginConfig } from '../mymueller-sso/sso-autologin-config';

/*
 * When saving redirect URL in sessionStorage mymueller_sso param needs to be removed otherwise it
 * will end up in a loop redirecting to mymueller
 */
export function sanitizeSessionRedirectUrl(url: string): string {
  const [baseUrl, queryParams] = url.split('?');

  const params = new URLSearchParams(queryParams);
  params.delete(SSOAutoLoginConfig.param);

  const hasParams = [...new Set(params.keys())].length > 0;

  if (hasParams) {
    return baseUrl + '?' + params.toString();
  }

  return baseUrl;
}
