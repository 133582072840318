export class EntityNotFound extends Error {
  override name = 'Entity not found';
  override message = '';

  constructor(message: string) {
    super(message);
    this.message = message;
    Object.setPrototypeOf(this, EntityNotFound.prototype);
  }
}
