import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CellConfig } from '../../models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { cloneDeep } from 'lodash-es';
import { ControlConfig, ControlTypeEnum, findConfig } from '@quick-form';
import { QuickFormError } from 'src/app/modules/quick-forms/errors/forms.error';

@Component({
  selector: 'jm-column-settings',
  templateUrl: './jm-column-settings.component.html',
})
export class JmColumnSettingsComponent {
  @Input()
  set columns(columns: Array<CellConfig>) {
    this._columns = cloneDeep(columns);
    this.formColumnSettings = this.setupColumnSettingForm();
  }
  get columns(): Array<CellConfig> {
    return this._columns;
  }
  private _columns: Array<CellConfig> = [];

  @Output() applyChanges = new EventEmitter<Array<CellConfig>>();
  @Output() closeMenu = new EventEmitter<void>();

  get canUserUnselectColumns(): boolean {
    return this._columns.filter((column) => column.display).length >= 2;
  }

  formColumnSettings: Array<ControlConfig> = [];

  drop(event: CdkDragDrop<Array<CellConfig>>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  apply(): void {
    this.applyChanges.emit(this._columns);
  }

  close(): void {
    this.closeMenu.emit();
  }

  reset(): void {
    this._columns.forEach((column) => {
      column.display = true;
    });
    this.applyChanges.emit(this._columns);
  }

  getConfig(id: string): ControlConfig {
    const config = findConfig(this.formColumnSettings, id);

    if (!config) {
      throw new QuickFormError('Cant find form config');
    }

    return config;
  }

  onSelect(checked: boolean, id: string): void {
    const index = this._columns.findIndex((column) => column.id === id);
    this._columns[index].display = checked;
  }

  private setupColumnSettingForm(): Array<ControlConfig> {
    const formColumnSettings: Array<ControlConfig> = [];

    this.columns.forEach((value) => {
      const config = new ControlConfig().loadModel({
        id: value.id,
        type: ControlTypeEnum.Checkbox,
      });
      formColumnSettings.push(config);
    });

    return formColumnSettings;
  }
}
