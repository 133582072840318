import { CellConfig } from 'src/app/modules/jm-table/models/cells.config';

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jm-column-filter-date-range',
  templateUrl: './jm-column-filter-date-range.component.html',
  styleUrls: ['./jm-column-filter-date-range.component.scss'],
})
export class JmColumnFilterDateRangeComponent {
  @Input() column: CellConfig = new CellConfig();

  @Input() value: Array<any> | null = [];

  @Output() valueChanged: EventEmitter<Array<any> | null> = new EventEmitter<Array<any> | null>();

  clear(): void {
    this.value = null;
    this.valueChanged.emit(this.value);
  }

  apply(): void {
    this.valueChanged.emit(this.value);
  }

  isDateRangeEnabled(): boolean {
    return this.value?.filter((value) => Boolean(value)).length === 2;
  }
}
