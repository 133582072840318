import { Component, Input } from '@angular/core';
import { CellType } from '../../../enum/cell-type.enum';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'jm-extra-mobile-cell',
  templateUrl: './jm-extra-mobile-cell.component.html',
})
export class JmExtraMobileCellComponent extends AbstractCellComponent {
  @Input() rowData: any;

  readonly cellType = CellType;
}
