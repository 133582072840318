import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDTO } from 'src/app/core/user/interfaces/user.interface';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';

import { LookupResult, LookupService } from '../../modules/quick-forms';
import { AbstractCRUDService } from '../abstract-crud.service';
import { listItemsToLookupResult } from '../helpers/lookup-mapper.helper';
import { User } from './models/user.model';

@Injectable({ providedIn: 'root' })
export class UserGroupService extends AbstractCRUDService<User, UserDTO> implements LookupService {
  getSuggestions(): Observable<ListResultDTO<LookupResult>> {
    const query = gql`
      query {
        userGroups {
          id
          name
        }
      }
    `;
    return this.apollo.query<any>({ query }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('user-group.error.get-all');
      }),
      map((response: ApolloQueryResult<any>) => {
        return {
          pageDetails: null,
          records: listItemsToLookupResult(response.data.userGroups),
        };
      })
    );
  }
}
