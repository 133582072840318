<app-box [className]="'without-content-scroll'">
  <ng-container header>
    {{ 'performance-dashboard.sustainability-details.title' | translate }}
  </ng-container>

  <ng-container content>
    <div class="table-wrapper">
      <jm-table
        *ngIf="!error"
        [tableConfig]="tableConfig"
        [loading]="loader"
        [data]="data"
        [rowExpandTemplate]="expandedTemplate"
        [tableSearchConfig]="tableSearchConfig"
        (lazyLoadChanged)="onLazyLoadChanged($event)"
        (loadMore)="loadMore($event)"
      />

      @if(error && !loader){
      <div class="error-wrapper">
        <app-empty-block
          [title]="'global.errors.error' | translate"
          [image]="'./assets/images/machines-error.svg'"
          [description]="'global.errors.could-not-fetch-data' | translate"
        />
      </div>
      }
    </div>
  </ng-container>
</app-box>

<ng-template #expandedTemplate let-rowData>
  <div class="expanded">
    <div class="title-holder">
      <span class="title">{{ 'performance-dashboard.sustainability-details.table.columns.targetEnergy' | translate }}</span>
    </div>
    <div class="value-holder">
      <span class="value">{{ rowData.targetEnergy | roundNumber : 2 }}</span>
    </div>
  </div>
  <div class="expanded">
    <div class="title-holder">
      <span class="title">{{ 'performance-dashboard.sustainability-details.table.columns.actualEnergy' | translate }}</span>
    </div>
    <div class="value-holder">
      <span class="value">{{ rowData.actualEnergy | roundNumber : 2 }}</span>
    </div>
  </div>
  <div class="expanded">
    <div class="title-holder">
      <span class="title">{{ 'performance-dashboard.sustainability-details.table.columns.diffEnergy' | translate }}</span>
    </div>
    <div class="value-holder">
      <span class="value">{{ rowData.diffEnergy | roundNumber : 2 }}</span>
    </div>
  </div>
</ng-template>
