import { ObjectLiteral } from './object-literal';
import { isSet } from './util';

export function flatten(obj: ObjectLiteral, out: ObjectLiteral = {}, parentKey = ''): ObjectLiteral {
  if (!isSet(obj)) {
    return {};
  }

  Object.keys(obj).forEach((key: string) => {
    const value = obj[key];

    if (typeof value === 'object') {
      const childObject = flatten(value, out, key);
      out = {
        ...out,
        ...childObject,
      };
    } else {
      if (parentKey) {
        key = parentKey + '.' + key;
      }

      out[key] = value;
    }
  });

  return out;
}

/**
 * Es lint rule prevents accessing obj.hasOwnProperty direcly.
 * This is a helper that checks that property exist on given object
 * @param obj
 * @param property
 * @returns
 */
export function hasProperty(obj: ObjectLiteral, property: string): boolean {
  return property in obj;
}
