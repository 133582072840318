<div class="alert-configuration">
  <div class="alert-configuration__options">
    <app-alert-table
      *ngIf="(alertConfigurationLoader$ | async) === false"
      [tableTitle]="'notifications.production-notifications' | translate"
      [alertConfiguration]="alertConfiguration"
      [alertConfigurationLoader]="alertConfigurationLoader$ | async"
      [alertSubmittingLoading]="alertSubmittingLoading$ | async"
      [rows]="alertConfigurationSettingsRows"
      (emitAlertConfiguration)="emitAlertConf($event)"
    ></app-alert-table>
  </div>
</div>
