import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ShowNotification } from 'src/app/shared/components/notification/store/snack-bar.actions';
import { NotificationUtils } from 'src/app/util/notification.util';
import { NotificationConfigurationService } from '../notification-configuration.service';
import {
  ErrorAlertConfiguration,
  ErrorUpdateAlertConfiguration,
  FetchAlertConfiguration,
  NotificationConfigurationActionType,
  SaveAlertConfiguration,
  SuccessfullyUpdateAlertConfiguration,
  UpdateAlertConfiguration,
} from './notification-configuration.actions';

@Injectable()
export class NotificationConfigurationEffects {
  fetchAlertConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchAlertConfiguration>(NotificationConfigurationActionType.FETCH_ALERT_CONFIGURATION),
      mergeMap(() => {
        return this.notificationConfigurationService.getAlertConfiguration().pipe(
          mergeMap((res) => {
            if (res.errors) {
              return of(
                new ErrorAlertConfiguration(),
                new ShowNotification({
                  message: res.errors[0].message,
                  config: NotificationUtils.snackBarErrorConfig(),
                })
              );
            } else {
              return of(new SaveAlertConfiguration({ alertConfiguration: res.data.alertConfiguration }));
            }
          }),
          catchError(() => {
            return of(
              new ErrorAlertConfiguration(),
              new ShowNotification({
                message: 'Something went wrong',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  updateAlertConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAlertConfiguration>(NotificationConfigurationActionType.UPDATE_ALERT_CONFIGURATION),
      mergeMap((action: UpdateAlertConfiguration) => {
        return this.notificationConfigurationService.updateAlertConfiguration(action.payload.alertConfiguration).pipe(
          mergeMap((res: any) => {
            return of(
              new SaveAlertConfiguration({ alertConfiguration: (res as any).updateAlertConfiguration }),
              new SuccessfullyUpdateAlertConfiguration(),
              new ShowNotification({
                message: 'Notification configuration is successfully updated!',
                config: NotificationUtils.snackBarSuccessConfig(),
              })
            );
          }),
          catchError(() => {
            return of(
              new ErrorUpdateAlertConfiguration(),
              new ShowNotification({
                message: 'Something went wrong',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  constructor(private readonly actions$: Actions, private readonly notificationConfigurationService: NotificationConfigurationService) {}
}
