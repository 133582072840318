import { ObjectLiteral } from 'src/app/util/object-literal';

export interface AlertConfiguration extends ObjectLiteral {
  machineSpeedAlertDelay: number | null;
  machineEfficiencyAlertDelay: number | null;
  articleSpeedAlertDelay: number | null;
  articleEfficiencyAlertDelay: number | null;
  locationEfficiencyAlertDelay: number | null;
  machineStoppedAlert: boolean | null;
  orderRunoutAlert: number | null;
  muconAlertDelay: number | null;
  locationEfficiencyGood?: number | null;
  locationEfficiencyPoor?: number | null;
  machineStoppedAlertReasons?: Array<string> | null;
}

export class IAlertConfiguration implements AlertConfiguration {
  constructor(
    public machineSpeedAlertDelay: number | null,
    public machineEfficiencyAlertDelay: number | null,
    public articleSpeedAlertDelay: number | null,
    public articleEfficiencyAlertDelay: number | null,
    public locationEfficiencyAlertDelay: number | null,
    public machineStoppedAlert: boolean | null,
    public orderRunoutAlert: number | null,
    public muconAlertDelay: number | null,
    public locationEfficiencyGood?: number | null,
    public locationEfficiencyPoor?: number | null,
    public machineStoppedAlertReasons?: Array<string> | null
  ) {}
}
