import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { AbstractTableComponent } from 'src/app/modules/jm-table/components/abstract-table/abstract-table.component';
import { JmTableModule } from 'src/app/modules/jm-table/jm-table.module';
import { TableConfig } from 'src/app/modules/jm-table/models/table.config';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { CommonModule } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OeeByMachineDTO } from './interfaces';
import { OeeByMachine } from './models';
import { OeeByMachineService } from './oee-by-machine.service';
import { oeeByMachineTableConfig } from './settings/table.config';

@Component({
  selector: 'widget-oee-by-machine',
  standalone: true,
  imports: [CommonModule, JmTableModule, TranslateModule, BoxModule, EmptyBlockModule],
  templateUrl: './oee-by-machine.component.html',
  styleUrls: ['./oee-by-machine.component.scss'],
})
export class OeeByMachineComponent extends AbstractTableComponent<OeeByMachine, OeeByMachineDTO> {
  @Input()
  set loader(loader: boolean) {
    this.loading = loader;
  }
  @Input()
  set tableData(data: Array<OeeByMachine>) {
    this.data = data;
  }
  @Input()
  error: string | null = null;

  override tableConfig: TableConfig = new TableConfig().loadModel(oeeByMachineTableConfig);

  constructor(override service: OeeByMachineService, injector: Injector) {
    super(service, injector);
  }
}
