<div class="quick-dropdown">
  <label *ngIf="config.label" class="label label--info">
    {{ config.label | translate }} <span *ngIf="config?.validators?.required" (click)="onOpenInfoWindow($event)">*</span>
    <svg-icon
      *ngIf="config?.meta?.info"
      class="info-icon"
      [src]="'./assets/images/question-mark.svg'"
      (click)="onOpenInfoWindow($event)"
    ></svg-icon>
  </label>
  <ng-select
    #select
    [(ngModel)]="value"
    [items]="options"
    [class.invalid]="control.invalid && control.touched"
    [id]="config.getDisplayId()"
    [placeholder]="placeholder || '' | translate"
    [class]="config.cssClass"
    [loading]="loading"
    [bindLabel]="'name'"
    [bindValue]="bindValueProperty"
    [compareWith]="compareFn"
    [markFirst]="true"
    [clearable]="config.meta?.clearable"
    (change)="onChange($event)"
    [disabled]="isDisabled || control.disabled"
    [appendTo]="appendTo"
    (blur)="onBlur()"
    [typeahead]="searchInput$"
    (clear)="clearValue()"
    (focus)="onFocus()"
    (close)="close()"
    [virtualScroll]="virtualScroll"
    (scrollToEnd)="onScrollToEnd()"
  >
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <span class="checkbox-label">{{ item.name | translate }}</span>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <ng-container *ngIf="!loading">
        <span class="ng-value-label">{{ item.name || item | translate }}</span>
      </ng-container>
    </ng-template>
  </ng-select>

  @if(config.hasAnchorId()){
  <div [tourAnchor]="config.getAnchorId()"></div>
  }
</div>
