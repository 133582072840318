import { gql } from 'apollo-angular';
import { upperFirst } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AbstractCRUDService } from 'src/app/core/abstract-crud.service';
import { LocalStorageService } from 'src/app/local-storage.service';
import { CreateArticleInput } from 'src/app/main/library/models';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { SearchCriteriaDTO } from 'src/app/modules/jm-table/interfaces/search-criteria.interface';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { LookupResult, LookupService } from '@quick-form';

import { catchAllErrors } from '../../../errors/pipes/catch-all-errors';
import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import { AutoFillContenDTO } from '../content/interfaces/auto-fill-content.interface';
import deleteMutation from './gql/delete.graphql';
import oneQuery from './gql/one.graphql';
import { ArticleDTO } from './interfaces/article.interface';
import { Article } from './models/article.model';

@Injectable({
  providedIn: 'root',
})
export class ArticleService extends AbstractCRUDService<Article, ArticleDTO> implements LookupService {
  private readonly http = inject(HttpClient);
  private readonly localStorageService = inject(LocalStorageService);

  override modelName = 'article';
  override modelNamePlural = 'articles';

  protected override deleteMutation = deleteMutation;
  protected override oneQuery = oneQuery;

  override model = Article;

  override fields = `
    guid
    name
    status
    companyId
    goodEfficiency
    stopsGood
    speedGood
    poorEfficiency
    stopsPoor
    speedPoor
    updatedAt
    createdAt
    articlePatterns {
      id
      pattern {
        id
        uptFile
        editorFilepath
        company {
          id
          name
        }
      }
      repeats
      compression
      ribbonWidth
      ribbonLength
    }
    previewDocuments {
      id
      filename
      filetype
      urls {
        thumbnail
        medium
        original
      }
      createdAt
      updatedAt
      fileSize
      creator {
        name
      }
    }
    articleContentVersions {
      id
      createdBy {
        id
        name
      }
      version
      createdAt
    }
    template {
      ... on ArticleTemplate {
        id
        name
        status
        machineCategories {
          id
          name
        }
      }
    }
    articleContent {
      id
      data
      createdAt
      version
    }
    projectCategories {
      id
      name
    }
    machineCategories {
      id
      name
    }
    machineTypes {
      id
      name
    }
  `;

  override getByGuid(guid: string): Observable<Article> {
    const query = gql`
      query article($guid: GUID!) {
        article(guid: $guid) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          guid,
        },
      })
      .pipe(
        catchAllErrors((error: Error) => {
          this.handleMessage(error, 'article.error.fetching-one');
        }),
        map((response: ApolloQueryResult<any>) => new Article().loadModel((response.data as ObjectLiteral).article))
      );
  }

  override delete(id: number): Observable<any> {
    const mutation = this.deleteMutation;

    const modelName = upperFirst(this.modelName);

    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid: id,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('article.error.delete');
        }),
        tap(() => {
          this.snackBarService.success('article.success.delete');
        }),
        map((response: ApolloQueryResult<ArticleDTO>) => {
          const mutation = `delete${modelName}`;

          return (response.data as ObjectLiteral)[mutation];
        })
      );
  }

  override create(input: CreateArticleInput): Observable<Article> {
    const mutation = gql`
      mutation createArticle($input: CreateArticleInput!) {
        createArticle(input: $input) {
          guid
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          input,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('article.error.create');
        }),
        tap(() => {
          this.snackBarService.success('article.success.create');
        }),
        map((response: ApolloQueryResult<any>) => new Article().loadModel((response.data as ObjectLiteral).createArticle))
      );
  }

  override update(input: CreateArticleInput, options?: ObjectLiteral): Observable<Article> {
    const guid = input.id;
    delete input.id;

    const mutation = gql`
      mutation updateArticle($input: UpdateArticleInput!, $guid: GUID!) {
        updateArticle(guid: $guid, input: $input) {
          guid
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid,
          input,
          ...options,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('article.error.update');
        }),
        tap(() => {
          this.snackBarService.success('article.success.update');
        }),
        map((response: ApolloQueryResult<any>) => new Article().loadModel((response.data as ObjectLiteral).updateArticle))
      );
  }

  getContentAutoFillData(data: string, patternId: number | null | undefined): Observable<AutoFillContenDTO> {
    const mutation = gql`
      mutation autofillArticleTemplateContentData($data: String!, $patternId: Int!) {
        autofillArticleTemplateContentData(data: $data, patternId: $patternId) {
          uptFileInfo {
            filename
          }
          sections {
            name
            index
            components {
              value
              index
            }
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          data,
          patternId,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('article.error.auto-fill-content');
        }),
        map((response: ApolloQueryResult<any>) => (response.data as ObjectLiteral).autofillArticleTemplateContentData)
      );
  }

  duplicateArticle(guid: string): Observable<any> {
    const mutation = gql`
      mutation duplicateArticle($guid: GUID!) {
        duplicateArticle(guid: $guid) {
          guid
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('article.error.duplicate');
        }),
        tap(() => {
          this.snackBarService.success('article.success.duplicate');
        }),
        map((response: ApolloQueryResult<any>) => (response.data as ObjectLiteral).duplicateArticle)
      );
  }

  exportArticleToPdf(guid: string) {
    const user = this.localStorageService.getSavedState('muCloudUser');

    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'x-token': user.token,
      }),
    };

    return this.http.get(`${environment.rootUrl}/api/pdf/article-card/${guid}`, httpOptions);
  }

  getSuggestions(searchCriteria?: SearchCriteriaDTO | undefined): Observable<ListResultDTO<LookupResult>> {
    return this.getAll(searchCriteria).pipe(
      map((value) => {
        return {
          records: listItemsToLookupResult(value.records, { id: 'guid', name: 'name' }),
          pageDetails: value.pageDetails,
        };
      })
    );
  }
}
