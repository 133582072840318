import { Model } from 'ngx-super-model';

export interface MachineCategoryDTO {
  id: number;
  name?: string;
  goodEfficiency?: number;
  stopsGood?: number;
  speedGood?: number;
  poorEfficiency?: number;
  stopsPoor?: number;
  speedPoor?: number;
  energyConsumptionKw?: number;
}

export class MachineCategory extends Model<MachineCategoryDTO> {
  id: number | null = null;
  name = '';
  goodEfficiency = 0;
  stopsGood = 0;
  speedGood = 0;
  poorEfficiency = 0;
  stopsPoor = 0;
  speedPoor = 0;
  energyConsumptionKw = 0;
}
