import { Model } from 'ngx-super-model';

import { ControlConfigDTO } from '../../quick-forms/interfaces/control-config.interface';
import { TableGlobalFilterDTO } from '../interfaces/global-filter.interface';

export class TableGlobalFilter extends Model<TableGlobalFilterDTO> {
  controls: Array<ControlConfigDTO> = [];
  icon = '';
  label = '';
}
