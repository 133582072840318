import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { Period } from 'src/app/util/period/period.interface';
import { isSet } from 'src/app/util/util';

import { Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { LookupResult } from '@quick-form';

import { selectDashboardPeriod } from '../../custom-dashboard/store/dashboard.selector';
import { AverageStopsWidget } from '../average-stop/average-stops.model';
import { FooterTag } from '../core/interfaces/footer-tag.interface';
import { MultipleMachinesRequestDTO } from '../core/interfaces/location-chart-request.interface';
import { PeriodDTO } from '../core/interfaces/period.interface';
import { TagTypeEnum } from '../core/interfaces/tags.interface';
import { periodToBucketSize, statePeriodToStartEndDate } from '../core/mappers/period-mapper';
import { efficiencyPerShiftChartConfig } from './chart-config';
import { EfficiencyPerShiftService } from './efficiency-per-shift.service';
import { EfficiencyPerShiftDTO } from './interface/efficiency-per-shift';
import { dataToChartMapper } from './mappers/data-to-chart.maper';

@UntilDestroy()
@Component({
  selector: 'app-efficiency-per-shift',
  templateUrl: './efficiency-per-shift.component.html',
  styleUrls: ['./efficiency-per-shift.component.scss'],
})
export class EfficiencyPerShiftComponent implements OnInit {
  @Input() widget: AverageStopsWidget = new AverageStopsWidget();
  @Input()
  dashboardId: string | undefined | null = null;

  data: ObjectLiteral | null = null;
  error = false;
  noData = false;

  chartConfig: ChartConfiguration = cloneDeep(efficiencyPerShiftChartConfig);
  loading = true;

  tags: Array<FooterTag> = [];

  private readonly store$ = inject(Store);
  private readonly widgetService = inject(EfficiencyPerShiftService);

  ngOnInit(): void {
    this.watchPeriodChange();
    this.populateTags();
  }

  private populateTags() {
    const { location, machines } = this.widget.config;

    const machinesTag = this.getMachineTag(machines);

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      machinesTag,
    ];
  }

  getMachineTag(machines?: Array<LookupResult>): FooterTag {
    if (!isSet(machines) || machines?.length === 0) {
      return {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: 'All machines',
      };
    }

    const machinesList = machines.map((machine: LookupResult) => {
      return {
        type: TagTypeEnum.basic,
        text: machine.name,
      };
    });

    return {
      type: TagTypeEnum.list,
      icon: 'machines',
      text: 'Machines',
      children: machinesList,
    };
  }

  private watchPeriodChange(): void {
    if (!this.dashboardId) {
      return;
    }
    this.store$.pipe(select(selectDashboardPeriod(this.dashboardId)), untilDestroyed(this)).subscribe((period: Period) => {
      this.loadData(period);
    });
  }

  private loadData(period: Period): void {
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(period);
    const bucketSize = periodToBucketSize(mappedPeriod);
    const { machines } = this.widget.config;

    const request: MultipleMachinesRequestDTO = {
      startDate: mappedPeriod.startDate,
      endDate: mappedPeriod.endDate,
      bucketSize,
      machineGuids: machines.map((machine: LookupResult) => machine.id),
    };

    this.loading = true;
    this.noData = false;
    this.error = false;

    this.widgetService
      .getData(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: Array<EfficiencyPerShiftDTO>) => {
          if (data.length === 0) {
            this.noData = true;
            return;
          }

          this.chartConfig.data = dataToChartMapper(data, mappedPeriod);
          this.data = dataToChartMapper(data, mappedPeriod);

          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.error = true;
        },
      });
  }
}
