<ng-content *ngIf="canLoadContent && (hasLicence$ | async)"></ng-content>

<app-empty-block
  *ngIf="error"
  [title]="'global.errors.error' | translate"
  [image]="'./assets/images/machines-error.svg'"
  [description]="'global.errors.could-not-fetch-data' | translate"
></app-empty-block>

<app-empty-block *ngIf="noData" [title]="'global.no-data' | translate"> </app-empty-block>

<app-empty-block
  *ngIf="(hasLicence$ | async) === false"
  [title]="'licenses.no-license.title' | translate : ({ license: 'licenses.license.' + requiredLicenses[0] } | ParamsTranslate)"
  [description]="'licenses.no-license.description' | translate"
>
</app-empty-block>

<app-loader class="jm-loader" [sizing]="'app'" *ngIf="loading"></app-loader>
