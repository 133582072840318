<td jm-number-cell *ngIf="column?.type === cellType.NUMBER" [cellData]="rowData" [column]="column"></td>
<td jm-date-time-cell *ngIf="column?.type === cellType.DATE_TIME" [cellData]="rowData" [column]="column"></td>
<td jm-string-cell *ngIf="column?.type === cellType.STRING" [cellData]="rowData" [column]="column"></td>
<td jm-relation-cell *ngIf="column?.type === cellType.RELATION" [cellData]="rowData" [column]="column"></td>
<td jm-data-translation-cell *ngIf="column?.type === cellType.DATA_TRANSLATION" [cellData]="rowData" [column]="column"></td>
<td
  jm-assigned-cell
  *ngIf="column?.type === cellType.ASSIGNED"
  [assigned]="rowData[column?.assigned || '']"
  [total]="rowData[column?.total || '']"
  [column]="column"
></td>
<td jm-time-cell *ngIf="column?.type === cellType.TIME" [cellData]="rowData" [column]="column"></td>
<td jm-tag-cell *ngIf="column?.type === cellType.TAG" [cellData]="rowData" [column]="column"></td>
