import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DialogComponent } from 'src/app/layout';
import { DialogConfigDTO } from 'src/app/layout/dialog/dialog.interface';
import { createControls, loadFormConfig } from 'src/app/modules/quick-forms';
import { ControlConfig } from 'src/app/modules/quick-forms/models/control.config';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { LoginActionType, SetNewPassword } from '../../store/login.actions';
import { selectPasswordResetLoader } from '../../store/login.selector';
import { formConfig } from '../shared/password-form-config';

@UntilDestroy()
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  token: string | null = null;
  form!: FormGroup;
  formConfig: Array<ControlConfig> = [];

  readonly loader$: Observable<boolean> = this.store$.pipe(select(selectPasswordResetLoader));

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly store$: Store<any>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly actionsSubject$: ActionsSubject,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    this.formConfig = loadFormConfig(formConfig);

    this.formConfig[0].label = 'forgot-password.new-password';
    this.form = this.formBuilder.group(createControls(this.formConfig));

    this.actionsSubject$
      .pipe(
        filter((action) => action.type === LoginActionType.SUCCESSFULLY_FORGOT_PASSWORD),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.router.navigateByUrl('/auth/login');
      });
  }

  submit(): void {
    const dialogConfig: DialogConfigDTO = {
      title: this.translateService.instant('forgot-password.welcome-new-password'),
      message: this.translateService.instant('forgot-password.are-you-sure-to-reset-password'),
    };

    const dialogRef = this.dialog.open(DialogComponent, {
      data: dialogConfig,
    });

    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          const password: string = this.form.get('password')?.value;
          this.store$.dispatch(new SetNewPassword({ password, resetToken: this.token }));
        }
      });
  }
}
