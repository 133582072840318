<div class="main-holder main-holder--tutorial">
  <div class="main-holder__content">
    <div class="tutorial-wrapper">
      <app-header-bar [title]="'global.tutorials' | translate"> </app-header-bar>
      <ng-container *ngIf="doesUserHaveAnyPermissionOnSteps()">
        <div class="filters">
          <app-sb-filters
            [ngClass]="'filters-desktop'"
            [filters]="filters"
            [activeFilters]="activeFilters"
            (addOrRemoveActiveFilter)="addOrRemoveActiveFilter($event)"
          ></app-sb-filters>
        </div>
      </ng-container>
      <ng-container *ngIf="doesUserHaveAnyPermissionOnSteps()">
        <div class="jakob-muller-table">
          <ng-template ngFor let-section [ngForOf]="sections">
            <ng-container *ngIf="sectionSteps[section] && (activeFilters.includes(section) || activeFilters.includes('all'))">
              <ng-container *ngIf="isSectionPartOfSectionViewAndUserPermission(section)">
                <div class="table-data">
                  <ng-template ngFor let-sectionStep [ngForOf]="sectionSteps[section]">
                    <ng-container *ngIf="sectionStep.permission.includes(userGroup)">
                      <div class="table-row table-data-row" (click)="startTour(section, sectionStep.step)">
                        <div class="preview-wrapper">
                          <img src="./assets/images/interactive.svg" alt="interactive.svg" />
                        </div>
                        <div class="text">
                          <div class="approximately">{{ 'global.approximately' | translate }} 2:00 {{ 'global.min' | translate }}</div>
                          <div class="title">
                            {{ 'tutorial.' + section + '.' + sectionStep.step | translate }}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>

      <ng-container *ngIf="!doesUserHaveAnyPermissionOnSteps()">
        <div class="jakob-muller-table">
          <app-empty-block [title]="'global.no-data' | translate"> </app-empty-block>
        </div>
      </ng-container>
    </div>
  </div>
</div>
