import { Component, Input } from '@angular/core';
import { FooterTag } from '../../../../interfaces/footer-tag.interface';
import { TagTypeEnum } from '../../../../interfaces/tags.interface';

@Component({
  selector: 'widget-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() config: FooterTag = {} as FooterTag;

  get tagTypeEnum() {
    return TagTypeEnum;
  }
}
