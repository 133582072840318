import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { ObjectLiteral } from 'ngx-super-model/types/object-literal';
import { filter, mergeMap, of } from 'rxjs';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { Period } from 'src/app/util/period/period.interface';
import { downloadAFile, isSet } from 'src/app/util/util';
import { environment } from 'src/environments/environment';

import { Component, inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ExportSensorData, ExportSensorDataComponent, SensorExport, SensorReadingSubscriptionService, SensorService } from '@sensor';

import { selectDashboardPeriod } from '../../custom-dashboard/store/dashboard.selector';
import { FooterTag, PeriodDTO, statePeriodToStartEndDate, TagTypeEnum } from '../core';
import { chartConfig } from './chart.config';
import { HistoricalDataWidget } from './historical-sensor.model';
import { HistoricalSensorService } from './historical-sensor.service';
import { InputHistoricalSensor } from './interfaces/input-historical-sensor.interface';
import { dataToHistoricalSensorChart, mapSensorConfiguration, mapYAxisValuesToKeys } from './mappers/data-to-chart.mapper';
import { HistoricalSensor } from './models/historical-sensor.model';

@UntilDestroy()
@Component({
  selector: 'widget-historical-sensor',
  templateUrl: './historical-sensor.component.html',
  styleUrls: ['./historical-sensor.component.scss'],
})
export class HistoricalSensorComponent implements OnInit {
  private readonly dialog = inject(MatDialog);
  private readonly historicalSensorService = inject(HistoricalSensorService);
  private readonly sensorReadingSubscriptionService = inject(SensorReadingSubscriptionService);
  private readonly sensorService = inject(SensorService);
  private readonly store$ = inject(Store);

  @Input()
  widget: HistoricalDataWidget = new HistoricalDataWidget();
  @Input()
  dashboardId: string | undefined | null = null;
  @Input()
  isEdit = false;

  data: ObjectLiteral | null = null;
  error = false;
  noData = false;
  tags: Array<FooterTag> = [];
  loading = false;
  chartConfig: ChartConfiguration = cloneDeep(chartConfig);
  licenseHasExpired = false;

  private period!: Period;
  private machineGuid!: string;
  private sensorData = new HistoricalSensor();

  get isPeriodLargerThanTwoDays(): boolean {
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(this.period);

    const startMoment = moment(mappedPeriod.startDate);
    const endMoment = moment(mappedPeriod.endDate);

    const durationInDays = endMoment.diff(startMoment, 'days');

    return durationInDays > 2;
  }

  get isRealTimePeriodSelected(): boolean {
    if (
      this.period.type === PeriodTypeEnum.PERIOD_LAST_HOUR ||
      this.period.type === PeriodTypeEnum.PERIOD_TODAY ||
      this.period.type === PeriodTypeEnum.PERIOD_LAST_24_HOURS ||
      this.period.type === PeriodTypeEnum.PERIOD_LAST_TWO_DAYS
    ) {
      return true;
    }

    if (this.period.type === PeriodTypeEnum.CUSTOM_RANGE) {
      const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(this.period);

      return moment().isBetween(mappedPeriod.startDate, mappedPeriod.endDate);
    }

    return false;
  }

  ngOnInit(): void {
    this.populateTags();
    this.watchPeriodChange();
    this.watchSensorReadingChanged();
  }

  onDownload(): void {
    const { sensorId } = this.widget.config;
    const { name } = this.widget;
    const sensorIds = Array.isArray(sensorId) ? sensorId.map((value) => value.id) : [sensorId.id];

    const data: ExportSensorData = {
      period: this.period,
      sensorIds,
      machineGuid: this.machineGuid,
    };

    const dialogRef = this.dialog.open(ExportSensorDataComponent, {
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        filter(isSet),
        mergeMap((exportData: ExportSensorData) => {
          const { period, sensorIds, filename } = exportData;

          const { startDate, endDate } = statePeriodToStartEndDate(period);
          if (!isSet(startDate) || !isSet(endDate)) {
            return of(null);
          }

          const input: SensorExport = {
            startDate,
            endDate,
            sensorIds,
            machineGuid: this.machineGuid,
            filename: this.checkFilename(filename, name),
          };

          return this.sensorService.exportData(input);
        })
      )
      .subscribe((response) => {
        if (!isSet(response)) {
          return;
        }

        const { url, filename } = response;

        if (!isSet(url) || !isSet(filename)) {
          return;
        }

        const link = `${environment.rootUrl}${url}`;
        downloadAFile(link, filename);
      });
  }

  private watchPeriodChange(): void {
    if (!isSet(this.dashboardId)) {
      return;
    }
    this.store$.pipe(select(selectDashboardPeriod(this.dashboardId)), untilDestroyed(this)).subscribe((period: Period) => {
      this.period = period;
      this.loadData();
    });
  }

  private populateTags() {
    const { location, machine } = this.widget.config;

    this.machineGuid = machine.id;

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: machine.name,
      },
    ];
  }

  private loadData(): void {
    if (this.isPeriodLargerThanTwoDays) {
      return;
    }

    const input: InputHistoricalSensor = this.createInputHistoricalSensor();

    this.loading = true;
    this.noData = false;
    this.error = false;

    this.historicalSensorService
      .getData(input)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          this.loading = false;

          if (value.readings.length === 0) {
            this.noData = true;
            return;
          }
          this.sensorData = value;
          this.fillChartWithData();
        },
        error: (error: Error) => {
          this.loading = false;
          if (error.message === 'This machine has not been assigned a SENSOR license') {
            this.licenseHasExpired = true;
            return;
          }
          this.error = true;
        },
      });
  }

  private createInputHistoricalSensor(): InputHistoricalSensor {
    const { machine, sensorId } = this.widget.config;
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(this.period);

    const sensorIds = Array.isArray(sensorId) ? sensorId.map((value) => value.id) : [sensorId.id];

    const input: InputHistoricalSensor = {
      startDate: mappedPeriod.startDate,
      endDate: mappedPeriod.endDate,
      machineGuid: machine.id,
      sensorIds: sensorIds,
    };

    return input;
  }

  private watchSensorReadingChanged(): void {
    if (!this.isRealTimePeriodSelected || this.isPeriodLargerThanTwoDays) {
      return;
    }

    if (!isSet(this.machineGuid)) {
      return;
    }

    this.sensorReadingSubscriptionService
      .watchSensorChanges(this.machineGuid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          const arrIndex = this.sensorData.readings.findIndex((reading) => reading.sensorId === response.sensorId);

          if (arrIndex === -1) {
            return;
          }

          const { timestamp, value } = response;

          this.sensorData.readings[arrIndex].values.shift();
          this.sensorData.readings[arrIndex].values.push({ timestamp, value });
          this.fillChartWithData();
          this.disableChartAnimations();
        },
      });
  }

  private fillChartWithData(): void {
    const sensorConfigurations = mapSensorConfiguration(this.sensorData.sensorConfigurations);
    this.chartConfig.data = dataToHistoricalSensorChart(this.sensorData.readings, sensorConfigurations) as any;
    this.data = dataToHistoricalSensorChart(this.sensorData.readings, sensorConfigurations) as any;
    const options = this.chartConfig?.options as any;

    options.scales = {
      ...options?.scales,
      ...mapYAxisValuesToKeys(this.sensorData.sensorConfigurations, this.sensorData.readings),
    };
  }

  private disableChartAnimations(): void {
    const options = this.chartConfig?.options;

    if (options?.animation) {
      options.animation.duration = 0;
    }
  }

  private checkFilename(filename: string | undefined, widgetName: string): string {
    if (!isSet(filename) || filename.length === 0) {
      return widgetName;
    }

    return filename;
  }
}
