import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { OeeByMachineCategoryDTO } from './interfaces';
import { OeeByMachineCategory } from './models';
import { ProfitabilityInput } from '../../performance-dashboard/interfaces/profitability-input.interface';

@Injectable({
  providedIn: 'root',
})
export class OeeByMachineCategoryService extends AbstractCRUDService<OeeByMachineCategory, OeeByMachineCategoryDTO> {
  override model = OeeByMachineCategory;

  override fields = `
    machineCategoryId
    machineCategoryName
    OEE
  `;

  getData(input: ProfitabilityInput): Observable<Array<OeeByMachineCategory>> {
    const query = gql`
     query oeeByMachineCategory($input: OEEByMachineCategoryInput!) {
        oeeByMachineCategory(input: $input) {
          ${this.fields}
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<OeeByMachineCategoryDTO>>>) =>
          response.data.oeeByMachineCategory.map((item: OeeByMachineCategoryDTO) => this.loadModel(item))
        )
      );
  }
}
