import { Model } from 'ngx-super-model';

import { ProfitabilityDTO } from '../interfaces';

export class Profitability extends Model<ProfitabilityDTO> {
  articleName: string | null = null;
  articleGuid: string | null = null;
  revenue: number | null = null;
  cost: number | null = null;
  profit: number | null = null;
}
