import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { AvailabilityDTO } from './interfaces';
import { Availability } from './models';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService extends AbstractCRUDService<Availability, AvailabilityDTO> {
  override model = Availability;

  getData(input: PerformanceInput): Observable<Availability> {
    const query = gql`
      query LocationAvailability($input: OEEInput!) {
        locationAvailability(input: $input) {
          availability
          plannedProductionTime
          actualProductionTime
          unplannedDowntime
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: { input },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<AvailabilityDTO>>) => {
          return this.loadModel(response.data.locationAvailability);
        })
      );
  }
}
