import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { UserGroupEnum } from 'src/app/enum';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { ListResultDTO } from '@jm-table';
import { enumToOptions, LookupResult, LookupService } from '@quick-form';

import { ResponseData } from '../../abstract/interface/response-data';
import { COMEZ_STATUS, FRICK_STATUS, MachineStatus } from '../enums/machine-status.enum';
import hasComezMachines from './gql/has-comez-machine.graphql';
import { HasComezMachine } from './interfaces/has-comez-machine.interface';

@Injectable({ providedIn: 'root' })
export class MachineStatusService implements LookupService {
  readonly apollo = inject(Apollo);

  getSuggestions(): Observable<ListResultDTO<LookupResult>> {
    return this.apollo.query<ResponseData<HasComezMachine>>({ query: hasComezMachines }).pipe(
      map((response: ApolloQueryResult<ResponseData<HasComezMachine>>) => {
        const { hasComezMachines, group } = response.data.me;
        if (group.id !== UserGroupEnum.JM_ADMIN) {
          return {
            records: hasComezMachines ? enumToOptions(COMEZ_STATUS, 'machines.status') : enumToOptions(FRICK_STATUS, 'machines.status'),
            pageDetails: null,
          };
        }

        return {
          records: enumToOptions(MachineStatus, 'machines.status'),
          pageDetails: null,
        };
      })
    );
  }
}
