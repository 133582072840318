import { ChartData } from 'chart.js';

import { DownTimeByStopReason } from '../models';
import { machineFrickStatusColorMapper } from 'src/app/core/machines/mappers/stop-color.mapper';
import { FRICK_STATUS } from 'src/app/core/machines/enums/machine-status.enum';

export function dataToChartMapper(downTimeReasons: Array<DownTimeByStopReason>): ChartData {
  const colors = downTimeReasons.map((item: DownTimeByStopReason) => {
    return machineFrickStatusColorMapper.get(item.stopStatus as FRICK_STATUS);
  });

  const datasets = [
    {
      id: 'downTimeByStopReason',
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: downTimeReasons.map((downTime: DownTimeByStopReason) => downTime.toHours() || 0),
      backgroundColor: colors,
      borderColor: colors,
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      maxBarThickness: 40,
      myCustomData: {
        prettyDuration: downTimeReasons.map((downTime) => downTime.secondsToHMS()),
      },
    },
  ];

  const labels = downTimeReasons.map((downTime: DownTimeByStopReason) => downTime.stopStatus);

  return {
    labels,
    datasets,
  };
}
