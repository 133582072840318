import { ChangeDetectionStrategy, Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Input()
  checked = false;

  @Output()
  valueChanged = new EventEmitter<boolean>();

  disabled = false;

  writeValue(value: any): void {
    if (typeof value !== 'undefined') {
      this.checked = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onValueChanged(event: MatCheckboxChange): void {
    this.propagateChange(event.checked);
    this.valueChanged.emit(event.checked);
  }

  onBlur() {
    this.propagateTouched();
  }

  // eslint-disable-next-line
  private propagateChange = (value: any) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
