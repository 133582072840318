import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObjectLiteral } from '../object-literal';

@Pipe({
  name: 'ParamsTranslate',
  standalone: true,
})
export class ParamsTranslatePipe implements PipeTransform {
  constructor(private readonly _translate: TranslateService) {}

  transform(obj: ObjectLiteral): ObjectLiteral {
    const newObject = Object.assign({}, obj);
    Object.keys(obj).forEach((key) => {
      newObject[key] = this._translate.instant(obj[key]);
    });

    return newObject;
  }
}
