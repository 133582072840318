<p-columnFilter
  #columFilter
  *ngIf="column.filter"
  [field]="column.field || ''"
  display="menu"
  [showMatchModes]="false"
  [showOperator]="false"
  [showAddButton]="false"
  [hideOnClear]="true"
  [showClearButton]="false"
  [showApplyButton]="false"
>
  <ng-template pTemplate="header">
    <span class="p-column-filter-header">{{ column.filter.title || '' | translate }}</span>
  </ng-template>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    @if(column.filter.type === cellFilterType.DROPDOWN){
    <jm-column-filter-dropdown
      [value]="value"
      [column]="column"
      (valueChanged)="onValueChanged(filter, $event)"
      (focused)="onFocusToggle($event)"
    />} @if(column.filter.type === cellFilterType.DATE_RANGE){<jm-column-filter-date-range
      [value]="value"
      [column]="column"
      (valueChanged)="onValueChanged(filter, $event)"
    />} @if(column.filter.type === cellFilterType.MULTISELECT){
    <jm-colum-filter-multiselect [value]="value" [column]="column" (valueChanged)="onValueChanged(filter, $event)" />}
  </ng-template>
</p-columnFilter>
