<app-modal-layout (closeDialog)="onCloseDialog()">
  <span class="add-widget-title" header>{{ getTitle() | translate }}</span>

  <div main>
    <div class="add-widget">
      <app-widget-select
        [widgetsConfig]="widgetsConfig"
        [selectedWidget]="selectedWidget?.type || null"
        (widgetSelect)="onWidgetSelect($event)"
        [formMode]="formMode"
      ></app-widget-select>
      <div *ngIf="selectedWidget" class="panel add-widget__panel">
        <header class="panel__header">{{ selectedWidget.name | translate }}</header>

        <main class="panel__main" [class.has-info]="selectedWidget.info">
          <div class="form-section">
            <quick-form
              *ngIf="selectedWidget?.type !== widgetType.HistoricalSensor && selectedWidget?.type !== widgetType.RealtimeSensor"
              [id]="'widget-configuration-form'"
              [config]="widgetFormConfig"
              [form]="widgetForm"
            ></quick-form>

            <ng-container *ngIf="selectedWidget?.type === widgetType.RealtimeSensor">
              <form *ngIf="widgetForm" id="realtime-sensor-configuration" class="quick-form form" [formGroup]="widgetForm">
                <ng-container *ngTemplateOutlet="sensorForm"> </ng-container>
              </form>
            </ng-container>

            <ng-container *ngIf="selectedWidget?.type === widgetType.HistoricalSensor">
              <form *ngIf="widgetForm" id="historical-sensor-configuration" class="quick-form form" [formGroup]="widgetForm">
                <ng-container *ngTemplateOutlet="sensorForm"> </ng-container>
              </form>
            </ng-container>
          </div>
          <div class="notice-section" *ngIf="selectedWidget.info">
            <app-notice type="info" [description]="selectedWidget.info | translate"></app-notice>
          </div>
        </main>

        <footer class="panel__footer">
          <jm-button [class]="'green-button btn-create'" [suffixIcon]="'plus'" (clicked)="onAddWidget()" [dataTestSelector]="'save-widget'">
            {{ getButtonText() | translate }}</jm-button
          >
          <jm-button [class]="'red-border-button'" (clicked)="onCloseDialog()">
            {{ 'button.cancel' | translate }}
          </jm-button>
        </footer>
      </div>
    </div>
  </div>
</app-modal-layout>

<ng-template #sensorForm>
  <div class="form__fieldset form__input">
    <quick-form-control [config]="getConfig('name')" [control]="getFormControl('name')"></quick-form-control>
  </div>
  <div class="form__fieldset form__input">
    <quick-form-control [config]="getConfig('location')" [control]="getFormControl('location')"></quick-form-control>
  </div>
  <div class="form__fieldset form__input">
    <quick-form-control [config]="getConfig('machine')" [control]="getFormControl('machine')"></quick-form-control>
  </div>
  <div class="form__fieldset form__input">
    <quick-form-multiselect
      [options]="sensorWidgetOptions"
      [loading]="sensorDropdownLoading"
      [config]="getConfig('sensorId')"
      [control]="getFormControl('sensorId')"
      [formControl]="getFormControl('sensorId')"
    ></quick-form-multiselect>
  </div>
</ng-template>

<ng-template #noLicense>
  <app-empty-block [title]="'sensor.error.license' | translate" [description]="'global.contact-jm' | translate"> </app-empty-block>
</ng-template>
