import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isSet } from 'src/app/util/util';
import { ControlConfig } from '../../../models/control.config';
import { Calendar } from 'primeng/calendar';
import { browserLocaleFormat } from 'src/app/util/datetime/helpers';
import { DateTimeFormatStyle } from 'src/app/util/datetime/format-style.enum';

@Component({
  selector: 'quick-form-p-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PDatepickerComponent),
    },
  ],
})
export class PDatepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() config: ControlConfig = new ControlConfig();
  @Input() control: FormControl = new FormControl();

  @ViewChild('calendar') calendar?: Calendar;

  private _minDate: Date = this.defaultMinDate;

  @Input()
  set minDate(date: Date | null) {
    if (!date) {
      this._minDate = this.defaultMinDate;
      return;
    }

    this._minDate = date;
  }

  get minDate(): Date {
    return this._minDate;
  }

  private _maxDate: Date = this.defaultMaxDate;
  @Input()
  set maxDate(date: Date | null) {
    if (!date) {
      return;
    }

    this._maxDate = date;
  }

  get maxDate(): Date {
    return this._maxDate;
  }

  @Output() valueChanged: EventEmitter<Date> = new EventEmitter<Date>();

  value: Date | null = null;
  isDisabled = false;

  showTime = false;
  showSeconds = false;
  appendTo = 'body';

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(this.config.disabled);
    }

    const showTime = this.config.meta?.showTime;
    if (isSet(showTime)) {
      this.showTime = showTime;
    }

    const showSeconds = this.config.meta?.showSeconds;
    if (isSet(showSeconds)) {
      this.showSeconds = showSeconds;
    }

    const appendTo = this.config.meta?.appendTo;
    if (isSet(appendTo)) {
      this.appendTo = appendTo;
    }
  }

  ngAfterViewInit(): void {
    if (!this.calendar) {
      return;
    }

    this.calendar.formatDate = (date) => {
      return browserLocaleFormat(date, DateTimeFormatStyle.date);
    };
  }

  writeValue(value: Date | null | ''): void {
    if (isSet(value) && value !== '') {
      this.value = new Date(value);
      return;
    }

    this.value = null;
  }

  registerOnChange(fn: (value: Date | null) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChanged() {
    if (!this.value) {
      return;
    }

    this.propagateChange(this.value);
    this.valueChanged.emit(this.value);
  }

  onValueSelected(value: Date) {
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  focus(): void {
    if (!isSet(this.calendar?.inputfieldViewChild)) {
      return;
    }

    this.calendar?.inputfieldViewChild.nativeElement.focus();
  }

  /**
   * Blur triggers before value is inserted
   * so the validator message blinks for a fraction of second.
   * setTimout prevents that
   */
  onBlur() {
    setTimeout(() => {
      this.propagateTouched();
    }, 100);
  }

  // eslint-disable-next-line
  private propagateChange = (value: Date | null) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};

  get defaultMaxDate(): Date {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 20);
    return maxDate;
  }

  get defaultMinDate(): Date {
    return new Date('01/01/1970');
  }
}
