import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import createMutation from './gql/create.mutation.graphql';
import { FeedbackDTO } from './interfaces/create-feedback.dto';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private readonly apollo = inject(Apollo);
  private readonly snackBarService = inject(SnackBarService);
  private readonly translateService = inject(TranslateService);

  send(feedback: FeedbackDTO) {
    return this.apollo
      .mutate({
        mutation: createMutation,
        variables: {
          input: {
            ...feedback,
            submittedFrom: 'mymuenet web application',
          },
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error(this.translateService.instant('feedback.error.send', { type: feedback.type }));
        }),
        tap(() => {
          this.snackBarService.success(
            this.translateService.instant('feedback.success.send', {
              type: feedback.type,
            })
          );
        })
      );
  }
}
