import { ObjectLiteral } from 'ngx-super-model/types/object-literal';
import { LookupResult } from 'src/app/modules/quick-forms/interfaces/lookup-result.interface';

export function listItemsToLookupResult(data: Array<ObjectLiteral>, keys = { id: 'id', name: 'name' }): Array<LookupResult> {
  return data.map((item: ObjectLiteral) => {
    return {
      id: item[keys.id],
      name: item[keys.name],
    } as LookupResult;
  });
}
