import { selectUserInfo } from '@auth';
import { createControls, loadFormConfig } from 'src/app/modules/quick-forms';
import { ControlConfig } from 'src/app/modules/quick-forms/models/control.config';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { LoginService } from '../../services/login.service';
import { passwordResetConfig, userInfoConfig } from './form-config';

@UntilDestroy()
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  infoConfig: Array<ControlConfig> = [];
  infoForm: FormGroup | null = null;

  passwordConfig: Array<ControlConfig> = [];
  passwordResetForm: FormGroup | null = null;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store$: Store<any>,
    private readonly loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.loadUserInfoForm();
    this.createPasswordForm();
  }

  loadUserInfoForm(): void {
    this.store$.pipe(select(selectUserInfo), untilDestroyed(this)).subscribe((response) => {
      const model = { name: response.name, email: response.email };
      this.infoConfig = loadFormConfig(userInfoConfig, model);
      this.infoForm = this.formBuilder.group(createControls(this.infoConfig));
    });
  }

  createPasswordForm() {
    this.passwordConfig = loadFormConfig(passwordResetConfig);
    this.passwordResetForm = this.formBuilder.group(createControls(this.passwordConfig));
  }

  save(): void {
    if (!this.passwordResetForm?.valid) {
      return;
    }

    // eslint-disable-next-line no-unsafe-optional-chaining
    const { currentPassword, newPassword } = this.passwordResetForm?.value;
    this.loginService
      .updatePassword(currentPassword, newPassword)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.passwordResetForm?.reset();
      });
  }
}
