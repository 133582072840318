<div class="footer-wrapper">
  <div class="container">
    <div class="link-wrapper">
      <span *ngFor="let link of links; trackBy: trackByIndex" class="link">
        <a (click)="navigateLink(link)">{{ 'footer.' + link.key | translate }}</a>
      </span>
    </div>
    <span class="copy-right">
      {{ 'footer.copyright' | translate }}
      <span *ngIf="backendVersion$ | async" class="version" [class.no-hover]="!isLogged" (click)="openReleaseNotesDialog()"
        >{{ 'FV' + frontendVersion }}/{{ 'BV' + (backendVersion$ | async) }}
      </span>
    </span>
  </div>
</div>
