import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { sanitizeSessionRedirectUrl } from '@auth';
import { SSOAutoLoginConfig } from '@auth';
import { PushNotificationsService } from '@push-notifications';
import { LocalStorageService } from 'src/app/local-storage.service';

const REDIR_ALLOWLIST = [
  'dashboard.textrace.com',
  'textrace.stg.kstg.io',
  'mymuenet.swiss',
  'staging.mymuenet.swiss',
  'localhost:5000',
  'mymuenet.dev.kstg.io',
  'localhost:4200',
];

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  redirectUrl = '';

  private readonly loggedUser$ = new BehaviorSubject<boolean>(this.hasToken());

  constructor(
    private readonly router: Router,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly localStorageService: LocalStorageService
  ) {}

  setToken(token: any, email: string | null = null): void {
    localStorage.setItem('muCloudUser', JSON.stringify(token));

    if (email) {
      localStorage.setItem('loggedInEmail', email);
    }

    this.setCookie('authToken', token.token, 364);
    this.loggedUser$.next(true);
  }

  getToken(): any {
    try {
      return JSON.parse(localStorage.getItem('muCloudUser') as any)?.token;
    } catch {
      return null;
    }
  }

  loginRedirect(redirectUrl?: string): void {
    if (redirectUrl) {
      const url = new URL(redirectUrl);

      if (REDIR_ALLOWLIST.includes(url.host)) {
        const token = this.getToken();
        if (token) url.searchParams.set('token', this.getToken());
      } else {
        // eslint-disable-next-line
        console.error(`Redirect to host ${url.host} not allowed. Not adding token.`);
      }

      window.location.href = url.toString();
    } else {
      this.router.navigate(['/']);
    }
  }

  loginSessionRedirect(): void {
    const redirectUrl = sessionStorage.getItem(SSOAutoLoginConfig.redirectUrlKey) || environment.rootUrl;
    this.clearSessionRedirectUrl();
    window.location.href = redirectUrl;
  }

  logOut(): void {
    const osUserId: string | null = localStorage.getItem('osUserId');
    const muCloudUserImpersonateUser: string | null = localStorage.getItem('muCloudUserImpersonateUser');

    if (osUserId) {
      this.pushNotificationsService.unregisterDevice(osUserId).subscribe(() => {
        // eslint-disable-next-line
        console.log('OneSignalId is successfully removed');
      });
    }

    localStorage.removeItem('muCloudUser');
    localStorage.removeItem('loggedInEmail');

    if (muCloudUserImpersonateUser) {
      this.localStorageService.clearImpersonateUserToken();
      location.reload();
    }
    this.unsetCookie('authToken');
    this.loggedUser$.next(false);
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedUser$.asObservable();
  }

  hasToken(): boolean {
    return Boolean(localStorage.getItem('muCloudUser'));
  }

  private unsetCookie(cname: string): void {
    document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${environment.authCookieDomain}`;
  }

  private setCookie(cname: string, cvalue: string, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    // TODO: Make configurable or auto detect
    document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/;domain=${environment.authCookieDomain}`;
  }

  setSessionRedirectUrl(url: string): void {
    const redirectExceptions = ['/auth/login', '/auth/mymueller-sso'];

    const isException = redirectExceptions.some((exception: string) => url.includes(exception));

    if (isException) {
      return;
    }

    const sanitizedUrl = sanitizeSessionRedirectUrl(url);

    sessionStorage.setItem(SSOAutoLoginConfig.redirectUrlKey, sanitizedUrl);
  }

  getSessionRedirectUrl(): string | null {
    return sessionStorage.getItem(SSOAutoLoginConfig.redirectUrlKey);
  }

  clearSessionRedirectUrl(): void {
    return sessionStorage.removeItem(SSOAutoLoginConfig.redirectUrlKey);
  }
}
