import { Model } from 'ngx-super-model';
import { TimezoneDTO } from '../interfaces';

export class Timezone extends Model<TimezoneDTO> {
  dstOffset: number | null = null;
  rawOffset: number | null = null;
  status: string | null = null;
  timeZoneId: string | null = null;
  timeZoneName: string | null = null;
}
