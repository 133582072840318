import { createAction, props } from '@ngrx/store';
import { SearchCriteria } from '../modules/jm-table/models/search-criteria.config';

//SETTINGS SIDEBAR
export const toggleSettingsSidebar = createAction('[APPLICATION STATE] TOGGLE_SETTINGS_SIDEBAR');
export const showSettingsSidebar = createAction('[APPLICATION STATE] SHOW_SETTINGS_SIDEBAR');
export const closeSettingsSidebar = createAction('[APPLICATION STATE] CLOSE_SETTINGS_SIDEBAR');

//MACHINE TYPE
export const addMachineCategory = createAction('[APPLICATION STATE] ADD_MACHINE_TYPE');
export const addMachineCategoryComplete = createAction('[APPLICATION STATE] ADD_MACHINE_TYPE_COMPLETE');
export const addMachineCategoryFailed = createAction('[APPLICATION STATE] ADD_MACHINE_TYPE_FAILED');

//WIDGET DASHBOARD
export const setActiveDashboard = createAction('[WIDGETS] ACTIVE_DASHBOARD', props<{ id: string | null }>());

//TABLES
export const tableFilterChanged = createAction('[TABLES] TABLE FILTER CHANGED', props<{ id: string; searchCriteria: SearchCriteria }>());
