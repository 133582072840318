<div class="quick-form__repeater" [ngClass]="config.cssClass" *ngFor="let item of config.children; let i = index">
  <quick-form-controls [config]="item" [form]="control.at(i)"></quick-form-controls>
  <div class="form__remove" (click)="remove(i)" *ngIf="!config?.meta?.hideRemoveButton">
    <svg-icon [src]="'./assets/images/bin.svg'"></svg-icon>
  </div>
</div>

<jm-button
  *ngIf="config.meta?.addButtonText"
  [class]="'green-button'"
  [suffixIcon]="'plus'"
  (clicked)="addControl()"
  [dataTestSelector]="'button-add-location'"
>
  {{ config.meta?.addButtonText | translate }}
</jm-button>

<div
  *ngIf="this.config.children?.length === 0 && this.config.validators?.formArrayAtLeastOne && !control.touched"
  class="validation-wrapper"
>
  <small>{{ this.config.validators?.formArrayAtLeastOne?.message }}</small>
</div>
