import { filter, switchMap, tap } from 'rxjs';
import { AuthCardComponent } from 'src/app/layout/partials/auth-card/auth-card.component';
import { environment } from 'src/environments/environment';

import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoaderComponent } from '@app-ui';
import { AuthenticationService } from '@auth';
import { LegacyComponentsModule } from '@legacy-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { PushNotificationsService } from '@push-notifications';

import { SignInDTO } from '../../intefaces/sign-in';
import { MyMuellerSSOError } from '../errors/mymueller-sso.error';
import { MymuellerSSOService } from '../services/mymueller-sso.service';

@UntilDestroy()
@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent, AuthCardComponent, LegacyComponentsModule, TranslateModule],
})
export class CallbackComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly myMullersSSOService = inject(MymuellerSSOService);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly pushNotificationsService = inject(PushNotificationsService);
  private readonly router = inject(Router);

  loading = false;
  errorMessage = '';

  ngOnInit(): void {
    this.logIn();
  }

  private logIn(): void {
    this.loading = true;
    this.activeRoute.queryParamMap
      .pipe(
        tap((params) => {
          if (!params.get('code')) {
            throw new MyMuellerSSOError('auth.mymueller-sso.errors.param-code-missing');
          }
        }),
        switchMap((params: Params) => {
          return this.myMullersSSOService.signIn(params.get('code'));
        }),
        switchMap((result: SignInDTO) => {
          const { token, user } = result;
          this.authenticationService.setToken({ token }, user.email);
          return this.authenticationService.isLoggedIn().pipe(filter((loggedIn: boolean) => loggedIn));
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            throw new MyMuellerSSOError('Problem occured during loggin in user');
          }

          this.pushNotificationsService.checkAndSendToApiIfUserIdExist();
          window.location.href = environment.rootUrl;
        },
        error: (error: Error) => {
          this.errorMessage = error.message;
          this.loading = false;
        },
      });
  }

  goBack(): void {
    this.router.navigateByUrl('auth/login');
  }
}
