<div class="cell" [ngClass]="column?.cssClass" [class.not-applicable]="!total && total !== 0">
  <ng-container *ngIf="total || total === 0">
    <b>{{ assigned }}</b> / {{ total }}
  </ng-container>
  <ng-container *ngIf="!total && total !== 0">N/A</ng-container>
</div>

<div class="extra-column hidden-tablet hidden-desktop">
  <ng-content></ng-content>
</div>
