import { UserGroupEnum } from 'src/app/enum';

export const sections: Array<string> = ['dashboard', 'library', 'analytics', 'admin'];

export const sectionSteps: { [params: string]: Array<{ step: string; permission: Array<UserGroupEnum> }> } = {
  admin: [
    { step: 'newLocation', permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN] },
    { step: 'configureAliasShifts', permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN] },
    { step: 'newUsers', permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN] },
  ],
};
