import { cloneDeep } from 'lodash-es';
import { map } from 'rxjs';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { DeviceService } from 'src/app/modules/device/device.service';
import { Period } from 'src/app/util/period/period.interface';
import { isSet } from 'src/app/util/util';

import { CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LookupResult } from '@quick-form';
import { SensorReadingSubscriptionService, SensorType } from '@sensor';

import { MachineStatus } from '../../machines/enums/machine-status.enum';
import { MachineService } from '../../machines/services/machine.service';
import { FooterTag, PeriodDTO, statePeriodToStartEndDate, TagTypeEnum } from '../core';
import { RawSensorChartInput } from './interfaces/raw-sensor-chart-input.interface';
import { RealtimeSensorChartDTO } from './interfaces/real-time-sensor-chart.interface';
import { sensorDataToLookup } from './mappers';
import { RealtimeSensorChartConfig } from './model/chart-config.model';
import { RealtimeSensorChart } from './model/real-time-sensor-chart.model';
import { RealtimeSensor } from './model/real-time-sensor.model';
import { RealtimeSensorWidget } from './realtime-sensor.model';
import { RealtimeSensorService } from './services/realtime-sensor.service';

@UntilDestroy()
@Component({
  selector: 'widget-realtime-sensor',
  templateUrl: './realtime-sensor.component.html',
  styleUrls: ['./realtime-sensor.component.scss'],
})
export class RealtimeSensorComponent implements OnInit {
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly deviceService = inject(DeviceService);
  private readonly machineService = inject(MachineService);
  private readonly realtimeSensorService = inject(RealtimeSensorService);
  private readonly sensorReadingSubscriptionService = inject(SensorReadingSubscriptionService);

  @Input()
  widget: RealtimeSensorWidget = new RealtimeSensorWidget();
  @Input()
  dashboardId: string | undefined | null = null;
  @Input()
  isEdit = false;

  @Output()
  widgetChange = new EventEmitter<RealtimeSensorWidget>();

  error = false;
  noData = false;
  tags: Array<FooterTag> = [];
  loading = true;
  machineGuid: string | null = null;
  isLive = false;
  licenseHasExpired = false;
  data: Array<RealtimeSensor> = [];

  private isMobile = false;

  get getColumnConfiguration(): number {
    if (this.isMobile) {
      return 1;
    }

    const { cols } = this.widget.gridsterConfig;

    if (cols === 6) {
      return 4;
    }

    if (cols === 4 || cols === 5) {
      return 3;
    }

    return 2;
  }

  get configurationRowClass(): string {
    const { cols } = this.widget.gridsterConfig;

    if (cols === 6) {
      return 'layout-with-four-elements';
    }

    if (cols === 4 || cols === 5) {
      return 'layout-with-three-elements';
    }

    return '';
  }

  ngOnInit(): void {
    this.populateTags();
    this.loadData();
    this.loadMachineData();
    this.watchSensorReadingChanged();

    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  onToggleChartWrapper(index: number): void {
    let {
      chart: { show },
    } = this.data[index];

    const { sensorConfiguration, sensorId } = this.data[index];

    if (!isSet(sensorConfiguration) || !isSet(sensorId) || sensorConfiguration?.type === SensorType.DICTIONARY || this.isEdit) {
      return;
    }
    const { unit } = sensorConfiguration;
    show = !show;
    if (!show) {
      const config = new RealtimeSensorChartConfig().clean();
      this.data[index].chart = config;
      return;
    }

    const config = new RealtimeSensorChartConfig();
    config.toggle();

    const input = this.createInputLiveSensorChart(sensorId);

    this.realtimeSensorService
      .getChartData(input)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          config.setResponse(response);
          config.setChartData(unit, this.data[index].getSensorDefinedState()?.color);
          config.setLoader(false);
          this.data[index].chart = config;
        },
        error: () => {
          config.setLoader(false);
        },
      });
  }

  onEntered(e: CdkDragEnter): void {
    moveItemInArray(this.data, e.item.data, e.container.data);
    this.changeDetector.markForCheck();
  }

  onDropped(_e: CdkDragEnter): void {
    const widget = cloneDeep(this.widget);
    if (isSet(widget.config.sensorId)) {
      widget.config.sensorId = sensorDataToLookup(this.data);
    }

    this.widgetChange.emit(widget);
  }

  private populateTags() {
    const { location, machine } = this.widget.config;
    this.machineGuid = machine.id;

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: machine.name,
      },
    ];
  }

  private loadData(): void {
    this.loading = true;
    const { machine, sensorId } = this.widget.config;

    const input = {
      machineGuid: machine.id,
      sensorIds: (sensorId as Array<LookupResult>).map((value) => value.id),
    };

    this.loading = true;
    this.noData = false;
    this.error = false;

    this.realtimeSensorService
      .getData(input)
      .pipe(
        untilDestroyed(this),
        map((sensorData) => sensorData.filter((data) => data.sensorConfiguration))
      )
      .subscribe({
        next: (value) => {
          this.loading = false;

          if (value.length === 0) {
            this.noData = true;
            return;
          }

          this.data = this.sortSensorData(value, sensorId);
        },
        error: (error: Error) => {
          this.loading = false;
          if (error.message === 'This machine has not been assigned a SENSOR license') {
            this.licenseHasExpired = true;
            return;
          }
          this.error = true;
        },
      });
  }

  private loadMachineData(): void {
    if (!isSet(this.machineGuid)) {
      return;
    }

    this.machineService
      .getByGuid(this.machineGuid)
      .pipe(untilDestroyed(this))
      .subscribe((machine) => {
        if (machine.currentStatus === MachineStatus.STOP_OFFLINE) {
          this.isLive = false;
          return;
        }

        this.isLive = true;
        this.watchSensorReadingChanged();
      });
  }

  private watchSensorReadingChanged(): void {
    if (!isSet(this.machineGuid) || this.isEdit) {
      return;
    }

    this.sensorReadingSubscriptionService
      .watchSensorChanges(this.machineGuid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          const arrIndex = this.data.findIndex((value) => value.sensorId === response.sensorId);
          if (arrIndex === -1) {
            return;
          }

          const { timestamp, value } = response;
          this.data[arrIndex].value = value;

          const {
            chart: { show },
          } = this.data[arrIndex];

          if (!show) {
            return;
          }

          const data: RealtimeSensorChartDTO = {
            timestamp,
            value,
          };

          const chartData = this.data[arrIndex].chart.response;

          chartData.shift();
          chartData.push(new RealtimeSensorChart().loadModel(data));

          this.data[arrIndex].chart.setResponse(chartData);
          this.data[arrIndex].chart.setChartData(
            this.data[arrIndex].sensorConfiguration.unit,
            this.data[arrIndex].getSensorDefinedState()?.color
          );
        },
      });
  }

  private createInputLiveSensorChart(sensorId: string): RawSensorChartInput {
    const period: Period = {
      type: PeriodTypeEnum.PERIOD_LAST_HALF_HOUR,
      customPeriod: null,
    };
    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(period);

    const input: RawSensorChartInput = {
      startDate: mappedPeriod.startDate,
      endDate: mappedPeriod.endDate,
      machineGuid: this.machineGuid,
      sensorId: sensorId,
    };

    return input;
  }

  private sortSensorData(data: Array<RealtimeSensor>, sensorIds: Array<LookupResult>): Array<RealtimeSensor> {
    const mappedSensorIds = sensorIds.map((sensor) => sensor.id);

    return data.sort((a, b) => {
      return mappedSensorIds.indexOf(a.sensorId) - mappedSensorIds.indexOf(b.sensorId);
    });
  }
}
