import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AccountService } from '../../../services/account.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard {
  readonly accountService = inject(AccountService);

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const authorities = route.data['authorities'];
    return this.checkPermissions(authorities);
  }

  checkPermissions(authorities: Array<string>): Promise<boolean> {
    return this.accountService.identity().then((res: Array<string> | null | undefined) => {
      const permissions = res?.map((value) => value) || [];
      let out = false;

      authorities.map((authoritiesValue) => {
        out = out || permissions.includes(authoritiesValue);
      });

      return out;
    });
  }
}
