import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

const imports = [AngularSvgIconModule, CommonModule];

@Component({
  selector: 'app-notice',
  standalone: true,
  imports: imports,
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent {
  @Input() description?: string;
  @Input() type!: 'success' | 'error' | 'info' | 'warning';
}
