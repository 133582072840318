import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { createControls } from '../../../helpers/form.helper';
import { ControlConfig } from '../../../models/control.config';
import { ControlRemovedDTO } from './control-removed.dto';
import { nextConfig } from '../../../helpers/repeater.helper';

@Component({
  selector: 'quick-form-repeater',
  templateUrl: './repeater.component.html',
  styleUrls: ['./repeater.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RepeaterComponent),
    },
  ],
})
export class RepeaterComponent implements OnInit {
  @Input() config: ControlConfig = new ControlConfig();
  @Input() control: FormArray = new FormArray<any>([]);

  @Output()
  valueChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  removed: EventEmitter<ControlRemovedDTO> = new EventEmitter<ControlRemovedDTO>();

  value = '';
  isDisabled = false;

  items: Array<any> = ['', ''];

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(true);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChanged(event: any) {
    const value = event.target.value;
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  onBlur() {
    this.propagateTouched();
  }

  // eslint-disable-next-line
  private propagateChange = (value: any) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};

  remove(index: number): void {
    this.config.children = this.getChildren().filter((item, i) => i != index);
    const controlValue = this.control.at(index).value;
    this.control.removeAt(index);
    this.removed.emit({
      index,
      value: controlValue,
    });
  }

  getChildren(): Array<ControlConfig> {
    return this.config.children as Array<ControlConfig>;
  }

  addControl(): void {
    const newConfig = nextConfig(this.config, this.control.disabled);

    this.config.children?.push(newConfig);
    const formGroup = new FormGroup(createControls(newConfig));
    this.control.push(formGroup);
  }
}
