import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

import { widgetsConfig } from '../../../../config';
import { globalWidgetConfigToOptions } from '../../../helpers/form.helper';

export const formConfig: Array<ControlConfigDTO> = [
  {
    id: 'selectedWidget',
    type: ControlTypeEnum.Dropdown,
    placeholder: 'widgets.actions.choose-widget',
    meta: {
      valueAsObject: true,
      options: globalWidgetConfigToOptions(widgetsConfig),
    },
  },
];
