import { Component, Input } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-toggle-cell]',
  templateUrl: './jm-toggle-cell.component.html',
})
export class JmToggleCellComponent extends AbstractCellComponent {
  @Input() expanded = false;
  @Input() rowData: any;
}
