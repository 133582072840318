import { JMPermissions } from './enums/permission.enum';

export const permissions = {
  all: [JMPermissions.AllPermission],
  admin: {
    configureCompany: [JMPermissions.CompanyManageAny, JMPermissions.CompanyManageOwn],
    configureUser: [JMPermissions.UserManageAny, JMPermissions.UserManageOwn],
    configureLocation: [JMPermissions.LocationManageAny, JMPermissions.LocationManageOwn],
    licensesView: [
      JMPermissions.LicenseManageAny,
      JMPermissions.LicenseViewAny,
      JMPermissions.LicenseViewOwn,
      JMPermissions.LicenseAllocateAny,
      JMPermissions.LicenseAllocateOwn,
    ],
    licenseManageAny: [JMPermissions.LicenseManageAny, JMPermissions.LicenseAllocateAny, JMPermissions.LicenseAllocateOwn],
    configureMachines: [JMPermissions.MachineCategoriesManageAny, JMPermissions.MachineCategoriesManageOwn],
    machineTypes: [JMPermissions.MachineTypesManageAny, JMPermissions.MachineTypesManageOwn],
    adminCompaniesView: [
      JMPermissions.CompanyViewAny,
      JMPermissions.CompanyViewOwn,
      JMPermissions.CompanyManageAny,
      JMPermissions.CompanyManageOwn,
    ],
    locationsCompanyView: [
      JMPermissions.LocationViewAny,
      JMPermissions.LocationViewOwn,
      JMPermissions.LocationManageAny,
      JMPermissions.LocationManageOwn,
    ],
    usersCompanyView: [JMPermissions.UserViewAny, JMPermissions.UserViewOwn, JMPermissions.UserManageAny, JMPermissions.UserManageOwn],
    userImpersonateAny: [JMPermissions.UserImpersonateAny],
    rootTemplates: [JMPermissions.ArticleRootTemplateManageAny],
    auditLogs: [JMPermissions.AuditLogViewAny, JMPermissions.AuditLogViewOwn],
    UserNotifications: [JMPermissions.UserNotificationsManageAny, JMPermissions.UserNotificationsViewAny],
    mconVersion: [JMPermissions.MconVersionsManage],
    rawEvents: [JMPermissions.EventViewAny],
    releaseNotes: [JMPermissions.ReleaseNotesManageAny, JMPermissions.ReleaseNotesViewAny],
    sensorConfiguration: [JMPermissions.SensorConfigurationViewAny, JMPermissions.SensorConfigurationManageOwn],
    sensorView: [JMPermissions.SensorConfigurationViewAny, JMPermissions.SensorConfigurationViewOwn],
  },
  home: {
    dashboards: [JMPermissions.ModuleDashboard],
    library: [JMPermissions.ModuleLibrary],
    admin: [JMPermissions.ModuleAdmin],
    analytics: [JMPermissions.ModuleAnalytics],
  },
};
