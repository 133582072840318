<div class="filter-multiselect">
  <p-multiSelect
    [(ngModel)]="value"
    [options]="options"
    (onChange)="onChange($event)"
    [placeholder]="column.filter?.placeholder || '' | translate"
    [dropdownIcon]="'pi pi-custom-caret-down'"
    [showHeader]="false"
  >
    <ng-template pTemplate="selectedItems">
      <ng-container *ngIf="value?.length > 0">
        <ng-template ngFor let-item [ngForOf]="value" let-index="index">
          {{ column.filter?.dataTranslation ? (column.filter?.dataTranslation + '.' + item | translate) : item
          }}{{ index + 1 < value?.length ? ', ' : '' }}
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!value || value?.length === 0">
        {{ column.filter?.placeholder || '' | translate }}
      </ng-container>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div class="item">
        <div>{{ column.filter?.dataTranslation ? (column.filter?.dataTranslation + '.' + item | translate) : item }}</div>
      </div>
    </ng-template>
  </p-multiSelect>
</div>
<div class="action-wrapper">
  <jm-button [disabled]="value?.length === 0" [class]="'red-border-button'" (clicked)="clear()">
    {{ 'button.clear' | translate }}
  </jm-button>
  <jm-button [disabled]="value?.length === 0" [class]="'red-button'" (clicked)="apply()">
    {{ 'button.apply' | translate }}
  </jm-button>
</div>
