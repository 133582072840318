<div class="jm-card--overview" [class.no-hover]="embedded">
  <div class="machine-intro">
    <div class="machine-name-date">
      <span class="machine-name">{{ data.machine.displayName }}</span>
      <span *ngIf="data.machine.lastDataReceivedAt; else noData" class="machine-last-data-received">
        {{ data.machine.lastDataReceivedAt | customDate : dateStyle.dateTime }}
      </span>
    </div>
    <app-machine-status [status]="data.machine.currentStatus" [showExtraField]="true"></app-machine-status>
  </div>

  <div class="machine-statistic">
    <ng-container *ngIf="data.machine?.currentStatus !== machineCurrentStatus.STOP_OFFLINE">
      <ng-container *ngFor="let column of statisticColumns; let last = last">
        <app-machine-statistic-column
          [src]="column.src"
          [value]="getValue(column.value)"
          [tooltipText]="column.tooltipText"
        ></app-machine-statistic-column>

        <div *ngIf="!last" class="separator"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data.machine?.currentStatus === machineCurrentStatus.STOP_OFFLINE">
      <div class="machine-statistic-no-data">
        <svg-icon class="cloud-icon" src="./assets/images/cloud-icon.svg"></svg-icon>
      </div>
    </ng-container>
  </div>

  <div class="machine-progress" *ngIf="data.machine.currentStatus !== machineCurrentStatus.STOP_OFFLINE">
    <img src="./assets/images/progress.svg" alt="progress" class="progress-icon" />
    <div class="jm-progress-bar">
      <app-order-progress-bar [order]="data.currentOrderStats.order" [showOnlyName]="true"></app-order-progress-bar>
    </div>
  </div>
</div>

<ng-template #noData>
  <span class="machine-last-data-received">
    {{ 'global.no-data-available' | translate }}
  </span>
</ng-template>
