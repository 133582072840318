import { Model } from 'ngx-super-model';
import { MediaGalleryDTO } from 'src/app/modules/media-gallery/interfaces/gallery-image.interface';
import { SectionDTO } from '../interfaces/section.interface';
import { Component } from './component.model';

export class Section extends Model<SectionDTO> {
  name = '';
  footnotes = '';
  notes: {
    title?: '';
    description?: '';
  } = {};
  components: Array<Component> = [];
  activeImagePreview?: MediaGalleryDTO | null = null;
  activeImages?: Array<MediaGalleryDTO> = [];
  index?: number = 0;
  hide? = false;

  toggleHidden(): boolean {
    this.hide = !this.hide;
    return this.hide;
  }

  override loadModel(input: SectionDTO): this {
    super.loadModel(input);
    this.components = input.components.map((component) => {
      const newComponent = new Component().loadModel(component);
      return newComponent;
    });
    return this;
  }
}
