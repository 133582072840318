<div class="machine-status-wrapper" *ngIf="status">
  <div
    class="machine-status"
    *ngIf="!showTooltip"
    [ngClass]="getMachineStatusClasses(status)"
    [class.rounded]="!shouldDisplayAnotherStatus(status)"
  >
    {{ getMachineStatusText(status) | translate }}
  </div>
  <div
    class="machine-status rounded"
    *ngIf="showTooltip"
    tooltipPosition="top"
    [pTooltip]="'machines.status.' + status | translate"
    [ngClass]="getMachineStatusClasses(status)"
  >
    {{ getMachineStatusText(status) | translate }}
  </div>
  <div class="machine-another-status" *ngIf="shouldDisplayAnotherStatus(status) && showExtraField">
    {{ getAnotherStatusText(status) | translate }}
  </div>
</div>
