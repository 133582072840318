import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult, gql } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { ProfitabilityInput } from '../../performance-dashboard/interfaces/profitability-input.interface';
import { ProfitabilityDTO } from './interfaces';
import { Profitability } from './models';

@Injectable({
  providedIn: 'root',
})
export class ProfitabilityService extends AbstractCRUDService<Profitability, ProfitabilityDTO> {
  override model = Profitability;

  getTopTen(input: ProfitabilityInput): Observable<Array<Profitability>> {
    const query = gql`
      query ArticleProfitability($input: ArticleProfitabilityInput!) {
        articleProfitability(input: $input) {
          articleGuid
          articleName
          revenue
          cost
          profit
        }
      }
    `;

    return this.apollo
      .query<any>({ query, variables: { input } })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<ProfitabilityDTO>>>) =>
          response.data.articleProfitability.map((item: ProfitabilityDTO) => this.loadModel(item))
        )
      );
  }

  getLeastTen(input: ProfitabilityInput): Observable<Array<Profitability>> {
    const query = gql`
      query ArticleProfitability($input: ArticleProfitabilityInput!) {
        articleProfitability(input: $input) {
          articleGuid
          articleName
          revenue
          cost
          profit
        }
      }
    `;

    return this.apollo
      .query<any>({ query, variables: { input } })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<ProfitabilityDTO>>>) =>
          response.data.articleProfitability.map((item: ProfitabilityDTO) => this.loadModel(item))
        )
      );
  }
}
