<div class="machine-statistic-column">
  <svg-icon
    class="statistic-icon"
    [src]="src"
    [class.no-value]="hasNoValue"
    tooltipPosition="top"
    [pTooltip]="'dashboards.labels.' + tooltipText | translate"
  ></svg-icon>
  <div class="statistic-value" [class.no-value]="hasNoValue">
    <p>{{ value }}</p>
  </div>
</div>
