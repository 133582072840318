import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { ResponseData } from '../../abstract/interface/response-data';
import { MultipleMachinesRequestDTO } from '../core/interfaces/location-chart-request.interface';
import { EfficiencyPerShiftDTO } from './interface/efficiency-per-shift';

@Injectable({
  providedIn: 'root',
})
export class EfficiencyPerShiftService {
  private readonly snackbarService = inject(SnackBarService);
  private readonly apollo = inject(Apollo);

  getData(request: MultipleMachinesRequestDTO): Observable<Array<EfficiencyPerShiftDTO>> {
    const query = gql`
      query efficiencyPerShiftChart($input: MultipleMachinesChartInput!) {
        efficiencyPerShiftChart(input: $input) {
          buckets {
            shifts {
              value
              shift
            }
            offline
            bucket
          }
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input: request,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackbarService.error('widgets.error.efficiency-per-shift');
        }),
        map((response: ApolloQueryResult<ResponseData<{ buckets: Array<EfficiencyPerShiftDTO> }>>) => {
          return response.data.efficiencyPerShiftChart.buckets;
        })
      );
  }
}
