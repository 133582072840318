import { AngularSvgIconModule } from 'angular-svg-icon';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ImageNotFoundDirective } from 'src/app/shared/directives/image-not-found/image-not-found.directive';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { ImagePreviewComponent } from 'src/app/shared/modules/image-preview/image-preview.component';
import { OrderProgressBarComponent } from 'src/app/shared/modules/order-progress-bar/order-progress-bar.component';
import { PercentageBoxComponent } from 'src/app/shared/modules/percentage-box/percentage-box.component';
import { ProgressBarComponent } from 'src/app/shared/modules/progress-bar/progress-bar.component';
import { PrettyDurationPipe } from 'src/app/shared/pipes/pretty-duration.pipe';
import { RoundNumberPipe } from 'src/app/shared/pipes/round-number.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomDatePipe } from 'src/app/util/datetime/custom-date.pipe';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { LoaderComponent } from '@app-ui';
import { LegacyComponentsModule } from '@legacy-components';
import { TranslateModule } from '@ngx-translate/core';

import { QuickFormsModule } from '../quick-forms/quick-forms.module';
import {
  AbstractCellComponent,
  AbstractRowComponent,
  JmArrayCellComponent,
  JmAssignedCellComponent,
  JmColumFilterMultiselectComponent,
  JmColumnFilterComponent,
  JmColumnFilterDateRangeComponent,
  JmColumnFilterDropdownComponent,
  JmColumnSettingsComponent,
  JmDataTranslationCellComponent,
  JmDateTimeCellComponent,
  JmExtraMobileCellComponent,
  JmGlobalFilterComponent,
  JmMachineStatusCellComponent,
  JmNumberCellComponent,
  JmOeeCellComponent,
  JmOrderNameComponent,
  JmOrderProgressCellComponent,
  JmRelationCellComponent,
  JmRowBodyComponent,
  JmRowExpansionComponent,
  JmRowHeaderComponent,
  JmStringCellComponent,
  JmTableComponent,
  JmTagCellComponent,
  JmTimeCellComponent,
  JmToggleCellComponent,
} from './components';
import { MachineStatusComponent } from '@machines';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    AbstractCellComponent,
    AbstractRowComponent,
    JmArrayCellComponent,
    JmAssignedCellComponent,
    JmColumFilterMultiselectComponent,
    JmColumnFilterComponent,
    JmColumnFilterDateRangeComponent,
    JmColumnFilterDropdownComponent,
    JmColumnSettingsComponent,
    JmDataTranslationCellComponent,
    JmDateTimeCellComponent,
    JmExtraMobileCellComponent,
    JmGlobalFilterComponent,
    JmMachineStatusCellComponent,
    JmNumberCellComponent,
    JmOeeCellComponent,
    JmOrderNameComponent,
    JmOrderProgressCellComponent,
    JmRelationCellComponent,
    JmRowBodyComponent,
    JmRowExpansionComponent,
    JmRowHeaderComponent,
    JmStringCellComponent,
    JmTableComponent,
    JmTagCellComponent,
    JmTimeCellComponent,
    JmToggleCellComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    TranslateModule,
    AngularSvgIconModule,
    LoaderComponent,
    DropdownModule,
    CalendarModule,
    MultiSelectModule,
    LegacyComponentsModule,
    SharedModule,
    MatMenuModule,
    EmptyBlockModule,
    TourMatMenuModule,
    OrderProgressBarComponent,
    QuickFormsModule,
    PrettyDurationPipe,
    RoundNumberPipe,
    PercentageBoxComponent,
    ProgressBarComponent,
    CustomDatePipe,
    DragDropModule,
    ImagePreviewComponent,
    ImageNotFoundDirective,
    TooltipModule,
    MachineStatusComponent,
  ],
  exports: [JmTableComponent],
})
export class JmTableModule {}
