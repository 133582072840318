import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

export const createDashboardConfig: Array<ControlConfigDTO> = [
  {
    id: 'name',
    label: 'dashboards.controls.name',
    type: ControlTypeEnum.InputText,
    validators: {
      required: true,
      maxLength: 30,
    },
  },
];
