import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Output()
  valueChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  inputBlur: EventEmitter<any> = new EventEmitter<any>();

  value = '';
  isDisabled = false;

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(true);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChanged(event: any) {
    const value = event.target.value || null;
    this.propagateChange(value);
    this.valueChanged.emit(value);
  }

  onFocus() {}

  onBlur(event: any) {
    this.inputBlur.emit(event);
    this.propagateTouched();
  }

  private propagateChange = (value: any) => {};
  private propagateTouched = () => {};
}
