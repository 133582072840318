import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProgressBar } from './progress-bar.interface';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [ProgressbarModule, CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() progressBar: ProgressBar = {
    type: 'secondary',
    value: null,
    striped: false,
    animate: false,
  };
}
