<nav class="nav-bar" [class.not-logged-user]="(isLoggedIn$ | async) === false">
  <div class="wrapper-parent">
    <nav class="wrapper">
      <div class="wrapper__left-holder">
        <img
          *ngIf="!hideLogo"
          class="logo-company"
          src="./assets/images/JM-logo.svg"
          alt="jakob_muller_logo"
          title="Jakob Muller"
          (click)="navigateToHome()"
        />

        <img
          *ngIf="!hideLogo"
          class="logo-company-mobile"
          src="./assets/images/JM-logo-mobile.svg"
          alt="jakob_muller_logo"
          title="Jakob Muller"
          (click)="navigateToHome()"
        />
        <div *ngIf="isMenuIconDisplayed && innerWidth < 1366" class="logo-holder">
          <jm-button [class]="'white-border-button button--small module-sidebar-icon'" (click)="toggleSettingsSidebar()">
            <svg-icon
              class="link-icon settings"
              [src]="'./assets/images/setting.svg'"
              *ngIf="!(isSettingsSideBarOpened$ | async)"
            ></svg-icon>
            <svg-icon class="link-icon close" [src]="'./assets/images/close.svg'" *ngIf="isSettingsSideBarOpened$ | async"></svg-icon>
          </jm-button>
        </div>
      </div>

      <div
        *ngIf="(isLoggedIn$ | async) && (userPermissions$ | async)?.length > 0"
        [class.open]="isMobileMenuOpened"
        [class.hidden]="!isMobileMenuOpened"
        [ngClass]="'main-nav'"
      >
        <div class="main-nav__holder">
          <div class="user-info">{{ loginName$ | async }}</div>

          <ng-container *ngFor="let link of navBarLinksUpdated; trackBy: trackByIndex">
            <jm-button
              *ngIf="!link.subMenu || innerWidth < 900"
              [class]="'nav-button full-width with-submenu'"
              [active]="routerUrl.includes(link.label)"
              [disabled]="link?.disabled"
              [ngClass]="link.label"
              [tourAnchor]="link.anchorId"
              (click)="navigateTo(link)"
              [attr.data-test]="'nav-link-' + link?.label"
              [prefixIcon]="link?.icon"
              [toggleable]="link.subMenu ? true : false"
            >
              {{ 'navigation.' + link?.label | translate }}
            </jm-button>
            <div [class]="'submenu'" [class.open]="selectedMenu === link.label" *ngIf="link.subMenu && innerWidth < 900">
              <app-dashboards-menu-dialog (create)="onCreateDashboard()"></app-dashboards-menu-dialog>
            </div>
            <jm-button
              *ngIf="link.subMenu && innerWidth > 900"
              [class]="'nav-button full-width with-submenu'"
              [active]="routerUrl.includes(link.label) || dashboardMenuTrigger.menuOpen"
              [class.disabled]="link?.disabled"
              [tourAnchor]="link?.anchorId"
              #dashboardMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="dashboardMenu"
              [attr.data-test]="'nav-link-' + link?.label"
              [prefixIcon]="link?.icon"
            >
              <label for="menu-toggle" class="link-url"> {{ 'navigation.' + link?.label | translate }} </label>
              <svg-icon class="expand-arrow" [src]="'./assets/images/angle.svg'"></svg-icon>
            </jm-button>
          </ng-container>

          <jm-button
            [class]="'nav-button full-width hidden-900-up'"
            [routerLink]="['/auth/user-profile']"
            (click)="toggleMobileMenu()"
            [prefixIcon]="'user-profile'"
          >
            {{ 'navigation.account' | translate }}
          </jm-button>

          <jm-button [class]="'nav-button full-width hidden-900-up'" [routerLink]="['/auth/tutorials']" [prefixIcon]="'tutorials'">
            {{ 'navigation.tutorials' | translate }}
          </jm-button>

          <jm-button [class]="'nav-button full-width hidden-900-up'" [routerLink]="['/auth/support']" [prefixIcon]="'support'">
            {{ 'navigation.support' | translate }}
          </jm-button>

          <jm-button [class]="'nav-button full-width hidden-900-up'" [prefixIcon]="'logout-gray'" (click)="logout()">
            {{ 'navigation.logout' | translate }}
          </jm-button>

          <div class="additional-links hidden-desktop hidden-900-up">
            <jm-button
              *ngFor="let link of footerLinks; trackBy: trackByIndex"
              [class]="'nav-button full-width'"
              [textCenter]="true"
              [routerLink]="link.targetBlank ? null : ['/auth/support']"
              (click)="link.targetBlank ? openExternalLink(link.url) : toggleMobileMenu()"
            >
              {{ 'footer.' + link.key | translate }}
            </jm-button>
          </div>
        </div>
      </div>

      <div *ngIf="(isLoggedIn$ | async) && (userPermissions$ | async)?.length > 0" class="wrapper__user-account">
        <app-alert-button />

        <jm-button
          *ngIf="innerWidth > 900"
          [class]="'white-border-button button--small user-profile'"
          #profileMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="userProfile"
          data-test="user-profile"
        >
          <svg-icon class="link-icon" src="./assets/images/user-profile.svg"></svg-icon>
        </jm-button>

        <jm-button
          *ngIf="innerWidth < 900"
          [class]="'white-border-button button--small mobile-menu'"
          (click)="toggleMobileMenu()"
          data-test="mobile-menu"
        >
          <svg-icon class="link-icon" [src]="!isMobileMenuOpened ? './assets/images/menu.svg' : './assets/images/close.svg'"></svg-icon>
        </jm-button>

        <app-impersonation-icon
          *ngIf="impersonateUserToken$ | async"
          #impersonationMenuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="impersonationMenu"
          data-test="impersonation-icon"
        />
      </div>
    </nav>
  </div>
</nav>
<div class="mobile-box-shadow" *ngIf="innerWidth < 900 && isMobileMenuOpened" (click)="toggleMobileMenu()"></div>

<mat-menu #dashboardMenu="matMenu" class="dashboard-menu">
  <ng-template matMenuContent>
    <app-dashboards-menu-dialog (create)="onCreateDashboard()" />
  </ng-template>
</mat-menu>

<mat-menu #userProfile="matMenu" class="user-profile-menu">
  <div class="user-profile-link-wrapper">
    <div class="user-profile-link" [routerLink]="['/auth/user-profile']">
      {{ 'navigation.account' | translate }}
    </div>
    <div class="user-profile-link" [routerLink]="['/auth/tutorials']">
      {{ 'navigation.tutorials' | translate }}
    </div>
    <div class="user-profile-link" [routerLink]="['/auth/support']">
      {{ 'navigation.support' | translate }}
    </div>
    <div class="user-profile-link" (click)="logout()">
      {{ 'navigation.logout' | translate }}
      <svg-icon class="link-icon" src="./assets/images/logout.svg"></svg-icon>
    </div>
  </div>
</mat-menu>

<mat-menu #impersonationMenu="matMenu" class="impersonation-menu">
  <div class="impersonation-menu__holder">
    <div class="impersonation-menu__title">
      <p class="current">{{ 'login.currently-impersonating' | translate }}:</p>
      <p class="user">{{ loginName$ | async }}</p>
    </div>
    <jm-button [class]="'black-button'" (clicked)="stopImpersonating()" [dataTestSelector]="'stop-impersonating'">
      {{ 'login.stop-impersonating' | translate }}
    </jm-button>
    <a
      [routerLink]="['/admin/users']"
      *appHasPermission="
        [permissions.UserViewOwn, permissions.UserManageOwn, permissions.UserViewAny, permissions.UserManageAny];
        operator: operator.OR
      "
      class="impersonation-menu__user-link"
      >{{ 'actions.go-to-users' | translate }} <svg-icon class="link-icon" src="./assets/images/arrow-line-right.svg"></svg-icon
    ></a>
  </div>
</mat-menu>
