import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

export const formConfig: Array<ControlConfigDTO> = [
  {
    id: 'email',
    label: 'login.email',
    placeholder: 'forgot-password.type-email',
    type: ControlTypeEnum.InputText,
    validators: {
      email: true,
      required: true,
    },
  },
];
