import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs';
import { LocationDTO } from 'src/app/core/locations/interfaces/location.interface';

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { UpdateLocationInfo } from '../../../main/dashboard/store/dashboard.action';
import locationStatusUpdated from '../gql/location-info-updates.graphql';

@Injectable({
  providedIn: 'root',
})
export class LocationSubscriptionsService {
  private readonly apollo = inject(Apollo);
  private readonly store$ = inject(Store);

  locationStatusUpdated() {
    return this.apollo
      .subscribe({
        query: locationStatusUpdated,
      })
      .pipe(
        tap((result: any) => {
          const location: LocationDTO = result.data.locationStatusUpdated;
          if (!location) {
            return;
          }

          this.store$.dispatch(new UpdateLocationInfo({ location }));
        })
      );
  }
}
