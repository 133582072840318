import { ChangeDetectorRef, Component, ElementRef, HostBinding } from '@angular/core';
import { MediaQuery, ObserveResizeService } from 'angular-container-media-query';

@Component({
  selector: 'widgets-abstract-component',
  template: '',
})
export class AbstractResizableComponent {
  @MediaQuery('(min-width: 300px) and (max-width: 500px)') @HostBinding('class.min-300') min300 = false;

  @MediaQuery('(min-width: 501px) and (max-width: 767px)') @HostBinding('class.min-501') min501 = false;

  @MediaQuery('(min-width: 768px) and (max-width: 1023px)') @HostBinding('class.min-768') min768 = false;

  @MediaQuery('(min-width: 1024px)') @HostBinding('class.min-1024') min1024 = false;

  constructor(resize: ObserveResizeService, elementRef: ElementRef, changeDetector: ChangeDetectorRef) {
    resize.register(this, elementRef, changeDetector);
  }
}
