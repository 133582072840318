import { Injectable, inject } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { isSet } from '@util';
import { IMachineStatusDto } from '@machines';

import machineStatusUpdated from './../gql/machine-live-update.graphql';
import { ResponseData } from '../../abstract/interface/response-data';

@Injectable({
  providedIn: 'root',
})
export class MachineLiveUpdateService {
  private readonly apollo = inject(Apollo);

  machineStatusUpdated(guid: string): Observable<IMachineStatusDto> {
    return this.apollo
      .subscribe<ResponseData<IMachineStatusDto>>({
        query: machineStatusUpdated,
        variables: { guid },
      })
      .pipe(
        map((response) => response.data?.machineStatusUpdated),
        filter((machineStatus: IMachineStatusDto | null | undefined): machineStatus is IMachineStatusDto => isSet(machineStatus))
      );
  }
}
