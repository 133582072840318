import { CellType } from 'src/app/modules/jm-table/enum/cell-type.enum';
import { CellConfigDTO } from 'src/app/modules/jm-table/interfaces/cell-config.interface';

export const oeeByMachineCellConfig: Array<CellConfigDTO> = [
  {
    type: CellType.STRING,
    field: 'machineName',
    title: 'performance-dashboard.oee-by-machine.table.columns.machineName',
    sortingField: 'machineName',
    sortable: true,
  },
  {
    type: CellType.OEE,
    field: 'OEE',
    title: 'performance-dashboard.oee-by-machine.table.columns.oee',
    sortingField: 'OEE',
    sortable: true,
  },
];
