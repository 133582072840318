import { StopReasonDTO } from '../core/machines/interfaces/stop-reasons.interface';

export const stopReasons: Array<StopReasonDTO> = [
  {
    value: 'STOP_REEDCOVER',
    label: 'machines.status.STOP_REEDCOVER',
  },
  {
    value: 'STOP_END_OF_ORDER',
    label: 'machines.status.STOP_END_OF_ORDER',
  },
  {
    value: 'STOP_BINDER',
    label: 'machines.status.STOP_BINDER',
  },
  {
    value: 'STOP_WEFT',
    label: 'machines.status.STOP_WEFT',
  },
  {
    value: 'STOP_FABRIC_OVERROLL',
    label: 'machines.status.STOP_FABRIC_OVERROLL',
  },
  {
    value: 'STOP_EXTERNAL_STOP',
    label: 'machines.status.STOP_EXTERNAL_STOP',
  },
  {
    value: 'STOP_WARP',
    label: 'machines.status.STOP_WARP',
  },
  {
    value: 'STOP_OTHER',
    label: 'machines.status.STOP_OTHER',
  },
];
