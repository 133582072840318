import { Chart, ChartConfiguration, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { ZoomActionEnum } from 'src/app/core/widgets/core/enums/zoom-action.enum';
import { ZoomChangedEvent } from 'src/app/core/widgets/core/interfaces/zoom.interface';
import { zoomChanged } from 'src/app/core/widgets/store/dashboard.actions';

import { AfterViewInit, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

Chart.register(...registerables, zoomPlugin);

@UntilDestroy()
@Component({
  selector: 'jm-chart',
  standalone: true,
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements AfterViewInit, OnDestroy {
  @Input()
  chartConfig: ChartConfiguration | any;

  @Input()
  set data(data: any) {
    if (data !== null) {
      this.chart?.update();
    }
  }

  @Input()
  id = '';

  chart: Chart | any;
  currentZoom = 0;

  constructor(private readonly ngZone: NgZone, private readonly actions$: Actions) {}

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.chart?.resetZoom();
      this.chart?.destroy();
    });
  }

  ngAfterViewInit(): void {
    this.chartInit();
    this.watchZoomChanged();
  }

  watchZoomChanged(): void {
    this.actions$
      .pipe(ofType(zoomChanged))
      .pipe(untilDestroyed(this))
      .subscribe((event: ZoomChangedEvent) => {
        if (event.widgetId === this.id) {
          this.changeZoom(event.action);
        }
      });
  }

  changeZoom(action: ZoomActionEnum): void {
    if (action === ZoomActionEnum.in) {
      this.chart.zoom(1.1);
      return;
    }

    this.chart.zoom(0.9);
  }

  private chartInit(): void {
    this.ngZone.runOutsideAngular(() => {
      if (!this.chart && this.id) {
        this.chart = new Chart(this.id, this.chartConfig);
      }
    });
  }
}
