import { ArticleService } from 'src/app/core/articles/article/article.service';
import { MachineService } from 'src/app/core/machines/services/machine.service';
import { CellFilterType } from 'src/app/modules/jm-table/enum/cell-filter-type.enum';
import { CellType } from 'src/app/modules/jm-table/enum/cell-type.enum';
import { CellConfigDTO } from 'src/app/modules/jm-table/interfaces/cell-config.interface';
import { DateTimeFormatStyle } from 'src/app/util/datetime/format-style.enum';

import { ControlTypeEnum } from '@quick-form';

import { SustainabilityDetails } from '../models';

export const sustainabilityDetailsCellConfig: Array<CellConfigDTO> = [
  {
    type: CellType.STRING,
    field: 'machineAlias',
    title: 'global.machine',
    sortingField: 'machineAlias',
    render: (details: SustainabilityDetails) => {
      if (details.machineAlias) {
        return 'machineAlias';
      }
      return 'machineName';
    },
    filter: {
      type: CellFilterType.DROPDOWN,
      control: [
        {
          id: 'machineGuid',
          label: 'actions.select-machine',
          placeholder: 'actions.choose-machine',
          type: ControlTypeEnum.Dropdown,
          meta: {
            appendTo: '.filter-dropdown',
            virtualScroll: true,
            lazyLoad: true,
            lookupService: MachineService,
          },
        },
      ],
    },
    activeMobileCell: true,
  },
  {
    type: CellType.STRING,
    field: 'articleGuid',
    title: 'global.article',
    sortingField: 'articleName',
    filter: {
      type: CellFilterType.DROPDOWN,
      control: [
        {
          id: 'articleGuid',
          label: 'actions.select-article',
          placeholder: 'actions.choose-article',
          type: ControlTypeEnum.Dropdown,
          meta: {
            appendTo: '.filter-dropdown',
            valueAsObject: false,
            virtualScroll: true,
            lazyLoad: true,
            lookupService: ArticleService,
          },
        },
      ],
    },
    render: () => {
      return 'articleName';
    },
  },
  {
    type: CellType.DATE_TIME,
    field: 'lastFinished',
    title: 'admin.my-admin.tables.audit-logs.date',
    sortingField: 'lastFinished',
    meta: {
      dateFormat: DateTimeFormatStyle.date,
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'targetTime',
    title: 'performance-dashboard.sustainability-details.table.columns.targetTime',
    cssClass: ['small'],
    sortingField: 'targetTime',
    sortable: true,
    meta: {
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'actualTime',
    title: 'performance-dashboard.sustainability-details.table.columns.actualTime',
    cssClass: ['small'],
    sortingField: 'actualTime',
    sortable: true,
    meta: {
      hiddenMobile: true,
    },
  },
  {
    type: CellType.NUMBER,
    field: 'co2Produced',
    title: 'performance-dashboard.sustainability-details.table.columns.produced',
    cssClass: ['small'],
    sortingField: 'co2Produced',
    sortable: true,
    meta: {
      roundNumber: 0,
      hiddenMobile: true,
    },
  },
];
