import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar.service';
import { SHOW_NOTIFICATION, ShowNotification, ShowErrorNotification, SHOW_ERROR_NOTIFICATION } from './snack-bar.actions';

import { cloneDeep } from 'lodash-es';
@Injectable()
export class SnackBarEffects {
  showNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SHOW_NOTIFICATION),
        map((action: ShowNotification) => action.payload),
        tap((payload) => {
          const message: string = payload.message;
          const config: MatSnackBarConfig | undefined = cloneDeep(payload.config);
          this.snackBarService.open(message, config);
        })
      ),
    { dispatch: false }
  );

  showErrorNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SHOW_ERROR_NOTIFICATION),
        map((action: ShowErrorNotification) => action.payload),
        tap((payload) => {
          this.snackBarService.error(payload.message);
        })
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly snackBarService: SnackBarService) {}
}
