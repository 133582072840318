<div class="controls" [formGroup]="form">
  <ng-container *ngFor="let controlConfig of config; let i = index" trackBy:trackById>
    <div *ngIf="controlConfig.type !== 'hidden' && controlConfig.isLeaf() && controlConfig.visible" class="form__fieldset form__input">
      <quick-form-control
        [config]="controlConfig"
        [control]="form.get(controlConfig.id)"
        (valueChanged)="onAfterValueChanged($event, controlConfig, i)"
        (openInfoWindow)="onOpenInfoWindow()"
      >
      </quick-form-control>
    </div>

    <ng-container *ngIf="controlConfig.type === 'repeater' && controlConfig.visible">
      <quick-form-control
        [config]="controlConfig"
        [control]="form.get(controlConfig.id)"
        (valueChanged)="onAfterValueChanged($event, controlConfig, i)"
      >
      </quick-form-control>
    </ng-container>

    <div *ngIf="controlConfig.type === 'group' && controlConfig.visible" class="quick-form__group" [ngClass]="controlConfig.cssClass">
      <quick-form-controls [config]="controlConfig.children || []" [form]="form"></quick-form-controls>
    </div>
  </ng-container>
</div>
