import { map, Observable, of } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ListResultDTO, SearchCriteria } from '@jm-table';
import { LookupResult, LookupService } from '@quick-form';
import { isSet } from '@util';

import { MachineService } from './machine.service';
import { setWidgetAvailableMachineStatuses } from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class MachineStopService implements LookupService {
  private readonly machineService = inject(MachineService);

  getSuggestions(searchCriteria: SearchCriteria): Observable<ListResultDTO<LookupResult>> {
    if (!isSet(searchCriteria?.filterBy) || !isSet(searchCriteria?.filterBy.machineGuid)) {
      return of({
        records: [],
        pageDetails: null,
      });
    }
    const { machineGuid } = searchCriteria.filterBy;

    return this.machineService.getByGuid(machineGuid).pipe(
      map((machine) => {
        return {
          records: setWidgetAvailableMachineStatuses([machine]),
          pageDetails: null,
        };
      })
    );
  }
}
