import { ActionReducer, createReducer, on } from '@ngrx/store';

import * as DashboardActions from './dashboard.actions';
import { CUSTOM_DASHBOARD_STORAGE_KEY, DashboardState, initialDashboardState } from './dashboard.state';

export const dashboardStateReducer: ActionReducer<DashboardState> = createReducer(
  initialDashboardState,
  on(DashboardActions.dashboardLoaded, (state, { mode }) => ({ ...state, mode })),

  on(DashboardActions.setDashboardPeriod, (state, { period, id }) => ({
    ...state,
    filters: {
      ...state.filters,
      [`${CUSTOM_DASHBOARD_STORAGE_KEY}-${id}`]: {
        ...state.filters[id],
        period,
      },
    },
  })),

  on(DashboardActions.setPublicDashboards, (state, { dashboards }) => ({ ...state, sharedDashboards: dashboards })),

  on(DashboardActions.setPrivateDashboards, (state, { dashboards }) => ({ ...state, privateDashboards: dashboards }))
);
