import { FeedbackService } from 'src/app/core/feedback/feedback.service';
import { createControls, loadFormConfig, QuickFormsModule } from 'src/app/modules/quick-forms';
import { ControlConfig } from 'src/app/modules/quick-forms/models/control.config';
import { LoaderComponent } from '@app-ui';
import { LegacyComponentsModule } from '@legacy-components';

import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { formConfig } from './form-config';

@UntilDestroy()
@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
  standalone: true,
  imports: [CommonModule, LegacyComponentsModule, TranslateModule, QuickFormsModule, LoaderComponent],
})
export class FeedbackFormComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly feedbackService = inject(FeedbackService);

  loader = false;

  form!: FormGroup;
  formConfig: Array<ControlConfig> = [];

  ngOnInit(): void {
    this.formConfig = loadFormConfig(formConfig);
    this.form = this.formBuilder.group(createControls(this.formConfig));
  }

  sendFeedback(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { type, message } = this.form.value;

    this.loader = true;
    this.feedbackService
      .send({ type, message })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.form.reset();
          this.loader = false;
        },
        error: () => {
          this.loader = false;
        },
      });
  }
}
