<div class="main-holder main-holder--notification-configuration main-holder--primary">
  <div class="main-holder__content">
    <div class="configuration-wrapper">
      <app-header-bar [title]="title | translate"></app-header-bar>

      <mat-tab-group (focusChange)="focusChange($event)">
        <mat-tab [label]="'notifications.configuration' | translate">
          <div class="tab-content">
            <app-alert-configuration
              (alertConfigurationChanged)="setAlertConfiguration($event)"
              [alertConfigurationCopy]="alertConfigurationCopy"
              [alertConfiguration]="alertConfiguration"
              (alertConfigurationChanged)="alertConfigChanged()"
            ></app-alert-configuration>

            <div class="action-buttons">
              <jm-button [class]="'grey-button'" [suffixIcon]="'reset-back'" (clicked)="resetAll()">
                {{ 'actions.reset-all' | translate }}
              </jm-button>
              <jm-button [class]="'red-button'" [suffixIcon]="'save'" (clicked)="save()">
                {{ 'actions.save' | translate }}
              </jm-button>
            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'notifications.push-notification-configuration' | translate">
          <div class="tab-content">
            <app-push-notifications [userNotificationEuids]="userNotificationEuids$ | async"></app-push-notifications>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
