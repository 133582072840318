import { LegacyComponentsModule } from '@legacy-components';

import { Location, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Router } from '@angular/router';

const imports = [LegacyComponentsModule, NgIf, AngularSvgIconModule];

@Component({
  selector: 'app-header-bar',
  standalone: true,
  imports,
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent {
  private readonly location = inject(Location);
  private readonly router = inject(Router);

  @Input() icon: string | null = null;
  @Input() title: string | null = null;
  @Input() className: string | null = null;
}
