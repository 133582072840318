import { AngularSvgIconModule } from 'angular-svg-icon';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthenticationService, AuthGuard, GuestGuard } from '@auth';
import { EffectsModule } from '@ngrx/effects';

import { FormUnsavedGuard } from '../modules/quick-forms/guards/form-unsaved-guard.service';
import { SnackBarEffects } from './components/notification/store/snack-bar.effects';
import { IsSetDirective } from './directives/is-set.directive';
import { I18nModule } from './i18n.module';
import { MaterialSharedModule } from './material-shared.module';
import { ValuePipe } from './pipes/value.pipe';

@NgModule({
  declarations: [IsSetDirective],
  imports: [
    CommonModule,
    HttpClientModule,
    ScrollingModule,
    MatSnackBarModule,
    MaterialSharedModule,
    EffectsModule.forFeature([SnackBarEffects]),
    AngularSvgIconModule.forRoot(),
    TourMatMenuModule,
    I18nModule,
    ValuePipe,
  ],
  providers: [AuthGuard, FormUnsavedGuard, AuthenticationService, GuestGuard],
  exports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    AngularSvgIconModule,
    TourMatMenuModule,
    I18nModule,
    ValuePipe,
    IsSetDirective,
  ],
})
export class SharedModule {}
