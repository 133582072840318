import { cloneDeep, isArray } from 'lodash-es';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { isSet } from 'src/app/util/util';
import { v4 as uuidv4 } from 'uuid';

import { CellConfigDTO } from '../interfaces/cell-config.interface';
import { TableGlobalFilterDTO } from '../interfaces/global-filter.interface';
import { CellConfig } from '../models/cells.config';
import { PrimeNGFilters } from '../types/filters.type';

export function loadTableCellConfig(config: Array<CellConfigDTO>): Array<CellConfig> {
  return config.map((value) => {
    if (!isSet(value.id)) {
      value.id = uuidv4();
    }
    return new CellConfig().loadModel(value);
  });
}

export function areFiltersActiveInTable(filters: PrimeNGFilters): boolean {
  const copyFilters = cloneDeep(filters);
  if (isSet(copyFilters['isDeleted'])) {
    delete copyFilters.isDeleted;
  }
  const filterValues = Object.values(copyFilters);

  const hasActive = filterValues.some((filterValue) => {
    if (!isSet(filterValue)) {
      return false;
    }

    if (isArray(filterValue) && filterValue.length === 0) {
      return false;
    }

    if (isArray(filterValue)) {
      return isSet(filterValue[0].value);
    }

    return filterValue;
  });
  return hasActive;
}

export function extractGlobalFilterValues(globalFilter: TableGlobalFilterDTO): ObjectLiteral {
  const values: ObjectLiteral = {};

  const { controls } = globalFilter;

  controls.forEach((control) => {
    values[control.id] = control.value;
  });

  return values;
}
