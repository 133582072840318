import { NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './layout';

import { Observable, of } from 'rxjs';
import { authRoutes } from './auth/auth.routing';

const APP_ROUTES: Routes = [
  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then((m) => m.RedirectModule),
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
  },
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  },
  ...authRoutes,
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

class JMCustomLazyLoadPreloader implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: JMCustomLazyLoadPreloader,
    }),
  ],
  exports: [RouterModule],
  providers: [JMCustomLazyLoadPreloader],
})
export class AppRoutingModule {}
