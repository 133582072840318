import 'chartjs-adapter-moment';

import { ChartConfiguration } from 'chart.js';

import { offlineMachinePlugin } from '../../chart/plugins';
import { UNIT } from './average-efficiency.constants';

export const efficiencyChartConfig: ChartConfiguration = {
  type: 'line',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.1,
      },
    },
    parsing: {
      xAxisKey: 'bucket',
      yAxisKey: 'value',
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: UNIT,
          font: {
            size: 16,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { formattedValue } = context;
            return `${formattedValue} ${UNIT}`;
          },
        },
      },
    },
  },

  plugins: [...offlineMachinePlugin],
};
