import { LegacyComponentsModule } from '@legacy-components';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { DialogConfig } from './dialog.model';
import { DialogConfigDTO } from './dialog.interface';
import { TranslateModule } from '@ngx-translate/core';

const imports = [CommonModule, LegacyComponentsModule, AngularSvgIconModule, TranslateModule];

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: imports,
})
export class DialogComponent {
  config: DialogConfig = new DialogConfig();

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: DialogConfigDTO, private readonly dialogRef: MatDialogRef<DialogComponent>) {
    this.config = new DialogConfig().loadModel(data);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}
