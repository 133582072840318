import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): string {
    if (bytes < 1048576) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    }
    return `${(bytes / 1048576).toFixed(2)} MB`;
  }
}
