import { Component, Input, OnInit } from '@angular/core';
import { CellConfig } from '../../models/cells.config';

import { AbstractRowComponent } from '../abstract-row/abstract-row.component';
import { isSet } from '@util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tr[jm-row-header]',
  templateUrl: './jm-row-header.component.html',
})
export class JmRowHeaderComponent extends AbstractRowComponent implements OnInit {
  @Input() sortField?: string;
  @Input() sortOrder: number | null = null;
  @Input()
  unit: string | null = null;

  readonly ASC: number = -1;
  readonly DESC: number = 1;

  activeMobileCell!: CellConfig | undefined;

  get mobileDefaultCellsColSpan(): number {
    return this.tableConfig.cellsConfig.filter((cell) => !isSet(cell.meta.hiddenMobile)).length;
  }

  ngOnInit(): void {
    this.activeMobileCell = this.columns.find((value) => value.activeMobileCell) ?? this.columns[0];
  }

  onMenuAction(column: CellConfig): void {
    this.activeMobileCell = column;
  }
}
