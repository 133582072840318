import { selectUserPermissions } from '@auth';
import { JMPermissions } from '@auth';

import { Directive, inject, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { shareReplay } from 'rxjs/operators';
import { OperatorEnum } from 'src/app/util/operator.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { checkPermissions } from '../helpers/check-permissions';

@UntilDestroy()
@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective implements OnChanges {
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly store$ = inject(Store);

  @Input()
  appHasPermission: Array<JMPermissions> = [];

  @Input()
  appHasPermissionOperator: OperatorEnum = OperatorEnum.AND;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.appHasPermission) {
      const requiredPermissions = changes.appHasPermission.currentValue;
      const operator = changes.appHasPermissionOperator?.currentValue || this.appHasPermissionOperator;

      this.store$
        .select(selectUserPermissions)
        .pipe(shareReplay(1), untilDestroyed(this))
        .subscribe((userPermissions: Array<JMPermissions>) => {
          this.updateView(userPermissions, requiredPermissions, operator);
        });
    }
  }

  private updateView(userPermissions: Array<JMPermissions>, requiredPermissions: Array<JMPermissions>, operator: OperatorEnum): void {
    this.viewContainer.clear();
    const hasPermission = checkPermissions(userPermissions, requiredPermissions, operator);

    if (!hasPermission) {
      return;
    }

    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
