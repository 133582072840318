export const offlineMachinePlugin = [
  {
    id: 'offlineMachine',
    beforeDraw(chart: any) {
      const {
        ctx,
        data,
        chartArea,
        scales: { x },
      } = chart;

      const { datasets } = data;

      if (datasets.length === 0) {
        return;
      }

      datasets[0].data.forEach((dataset: any, index: number) => {
        if (dataset['offline'] && index + 1 < datasets[0].data.length) {
          const start = x.getPixelForValue(dataset['bucket']);
          const end = x.getPixelForValue(datasets[0].data[index + 1]['bucket']);
          ctx.fillStyle = '#EFF3F6';
          ctx.fillRect(start, chartArea.top, end - start, chartArea.height);
        }
      });
    },
  },
];
