import { ChartConfiguration } from 'chart.js';

import 'chartjs-adapter-moment';

export const chartConfig: ChartConfiguration = {
  type: 'bar',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0,
      },
    },
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'kWh',
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US').format(context.parsed.y);
            }
            return `${label} kWh`;
          },
        },
      },
    },
  },
  plugins: [
    {
      id: 'chartAreaBorder',
      beforeDraw(chart, args, options) {
        const {
          ctx,
          chartArea: { left, top, width, height },
        } = chart;
        ctx.save();
        ctx.strokeStyle = '#D6DEE4';
        ctx.lineWidth = options.borderWidth;
        ctx.setLineDash(options.borderDash || []);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.strokeRect(left, top, width, height);
        ctx.restore();
      },
    },
    {
      id: 'customData',
      afterDatasetsDraw: function (chart) {
        const ctx = chart.ctx;
        chart.data.datasets.forEach(function (dataset, i) {
          const meta = chart.getDatasetMeta(i);
          if (!meta.hidden) {
            meta.data.forEach(function (element, index) {
              ctx.fillStyle = '#4F5A62';
              ctx.textAlign = 'center';
              ctx.font = 'bold 12px Mulish';
              const data = (dataset as any)['myCustomData']['co2Equivalent'][index];
              ctx.fillText(`${Number(data).toFixed(2) || 0}g`, element.x, element.y - 10);
            });
          }
        });
      },
    },
  ],
};
