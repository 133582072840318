import { cloneDeep, isString } from 'lodash-es';
import moment from 'moment';
import { environment } from 'src/environments/environment';

import { LookupResult } from '@quick-form';

import { IAppLinksDTO } from '../layout/navbar/navbar.model';
import { yearMonthDayFormat } from './default-values.settings';
import { ObjectLiteral } from './object-literal';
import { UrlSegment } from '@angular/router';

export const max32BitInteger = 2147483647;

export function downloadAFile(url: string, filename: string, extension?: string) {
  let download: string = filename;
  if (extension) {
    download = `${filename}.${extension}`;
  }

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = download;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export function secondsToHms(second: number | null): string {
  if (second) {
    second = Number(second);
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = Math.floor((second % 3600) % 60);

    const hoursDisplay = hours > 0 ? `${hours}h ` : '';
    const minutesDisplay = minutes > 0 ? `${minutes}m ` : '';
    const secondsDisplay = seconds > 0 ? `${seconds}s` : '';
    return hoursDisplay + minutesDisplay + secondsDisplay;
  } else {
    return '-';
  }
}

export function checkUserEditPermission(userPermissions: Array<string> | null, roles: Array<string>): boolean {
  if (!roles) {
    return true;
  }
  if (Boolean(userPermissions) && roles?.length > 0) {
    const result: Array<string> | undefined = userPermissions?.filter((value) => roles.includes(value));
    if (!result) {
      return false;
    }
    return result.length > 0;
  } else {
    return false;
  }
}

export function getImageDimensions(url: string): Promise<{ height: number; width: number }> {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => {
      const height: number = img.height;
      const width: number = img.width;
      if (height && width) {
        resolve({ height, width });
      }
      resolve({ height: 0, width: 0 });
    };
  });
}

export function checkUserPermission(userPermissions: Array<string>, roles: Array<string>): boolean {
  if (userPermissions) {
    const result: Array<string> = userPermissions?.filter((value) => roles?.includes(value));

    return result.length > 0 ? true : false;
  }
  return false;
}

export function checkEnvironmentPermission(environments: Array<string>): boolean {
  return environments.includes(environment.name);
}

export function checkFilterStatus(state: any, defaultFilter: string, filter: string): Array<string> {
  let filters: Array<string> = cloneDeep(state);

  if (!filters.includes(filter)) {
    if (filter === defaultFilter) {
      filters = [defaultFilter];
    } else {
      filters.push(filter);
      const allIndex: number = filters.findIndex((value) => value === defaultFilter);
      if (allIndex !== -1) {
        filters.splice(allIndex, 1);
      }
    }
  } else {
    const arrIndex: number = filters.findIndex((value) => value === filter);
    filters.splice(arrIndex, 1);
  }

  if (filters.length === 0) {
    filters = [defaultFilter];
  }

  return filters;
}

export function jmDateConverter(params: Date | string | null | undefined): Date {
  const date = moment(params) as any;
  return new Date(date['_d']);
}

export function secondsToStringTime(secondsRemaining: number | undefined): string {
  if (typeof secondsRemaining === 'undefined') {
    return '';
  }

  const secondsInDay = 86400;
  const secondsInHour = 3600;
  let levels: Array<any>;

  if (secondsRemaining > secondsInDay) {
    levels = [
      [Math.floor((secondsRemaining % 31536000) / secondsInDay), 'D'],
      [Math.floor(((secondsRemaining % 31536000) % secondsInDay) / secondsInHour), 'H'],
    ];
  } else {
    levels = [
      [Math.floor(((secondsRemaining % 31536000) % secondsInDay) / secondsInHour), 'H'],
      [Math.floor((((secondsRemaining % 31536000) % secondsInDay) % secondsInHour) / 60), 'M'],
    ];
  }
  let returnText = '';

  for (let i = 0, max = levels?.length; i < max; i++) {
    if (levels[i][0] === 0) {
      continue;
    }

    returnText += ' ' + levels[i][0] + ' ' + levels[i][1];
  }
  return returnText.trim();
}

export function objectToStringArray(object: ObjectLiteral): Array<any> {
  return Object.keys(object);
}

export function enumToLookupResultsArray(input: ObjectLiteral): Array<LookupResult> {
  return Object.values(input).map((value) => ({ id: value, name: value }));
}

export function formatDate(date: any): string | null {
  const formattedDate = date ? moment(date).format(yearMonthDayFormat) : null;
  return formattedDate;
}

export const isSet = <T>(input: T): input is NonNullable<T> => {
  return input !== null && input !== undefined;
};

export function valueFallback<T>(value: T): T | string {
  if (!isSet(value)) {
    return '-';
  }

  if (isString(value) && value === '') {
    return '-';
  }

  return value;
}

export function getActiveTab(tabIndex: number, tabs: Array<IAppLinksDTO>): IAppLinksDTO | undefined {
  return tabs.find((value, index) => index === tabIndex);
}

export function removeExtension(filename: string): string {
  return filename.substring(0, filename.lastIndexOf('.')) || filename;
}

export function getColorByValueForPercentage(value: number) {
  const colors = [
    'success-10',
    'success-20',
    'success-30',
    'success-40',
    'success-50',
    'success-60',
    'success-70',
    'success-80',
    'success-90',
    'success-100',
  ];
  const index = Math.floor(Math.ceil(value) / 10 - 0.01);
  return colors[index];
}

export function secondsToHMS(duration: number | null): string {
  if (!duration) {
    return '0s';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  let hmsFormat = '';

  if (hours > 0) {
    hmsFormat += `${hours}h `;
  }

  if (minutes > 0) {
    hmsFormat += `${minutes}m `;
  }

  if (seconds > 0) {
    hmsFormat += `${seconds}s`;
  }

  return hmsFormat.trim();
}

export function arrayPadEnd(values: Array<any>, maxLength: number, value: any = null): Array<any> {
  const filledList = [...values];
  const length = maxLength - values.length;

  return filledList.concat(Array(length).fill(value));
}

export function camelCaseToWords(input: string): string {
  return input.replace(/([A-Z])/g, ' $1');
}

export const hexToRgba = (hex: string, alpha: number): string => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  alpha = Math.min(1, Math.max(0, alpha));

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const isValidGUID = (guid: string): boolean => {
  const guidRegex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/i;
  return guidRegex.test(guid);
};

export function matcherPathId(url: Array<UrlSegment>): { consumed: Array<UrlSegment>; posParams: { [name: string]: UrlSegment } } | null {
  const id = url.find((segment) => Number(segment.path))?.path;

  if (isSet(id)) {
    return { consumed: url, posParams: { id: new UrlSegment(id, {}) } };
  }

  return null;
}

export function matcherPathGUID(url: Array<UrlSegment>): { consumed: Array<UrlSegment>; posParams: { [name: string]: UrlSegment } } | null {
  const guid = url.find((segment) => isValidGUID(segment.path))?.path;

  if (isSet(guid)) {
    return { consumed: url, posParams: { guid: new UrlSegment(guid, {}) } };
  }

  return null;
}

export function roundToDecimals(value: number | undefined | null, decimalPlaces: number): number | null {
  if (isSet(value)) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier) / multiplier;
  }
  return null;
}
