<div class="quick-form-control">
  <div class="input-wrapper" [ngClass]="config.wrapperCssClass">
    <label class="label" *ngIf="config.label"
      >{{ config.label | translate }}
      <span *ngIf="config?.validators?.required">*</span>
    </label>
    <input
      class="{{ config.cssClass }}"
      [id]="config.getDisplayId()"
      [name]="config.getDisplayId()"
      placeholder="{{ config.placeholder || config.label || '' | translate }}"
      [ngClass]="{
        invalid: control.invalid,
        readonly: config.attributes?.readonly
      }"
      [ngModel]="value"
      [appControlAttributes]="config.attributes"
      (input)="onValueChanged($event)"
      (focus)="onFocus()"
      (blur)="onBlur($event)"
      [mask]="config.meta?.mask"
      [placeHolderCharacter]="config.meta?.maskPlaceholderCharacter"
      [disabled]="isDisabled || control.disabled"
      [type]="config.meta?.inputType ?? 'text'"
    />
  </div>

  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
