import { Routes } from '@angular/router';
import { JMPermissions, PermissionGuard } from '@auth';

export const ADMIN_RELEASE_NOTES_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('../components/admin-release-notes/admin-release-notes.component').then((c) => c.AdminReleaseNotesComponent),
  },
  {
    path: 'new',
    canActivate: [PermissionGuard],
    data: {
      authorities: [JMPermissions.ReleaseNotesManageAny],
    },
    loadComponent: () => import('../components/details/release-notes-details.component').then((c) => c.ReleaseNotesDetailsComponent),
  },
  {
    path: ':version',
    loadComponent: () => import('../components/details/release-notes-details.component').then((c) => c.ReleaseNotesDetailsComponent),
  },
];
