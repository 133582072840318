import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { inject, Injectable } from '@angular/core';

import { AlertConfiguration } from './model';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigurationService {
  private readonly apollo = inject(Apollo);

  getAlertConfiguration() {
    const query = gql`
      query {
        alertConfiguration {
          machineSpeedAlertDelay
          machineEfficiencyAlertDelay
          articleSpeedAlertDelay
          articleEfficiencyAlertDelay
          locationEfficiencyAlertDelay
          machineStoppedAlert
          orderRunoutAlert
          muconAlertDelay
          machineStoppedAlertReasons
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
      })
      .pipe(map((res) => res));
  }

  updateAlertConfiguration(alertConfiguration: AlertConfiguration) {
    const mutation = gql`
      mutation updateAlertConfiguration(
        $machineSpeedAlertDelay: Int
        $machineEfficiencyAlertDelay: Int
        $articleSpeedAlertDelay: Int
        $articleEfficiencyAlertDelay: Int
        $locationEfficiencyAlertDelay: Int
        $locationEfficiencyGood: Float
        $locationEfficiencyPoor: Float
        $machineStoppedAlert: Boolean
        $orderRunoutAlert: Int
        $muconAlertDelay: Int
        $machineStoppedAlertReasons: [MachineStatus]
      ) {
        updateAlertConfiguration(
          machineSpeedAlertDelay: $machineSpeedAlertDelay
          machineEfficiencyAlertDelay: $machineEfficiencyAlertDelay
          articleSpeedAlertDelay: $articleSpeedAlertDelay
          articleEfficiencyAlertDelay: $articleEfficiencyAlertDelay
          locationEfficiencyAlertDelay: $locationEfficiencyAlertDelay
          locationEfficiencyGood: $locationEfficiencyGood
          locationEfficiencyPoor: $locationEfficiencyPoor
          machineStoppedAlert: $machineStoppedAlert
          orderRunoutAlert: $orderRunoutAlert
          muconAlertDelay: $muconAlertDelay
          machineStoppedAlertReasons: $machineStoppedAlertReasons
        ) {
          machineSpeedAlertDelay
          machineEfficiencyAlertDelay
          articleSpeedAlertDelay
          articleEfficiencyAlertDelay
          locationEfficiencyAlertDelay
          machineStoppedAlert
          orderRunoutAlert
          muconAlertDelay
          machineStoppedAlertReasons
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          ...alertConfiguration,
        },
      })
      .pipe(map((res) => res.data));
  }

  getPushNotificationConfiguration() {
    const query = gql`
      query {
        pushNotificationConfiguration {
          id
          userId
          defaultNotification
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
      })
      .pipe(map((res) => res));
  }

  updatePushNotificationConfiguration(defaultNotification: boolean) {
    const mutation = gql`
      mutation updatePushNotificationConfiguration($defaultNotification: Boolean!) {
        updatePushNotificationConfiguration(defaultNotification: $defaultNotification) {
          id
          userId
          defaultNotification
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          defaultNotification,
        },
      })
      .pipe(map((res) => res));
  }
}
