export const environment = {
  rootUrl: 'https://staging.mymuenet.swiss',
  apiUrl: 'https://staging.mymuenet.swiss/graphql',
  wsUrl: 'wss://staging.mymuenet.swiss/graphql-sub',
  editorUrl: 'https://editor.staging.mymuenet.swiss',
  editorApiUrl: 'https://editor-api.staging.mymuenet.swiss',
  authCookieDomain: '.staging.mymuenet.swiss',
  sentryDSN: 'https://dc5d0fddc17e40b29d6c18a2ab5a6192@sentry.kstg.io/2',
  name: 'staging',
  prodMode: true,
  debug: false,
  oneSignalAppID: 'e7be1452-2e7d-4597-b483-c05fb6357b5f',
  oneSignalSafariWebID: 'web.onesignal.auto.2460cc6e-4b7f-44ed-bf33-fcc5c16bd2af',
  googleMaps: {
    apiKey: 'AIzaSyA7KTvMaPUixDc6waE5HtKsB3wZQ0AD7Dg',
    timeZoneUrl: 'https://maps.googleapis.com/maps/api/timezone/json',
  },
  myMueller: {
    sso: {
      url: 'https://mymueller-testk.mueller-frick.com/o/oauth2/authorize/',
      clientId: 'id-9198a825-3ed5-7fee-c657-eb5c6428808',
    },
  },
};
