import { TooltipModule } from 'primeng/tooltip';

import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MachineStatus } from '../../enums/machine-status.enum';
import { COMEZ_ACTIVE, COMEZ_ERROR, COMEZ_SETUP } from '../../settings/comez-status.settings';
import { machineCustomClasses } from '../../settings/machine-custom-classes.settings';

@Component({
  selector: 'app-machine-status',
  standalone: true,
  imports: [NgClass, NgIf, TooltipModule, TranslateModule],
  templateUrl: './machine-status.component.html',
  styleUrls: ['./machine-status.component.scss'],
})
export class MachineStatusComponent {
  @Input() status: MachineStatus | undefined;
  @Input() showExtraField = false;
  @Input() showTooltip = false;

  statusClassMapping: Record<string, string> = {
    [MachineStatus.STOP_NO]: 'running',
    [MachineStatus.INVALID]: 'invalid',
    [MachineStatus.STOP_OFFLINE]: 'offline',
    [MachineStatus.STOP_END_OF_ORDER]: 'end-of-order',
  };

  getMachineStatusClasses(status: MachineStatus): Record<string, boolean> {
    if (COMEZ_SETUP.includes(status)) {
      return { setup: true };
    }

    if (COMEZ_ACTIVE.includes(status)) {
      return { active: true };
    }

    if (COMEZ_ERROR.includes(status)) {
      return { error: true };
    }

    const classes: Record<string, boolean> = {};
    for (const [statusKey, className] of Object.entries(this.statusClassMapping)) {
      classes[className] = status === statusKey;
    }
    return classes;
  }

  getMachineStatusText(status: MachineStatus): string {
    if (status === MachineStatus.STOP_NO || status === MachineStatus.STOP_OFFLINE || status === MachineStatus.INVALID) {
      return 'machines.status.' + status;
    }

    if (COMEZ_SETUP.includes(status)) {
      return 'machines.status.SETUP';
    }

    if (COMEZ_ACTIVE.includes(status)) {
      return 'machines.status.ACTIVE';
    }

    if (COMEZ_ERROR.includes(status)) {
      return 'machines.status.ERROR';
    }

    return 'machines.status.STOPPED';
  }

  shouldDisplayAnotherStatus(status: MachineStatus): boolean {
    return machineCustomClasses.includes(status);
  }

  getAnotherStatusText(status: MachineStatus): string {
    return 'machines.status.' + status;
  }
}
