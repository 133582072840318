import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StickyHolderComponent } from './sticky-holder.component';

@NgModule({
  declarations: [StickyHolderComponent],
  imports: [CommonModule, TourMatMenuModule],
  exports: [StickyHolderComponent],
})
export class StickyHolderModule {}
