import {
  NotificationConfigurationAction,
  NotificationConfigurationActionType,
  SaveAlertConfiguration,
} from './notification-configuration.actions';
import { NotificationConfigurationState, initialNotificationConfigurationState } from './notification-configuration.state';

export function notificationConfigurationReducer(
  oldState: NotificationConfigurationState = initialNotificationConfigurationState,
  action: NotificationConfigurationAction
): NotificationConfigurationState {
  switch (action.type) {
    case NotificationConfigurationActionType.FETCH_ALERT_CONFIGURATION: {
      const state = {
        ...oldState,
        alertConfiguration: {
          ...oldState.alertConfiguration,
          alertConfigurationLoader: true,
        },
      };

      return state;
    }

    case NotificationConfigurationActionType.SAVE_ALERT_CONFIGURATION: {
      const { alertConfiguration } = (action as SaveAlertConfiguration).payload;

      const state = {
        ...oldState,
        alertConfiguration: {
          ...oldState.alertConfiguration,
          alertConfigurationLoader: false,
          alertConfiguration,
        },
      };

      return state;
    }

    case NotificationConfigurationActionType.ERROR_ALERT_CONFIGURATION: {
      const state = {
        ...oldState,
        alertConfiguration: {
          ...oldState.alertConfiguration,
          alertConfigurationLoader: false,
        },
      };

      return state;
    }

    case NotificationConfigurationActionType.UPDATE_ALERT_CONFIGURATION: {
      const state = {
        ...oldState,
        alertConfiguration: {
          ...oldState.alertConfiguration,
          submittingLoading: true,
        },
      };

      return state;
    }

    case NotificationConfigurationActionType.SUCCESSFULLY_UPDATE_ALERT_CONFIGURATION:
    case NotificationConfigurationActionType.ERROR_UPDATE_ALERT_CONFIGURATION: {
      const state = {
        ...oldState,
        alertConfiguration: {
          ...oldState.alertConfiguration,
          submittingLoading: false,
        },
      };

      return state;
    }

    default:
      return oldState;
  }
}
