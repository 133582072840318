import { COMEZ_STATUS, MachineStatus } from '../enums/machine-status.enum';

export const COMEZ_SETUP: Array<MachineStatus> = [
  MachineStatus.INIT_START,
  MachineStatus.INIT_INITIALIZATION_IN_PROGRESS,
  MachineStatus.INIT_INIT,
  MachineStatus.SETUP_CONFIGURE_MACHINE,
  MachineStatus.SETUP_LOAD_CHECK_PATTERN,
  MachineStatus.SETUP_EXECUTE_HOME,
  MachineStatus.SETUP_WAITING_FOR_FIRST_LINE_RECALL,
  MachineStatus.SETUP_OK,
  MachineStatus.SETUP_INCORRECT_ANGLES,
  MachineStatus.ACTIVATION_LOADING_PATTERN,
];

export const COMEZ_ACTIVE: Array<MachineStatus> = [
  MachineStatus.ACTIVE_PATTERN_EXECUTING,
  MachineStatus.ACTIVE_CHECK_PATTERN,
  MachineStatus.ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS,
];

export const COMEZ_ERROR: Array<MachineStatus> = [
  MachineStatus.ERROR_FILE,
  MachineStatus.ERROR_INTERNAL,
  MachineStatus.ERROR_I_O,
  MachineStatus.ERROR_CAN,
  MachineStatus.ERROR_ACTUATORS,
  MachineStatus.ERROR_OPERATION,
  MachineStatus.ERROR_DEVICE,
  MachineStatus.ERROR_AUTOMATIC_CYCLE,
  MachineStatus.ERROR_INVERTER,
];

export const COMEZ_ALERTS: Array<COMEZ_STATUS> = [
  COMEZ_STATUS.ERROR_CAN,
  COMEZ_STATUS.ERROR_ACTUATORS,
  COMEZ_STATUS.ERROR_OPERATION,
  COMEZ_STATUS.ERROR_DEVICE,
  COMEZ_STATUS.ERROR_AUTOMATIC_CYCLE,
  COMEZ_STATUS.ERROR_INVERTER,
];
