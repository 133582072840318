import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
})
export class ModalLayoutComponent {
  @Input() className?: string;
  @Output()
  closeDialog: EventEmitter<never> = new EventEmitter<never>();

  onClose(): void {
    this.closeDialog.emit();
  }
}
