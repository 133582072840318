<div class="mobile-expanded-default-column">
  <div class="cell-title">
    <span class="title">{{ column?.title || '' | translate }}</span>
  </div>

  <td jm-number-cell *ngIf="column?.type === cellType.NUMBER" [cellData]="rowData" [column]="column"></td>
  <td jm-date-time-cell *ngIf="column?.type === cellType.DATE_TIME" [cellData]="rowData" [column]="column"></td>
  <td jm-string-cell *ngIf="column?.type === cellType.STRING" [cellData]="rowData" [column]="column"></td>
  <td jm-relation-cell *ngIf="column?.type === cellType.RELATION" [cellData]="rowData" [column]="column"></td>
  <td jm-data-translation-cell *ngIf="column?.type === cellType.DATA_TRANSLATION" [cellData]="rowData" [column]="column"></td>
  <td jm-array-cell *ngIf="column?.type === cellType.ARRAY" [cellData]="rowData" [column]="column"></td>
  <td
    jm-assigned-cell
    *ngIf="column?.type === cellType.ASSIGNED"
    [assigned]="rowData[column?.assigned || 0]"
    [total]="rowData[column?.total || 0]"
    [column]="column"
  ></td>
  <td jm-time-cell *ngIf="column?.type === cellType.TIME" [cellData]="rowData" [column]="column"></td>
</div>
