import { Injectable } from '@angular/core';
import { HashLocationStrategy, PlatformLocation } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import { isSet } from '../util/util';

@Injectable()
export class HashPreserveQueryLocationStrategy extends HashLocationStrategy {
  constructor(platformLocation: PlatformLocation, private readonly urlSerializer: UrlSerializer) {
    super(platformLocation);
  }

  override prepareExternalUrl(internal: string): string {
    const currentUrlTree = this.urlSerializer.parse(this.path());

    const existingQueryParams = currentUrlTree.queryParams;

    const targetUrlTree = this.urlSerializer.parse(internal);

    const hide_logo = existingQueryParams.hide_logo;
    if (isSet(hide_logo)) {
      targetUrlTree.queryParams = { ...targetUrlTree.queryParams, ...{ hide_logo } };
    }

    const embedded = existingQueryParams.embedded;
    if (isSet(embedded)) {
      targetUrlTree.queryParams = { ...targetUrlTree.queryParams, ...{ embedded } };
    }

    return super.prepareExternalUrl(this.urlSerializer.serialize(targetUrlTree));
  }
}
