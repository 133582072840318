<div class="quick-form-control">
  <div class="input-wrapper" [ngClass]="config.wrapperCssClass">
    <label class="label" *ngIf="config.label">
      {{ config.label | translate }}
      <span *ngIf="config?.validators?.required">*</span>
    </label>
    <div class="color-input-wrapper">
      <ngx-colors
        class="color-picker"
        ngx-colors-trigger
        [(ngModel)]="value"
        (change)="onColorChange($event)"
        [format]="colorFormat"
        [colorPickerControls]="colorPickerControls"
      ></ngx-colors>
      <input
        class="{{ config.cssClass }}"
        [id]="config.getDisplayId()"
        [name]="config.getDisplayId()"
        placeholder="#FFFFFF"
        [ngClass]="{
          invalid: control.invalid,
          readonly: config.attributes?.readonly
        }"
        [ngModel]="value"
        [appControlAttributes]="config.attributes"
        (input)="onValueChanged($event)"
        [mask]="config.meta?.mask"
        [placeHolderCharacter]="config.meta?.maskPlaceholderCharacter"
        [type]="'text'"
      />
    </div>
  </div>
</div>
