import { Model } from 'ngx-super-model';

import { UserDTO } from '@user';

import { WidgetFactory } from '../../widgets/core/factories/widget.factory';
import { WidgetDTO } from '../../widgets/core/interfaces/widget.interface';
import { Widget } from '../../widgets/core/models/widgets/widget.model';
import { DashboardUserRole } from '../enums/dashboard-user-role.enum';
import { DashboardDTO } from '../interfaces/dashboard.interface';
import { UsersWithAccessDTO } from '../interfaces/users-with-access.interface';

export class Dashboard extends Model<DashboardDTO> {
  guid: string | null = null;
  name = '';
  widgets: Array<Widget> = [];
  createdAt?: string | null = null;
  updatedAt?: string | null = null;
  user?: UserDTO | null = null;
  usersWithAccess?: Array<UsersWithAccessDTO> = [];
  dashboardUserRole?: DashboardUserRole | null = null;

  hasWidgets(): boolean {
    return this.widgets.length > 0;
  }

  forSave(): DashboardDTO {
    const dashboard: DashboardDTO = { ...this } as DashboardDTO;

    dashboard.widgets = dashboard.widgets.map((widget: WidgetDTO) => {
      const gridsterConfig = widget.gridsterConfig;

      if (gridsterConfig) {
        widget.gridsterConfig = {
          id: gridsterConfig.id,
          x: gridsterConfig.x,
          y: gridsterConfig.y,
          rows: gridsterConfig.rows,
          cols: gridsterConfig.cols,
        };
      }

      return widget;
    });

    return {
      guid: dashboard.guid,
      name: dashboard.name,
      widgets: dashboard.widgets,
    };
  }

  override loadModel(input: DashboardDTO): this {
    super.loadModel(input);
    if (input.widgets?.length > 0) {
      this.widgets = input?.widgets.map((widget) => WidgetFactory.fromObjectLiteral(widget));
    }
    return this;
  }
}
