import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { AbstractTableComponent } from 'src/app/modules/jm-table/components/abstract-table/abstract-table.component';
import { JmTableModule } from 'src/app/modules/jm-table/jm-table.module';
import { TableConfig } from 'src/app/modules/jm-table/models/table.config';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { CommonModule } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { PerformanceDetailsDTO } from './interfaces';
import { PerformanceDetails } from './models';
import { PerformanceDetailsService } from './performance-details.service';
import { performanceDetailsTableConfig } from './settings/table.config';

@Component({
  selector: 'widget-performance-details',
  standalone: true,
  imports: [CommonModule, JmTableModule, TranslateModule, BoxModule, EmptyBlockModule],
  templateUrl: './performance-details.component.html',
  styleUrls: ['./performance-details.component.scss'],
})
export class PerformanceDetailsComponent extends AbstractTableComponent<PerformanceDetails, PerformanceDetailsDTO> {
  @Input()
  set loader(loader: boolean) {
    this.loading = loader;
  }
  @Input()
  set tableData(data: Array<PerformanceDetails>) {
    this.data = data;
  }
  @Input()
  set currency(currency: string | null) {
    this.tableConfig.unit = currency;
  }
  @Input()
  error: string | null = null;

  override tableConfig: TableConfig = new TableConfig().loadModel(performanceDetailsTableConfig);

  constructor(override service: PerformanceDetailsService, injector: Injector) {
    super(service, injector);
  }
}
