import { Model } from 'ngx-super-model';

import { AvailabilityDTO } from '../interfaces';

export class Availability extends Model<AvailabilityDTO> {
  actualProductionTime: number | null = null;
  availability: number | null = null;
  plannedProductionTime: number | null = null;
  unplannedDowntime: number | null = null;
}
