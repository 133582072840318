import { ChartData } from 'chart.js';

import { CHART_COLORS } from 'src/app/layout/chart/chart.enum';
import { DowntimeByArticle } from '../models';
import { arrayPadEnd } from 'src/app/util/util';

export function dataToChartMapper(articles: Array<DowntimeByArticle>): ChartData {
  const maxLength = 10;
  const hours = articles.map((value) => value.hours());
  const labelsValues = articles.map((value) => value.articleName);

  const datasets = [
    {
      id: 'topTenDowntimeByArticle',
      backgroundColor: CHART_COLORS.blue,
      borderColor: CHART_COLORS.blue,
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: arrayPadEnd(hours, maxLength),
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      maxBarThickness: 40,
      myCustomData: {
        prettyDuration: articles.map((value) => value.secondsToHMS()),
      },
    },
  ];

  const labels = arrayPadEnd(labelsValues, maxLength);

  return {
    labels,
    datasets,
  };
}
