import { Pipe, PipeTransform } from '@angular/core';

import { isSet } from '../util';
import { DateTimeFormatStyle } from './format-style.enum';
import { browserLocaleFormat } from './helpers';

@Pipe({
  name: 'customDate',
  standalone: true,
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string | null | undefined, formatStyle?: DateTimeFormatStyle): string | null {
    if (!isSet(value)) {
      return '';
    }

    return browserLocaleFormat(value, formatStyle);
  }
}
