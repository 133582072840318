<div class="dialog-wrapper">
  <svg-icon class="close-icon" alt="close-icon" (click)="closeDialog()" [src]="'./assets/images/close.svg'"> </svg-icon>
  <h1 class="title">{{ 'sensor.export-dialog.title' | translate }}</h1>

  @if(form){
  <form [id]="'export-sensor-data-form'" class="quick-form form" [formGroup]="form">
    <quick-form-control [config]="getConfig('type')" [control]="form.get('type')"></quick-form-control>

    @if(form.get('type')?.value === periodTypeEnum.CUSTOM_RANGE){
    <div class="custom-range">
      <div class="form__fieldset form__input">
        <quick-form-p-datepicker
          [maxDate]="startMaxDate"
          [config]="getConfig('start')"
          [control]="getFormControl('start')"
          [formControl]="getFormControl('start')"
          (valueChanged)="onStartChanged($event)"
        ></quick-form-p-datepicker>
      </div>
      <div class="form__fieldset form__input">
        <quick-form-p-datepicker
          [minDate]="endMinDate"
          [config]="getConfig('end')"
          [control]="getFormControl('end')"
          [formControl]="getFormControl('end')"
          (valueChanged)="onEndChanged($event)"
        ></quick-form-p-datepicker>
      </div>
    </div>
    }

    <div class="form__fieldset form__input">
      <quick-form-multiselect
        [options]="sensorWidgetOptions"
        [loading]="sensorDropdownLoading"
        [config]="getConfig('sensorIds')"
        [control]="getFormControl('sensorIds')"
        [formControl]="getFormControl('sensorIds')"
      ></quick-form-multiselect>
    </div>

    <quick-form-control [config]="getConfig('filename')" [control]="form.get('filename')"></quick-form-control>
  </form>
  }

  <div class="buttons-wrapper">
    <jm-button [class]="'red-button'" (clicked)="export()">
      {{ 'actions.export' | translate }}
    </jm-button>
    <jm-button [class]="'red-border-button'" (clicked)="closeDialog()">
      {{ 'button.cancel' | translate }}
    </jm-button>
  </div>
</div>
