export enum OrderStatus {
  MUNET_CONNECT_QUEUED = 'MUNET_CONNECT_QUEUED',
  MACHINE_QUEUED = 'MACHINE_QUEUED',
  SENT = 'SENT',
  IN_PRODUCTION = 'IN_PRODUCTION',
  FINISHED = 'FINISHED',
  REMOVED = 'REMOVED',
  FAILED = 'FAILED',
  WAITING_FOR_DATA = 'WAITING_FOR_DATA',
}
