import { ChartData } from 'chart.js';

import { EnergyConsumedByArticleDTO } from '../interfaces';
import { CHART_COLORS } from 'src/app/layout/chart/chart.enum';

export function dataToChartMapper(machinesData: Array<EnergyConsumedByArticleDTO>): ChartData {
  const datasets = [
    {
      id: 'energyConsumedByArticles',
      backgroundColor: CHART_COLORS.blue,
      borderColor: CHART_COLORS.blue,
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: machinesData.map((value) => value.totalEnergyUsedKwh),
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      maxBarThickness: 40,
      myCustomData: {
        co2Equivalent: machinesData.map((value) => value.co2Equivalent),
      },
    },
  ];

  const labels = machinesData.map((value) => value.articleName);

  return {
    labels,
    datasets,
  };
}
