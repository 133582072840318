import { TableEnum } from 'src/app/modules/jm-table/enum/table.enum';
import { loadTableCellConfig } from 'src/app/modules/jm-table/helpers/table.helper';
import { TableConfigDTO } from 'src/app/modules/jm-table/interfaces/table-config.interface';

import { sustainabilityDetailsCellConfig } from './cells.config';

export const sustainabilityDetailsTableConfig: TableConfigDTO = {
  id: TableEnum.sustainabilityDetails,
  cellsConfig: loadTableCellConfig(sustainabilityDetailsCellConfig),
  footer: false,
  rowExpanded: true,
  mobileRowExpanded: true,
  dataKey: 'id',
  customSort: true,
  search: {
    show: true,
    placeholder: 'actions.enter-machine-name',
  },
  filtering: true,
  updateUrl: false,
  scrollHeight: '500px',
  autoLoad: false,
};
