<div class="quick-form-control">
  <div class="date-picker" [class.invalid]="control.invalid && control.touched">
    <label *ngIf="config.label" class="label">{{ config.label | translate }} <span *ngIf="config.validators?.required">*</span></label>

    <div class="input-wrapper">
      <svg-icon class="calendar-icon" [src]="'./assets/images/calendar.svg'"></svg-icon>

      <p-calendar
        #calendar
        [id]="config.getDisplayId()"
        [showTime]="showTime"
        [showSeconds]="showSeconds"
        [placeholder]="config.placeholder || config.label || '' | translate"
        [disabled]="control.disabled"
        [(ngModel)]="value"
        [inputStyleClass]="'input'"
        [ngModel]="value"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [appendTo]="appendTo"
        [readonlyInput]="true"
        (onInput)="onInputChanged()"
        (onSelect)="onValueSelected($event)"
        (onBlur)="onBlur()"
      >
      </p-calendar>
    </div>
  </div>

  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
