import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyBlockComponent } from './empty-block.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [EmptyBlockComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [EmptyBlockComponent],
})
export class EmptyBlockModule {}
