import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash-es';
import { StopReasonDTO } from 'src/app/core/machines/interfaces/stop-reasons.interface';
import { stopReasons } from 'src/app/util/stop-reasons.settings';

@Component({
  selector: 'app-configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.scss'],
})
export class ConfigurationDialogComponent implements OnInit {
  value: number | null = null;
  valueArray: Array<string> = [];
  checked: boolean;
  fieldName: string;
  stopReasons: Array<StopReasonDTO> = stopReasons;
  innerWidth = 0;
  type: 'number' | 'boolean' | 'string' | 'Date' | 'Object' | 'Array' | 'null' | 'undefined';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      value: number | Array<string>;
      fieldName: string;
      checked: boolean;
      type: 'number' | 'boolean' | 'string' | 'Date' | 'Object' | 'Array' | 'null' | 'undefined';
    },
    private readonly dialogRef: MatDialogRef<ConfigurationDialogComponent>,
    private readonly dialog: MatDialog
  ) {
    if (data?.type === 'number') {
      this.value = data.value as number;
    } else if (data.type === 'Array') {
      this.valueArray = cloneDeep(data.value as Array<string>);
    }
    this.checked = data.checked;
    this.type = data.type;
    this.fieldName = data.fieldName;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  setValue(value: number | null | undefined): void {
    if (!value) {
      return;
    }

    this.value = value;
  }

  toggleAlertConfiguration(event: boolean): void {
    this.checked = event;
  }

  onConfirmClick(): void {
    if (!this.value) {
      return;
    }

    if (this.type === 'number') {
      this.dialogRef.close({ value: Number(this.value), checked: this.checked, changeData: true });
    } else if (this.type === 'Array') {
      this.dialogRef.close({ value: this.valueArray, checked: this.checked, changeData: true });
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  changeCheckboxValue(
    event: MatCheckboxChange,
    reason: {
      value: string;
      label: string;
    }
  ): void {
    if (event.checked) {
      this.valueArray.push(reason.value);
    } else {
      this.valueArray.splice(this.valueArray.indexOf(reason.value), 1);
    }
  }
}
