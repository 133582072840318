import { TourService } from 'ngx-ui-tour-md-menu';
import { UserGroupEnum } from 'src/app/enum';
import { NavBarService } from 'src/app/services/navbar.service';
import { checkFilterStatus, isSet } from 'src/app/util/util';

import { Component, inject, OnInit } from '@angular/core';
import { selectUserGroup } from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { tutorialGuide } from './tutorial.guide';
import { sections, sectionSteps } from './tutorial.setting';

@UntilDestroy()
@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
})
export class TutorialComponent implements OnInit {
  private readonly tourService = inject(TourService);
  private readonly store$ = inject(Store);
  private readonly navBarService = inject(NavBarService);

  sections: Array<string> = sections;
  filters: Array<string> = ['all'].concat(sections);
  activeFilters: Array<string> = ['all'];
  userGroup!: UserGroupEnum;

  readonly sectionSteps = sectionSteps;

  ngOnInit(): void {
    this.store$.pipe(select(selectUserGroup), untilDestroyed(this)).subscribe((userGroup) => {
      if (!isSet(userGroup)) {
        return;
      }

      this.userGroup = userGroup;
    });
  }

  startTour(section: string, step: string): void {
    this.tourService.end();

    if (tutorialGuide[section][step].steps) {
      this.tourService.initialize(tutorialGuide[section][step].steps);

      if (window.innerWidth < 900) {
        this.navBarService.openHideLeftSideMenu(true);
      }
      this.tourService.start();
    }
  }

  addOrRemoveActiveFilter(filter: string): void {
    this.activeFilters = checkFilterStatus(this.activeFilters, 'all', filter);
  }

  isSectionPartOfSectionViewAndUserPermission(section: string): boolean {
    const sectionStepsFiltered = this.sectionSteps?.[section].filter((value) => value.permission.includes(this.userGroup));
    return Boolean(this.sectionSteps?.[section]) && sectionStepsFiltered.length > 0;
  }

  doesUserHaveAnyPermissionOnSteps(): boolean {
    let outs = false;
    Object.keys(this.sectionSteps).map((sectionStep) => {
      if (isSet(this.sectionSteps?.[sectionStep])) {
        const subSectionSteps = this.sectionSteps?.[sectionStep];
        outs = outs || subSectionSteps.filter((value) => value.permission.includes(this.userGroup)).length > 0;
      }
    });
    return outs;
  }
}
