import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { AbstractTableComponent } from 'src/app/modules/jm-table/components/abstract-table/abstract-table.component';
import { JmTableModule } from 'src/app/modules/jm-table/jm-table.module';
import { TableConfig } from 'src/app/modules/jm-table/models/table.config';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { RoundNumberPipe } from 'src/app/shared/pipes/round-number.pipe';

import { CommonModule } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SustainabilityDetailsDTO } from './interfaces';
import { SustainabilityDetails } from './models/sustainability-details.model';
import { sustainabilityDetailsTableConfig } from './settings/table.config';
import { SustainabilityDetailsService } from './sustainability-details.service';

@Component({
  selector: 'widget-sustainability-details',
  standalone: true,
  imports: [CommonModule, JmTableModule, TranslateModule, BoxModule, RoundNumberPipe, EmptyBlockModule],
  templateUrl: './sustainability-details.component.html',
  styleUrls: ['./sustainability-details.component.scss'],
})
export class SustainabilityDetailsComponent extends AbstractTableComponent<SustainabilityDetails, SustainabilityDetailsDTO> {
  @Input() loader = false;
  @Input()
  set tableData(data: Array<SustainabilityDetails>) {
    this.data = data;
  }
  @Input()
  error: string | null = null;

  override readonly tableConfig: TableConfig = new TableConfig().loadModel(sustainabilityDetailsTableConfig);

  constructor(override service: SustainabilityDetailsService, injector: Injector) {
    super(service, injector);
  }
}
