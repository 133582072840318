import { ObjectLiteral } from 'src/app/util/object-literal';

import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ControlConfig, ControlConfigDTO, createControls, loadFormConfig } from '@quick-form';
import { isSet } from '@util';

import { PrimeNGFilters } from '../../types';
import { FilterMetadata } from 'primeng/api';

@Component({
  selector: 'jm-global-filter',
  templateUrl: './jm-global-filter.component.html',
  styleUrls: ['./jm-global-filter.component.scss'],
})
export class JmGlobalFilterComponent implements OnInit {
  private readonly fb = inject(FormBuilder);

  @Input()
  controls: Array<ControlConfigDTO> = [];

  @Input()
  filters: PrimeNGFilters = {};

  @Output()
  valueChanged = new EventEmitter<ObjectLiteral>();

  @Output()
  cancel = new EventEmitter<void>();

  formConfig: Array<ControlConfig> = [];
  form: FormGroup | null = null;

  ngOnInit(): void {
    const mappedFilters = this.mapTableFiltersToObject();
    this.initForm(mappedFilters);
  }

  apply(): void {
    if (!isSet(this.form)) {
      return;
    }
    this.valueChanged.emit(this.form.value);
  }

  private initForm(model: ObjectLiteral) {
    this.formConfig = loadFormConfig(this.controls, model);

    this.form = this.fb.group(createControls(this.formConfig));
  }

  private mapTableFiltersToObject(): ObjectLiteral {
    const filters: ObjectLiteral = {};

    Object.keys(this.filters).forEach((filter) => {
      filters[filter] = (this.filters[filter] as Array<FilterMetadata>)[0]?.value;
    });

    return filters;
  }
}
