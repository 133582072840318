import { AngularSvgIconModule } from 'angular-svg-icon';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DeviceService } from 'src/app/modules/device/device.service';
import { TimeAgoPipe } from 'src/app/shared/pipes/time-ago.pipe';
import { SearchCriteria } from 'src/app/util/search/search-criteria';
import { initialSortBy } from 'src/app/util/search/sort-by.interface';

import { AlertDTO, AlertService, AlertSeverity, AlertsResponseDTO } from '@alerts';
import { ScrollDispatcher } from '@angular/cdk/overlay';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { PageDetails } from '@jm-table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { isSet } from '@util';
import { LoaderComponent } from '@app-ui';

const imports = [
  AngularSvgIconModule,
  AsyncPipe,
  LoaderComponent,
  MatMenuModule,
  NgClass,
  NgTemplateOutlet,
  RouterModule,
  ScrollingModule,
  TimeAgoPipe,
  TranslateModule,
];

@UntilDestroy()
@Component({
  selector: 'app-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports,
})
export class AlertsListComponent implements OnInit, OnDestroy {
  private readonly deviceService = inject(DeviceService);
  private readonly alertService = inject(AlertService);
  private readonly scrollDispatcher = inject(ScrollDispatcher);
  private readonly router = inject(Router);

  @ViewChild(CdkVirtualScrollViewport) virtualScroll?: CdkVirtualScrollViewport;
  @ViewChild('alertMenuTrigger') alertMenuTrigger!: MatMenuTrigger;

  alerts = signal<Array<AlertDTO>>([]);
  pageDetails = signal<PageDetails | null>(null);
  unreadCount = signal<number>(0);
  loader = signal<boolean>(true);

  showHideMoreOptions = false;

  private isMobile = false;
  private searchCriteria: SearchCriteria = {
    sortBy: initialSortBy,
    pagination: {
      limit: 20,
      offset: 0,
    },
  };

  private readonly unreadAlertsIds: Array<number> = [];
  private readonly subscription = new Subscription();

  ngOnInit() {
    this.loadAlerts();
    this.subscribeToUserAlerts();
    this.listenScrollDispatcher();

    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.alertService
      .unreadCount()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.unreadCount.set(value);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getAlertIcon(alert: AlertDTO): string {
    switch (alert.severity) {
      case AlertSeverity.SUCCESS:
        return 'alert-success';
      case AlertSeverity.WARNING:
        return 'alert-warning';
      case AlertSeverity.ERROR:
        return 'alert-error';
    }
  }

  onScroll(): void {
    setTimeout(() => {
      this.checkUserAlerts();
    }, 1000);
  }

  showOrHideMoreOptions(): void {
    this.showHideMoreOptions = !this.showHideMoreOptions;
  }

  markAllAsRead(): void {
    this.alertService
      .markAllAlertsAsRead()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        const { read } = response;
        if (read) {
          this.alerts.update((alerts) =>
            alerts.map((alert) => {
              return {
                ...alert,
                readAt: new Date().toDateString(),
              };
            })
          );
          this.alertService.setUnreadCount(0);
        }
      });
  }

  goToNotificationDetails(id: number | null): void {
    this.router.navigateByUrl(`notifications/${id}`);
  }

  navigateToNotificationList(): void {
    this.router.navigateByUrl('notifications');
  }

  private loadAlerts(): void {
    this.alertService
      .getAlerts(this.searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this.loader.set(false);
          this.pageDetails.set(data.alerts.pageDetails);
          this.alerts.set(data.alerts.records);
          this.alertService.setUnreadCount(data.alertsUnreadCount);
          this.checkUserAlerts();
        },
        error: () => this.loader.set(false),
      });
  }

  private subscribeToUserAlerts(): void {
    this.alertService
      .alertsSubscription()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (alert) => this.alerts.update((alerts) => [alert, ...alerts]),
      });
  }

  private listenScrollDispatcher(): void {
    if (this.pageDetails()?.isLast === true) {
      return;
    }

    const subscription = this.scrollDispatcher
      .scrolled()
      .pipe(
        filter((): boolean => {
          if (!this.isMobile) {
            return this.virtualScroll?.measureScrollOffset('bottom') === 0;
          }
          const measureBottom = this.virtualScroll?.measureScrollOffset('bottom');
          return measureBottom != null && measureBottom >= 0 && measureBottom <= 250;
        }),
        switchMap(() => {
          this.searchCriteria = {
            ...this.searchCriteria,
            pagination: {
              ...this.searchCriteria.pagination,
              offset: (this.searchCriteria.pagination?.offset || 0) + 20,
            },
          };

          return this.alertService.getAlerts(this.searchCriteria);
        })
      )
      .subscribe((response: AlertsResponseDTO) => {
        this.checkUserAlerts();
        this.alertService.setUnreadCount(response.alertsUnreadCount);
        this.pageDetails.set(response.alerts.pageDetails);
        this.alerts.update((alerts) => {
          return [...alerts, ...response.alerts.records];
        });
      });

    this.subscription.add(subscription);
  }

  private checkUserAlerts(): void {
    const userAlerts: Array<AlertDTO> = this.alerts()?.filter((value) => !isSet(value.readAt));
    userAlerts.map((value) => {
      const { id } = value;
      if (isSet(id) && this.elementInViewport(id)) {
        if (!this.unreadAlertsIds.includes(id)) {
          this.unreadAlertsIds.push(id);
          this.readAlert(value);
        }
      }
    });
  }

  private elementInViewport(alertId: number): boolean {
    const el = document.getElementById(alertId.toString());
    const virtualScrollViewport = document.getElementById('virtualScrollViewport');
    if (el && virtualScrollViewport) {
      const top: number = el.offsetTop;
      const virtualScrollViewportHeight: number = virtualScrollViewport.offsetHeight;
      const virtualScrollTop: number = virtualScrollViewport.scrollTop;

      return top - (virtualScrollViewportHeight - 20) < virtualScrollTop;
    }

    return false;
  }

  private readAlert(alert: AlertDTO): void {
    if (!isSet(alert?.readAt) && isSet(alert.id)) {
      this.alertService
        .markAlertAsRead(alert.id)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            const unreadAlertIndex = this.unreadAlertsIds.findIndex((id) => id === response[0].id);
            this.unreadAlertsIds.splice(unreadAlertIndex, 1);

            this.alertService.setUnreadCount(this.unreadCount() - 1);
            this.alerts.update((values) => {
              return values.map((alert) => {
                if (alert.id === response[0].id) {
                  return {
                    ...alert,
                    readAt: new Date().toDateString(),
                  };
                }

                return alert;
              });
            });
          },
        });
    }
  }
}
