import { GridsterModule } from 'angular-gridster2';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CalendarModule } from 'primeng/calendar';
import { HeaderBarComponent } from 'src/app/layout/header-bar/header-bar.component';
import { LocalStorageService } from 'src/app/local-storage.service';
import { NoticeComponent } from 'src/app/shared/components/notice/notice.component';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { StickyHolderModule } from 'src/app/shared/modules/sticky-holder/sticky-holder.module';
import { CustomDatePipe } from 'src/app/util/datetime/custom-date.pipe';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { LoaderComponent } from '@app-ui';
import { LegacyComponentsModule } from '@legacy-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { QuickFormsModule } from '@quick-form';

import { WidgetModule } from '../widgets/widget.module';
import { getDashboardModuleStorageConfig } from './dashboard-storage-config';
import { DASHBOARD_CONFIG_TOKEN, DASHBOARD_LOCAL_STORAGE_KEY, DASHBOARD_STORAGE_KEYS } from './dashboard.token';
import { DashboardEffects } from './store/dashboard.effect';
import { dashboardStateReducer } from './store/dashboard.reducer';
import { widgetsDashboardStateName } from './store/dashboard.state';
import { AddWidgetComponent } from './ui/add-widget/add-widget.component';
import { DashboardEditComponent } from './ui/dashboard-edit/dashboard-edit.component';
import { DashboardShareComponent } from './ui/dashboard-share/dashboard-share.component';
import { DashboardViewComponent } from './ui/dashboard-view/dashboard-view.component';
import { PeriodPickerComponent } from './ui/period-picker/period-picker.component';

const dashboardStorageKeys: Array<string> = ['filters'];

@NgModule({
  declarations: [DashboardViewComponent, DashboardEditComponent, AddWidgetComponent, PeriodPickerComponent, DashboardShareComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetModule,
    GridsterModule,
    StickyHolderModule,
    HeaderBarComponent,
    TranslateModule,
    MatMenuModule,
    AngularSvgIconModule,
    LegacyComponentsModule,
    QuickFormsModule,
    StoreModule.forFeature(widgetsDashboardStateName, dashboardStateReducer as any, DASHBOARD_CONFIG_TOKEN),
    EffectsModule.forFeature([DashboardEffects]),
    LoaderComponent,
    CalendarModule,
    CustomDatePipe,
    EmptyBlockModule,
    NoticeComponent,
  ],
  exports: [DashboardViewComponent],
  providers: [
    {
      provide: DASHBOARD_LOCAL_STORAGE_KEY,
      useValue: 'custom_dashboard',
    },
    { provide: DASHBOARD_STORAGE_KEYS, useValue: dashboardStorageKeys },
    {
      provide: DASHBOARD_CONFIG_TOKEN,
      deps: [DASHBOARD_STORAGE_KEYS, DASHBOARD_LOCAL_STORAGE_KEY, LocalStorageService],
      useFactory: getDashboardModuleStorageConfig,
    },
  ],
})
export class DashboardModule {}
