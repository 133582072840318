import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeviceTypeEnum } from './types/device-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly deviceTypeSub$ = new BehaviorSubject<DeviceTypeEnum | null>(null);
  private readonly deviceType$ = this.deviceTypeSub$.asObservable();

  device(): Observable<DeviceTypeEnum> {
    return this.deviceType$.pipe(filter((device) => device !== null)) as Observable<DeviceTypeEnum>;
  }

  isMobile(): Observable<boolean> {
    return this.device().pipe(map((device) => device === DeviceTypeEnum.Mobile));
  }

  isTablet(): Observable<boolean> {
    return this.device().pipe(map((device) => device === DeviceTypeEnum.Tablet));
  }

  isDesktop(): Observable<boolean> {
    return this.device().pipe(map((device) => device === DeviceTypeEnum.Desktop));
  }

  isDesktopLarge(): Observable<boolean> {
    return this.device().pipe(map((device) => device === DeviceTypeEnum.DesktopLarge));
  }

  updateDeviceInfo(width: number | any): void {
    const deviceType = this.resolveDeviceType(width);
    this.deviceTypeSub$.next(deviceType);
  }

  private resolveDeviceType(width: number) {
    if (width >= 1366) {
      return DeviceTypeEnum.DesktopLarge;
    }
    if (width >= 1024) {
      return DeviceTypeEnum.Desktop;
    }

    if (width >= 768) {
      return DeviceTypeEnum.Tablet;
    }

    return DeviceTypeEnum.Mobile;
  }
}
