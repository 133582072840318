import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { CalendarModule } from 'primeng/calendar';
import { FileSizePipe } from 'src/app/util/pipes/filesize.pipe';
import { ParamsTranslatePipe } from 'src/app/util/pipes/params-translate.pipe';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { JmButtonComponent } from '@app-ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AttributesDirective } from './directives/attributes.directive';
import { CheckboxComponent } from './ui/controls/checkbox/checkbox.component';
import { ColorPickerComponent } from './ui/controls/color-picker/color-picker.component';
import { ControlComponent } from './ui/controls/control/control.component';
import { ControlsComponent } from './ui/controls/controls.component';
import { PDatepickerComponent } from './ui/controls/date-picker/datepicker.component';
import { DropdownComponent } from './ui/controls/dropdown/dropdown.component';
import { QuickGooglePlacesComponent } from './ui/controls/google-places/google-places.component';
import { InputNumberComponent } from './ui/controls/input-number/input-number.component';
import { InputComponent } from './ui/controls/input-string/input.component';
import { MultiselectComponent } from './ui/controls/multiselect/multiselect.component';
import { PatternFileComponent } from './ui/controls/pattern-file/pattern-file.component';
import { RadioButtonComponent } from './ui/controls/radio-button/radio-button.component';
import { ReadOnlyCheckboxComponent } from './ui/controls/readonly-checkbox/readonly-checkbox.component';
import { ReadonlyComponent } from './ui/controls/readonly/readonly.component';
import { RepeaterComponent } from './ui/controls/repeater/repeater.component';
import { TextareaComponent } from './ui/controls/textarea/textarea.component';
import { UploadMultipartComponent } from './ui/controls/upload-multipart/upload-multipart.component';
import { UploadComponent } from './ui/controls/upload/upload.component';
import { QuickFormComponent } from './ui/quick-form/quick-form.component';
import { ValidationMessageComponent } from './ui/validation-message/validation-message.component';
import { NgxColorsModule } from 'ngx-colors';
import { SlideToggleComponent } from './ui/controls/slide-toggle/slide-toggle.component';
import { MaterialSharedModule } from 'src/app/shared/material-shared.module';
import { I18nModule } from 'src/app/shared/i18n.module';

const COMPONENTS = [
  ValidationMessageComponent,
  ControlComponent,
  DropdownComponent,
  InputComponent,
  RadioButtonComponent,
  CheckboxComponent,
  TextareaComponent,
  InputNumberComponent,
  ControlsComponent,
  QuickFormComponent,
  QuickGooglePlacesComponent,
  RepeaterComponent,
  ReadonlyComponent,
  UploadComponent,
  PDatepickerComponent,
  ReadOnlyCheckboxComponent,
  MultiselectComponent,
  PatternFileComponent,
  UploadMultipartComponent,
  ColorPickerComponent,
  SlideToggleComponent,
];

@NgModule({
  declarations: [AttributesDirective, ...COMPONENTS],
  imports: [
    CommonModule,
    JmButtonComponent,
    MaterialSharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    AngularSvgIconModule,
    TourMatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    FileSizePipe,
    CalendarModule,
    NgxMaskModule.forRoot(),
    ParamsTranslatePipe,
    NgxColorsModule,
    I18nModule,
  ],
  exports: [AttributesDirective, ...COMPONENTS],
})
export class QuickFormsModule {}
