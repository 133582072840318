import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { ResponseData } from '../../abstract/interface/response-data';
import { SingleMachineRequest } from '../core/interfaces/chart-request.interface';
import { AverageSpeedDTO } from './interfaces/average-speed.interface';

@Injectable({
  providedIn: 'root',
})
export class AverageSpeedService {
  private readonly apollo = inject(Apollo);
  private readonly snackbarService = inject(SnackBarService);

  getData(request: SingleMachineRequest): Observable<AverageSpeedDTO> {
    const query = gql`
      query AverageSpeedChart($input: SingleMachineChartInput!) {
        averageSpeedChart(input: $input) {
          buckets {
            bucket
            value
            offline
          }
          average
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          input: request,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackbarService.error('widgets.error.average-speed');
        }),
        map((response: ApolloQueryResult<ResponseData<AverageSpeedDTO>>) => {
          return response.data.averageSpeedChart;
        })
      );
  }
}
