<div class="sb-radio-button-wrapper">
  <mat-radio-group [(ngModel)]="value">
    <mat-radio-button
      *ngFor="let option of config.meta?.options"
      [id]="config.getDisplayId() + +option.id"
      [value]="option.id"
      [name]="config.getDisplayId()"
      [disabled]="disabled"
    >
      {{ option.name | translate }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
