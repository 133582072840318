import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sb-filters',
  templateUrl: './sb-filters.component.html',
  styleUrls: ['./sb-filters.component.scss'],
})
export class SbFiltersComponent {
  @Input() filters: Array<string> = [];
  @Input() activeFilters: Array<string> = [];
  @Input() className = '';

  @Output() addOrRemoveActiveFilter: EventEmitter<string> = new EventEmitter<string>();
}
