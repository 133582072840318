import { permissions } from '@auth';
import { IAppLinksDTO } from './navbar.model';

export const navBarLinks: Array<IAppLinksDTO> = [
  {
    label: 'home',
    path: '',
    disabled: false,
    show: true,
    icon: 'nav-home',
    environments: ['development', 'staging', 'production'],
  },
  {
    label: 'dashboard',
    path: 'dashboard',
    disabled: false,
    show: false,
    icon: 'nav-dashboard',
    permissions: permissions.home.dashboards,
    anchorId: 'dashboard-link',
    environments: ['development', 'staging', 'production'],
    subMenu: 'dashboard',
  },
  {
    label: 'library',
    path: 'library',
    disabled: false,
    show: false,
    icon: 'nav-library',
    permissions: permissions.home.library,
    anchorId: 'library-link',
    environments: ['development', 'staging', 'production'],
  },
  {
    label: 'analytics',
    path: 'analytics',
    disabled: false,
    show: false,
    icon: 'nav-analytics',
    permissions: permissions.home.analytics,
    anchorId: 'analytics-link',
    environments: ['development', 'staging', 'production'],
  },
  {
    label: 'admin',
    path: 'admin',
    disabled: false,
    show: false,
    icon: 'nav-admin',
    permissions: permissions.home.admin,
    anchorId: 'admin-link',
    environments: ['development', 'staging', 'production'],
  },
];
