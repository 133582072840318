import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeContext, Options } from 'ngx-slider-v2';

import { PerformanceThresholdsInfos } from 'src/app/main/library/models';
import { isNumber } from 'lodash-es';
@Component({
  selector: 'app-sb-performance-threshold',
  templateUrl: './sb-performance-threshold.component.html',
  styleUrls: ['./sb-performance-threshold.component.scss'],
})
export class SBPerformanceThresholdComponent {
  @Input() performanceTreshold: PerformanceThresholdsInfos | null = null;
  @Input() title = '';
  @Input() type = '';
  @Input() measureUnit = '';
  @Input() value = 0;
  @Input() highValue = 0;
  @Input() showValueSign: boolean | undefined = false;
  @Input() valueSign: string | undefined = '';
  @Input() showHighValueSign: boolean | undefined = false;
  @Input() highValueSign: string | undefined = '';
  @Input() invertedValues: boolean | undefined = false;
  @Input()
  set disabled(flag: boolean) {
    this.options.disabled = flag;
  }
  @Input() set ceil(data: number) {
    if (data) {
      const opts = {
        floor: 0,
        ceil: data,
        showOuterSelectionBars: true,
        animate: false,
        noSwitching: true,
      };
      this.options = opts;
    }
  }

  @Output() userChangeEnd: EventEmitter<ChangeContext> = new EventEmitter<ChangeContext>();

  options: Options = {
    floor: 0,
    ceil: 100,
    showOuterSelectionBars: true,
    animate: false,
    noSwitching: true,
  };

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.value = changeContext.value;

    if (changeContext.highValue) {
      this.highValue = changeContext.highValue;
    }

    this.userChangeEnd.emit(changeContext);
  }

  changeValue(value: number, params: string): void {
    if (params === 'highValue') {
      if (!value || value === this.value) {
        this.highValue = Number(this.value);
      }
    }
    if (params === 'value') {
      if ((!value || value === this.highValue) && isNumber(this.highValue)) {
        this.value = Number(this.highValue);
      }
    }
  }
}
