import { TourService } from 'ngx-ui-tour-md-menu';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ControlConfig } from '../../models/control.config';

@UntilDestroy()
@Component({
  selector: 'quick-form-controls',
  templateUrl: './controls.component.html',
})
export class ControlsComponent implements OnInit {
  @Input() config: Array<ControlConfig> = [];
  @Input() form!: any;
  @Output() openInfoWindow: EventEmitter<void> = new EventEmitter<void>();

  private isTutorialGuideActive = false;
  private stepId: string | undefined = '';
  private controlUpdatedId = '';

  trackById(index: number, control: any) {
    return control.id;
  }

  constructor(private readonly tourService: TourService) {}

  ngOnInit() {
    this.tourService.stepShow$.pipe(untilDestroyed(this)).subscribe((stepChangeParams) => {
      const step = stepChangeParams.step;
      this.isTutorialGuideActive = Boolean(step);
      this.stepId = step.stepId;
    });
  }

  onAfterValueChanged(value: any, controlConfig: ControlConfig, _index: number) {
    if (!this.isTutorialGuideActive) {
      return;
    }

    if (controlConfig.id === this.controlUpdatedId) {
      return;
    }

    this.controlUpdatedId = controlConfig.id;

    const nextStep = controlConfig.tour?.nextStep;
    if (nextStep) {
      this.tourService.startAt(nextStep);
      return;
    }

    if (!this.stepId) {
      return;
    }

    let nextStepId = Number(this.stepId) as number;
    nextStepId++;
    this.tourService.startAt(nextStepId.toString());
  }

  onOpenInfoWindow(): void {
    this.openInfoWindow.emit();
  }
}
