import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { PayloadAction } from 'src/app/shared/util';

export const SHOW_NOTIFICATION = '@NOTIFICATION';
export const SHOW_ERROR_NOTIFICATION = '@NOTIFICATION_ERROR';

export class ShowNotification implements PayloadAction {
  readonly type = SHOW_NOTIFICATION;

  constructor(
    public payload: {
      message: string;
      action?: string;
      config?: MatSnackBarConfig;
    }
  ) {}
}

export class ShowErrorNotification implements PayloadAction {
  readonly type = SHOW_ERROR_NOTIFICATION;
  constructor(public payload: { message: string }) {}
}

export type NotificationAction = ShowNotification | ShowErrorNotification;
