import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioButtonComponent),
    },
  ],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  disabled = false;
  value = '';
  options = [];

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable?(disabled: boolean): void {
    this.disabled = disabled;
  }

  valueChanged(event: any) {
    this.value = event.value;
    this.propagateChange(event.value);
  }

  onBlur() {
    this.propagateTouched();
  }

  /* eslint-disable-next-line */
  private propagateChange = (value: any) => {};

  /* eslint-disable-next-line */
  private propagateTouched = () => {};
}
