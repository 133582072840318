import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { FetchResult } from '@apollo/client';

import sensorReadingUpdated from '../gql/sensor-reading-updated.graphql';

@Injectable({ providedIn: 'root' })
export class SensorReadingSubscriptionService {
  private readonly apollo = inject(Apollo);

  watchSensorChanges(guid: string) {
    return this.apollo
      .subscribe<any>({
        query: sensorReadingUpdated,
        variables: {
          guid,
        },
      })
      .pipe(
        map((response: FetchResult<any>) => {
          return response.data.sensorReadingUpdated;
        })
      );
  }
}
