import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { DownTimeByStopReasonDTO } from './interfaces';
import { DownTimeByStopReason } from './models';

@Injectable({
  providedIn: 'root',
})
export class DownTimeByStopReasonService extends AbstractCRUDService<DownTimeByStopReason, DownTimeByStopReasonDTO> {
  override model = DownTimeByStopReason;

  getData(input: PerformanceInput): Observable<Array<DownTimeByStopReason>> {
    const query = gql`
      query downtimeByStopStatus($input: OEEInput!) {
        downtimeByStopStatus(input: $input) {
          stopStatus
          duration
        }
      }
    `;

    return this.apollo
      .query<any>({ query, variables: { input } })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<DownTimeByStopReasonDTO>>>) =>
          response.data.downtimeByStopStatus.map((item: DownTimeByStopReasonDTO) => this.loadModel(item))
        )
      );
  }
}
