import { cloneDeep } from 'lodash-es';
import { FilterMetadata } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { filter, fromEvent } from 'rxjs';
import { DeviceService } from 'src/app/modules/device/device.service';
import { ScrollPositionService } from 'src/app/services/scroll-position.service';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { Pagination } from 'src/app/util/search/pagination.interface';
import { SortBy } from 'src/app/util/search/sort-by.interface';
import { SortOrder } from 'src/app/util/search/sort-order.enum';

import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isSet } from '@util';

import { areFiltersActiveInTable, extractGlobalFilterValues } from '../../helpers/table.helper';
import { CustomLazyLoadEvent } from '../../interfaces/lazy-load-event';
import { TableMoreOptions } from '../../interfaces/table-more-options.interface';
import { CellConfig } from '../../models/cells.config';
import { TableConfig } from '../../models/table.config';
import { PrimeNGFilters } from '../../types/filters.type';

@UntilDestroy()
@Component({
  selector: 'jm-table',
  templateUrl: './jm-table.component.html',
})
export class JmTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  private readonly deviceService = inject(DeviceService);
  private readonly router = inject(Router);
  private readonly scrollPositionService = inject(ScrollPositionService);

  @Input() loading = false;
  @Input()
  set data(data: Array<ObjectLiteral>) {
    this._data = data;
    if (data.length > 0) {
      this.restoreScrollPosition();
    }
  }
  get data(): Array<ObjectLiteral> {
    return this._data;
  }

  private _data: Array<ObjectLiteral> = [];
  @Input()
  set tableConfig(config: TableConfig) {
    this._tableConfig = config;
    this.updateCellConfig();
  }
  get tableConfig(): TableConfig {
    return this._tableConfig;
  }
  private _tableConfig: TableConfig = new TableConfig();

  @Input()
  rowExpandTemplate?: TemplateRef<any>;

  @Input()
  matMenuTemplate?: TemplateRef<any>;

  @Input()
  set sortBy(data: SortBy | undefined) {
    this.selectedSortField = data?.columnName;
    this.sortOrder = data?.direction === SortOrder.ASC ? 1 : -1;
  }

  @Input()
  set pagination(pagination: Pagination) {
    this.rows = pagination.limit || 20;
    this.first = this.pagination.offset || 0;
  }

  @Input()
  set tableSearchConfig(config: CustomLazyLoadEvent) {
    if (config.filters) {
      this.filters = config.filters;
    }

    if (config.rows) {
      this.rows = config.rows;
    }

    if (config.first) {
      this.first = config.first;
    }

    if (config.sortField) {
      this.selectedSortField = config.sortField;
    }

    if (config.sortOrder) {
      this.sortOrder = config.sortOrder;
    }

    if (config.globalFilter) {
      this.globalSearch = config.globalFilter;
    }
  }

  @Output() lazyLoadChanged = new EventEmitter<TableLazyLoadEvent>();
  @Output() loadMore = new EventEmitter<TableLazyLoadEvent>();
  @Output() optionsSelected: EventEmitter<{ option: TableMoreOptions; data: any }> = new EventEmitter<{
    option: TableMoreOptions;
    data: any;
  }>();
  @Output() rowNavigation: EventEmitter<any> = new EventEmitter<any>();
  @Output() recordMoreOptions: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementInViewPort = new EventEmitter<{ dataKey: string; index: number }>();
  @Output() resetAllFilters = new EventEmitter<void>();
  @Output() changeColumnSettings = new EventEmitter<Array<CellConfig>>();

  @ViewChild('dt') table: Table = {} as Table;
  @ViewChild(MatMenuTrigger) columnSettingsTrigger!: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) globalFilterTrigger!: MatMenuTrigger;

  cellsConfig: Array<CellConfig> = [];
  expandedCellsConfig: Array<CellConfig> = [];
  filters: PrimeNGFilters = {};

  globalSearch: string | null = null;
  selectedSortField?: string;
  sortOrder = -1;

  first = 0;
  rows = 20;

  private isMobile = false;

  get selectionMode(): 'single' | 'multiple' | null {
    if (this.tableConfig.rowNavigationAvailable) {
      return 'single';
    }

    return null;
  }

  get colspan(): number {
    if (!this.isMobile) {
      return this.tableConfig.cellsConfig.length + 1;
    }

    return this.tableConfig.cellsConfig.length;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (isSet(this.tableConfig.scrollHeight)) {
      return;
    }

    if (this.tableConfig.checkElementViewport) {
      this.data.forEach((value, index) => {
        const dataKey = value[this.tableConfig.dataKey];
        if (this.checkIsElementInViewport(dataKey)) {
          this.elementInViewPort.emit({ dataKey, index });
        }
      });
    }

    const scrollOffset = 5;
    const scrollHeight = document.body.scrollHeight - scrollOffset;
    const scrolled = window.innerHeight + window.scrollY;

    if (scrolled >= scrollHeight && !this.loading) {
      this.table.first = (this.table.first ?? 0) + (this.table.rows ?? 0);
      const metadata: CustomLazyLoadEvent = this.table.createLazyLoadMetadata();
      this.loadMore.emit(metadata);
    }
  }

  ngOnInit(): void {
    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe((response) => (this.isMobile = response));
  }

  ngAfterViewInit(): void {
    const event = this.table.createLazyLoadMetadata();
    this.lazyLoadChanged.emit(event);

    if (this.tableConfig.loadMoreOnScrollHeight) {
      this.listenTableScroll();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isSet(Boolean(changes['tableConfig']?.currentValue) && isSet(changes['tableConfig']?.previousValue))) {
      this.updateCellConfig();
    }
  }

  ngOnDestroy(): void {
    this.scrollPositionService.setScrollPosition(this.tableConfig.id, window.scrollY);
  }

  getConditionalClasses(rowData: ObjectLiteral): { [className: string]: boolean } | null {
    if (!this.tableConfig?.conditionalClasses) {
      return null;
    }

    return Object.keys(this.tableConfig.conditionalClasses).reduce((accumulator: { [className: string]: boolean }, key) => {
      if (this.tableConfig?.conditionalClasses) {
        accumulator[key] = this.tableConfig.conditionalClasses[key](rowData);
      }
      return accumulator;
    }, {});
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    const { sortField, sortOrder, rows, first, filters } = event;
    if (isSet(sortField) && typeof sortField === 'string') {
      this.selectedSortField = sortField;
      this.sortOrder = sortOrder ?? -1;
    }

    if (isSet(rows)) {
      this.rows = rows;
    }

    if (isSet(first)) {
      this.first = first;
    }

    if (isSet(filters?.global)) {
      this.globalSearch = (filters.global as FilterMetadata).value;
    }

    this.lazyLoadChanged.emit(event);
  }

  onItemClick(rowData: ObjectLiteral): void {
    if (this.isMobile) {
      const expanded = Boolean(this.table.expandedRowKeys[rowData[this.tableConfig.dataKey]]);
      this.table.expandedRowKeys[rowData[this.tableConfig.dataKey]] = !expanded;
      return;
    }

    if (this.tableConfig.rowNavigationAvailable) {
      this.rowNavigation.emit(rowData);
    }
  }

  onGlobalSearchChanged(query: string): void {
    this.first = 0;
    this.table.filterGlobal(query, 'contains');
  }

  onResetAllFilters(): void {
    this.globalSearch = null;

    const globalFilters = extractGlobalFilterValues(this.tableConfig.globalFilter);

    if (isSet(globalFilters)) {
      Object.keys(globalFilters).forEach((filter) => {
        this.table.filter(globalFilters[filter], filter, 'startsWith');
      });
    }

    this.table.clear();
  }

  areFiltersActiveInTable(): boolean {
    if (!isSet(this.table.filters)) {
      return false;
    }

    return areFiltersActiveInTable(this.table.filters);
  }

  onRecordMoreOptions(event: any) {
    this.recordMoreOptions.emit(event);
  }

  onApplyColumnSettings(cells: Array<CellConfig>): void {
    this.cellsConfig = cells;
    this.columnSettingsTrigger.closeMenu();
    this.changeColumnSettings.emit(this.cellsConfig);
  }

  onCloseColumnSettings(): void {
    this.columnSettingsTrigger.closeMenu();
  }

  onGlobalFilterChange(filters: ObjectLiteral): void {
    this.first = 0;
    Object.keys(filters).forEach((property) => {
      this.table.filter(filters[property], property, 'startsWith');
    });
    this.globalFilterTrigger.closeMenu();
  }

  onGlobalFilterCancel(): void {
    this.globalFilterTrigger.closeMenu();
  }

  private checkIsElementInViewport(id: string): boolean {
    const el = document.getElementById(id);

    if (el) {
      const top: number = el.offsetTop;
      const virtualScrollViewportHeight: number = window.innerHeight;
      const virtualScrollTop: number = window.pageYOffset;

      return top - (virtualScrollViewportHeight - 20) < virtualScrollTop;
    }

    return false;
  }

  private listenTableScroll(): void {
    const scrollable = document.querySelector('.p-datatable-scrollable');
    if (!scrollable) {
      return;
    }

    fromEvent(scrollable, 'scroll')
      .pipe(
        untilDestroyed(this),
        filter(() => {
          return scrollable.scrollTop + scrollable.clientHeight === scrollable.scrollHeight;
        })
      )
      .subscribe(() => {
        const scrollOffset = 100;
        const scrollHeight = scrollable.scrollHeight - scrollOffset;
        const scrolled = scrollable.getBoundingClientRect().bottom + scrollable.scrollTop;
        if (scrolled >= scrollHeight && !this.loading) {
          this.table.first = (this.table.first ?? 0) + (this.table.rows ?? 0);
          const metadata: CustomLazyLoadEvent = this.table.createLazyLoadMetadata();
          this.loadMore.emit(metadata);
        }
      });
  }

  private updateCellConfig(): void {
    this.cellsConfig = cloneDeep(this.tableConfig.cellsConfig);
  }

  private restoreScrollPosition(): void {
    if (this.tableConfig.restoreStore === false) {
      return;
    }

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          const scrollPosition = this.scrollPositionService.getScrollPosition(this.tableConfig.id);

          if (isSet(scrollPosition)) {
            setTimeout(() => {
              window.scrollTo({ top: Number(scrollPosition), behavior: 'instant' });
            }, 10);
          }
        }
      }
    });
  }
}
