import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { PerformanceInput } from '../../performance-dashboard/interfaces/performance-input.interface';
import { RevenueDTO } from './interfaces';
import { Revenue } from './models';

@Injectable({
  providedIn: 'root',
})
export class RevenueService extends AbstractCRUDService<Revenue, RevenueDTO> {
  override model = Revenue;

  getData(input: PerformanceInput): Observable<Array<Revenue>> {
    const query = gql`
      query ArticleProfitability($input: ArticleProfitabilityInput!) {
        articleProfitability(input: $input) {
          articleGuid
          articleName
          revenue
          cost
          profit
        }
      }
    `;

    return this.apollo
      .query<any>({ query, variables: { input } })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<RevenueDTO>>>) =>
          response.data.articleProfitability.map((item: RevenueDTO) => this.loadModel(item))
        )
      );
  }
}
