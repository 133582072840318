import { ErrorHandler, Injectable, NgZone, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';

import { ReloadDialogComponent } from '@layout';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly dialog = inject(MatDialog);
  private readonly ngZone = inject(NgZone);

  handleError(error: unknown): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    // @ts-expect-error unknown type
    if (chunkFailedMessage.test(error.message)) {
      this.ngZone.run(() => {
        this.openDialog();
      });
    } else {
      // @ts-expect-error unknown type
      Sentry.captureException(error.originalError || error);
    }
    // eslint-disable-next-line
    console.error(error);
  }

  private openDialog(): void {
    this.dialog.open(ReloadDialogComponent, {
      data: {
        message: 'reload-page.message',
      },
    });
  }
}
