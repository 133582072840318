import { gql } from 'apollo-angular';
import { upperFirst } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetAllOptionsDTO } from '@jm-table';
import { ShiftConfiguration } from '@machines';
import { camelCaseToWords } from '@util';

import { catchAllErrors } from '../../../errors/pipes/catch-all-errors';
import { IShiftValueDTO } from '../../../main/admin/models';
import { SearchCriteriaDTO } from '../../../modules/jm-table/interfaces/search-criteria.interface';
import { LookupResult, LookupService } from '../../../modules/quick-forms';
import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import deleteMutation from '../gql/delete.graphql';
import getAllBasic from '../gql/get-all-basic.graphql';
import oneQuery from '../gql/one.graphql';
import shifts from '../gql/shifts.graphql';
import { LocationDTO, TimezoneDTO } from '../interfaces';
import { Timezone } from '../models';
import { Location } from '../models/location.model';
import getAll from '../gql/get-all.graphql';

@Injectable({ providedIn: 'root' })
export class LocationService extends AbstractCRUDService<Location, LocationDTO> implements LookupService {
  private readonly http = inject(HttpClient);

  override modelName = 'location';
  override modelNamePlural = 'locations';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  override model = Location;

  override readonly fields: string = `
    id
    company {
      id
      name
    }
    lat
    lon
    address
    status
    name
    noActiveMachines
    accessKeys {
      id
      key
    }
    machines {
      guid
      name
      platform
    }
    lastHeartbeat
    timezone
    createdAt
    updatedAt
    isActive
    noUsers
    workingHours{
      dayOfWeek
      startTime
      endTime
    }
    lastHeartbeatIpAddress
    lastHeartbeatMconVersion
    users {
      id
      name
    }
    energyPricePerKwh
    currencyCode
    co2GramsPerKwh
    holidays {
      name
      date
    }
  `;

  fetchAllShifts(locationId: number): Observable<ShiftConfiguration> {
    return this.apollo.query<ShiftConfiguration>({ query: shifts, variables: { locationId } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('locations.error.fetch-all-shifts');
      }),
      map((response: ApolloQueryResult<ResponseData<ShiftConfiguration>>) => {
        return response.data.shifts;
      })
    );
  }

  saveShiftValues(locationId: number | null | undefined, shifts: Array<IShiftValueDTO>) {
    const mutation = gql`
      mutation setShiftAliases($locationId: Int!, $shifts: [ShiftInput]!) {
        setShiftAliases(locationId: $locationId, shifts: $shifts) {
          shiftId
          alias
        }
      }
    `;

    return this.apollo.mutate<any>({ mutation, variables: { locationId, shifts } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('locations.error.set-shifts');
      }),
      map((response: ApolloQueryResult<any>) => {
        this.snackBarService.success('locations.success.set-shifts');
        return response.data;
      })
    );
  }

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<Location>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<LocationDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<LocationDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override update(model: any, options?: any): Observable<any> {
    const mutation = gql`
      ${this.updateQuery}
    `;

    return this.apollo.mutate<LocationDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('locations.error.update');
      }),
      map((response: ApolloQueryResult<LocationDTO>) => {
        this.snackBarService.success('locations.success.update');
        const modelName = `updateLocation`;
        return (response.data as ObjectLiteral)[modelName];
      })
    );
  }

  override create(model: any, options?: any): Observable<Location> {
    const mutation = gql`
      ${this.createQuery}
    `;

    const modelName = upperFirst(this.modelName);

    return this.apollo.mutate<LocationDTO>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('locations.error.create');
      }),
      tap(() => {
        this.snackBarService.success('locations.success.create');
      }),
      map((response: ApolloQueryResult<LocationDTO>) => {
        const mutation = `add${modelName}`;
        return (response.data as ObjectLiteral)[mutation];
      })
    );
  }

  protected override get updateQuery(): string {
    return `
      mutation updateLocation($id: Int!, $lon: Float!, $lat: Float!, $address: String!, $name: String!, $companyId: Int!, $currencyCode: CurrencyCode, $co2GramsPerKwh: Float, $energyPricePerKwh: Float, $workingHours: [WorkingHoursInput], $holidays: [HolidayInput], $timezone: String!) {
        updateLocation(id: $id,lon: $lon, lat: $lat, address: $address, name: $name, companyId: $companyId, currencyCode: $currencyCode, co2GramsPerKwh: $co2GramsPerKwh, energyPricePerKwh: $energyPricePerKwh, workingHours: $workingHours, holidays: $holidays, timezone: $timezone) {
          ${this.fields}
        }
      }
    `;
  }

  protected override get createQuery(): string {
    return `
      mutation addLocation($lon: Float!, $lat: Float!, $address: String!, $name: String!, $companyId: Int!, $currencyCode: CurrencyCode, $co2GramsPerKwh: Float, $energyPricePerKwh: Float, $workingHours: [WorkingHoursInput], $holidays: [HolidayInput], $timezone: String!) {
        addLocation(lon: $lon, lat: $lat, address: $address, name: $name, companyId: $companyId, currencyCode: $currencyCode, co2GramsPerKwh: $co2GramsPerKwh, energyPricePerKwh: $energyPricePerKwh, workingHours: $workingHours, holidays: $holidays, timezone: $timezone) {
          ${this.fields}
        }
      }
    `;
  }

  getSuggestions(searchCriteria: SearchCriteriaDTO): Observable<ListResultDTO<LookupResult>> {
    return this.getAll(searchCriteria, { query: getAllBasic }).pipe(
      map((value) => {
        return {
          pageDetails: value.pageDetails,
          records: listItemsToLookupResult(value.records),
        };
      })
    );
  }

  getTimeZone(latitude: number, longitude: number): Observable<Timezone> {
    const timeStamp = Math.floor(Date.now() / 1000);
    const url = `${environment.googleMaps.timeZoneUrl}`;

    const params = new HttpParams()
      .set('location', `${latitude},${longitude}`)
      .set('timestamp', timeStamp.toString())
      .set('key', environment.googleMaps.apiKey);

    return this.http.get<TimezoneDTO>(url, { params }).pipe(map((timezone) => new Timezone().loadModel(timezone)));
  }
}
