<div class="upload-box" *ngIf="!isValueDefined">
  <input
    type="file"
    class="file"
    [id]="config.getDisplayId()"
    (change)="onFileChange($event)"
    [accept]="config.meta?.fileAcceptFormat"
    #fileUpload
  />
  <div class="upload-intro">
    <p class="upload-title">
      <b>{{ config.label || '' | translate }}</b>
    </p>
  </div>
  <div class="upload-intro">{{ config.placeholder || '' | translate }}</div>
  <jm-button
    [class]="'grey-button full-width'"
    [textCenter]="true"
    [suffixIcon]="'plus'"
    (clicked)="fileUpload.click()"
    [dataTestSelector]="'media-gallery-browse-button'"
  >
    {{ 'actions.browse' | translate }}
  </jm-button>
</div>

<ng-container *ngIf="isValueDefined">
  <div class="upload-preview">
    <div class="image-info">
      <div class="image-preview-box--default-image">
        <img class="preview" [src]="'./assets/images/zip.svg'" />
      </div>
      <div class="image-data">
        <p class="image-title">
          {{ 'global.file-name' | translate }}
        </p>
        <p class="image-name" *ngIf="value?.filename">
          {{ value?.filename }}<ng-container *ngIf="value?.extension">.{{ value?.extension }}</ng-container>
        </p>
        <p class="image-title" *ngIf="value?.fileSize">
          <span>{{ 'global.file-size' | translate }} {{ value?.fileSize || 0 | fileSize }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="action-wrapper">
    <jm-button [class]="'red-border-button'" (clicked)="removeFile()" *ngIf="canUserEditFile">
      {{ 'actions.remove-file' | translate }}
    </jm-button>
    <jm-button [class]="'red-border-button'" (clicked)="download()" *ngIf="value?.url">
      {{ 'actions.download' | translate }}
    </jm-button>
  </div>
</ng-container>
