import { createControls, loadFormConfig } from 'src/app/modules/quick-forms';
import { ControlConfig } from 'src/app/modules/quick-forms/models/control.config';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { ForgotPassword } from '../../store/login.actions';
import { formConfig } from './form-config';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  form!: FormGroup;
  formConfig: Array<ControlConfig> = [];
  mailSent = false;

  constructor(private readonly formBuilder: FormBuilder, private readonly router: Router, private readonly store$: Store<any>) {}

  ngOnInit(): void {
    this.formConfig = loadFormConfig(formConfig);
    this.form = this.formBuilder.group(createControls(this.formConfig));

    this.resetMessageOnEmailChange();
  }

  resetMessageOnEmailChange(): void {
    this.form
      .get('email')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.mailSent = false;
      });
  }

  navigateTo(params: string): void {
    this.router.navigate([params]);
  }

  submit(): void {
    const email: string = this.form.get('email')?.value;
    this.store$.dispatch(new ForgotPassword({ email }));
    this.mailSent = true;
  }
}
