import { ChartData } from 'chart.js';
import moment from 'moment';
import { DateTimeFormat } from 'src/app/enum/date-time-format.enum';

import { hexToRgba, roundToDecimals } from '@util';

import { RealtimeSensorChart } from '../model/real-time-sensor-chart.model';

const CHART_COLOR = '#80C953';

export function dataToRealtimeSensorChart(
  backendData: Array<RealtimeSensorChart>,
  unit: string | null | undefined,
  color: string | undefined
): ChartData {
  const labels = backendData.map((data) => moment(data.timestamp).format(DateTimeFormat.MOMENT_JS_HOURS_MINUTES_SECONDS));

  const data: Array<number | null> = backendData.map((data) => roundToDecimals(data.value, 2));
  const prettyDuration: Array<string> = backendData.map((data) => `${roundToDecimals(data.value, 2)} ${unit ?? ''}`);

  const datasets = [
    {
      data,
      borderColor: color ?? CHART_COLOR,
      fill: true,
      tension: 0.4,
      pointRadius: 0,
      myCustomData: {
        prettyDuration,
      },
      backgroundColor: (context: { chart: any }) => {
        const chart = context.chart;
        const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
        gradient.addColorStop(0, hexToRgba(color ?? CHART_COLOR, 0.2));
        gradient.addColorStop(1, hexToRgba(color ?? CHART_COLOR, 0));
        return gradient;
      },
    },
  ];

  return {
    labels,
    datasets,
  };
}
