import { ProductionUnit } from 'src/app/core/articles/article/enums/production-unit.enum';
import { OrderDTO } from 'src/app/core/orders/interfaces/order.interface';
import { isSet, secondsToStringTime } from 'src/app/util/util';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { OrderStatus } from '@orders';

import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { ProgressBar } from '../progress-bar/progress-bar.interface';

/**
 * A little fraction of the progress bar must always be visible for the user to reliably
 * identify the status. If the progress is >0 but <3% (order has just started),
 * we round it up to 3 to show that stump.
 */
function progOrMin(progress: number | undefined): number | null {
  if (typeof progress === 'undefined') {
    return null;
  }

  if (progress === null) {
    return null;
  } else if (progress === 0) {
    return 0;
  } else if (progress < 3) {
    return 3;
  } else {
    return progress;
  }
}

@Component({
  selector: 'app-order-progress-bar',
  templateUrl: './order-progress-bar.component.html',
  styleUrls: ['./order-progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, ProgressBarComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderProgressBarComponent {
  @Input()
  set order(order: OrderDTO | null | undefined) {
    if (order === null) {
      return;
    }
    this.setProgressType(order);
  }
  @Input() showOnlyName!: boolean;
  @Input() showOnlyBar = false;

  readonly productionUnit = ProductionUnit;
  readonly orderStatus = OrderStatus;

  progressBar: ProgressBar = {
    type: 'secondary',
    value: null,
    translation: null,
    secondsRemaining: null,
    striped: false,
    animate: false,
    name: null,
  };

  private setProgressType(order: OrderDTO | undefined): void {
    this.progressBar.name = order?.name;
    // A grey progress bar with the text “Draft” is shown whenever the productionUnit of the order is null
    if (order?.productionUnit === null) {
      this.progressBar = {
        ...this.progressBar,
        value: 100,
        translation: 'dashboards.orders.draft-order',
      };
      return;
    }

    // When order status is finished, the progress bar will be filled green and text shown will be "finished"
    if (order?.status === OrderStatus.FINISHED) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: progOrMin(order?.progressPercent),
        translation: 'dashboards.orders.order-status.finished',
      };
      return;
    }

    // When status is IN_PRODUCTION, the green part of the progress bar is shown with a candy cane animation
    if (order?.status === OrderStatus.IN_PRODUCTION && order?.progressPercent !== null) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: progOrMin(order?.progressPercent),
        secondsRemaining: order?.secondsRemaining === null ? null : secondsToStringTime(order?.secondsRemaining),
        striped: true,
        animate: true,
        // When the productionUnit is not UNLIMITED but the progressPercentage is null, a grey progress bar with the text “Progress unknown” is shown
        translation: order?.progressPercent === null ? 'dashboards.orders.progress-unknown' : null,
      };
      return;
    }

    // When the productionUnit is UNLIMITED and the order is in production, a fully filled green bar with animated stripes is shown
    if (order?.status === OrderStatus.IN_PRODUCTION && order?.productionUnit === ProductionUnit.UNLIMITED) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: 100,
        secondsRemaining: order?.secondsRemaining ? null : secondsToStringTime(order?.secondsRemaining),
        striped: true,
        animate: true,
        translation: 'dashboards.orders.unlimited-order',
      };
      return;
    }

    // When the productionUnit is not UNLIMITED but the progressPercentage is null, a grey progress bar with the text “Progress unknown” is shown
    if (order?.status === OrderStatus.IN_PRODUCTION && order?.progressPercent === null) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: 100,
        secondsRemaining: order?.secondsRemaining === null ? null : secondsToStringTime(order?.secondsRemaining),
        striped: true,
        animate: true,
        translation: 'dashboards.orders.progress-unknown',
      };
      return;
    }

    // A grey progress bar with the text “Unlimited order” is shown whenever the productionUnit of the order equals UNLIMITED
    if (order?.productionUnit === ProductionUnit.UNLIMITED) {
      this.progressBar = {
        ...this.progressBar,
        value: 100,
        translation: 'dashboards.orders.unlimited-order',
      };
      return;
    }

    // When secondsRemaining is not null, a timer (hh:mm:ss) will be shown within the progress bar
    if (order?.secondsRemaining || order?.secondsRemaining === 0) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: progOrMin(order?.progressPercent),
        secondsRemaining: secondsToStringTime(order?.secondsRemaining),
      };
      return;
    }

    // When progressPercentage is set, the progress bar will be filled green according to the percentage that’s given
    if (order?.progressPercent || order?.progressPercent === 0) {
      this.progressBar = {
        ...this.progressBar,
        type: 'success',
        value: progOrMin(order?.progressPercent),
      };
      return;
    }

    if (!this.isUnlimited(order) && order?.progressPercent === null) {
      this.progressBar = {
        ...this.progressBar,
        value: 100,
        translation: 'dashboards.orders.progress-unknown',
      };
      return;
    }
  }

  isUnlimited(order: OrderDTO | undefined): boolean {
    if (!isSet(order)) {
      return false;
    }

    return order.productionUnit === ProductionUnit.UNLIMITED;
  }
}
