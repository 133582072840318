import { ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-moment';

export const chartConfig: ChartConfiguration = {
  type: 'bar',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    animation: {},
  },
};
