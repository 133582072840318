<div class="app-modal {{ className }}">
  <header class="app-modal__header">
    <svg-icon class="close-icon" alt="close-icon" [src]="'./assets/images/close.svg'" (click)="onClose()"> </svg-icon>
    <h3 class="title">
      <ng-content select="[header]"></ng-content>
    </h3>
  </header>

  <main class="app-modal__main">
    <ng-content select="[main]"></ng-content>
  </main>

  <footer class="app-modal__footer">
    <ng-content select="[footer]"></ng-content>
  </footer>
</div>
