import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlConfig } from '../../models/control.config';

@Component({
  selector: 'quick-form',
  templateUrl: './quick-form.component.html',
})
export class QuickFormComponent {
  @Input() id = '';
  @Input() config: Array<ControlConfig> = [];
  @Input() form!: any;
  @Output() openInfoWindow: EventEmitter<void> = new EventEmitter<void>();

  onOpenInfoWindow(): void {
    this.openInfoWindow.emit();
  }
}
