import { NgxSliderModule } from 'ngx-slider-v2';
import { MaterialSharedModule } from 'src/app/shared/material-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { JmButtonComponent } from '@app-ui';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  SbCheckboxComponent,
  SbDashNumberInputComponent,
  SbFiltersComponent,
  SbNumberInputComponent,
  SBPerformanceThresholdComponent,
  SbSlideToggleComponent,
  SbStringInputComponent,
  SbTextBoxComponent,
} from './components';
import { AfterValueChangedDirective } from './directive/after-value-changed.directive';

const COMPONENTS = [
  SbCheckboxComponent,
  SbStringInputComponent,
  SbNumberInputComponent,
  SBPerformanceThresholdComponent,
  SbSlideToggleComponent,
  AfterValueChangedDirective,
  SbDashNumberInputComponent,
  SbFiltersComponent,
  SbTextBoxComponent,
];

/**
 * @deprecated use '&#64;quick-form' or '&#64;app-ui' components instead
 **/
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MaterialSharedModule,
    SharedModule,
    RouterModule,
    JmButtonComponent,
    NgxSliderModule,
  ],
  exports: [COMPONENTS, JmButtonComponent],
})
export class LegacyComponentsModule {}
