<div class="alert-data">
  <ng-container *ngIf="alertConfiguration">
    <ng-template ngFor let-row [ngForOf]="generalOptions">
      <ng-container *ngIf="alertConfiguration[row.dtoValue]">
        <div
          class="alert-row"
          [ngClass]="{
            disabled: row.disabled,
            deselected: !alertConfiguration[row.dtoValue].checked,
            loading: alertConfigurationLoader || alertSubmittingLoading
          }"
        >
          <div class="row-name">
            <span class="row-title">
              {{ 'notifications.table-rows.' + row.label | translate }}
            </span>
            <span class="number-field" *ngIf="row.type === 'number'">
              <app-sb-dash-number-input
                [value]="alertConfiguration[row.dtoValue].value"
                [disabled]="!alertConfiguration[row.dtoValue].checked"
                [unit]="'global.minutes' | translate"
                (valueChanged)="onDashInputValueChange($event, row.dtoValue)"
              ></app-sb-dash-number-input>
            </span>
          </div>
          <div class="setting-option d-md-none" (click)="openConfigurationOption(row)" [ngClass]="{ disabled: row.disabled }">
            <svg-icon class="setting-icon" [src]="'./assets/images/setting.svg'"></svg-icon>
          </div>
          <div class="toogle d-none d-md-flex">
            <app-sb-slide-toggle
              [value]="alertConfiguration[row.dtoValue].checked"
              (toggleChange)="toggleAlertConfiguration($event, row.dtoValue)"
              [disabled]="row.disabled"
            ></app-sb-slide-toggle>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <app-loader class="jm-loader" *ngIf="alertConfigurationLoader || alertSubmittingLoading"></app-loader>
</div>

<app-machine-stopped-control
  [control]="getConfig('machineStoppedAlertReasons')"
  (valueChanged)="onAlertReasonValueChanged($event)"
></app-machine-stopped-control>
