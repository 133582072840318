import { map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import volumeByArticle from './gql/query.graphql';
import { VolumeByArticleDTO } from './interfaces';
import { VolumeByArticleInput } from './interfaces/volume-by-article-input.interface';
import { VolumeByArticle } from './models';

@Injectable({
  providedIn: 'root',
})
export class VolumeByArticleService extends AbstractCRUDService<VolumeByArticle, VolumeByArticleDTO> {
  override model = VolumeByArticle;

  getTopTenData(input: VolumeByArticleInput): Observable<Array<VolumeByArticle>> {
    return this.apollo
      .query<any>({
        query: volumeByArticle,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<VolumeByArticleDTO>>>) => {
          const records = response.data.volumeByArticle.map(this.loadModel);
          return records;
        })
      );
  }

  getLeaseTenData(input: VolumeByArticleInput): Observable<Array<VolumeByArticle>> {
    return this.apollo
      .query<any>({
        query: volumeByArticle,
        variables: {
          input,
        },
      })
      .pipe(
        map((response: ApolloQueryResult<ResponseData<Array<VolumeByArticleDTO>>>) => {
          const records = response.data.volumeByArticle.map(this.loadModel);
          return records;
        })
      );
  }
}
