import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { ChartComponent } from 'src/app/layout/chart/chart.component';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';
import { LoaderComponent } from '@app-ui';

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';

import { chartConfig } from './chart.config';
import { EnergyConsumedByArticleDTO } from './interfaces';
import { dataToChartMapper } from './mappers/data-to-chart.mapper';

const imports = [CommonModule, ChartComponent, BoxModule, LoaderComponent, TranslateModule, EmptyBlockModule];

@Component({
  selector: 'widget-energy-consumed-by-article',
  standalone: true,
  imports,
  templateUrl: './energy-consumed-by-article.component.html',
  styleUrls: ['./energy-consumed-by-article.component.scss'],
})
export class EnergyConsumedByArticleComponent {
  @Input() loader = false;
  @Input()
  set data(data: Array<EnergyConsumedByArticleDTO>) {
    if (!data) {
      return;
    }
    this.setChartData(data);
  }
  @Input()
  error: string | null = null;

  chartConfig: ChartConfiguration = cloneDeep(chartConfig);

  readonly id: string = `energy-consumed-by-article-${uuidv4()}`;

  private setChartData(data: Array<EnergyConsumedByArticleDTO>): void {
    this.chartConfig.data = dataToChartMapper(data);
  }
}
