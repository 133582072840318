import { Chart, Point } from 'chart.js';

export const commonZoomConfig = {
  pan: {
    enabled: true,
    mode: 'x' as const,
    onPanStart(ctx: { chart: Chart; event: Event; point: Point }) {
      const { chart } = ctx;
      chart.canvas.style.cursor = 'grabbing';
      return true;
    },
    onPanComplete(ctx: { chart: Chart }) {
      const { chart } = ctx;
      chart.canvas.style.cursor = 'default';
    },
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: 'x' as const,
  },
};
