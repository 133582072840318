import { hexToRgba, isSet } from 'src/app/util/util';

import { CdkDragEnter } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SensorType } from '@sensor';

import { RealtimeSensor } from '../model/real-time-sensor.model';

@UntilDestroy()
@Component({
  selector: 'app-realtime-sensor-configuration',
  templateUrl: './realtime-sensor-configuration.component.html',
  styleUrls: ['./realtime-sensor-configuration.component.scss'],
})
export class RealtimeSensorConfigurationComponent {
  @Input()
  sensorData: RealtimeSensor = new RealtimeSensor();
  @Input()
  machineGuid: string | null = null;
  @Input()
  isEdit = false;
  @Input()
  index = 0;
  @Output()
  toggleChartWrapper = new EventEmitter<void>();
  @Output()
  entered = new EventEmitter<CdkDragEnter>();
  @Output()
  dropped = new EventEmitter<CdkDragEnter>();

  readonly sensorType = SensorType;

  hexToRgba(hex: string | undefined, alpha: number): string {
    if (!isSet(hex)) {
      return '#D6DEE4';
    }
    return hexToRgba(hex, alpha);
  }

  onSensorConfigurationClick(): void {
    if (this.isEdit) {
      return;
    }

    this.toggleChartWrapper.emit();
  }
}
