import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ApolloLink, DefaultContext, DefaultOptions, InMemoryCache, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { LegacyComponentsModule } from '@legacy-components';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { JM_ROOT_LOCAL_STORAGE_KEY, JM_ROOT_STORAGE_KEYS } from './app.tokens';
import { GlobalErrorHandler } from './errors/services/global-error-handler';
import { LocalStorageService } from './local-storage.service';
import { MainModule } from './main/main.module';
import { QuickFormsModule } from './modules/quick-forms';
import { ModuleSidebarService } from './services/module-sidebar.service';
import { MaterialSharedModule } from './shared/material-shared.module';
import { SharedModule } from './shared/shared.module';
import { applicationStateReducer } from './store/application.reducer';
import { applicationStateName } from './store/application.state';
import { createClient } from 'graphql-ws';
import { OperationDefinitionNode } from 'graphql';

import { noop } from './util/function.helper';
import { LocationStrategy } from '@angular/common';
import { HashPreserveQueryLocationStrategy } from './config/custom-hash-location-strategy';
import { isSet } from './util/util';
import { ServiceWorkerModule } from '@angular/service-worker';

// eslint-disable-next-line
// @ts-ignore - versions mismatch ts-ignore is necessary
import extractFiles from 'extract-files/extractFiles.mjs';

// eslint-disable-next-line
// @ts-ignore - versions mismatch ts-ignore is necessary
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { getTimeZoneLink } from './config/apollo/timezone-link';
import { GoogleMapsLoaderService } from './services/google-maps-loader.service';

declare const SENTRY_RELEASE: string;

Sentry.init({
  dsn: isSet(environment.sentryDSN) ? environment.sentryDSN : undefined,
  environment: environment.name,
  release: SENTRY_RELEASE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],

  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/mymuenet\.swiss/,
    /^https:\/\/staging\.mymuenet\.swiss/,
    /^https:\/\/mymuenet\.dev\.kstg\.io/,
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MainModule,
    AppRoutingModule,
    SharedModule,
    QuickFormsModule,
    ApolloModule,
    MaterialSharedModule,
    StoreModule.forFeature(applicationStateName, applicationStateReducer),
    StoreModule.forRoot(
      {
        routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    LegacyComponentsModule,
    HammerModule,
    TourMatMenuModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.prodMode,
      connectInZone: true,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => noop,
      deps: [Sentry.TraceService],
      multi: true,
    },
    ModuleSidebarService,
    { provide: JM_ROOT_STORAGE_KEYS, useValue: ['JM_persistent_storage'] },
    { provide: JM_ROOT_LOCAL_STORAGE_KEY, useValue: '__app_storage__' },
    { provide: LocationStrategy, useClass: HashPreserveQueryLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (googleMapsLoaderService: GoogleMapsLoaderService) => () => googleMapsLoaderService.load(),
      deps: [GoogleMapsLoaderService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  private readonly apollo = inject(Apollo);
  private readonly httpLink = inject(HttpLink);
  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.watchImpersonation();
  }

  private watchImpersonation(): void {
    this.localStorageService.impersonateUserToken$.pipe().subscribe((impersonateUserToken: string | null) => {
      if (isSet(this.apollo.client)) {
        return;
      }

      const wsLink = this.getWsLink(impersonateUserToken);
      const authLink = this.getAuthLink(impersonateUserToken);
      const timezoneLink = getTimeZoneLink();

      this.apollo.create({
        link: ApolloLink.from([timezoneLink, authLink.concat(wsLink)]),
        cache: new InMemoryCache({ addTypename: false }),
        defaultOptions: defaultOptions,
      });
    });
  }

  private getWsLink(impersonateUserToken: string | null) {
    const http = this.httpLink.create({ uri: environment.apiUrl, extractFiles: (body) => extractFiles(body, isExtractableFile) });

    // Create a WebSocket link:
    const ws = new GraphQLWsLink(
      createClient({
        url: environment.wsUrl,
        connectionParams: () => {
          const token = this.getToken(impersonateUserToken);

          return { 'x-token': token };
        },
      })
    );

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      http
    );

    return link;
  }

  private getAuthLink(impersonateUserToken: string | null) {
    return new ApolloLink((operation, forward) => {
      const token = this.getToken(impersonateUserToken);

      operation.setContext((context: DefaultContext) => {
        return {
          headers: {
            ...context.headers,
            'x-token': token,
          },
        };
      });

      return forward(operation);
    });
  }

  getToken(impersonateUserToken: string | null): string {
    if (isSet(impersonateUserToken)) {
      return impersonateUserToken;
    }

    const user = JSON.parse(localStorage.getItem('muCloudUser') as string);

    if (isSet(user)) {
      return user.token;
    }

    return '';
  }
}
