import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ControlConfig, createControls, loadFormConfig } from '@quick-form';

import { DashboardService } from '../../dashboard.service';
import { loadPrivateDashboards, loadPublicDashboards } from '../../store/dashboard.actions';
import { createDashboardConfig } from './form-config';

@UntilDestroy()
@Component({
  selector: 'app-create-dashboard-modal',
  templateUrl: './create-dashboard-modal.component.html',
})
export class CreateDashboardModalComponent implements OnInit {
  form: FormGroup | null = null;
  formConfig: Array<ControlConfig> = [];

  constructor(
    private readonly dialogRef: MatDialogRef<CreateDashboardModalComponent>,
    private readonly fb: FormBuilder,
    private readonly dashboardService: DashboardService,
    private readonly router: Router,
    private readonly store$: Store
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.formConfig = loadFormConfig(createDashboardConfig);
    this.form = this.fb.group(createControls(this.formConfig));
  }

  save(): void {
    if (this.form?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.dashboardService
      .save(this.form?.value)
      .pipe(untilDestroyed(this))
      .subscribe((model) => {
        this.loadDashboards();
        this.dialogRef.close();
        this.router.navigateByUrl(`dashboard/edit/${model.guid}`);
      });
  }

  private loadDashboards(): void {
    this.store$.dispatch(loadPublicDashboards());
    this.store$.dispatch(loadPrivateDashboards());
  }
}
