import { Model } from 'ngx-super-model';
import { ContentDataDTO } from '../interfaces/content.interface';
import { Section } from './section.model';

export class ContentData extends Model<ContentDataDTO> {
  sections: Array<Section> = [];
  notes = {
    text: '',
  };

  override loadModel(input: ContentDataDTO): this {
    super.loadModel(input);
    this.sections = input.sections.map((section) => new Section().loadModel(section));
    return this;
  }
}
