import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@auth';

@Injectable()
export class SSOGuard {
  private readonly authService = inject(AuthenticationService);
  private readonly router = inject(Router);

  async canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.isLoggedIn().subscribe((data) => {
        const isLoggedIn = data;

        if (!isLoggedIn) {
          resolve(true);
          return;
        }

        this.router.navigateByUrl('/');
        resolve(false);
      });
    });
  }
}
