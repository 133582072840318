import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'prettyDuration',
  standalone: true,
})
export class PrettyDurationPipe implements PipeTransform {
  transform(value: string | number | null | undefined): string {
    if (!value) {
      return '-';
    }

    const isNegative = Number(value) < 0;
    value = Math.abs(Number(value));

    const duration = moment.duration(value, 'seconds');
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let result = '';
    if (days > 0) {
      result += `${days}d `;
    }
    if (hours > 0) {
      result += `${hours}h `;
    }
    if (minutes > 0) {
      result += `${minutes}m `;
    }
    if (seconds > 0 || result === '') {
      result += `${seconds}s`;
    }

    if (isNegative) {
      return `-${result.trim()}`;
    }
    return result.trim();
  }
}
