import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';
import { AbstractFormInterface } from '../interfaces/abstract-form.interface';

@Injectable()
export class FormUnsavedGuard {
  constructor(private readonly dialog: MatDialog, private readonly translateService: TranslateService) {}

  canDeactivate(component: AbstractFormInterface) {
    if (!component.formTouched) {
      return true;
    }

    // TODO: it would be better if this was handled by observable and material dialog but Firefox is not playing well
    return confirm('Are you sure you want to leave page with unsaved data?');
  }
}
