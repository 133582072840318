import { Model } from 'ngx-super-model';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { Pagination } from 'src/app/util/search/pagination.interface';
import { SearchQuery } from 'src/app/util/search/serch-query.interface';
import { SortBy } from 'src/app/util/search/sort-by.interface';
import { SortOrder } from 'src/app/util/search/sort-order.enum';
import { SearchCriteriaDTO } from '../interfaces/search-criteria.interface';
import { PersistentFiltersState } from 'src/app/main/dashboard/models';

export class SearchCriteria extends Model<SearchCriteriaDTO> {
  pagination: Pagination = {
    limit: 100,
    offset: 0,
  };

  searchQuery?: SearchQuery = {
    name: null,
  };

  sortBy: SortBy = {
    columnName: 'createdAt',
    direction: SortOrder.DESC,
  };

  filterBy?: ObjectLiteral = {};

  // ToDo => this should be properly added on backend side, here is added because of `SearchCriteriaEventInput`
  locationId?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  guid?: string;
  filter?: PersistentFiltersState;

  addFilter(filter: ObjectLiteral): void {
    this.filterBy = { ...this.filterBy, ...filter };
  }

  removeFilter(key: string) {
    if (this.filterBy) {
      delete this.filterBy[key];
    }
  }

  setPageLimit(limit: number): void {
    this.pagination = {
      ...this.pagination,
      limit,
    };
  }
}
