import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-pattern-file',
  templateUrl: './pattern-file.component.html',
  styleUrls: ['./pattern-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PatternFileComponent),
    },
  ],
})
export class PatternFileComponent implements ControlValueAccessor, OnInit {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Output()
  inputClick = new EventEmitter<void>();

  value = '';
  isDisabled = true;

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(true);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // eslint-disable-next-line
  private propagateChange = (value: any) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
