import { User } from '@user';

export const loginStateName = 'login';

export interface LoginState {
  user: User;
  backendVersion: string | null;
  passwordResetLoader: boolean;
  loginLoader: boolean;
  notification: { id: number; message: string } | null;
}

export const initialLoginState: LoginState = {
  user: new User(),
  backendVersion: null,
  passwordResetLoader: false,
  loginLoader: false,
  notification: null,
};
