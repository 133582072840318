import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { LookupResult, LookupService } from 'src/app/modules/quick-forms';

import { inject, Injectable } from '@angular/core';
import { SearchCriteria } from '@jm-table';

import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import { LicenseTypesEnum } from 'src/app/main/admin/models';
import { LocationDTO } from '../interfaces';
import { LocationService } from './location.service';

@Injectable({
  providedIn: 'root',
})
export class LocationSensorLicenseService implements LookupService {
  private readonly locationService = inject(LocationService);

  getSuggestions(searchCriteria: SearchCriteria): Observable<ListResultDTO<LookupResult>> {
    searchCriteria.filterBy = {
      ...searchCriteria.filterBy,
      hasLicensedMachines: true,
      licenseType: LicenseTypesEnum.SENSOR,
    };

    return this.locationService.getAll(searchCriteria).pipe(
      map((response: ListResultDTO<LocationDTO>) => {
        return {
          records: listItemsToLookupResult(response.records),
          pageDetails: null,
        };
      })
    );
  }
}
