import moment from 'moment';

import { TimeRange } from '../enums/bucket-size.enum';
import { ChartResponseItemDTO } from '../interfaces/chart-response.interface';
import { PeriodDTO } from '../interfaces/period.interface';

export function mapLabels(backendData: Array<ChartResponseItemDTO>, period: PeriodDTO): Array<string> {
  const format = getDateFormatFromPeriod(period);

  return backendData.map((item) => moment(item.bucket).format(format));
}

export function getDateFormatFromPeriod(period: PeriodDTO, legacyGraph = false): string {
  const diff = moment(period.endDate).diff(period.startDate, 'seconds');

  if (diff <= TimeRange.OneDay) {
    return 'HH:mm';
  }

  if (diff <= TimeRange.TenDays) {
    return 'MMM D, HH:mm';
  }

  // Two hours extra is to account for daylight saving changes where diff can be 1 month and 1hour
  if (diff <= TimeRange.OneMonth + 7200) {
    return 'MMM D, HH:mm';
  }

  if (diff <= TimeRange.SixMonths || legacyGraph) {
    return 'MMM D';
  }

  return 'YYYY MMM D';
}
