import { MutationResult } from 'apollo-angular';
import { EMPTY, NEVER, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, throttleTime, withLatestFrom } from 'rxjs/operators';
import { ResponseData } from 'src/app/core/abstract/interface/response-data';
import { ShowNotification } from 'src/app/shared/components/notification/store/snack-bar.actions';
import { NotificationUtils } from 'src/app/util/notification.util';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { isSet } from 'src/app/util/util';

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@auth';
import { CompanyService } from '@company';
import { ActiveLicenses } from '@licenses';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { PushNotificationsService } from '@push-notifications';

import { SignInDTO } from '../intefaces/sign-in';
import { LoginService } from '../services/login.service';
import {
  ActiveLicensesRequested,
  ActiveLicensesUpdated,
  CloseScheduledNotifications,
  ErrorNewPassword,
  ForgotPassword,
  GetUserInfo,
  LoginActionType,
  LoginError,
  LoginSuccess,
  RequestBackendVersion,
  ResponseBackendVersion,
  ResponseScheduledNotifications,
  SetNewPassword,
  SetUserInfo,
  SuccessfullyForgotPassword,
  UserLogin,
} from './login.actions';
import { selectUserCompanyId } from './login.selector';

@Injectable()
export class LoginEffects {
  getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetUserInfo>(LoginActionType.GET_USER_INFO),
      throttleTime(200),
      switchMap(() => {
        return this.loginService.getUserInfo().pipe(
          map((user) => {
            return new SetUserInfo({
              user,
            });
          }),
          catchError(() => {
            return of(
              new ShowNotification({
                message: 'Something went wrong during fetching user info',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  userLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserLogin>(LoginActionType.USER_LOGIN),
      switchMap((action: UserLogin) => {
        return this.loginService.userLogin(action.payload.email, action.payload.password).pipe(
          switchMap((res: MutationResult<ResponseData<SignInDTO>>) => {
            const token = res.data?.signIn;
            this.authenticationService.setToken(token, action.payload.email);

            if (token) {
              this.pushNotificationsService.checkAndSendToApiIfUserIdExist().pipe(take(1)).subscribe();
              this.authenticationService.loginRedirect(this.route.snapshot.queryParams.redirectUrl);
              return of(new LoginSuccess());
            }

            return NEVER;
          }),
          catchError(() => {
            return of(
              new LoginError(),
              new ShowNotification({
                message: 'Username or password invalid',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  updateActiveLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ActiveLicensesRequested>(LoginActionType.ACTIVE_LICENSES_REQUESTED),
      withLatestFrom(this.store$.select(selectUserCompanyId)),
      switchMap(([, companyId]: [ActiveLicensesRequested, number | undefined]) => {
        if (!isSet(companyId)) {
          return EMPTY;
        }

        return this.companyService.getActiveLicenses(companyId).pipe(
          map((activeLicenses: ActiveLicenses) => {
            return new ActiveLicensesUpdated({
              activeLicenses,
            });
          }),
          catchError(() => {
            return of(
              new ShowNotification({
                message: 'Something went wrong during updating active licenses.',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  setNewPassword$: Observable<Action | Array<Action>> = createEffect(() =>
    this.actions$.pipe(
      ofType<SetNewPassword>(LoginActionType.SET_NEW_PASSWORD),
      switchMap((action: SetNewPassword) => {
        const { password, resetToken } = action.payload;
        return this.loginService.setNewPassword(password, resetToken).pipe(
          mergeMap((response) => {
            if (response.data) {
              return of(
                new ShowNotification({
                  message: 'New password successfully set!',
                  config: NotificationUtils.snackBarSuccessConfig(),
                }),

                new SuccessfullyForgotPassword({ user: (response.data as ObjectLiteral).resetPassword })
              );
            } else {
              return of(
                new ErrorNewPassword(),
                new ShowNotification({
                  message: 'Token already used.',
                  config: NotificationUtils.snackBarErrorConfig(),
                })
              );
            }
          }),
          catchError(() => {
            return of(
              new ErrorNewPassword(),
              new ShowNotification({
                message: 'Setting new password failed, please try again',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ForgotPassword>(LoginActionType.FORGOT_PASSWORD),
      switchMap((action: ForgotPassword) => {
        return this.loginService.forgotPassword(action.payload.email).pipe(
          mergeMap((response) => {
            if (response.data) {
              return of(
                new ShowNotification({
                  message: 'Email has been sent successfully.',
                  config: NotificationUtils.snackBarSuccessConfig(),
                }),
                new SuccessfullyForgotPassword((response.data as ObjectLiteral).resetPassword)
              );
            } else {
              return of(
                new ErrorNewPassword(),
                new ShowNotification({
                  message: (response.errors as ObjectLiteral)[0].message,
                  config: NotificationUtils.snackBarErrorConfig(),
                })
              );
            }
          }),
          catchError(() => {
            return of(
              new ShowNotification({
                message: 'Mail sending failed, please try again',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  requestBackendVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestBackendVersion>(LoginActionType.REQUEST_BACKEND_VERSION),
      switchMap(() => {
        return this.loginService.getReleaseInfo().pipe(
          map((res: { version: string }) => {
            return new ResponseBackendVersion({ version: res.version });
          }),
          catchError(() => {
            return of(
              new ShowNotification({
                message: 'Something went wrong during fetching application version',
                config: NotificationUtils.snackBarErrorConfig(),
              })
            );
          })
        );
      })
    )
  );

  closeScheduledNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CloseScheduledNotifications>(LoginActionType.CLOSE_SCHEDULED_NOTIFICATIONS),
      switchMap((action: CloseScheduledNotifications) => {
        return this.loginService.closeUserNotification(action.payload.id).pipe(
          map(
            () => new ResponseScheduledNotifications({ userNotification: null }),
            catchError(() => {
              return of(
                new ShowNotification({
                  message: 'Something went wrong',
                  config: NotificationUtils.snackBarErrorConfig(),
                })
              );
            })
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly loginService: LoginService,
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly companyService: CompanyService,
    private readonly store$: Store
  ) {}
}
