<div class="filters-wrapper {{ className || '' }}">
  <div
    class="sb-filter"
    *ngFor="let filter of filters"
    [ngClass]="{ active: activeFilters.includes(filter) }"
    (click)="addOrRemoveActiveFilter.emit(filter)"
    [tourAnchor]="'filter-' + filter"
  >
    {{ 'filters.' + filter.toLocaleLowerCase() | translate }}
  </div>
</div>
