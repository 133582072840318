<div class="quick-dropdown">
  <label *ngIf="config.label" class="label label--info">
    {{ config.label | translate }} <span *ngIf="config?.validators?.required" (click)="onOpenInfoWindow($event)">*</span>
    <svg-icon
      *ngIf="config?.meta?.info"
      class="info-icon"
      [src]="'./assets/images/question-mark.svg'"
      (click)="onOpenInfoWindow($event)"
    ></svg-icon>
  </label>
  <ng-select
    #select
    [(ngModel)]="value"
    [items]="options"
    [class.invalid]="control.invalid && control.touched"
    [id]="config.getDisplayId()"
    [placeholder]="placeholder || '' | translate"
    [class]="config.cssClass"
    [loading]="loading"
    [bindLabel]="'name'"
    [compareWith]="compareFn"
    [markFirst]="true"
    [multiple]="true"
    [clearable]="false"
    [closeOnSelect]="false"
    (change)="onChange($event)"
    [disabled]="isDisabled || control.disabled"
    [appendTo]="appendTo"
    (blur)="onBlur()"
    [typeahead]="searchInput$"
    (clear)="clearValue()"
    [virtualScroll]="virtualScroll"
    (scrollToEnd)="onScrollToEnd()"
  >
    <ng-template ng-header-tmp>
      <div class="multiple_select-all" *ngIf="!config.meta?.hideSelectAll">
        <quick-form-checkbox [config]="selectAllConfig" [checked]="selectAll" (valueChanged)="onSelectAll($event)"></quick-form-checkbox>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <quick-form-readonly-checkbox [checked]="item$.selected"></quick-form-readonly-checkbox>
      <span class="checkbox-label">{{ item.name | translate }}</span>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngFor="let item of items | slice : 0 : multiSelectVisibleItems">
        <span class="ng-value-label"> {{ $any(item).name | translate }}</span>
        <span class="ng-value-icon right" (click)="clear(item); updateDropdownPosition()" aria-hidden="true">
          <svg-icon class="close-icon" [src]="'./assets/images/red_close_icon.svg'"></svg-icon>
        </span>
      </div>
      <div class="ng-value" *ngIf="items.length > multiSelectVisibleItems">
        <span class="ng-value-label">{{ items.length - multiSelectVisibleItems }} more...</span>
      </div>
    </ng-template>
  </ng-select>
  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
