import { Model } from 'ngx-super-model';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { v4 as uuidv4 } from 'uuid';

import { CellType } from '../enum/cell-type.enum';
import { CellConfigDTO } from '../interfaces/cell-config.interface';
import { CellFiltersDTO } from '../interfaces/cell-filters.interface';

export class CellConfig extends Model<CellConfigDTO> {
  type: CellType | null = null;
  field: string | null = null;
  id = uuidv4();
  take: string | null = null;
  title: string | null = null;
  unit: string | null = null;
  assigned = '';
  total = '';
  cssClass: Array<string> = ['medium'];
  placeholder: string | null = null;
  sortable = true;
  filter: CellFiltersDTO | null = null;
  sortingField = '';
  display = true;
  meta: ObjectLiteral = {};
  render?: Function | null = null;
  activeMobileCell?: boolean = false;
}
