import { Apollo, gql, MutationResult, QueryRef } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ResponseData } from 'src/app/core/abstract/interface/response-data';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { LocalStorageService } from 'src/app/local-storage.service';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';

import { inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@auth';
import { Store } from '@ngrx/store';

import meQuery from '../gql/me-query.graphql';
import { SignInDTO } from '../intefaces/sign-in';
import { ResponseScheduledNotifications } from '../store/login.actions';
import { User } from '@user';
import { isSet } from '@util';

const subscription = gql`
  subscription {
    newUserNotification {
      id
      name
      message
      status
      startDate
      endDate
    }
  }
`;

@Injectable({ providedIn: 'root' })
export class LoginService {
  private userNotificationQuery!: QueryRef<any>;

  private readonly apollo = inject(Apollo);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly store$ = inject(Store);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly snackBarService = inject(SnackBarService);

  getUserInfo(): Observable<User> {
    return this.apollo
      .query<any>({
        query: meQuery,
      })
      .pipe(
        map((res) => res.data.me),
        catchError(() => {
          this.localStorageService.impersonateUserToken$.subscribe((impersonateUserToken: string | null) => {
            if (isSet(impersonateUserToken)) {
              this.localStorageService.clearImpersonateUserToken();
              location.replace(`${location.origin}/#/admin/users`);
              location.reload();
            } else {
              this.authenticationService.logOut();
            }
          });

          return of(null as any);
        })
      );
  }

  updatePassword(currentPassword: string, newPassword: string) {
    const mutation = gql`
      mutation updatePassword($currentPassword: String!, $newPassword: String!) {
        updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
          id
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          currentPassword,
          newPassword,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('login.error.update');
        }),
        tap(() => {
          this.snackBarService.success('login.success.update');
        })
      );
  }

  userLogin(email: string, password: string): Observable<MutationResult<ResponseData<SignInDTO>>> {
    const mutation = gql`
      mutation signIn($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
          token
        }
      }
    `;

    return this.apollo
      .mutate<SignInDTO>({
        mutation,
        variables: {
          email,
          password,
        },
      })
      .pipe(catchAllErrors());
  }

  setNewPassword(password: string, resetToken: string | null) {
    const mutation = gql`
      mutation resetPassword($password: String!, $resetToken: String!) {
        resetPassword(password: $password, resetToken: $resetToken) {
          id
          name
          email
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          resetToken,
          password,
        },
      })
      .pipe(map((res) => res));
  }

  forgotPassword(email: string) {
    const mutation = gql`
      mutation requestPasswordReset($email: String!) {
        requestPasswordReset(email: $email)
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          email,
        },
      })
      .pipe(map((res) => res));
  }

  getReleaseInfo() {
    const query = gql`
      query {
        releaseInfo {
          version
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
      })
      .pipe(map((res) => res.data.releaseInfo));
  }

  closeUserNotification(id: number) {
    const mutation = gql`
      mutation closeUserNotification($id: Int!) {
        closeUserNotification(id: $id) {
          id
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: { id },
      })
      .pipe(map((res) => res));
  }

  fetchNewUserNotification() {
    const query = gql`
      query newUserNotification {
        userNotification {
          id
          name
          message
          status
          startDate
          endDate
        }
      }
    `;
    this.userNotificationQuery = this.apollo.watchQuery({
      query,
    }) as any;

    this.setupSubscription();
    return this.userNotificationQuery;
  }

  setupSubscription() {
    this.userNotificationQuery.subscribeToMore({
      document: subscription,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }
        this.store$.dispatch(
          new ResponseScheduledNotifications({
            userNotification: {
              id: (subscriptionData.data as any)?.newUserNotification?.id,
              message: (subscriptionData.data as any)?.newUserNotification?.message,
            },
          })
        );
        return Object.assign({}, prev, {
          ...prev,
        });
      },
    });
  }
}
