import moment from 'moment';

import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { Period } from 'src/app/util/period/period.interface';

import { TimeRange } from '../enums/bucket-size.enum';
import { PeriodDTO } from '../interfaces/period.interface';
import { Seconds } from '../types/bucket.type';

export function shortPeriodMapper(type: PeriodTypeEnum): PeriodDTO {
  const now = moment();
  let startDate;
  let endDate;

  if (type === PeriodTypeEnum.PERIOD_LAST_HALF_HOUR) {
    return {
      startDate: moment().subtract(0.5, 'hour').toISOString(),
      endDate: now.toISOString(),
    };
  }

  if (type === PeriodTypeEnum.PERIOD_LAST_HOUR) {
    return {
      startDate: moment().subtract(1, 'hour').toISOString(),
      endDate: now.toISOString(),
    };
  }

  if (type === PeriodTypeEnum.PERIOD_TODAY) {
    return {
      startDate: moment().startOf('day').toISOString(),
      endDate: now.toISOString(),
    };
  }

  if (type === PeriodTypeEnum.PERIOD_LAST_24_HOURS) {
    const startDate = moment().subtract(24, 'hours').toISOString();
    const endDate = moment().toISOString();

    return {
      startDate,
      endDate,
    };
  }

  if (type === PeriodTypeEnum.PERIOD_YESTERDAY) {
    startDate = moment().subtract(1, 'days').startOf('day');
    endDate = moment().subtract(1, 'days').endOf('day');

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  }

  if (type === PeriodTypeEnum.PERIOD_LAST_TWO_DAYS) {
    const startDate = moment().subtract(48, 'hours').toISOString();
    const endDate = moment().toISOString();

    return {
      startDate,
      endDate,
    };
  }

  if (type === PeriodTypeEnum.PERIOD_CURRENT_MONTH) {
    startDate = moment().startOf('month');
    return { startDate: startDate.toISOString(), endDate: now.toISOString() };
  }

  if (type === PeriodTypeEnum.PERIOD_LAST_MONTH) {
    startDate = moment().subtract(1, 'months').startOf('month');
    endDate = moment().subtract(1, 'months').endOf('month');

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
  }

  throw new Error(`Provided period "${type}" type does not exist`);
}

export function isShortPeriod(period: PeriodTypeEnum): boolean {
  return period !== PeriodTypeEnum.CUSTOM_RANGE;
}

/**
 * TODO:
 * Temporary mapper until all widgets switch to new endpoint
 * and state has only one pariod holding start and end, without shortcuts
 */
export function statePeriodToStartEndDate(periodFromState: Period): PeriodDTO {
  if (isShortPeriod(periodFromState.type)) {
    return shortPeriodMapper(periodFromState.type);
  }

  const customPeriod = periodFromState.customPeriod;
  const startDate = moment(customPeriod?.start).toISOString();
  const endDate = moment(customPeriod?.end).toISOString();

  return {
    startDate: startDate || null,
    endDate: endDate || null,
  };
}

export function periodToBucketSize(period: PeriodDTO): Seconds {
  const { startDate, endDate } = period;

  const diff = moment(endDate).diff(startDate, 'seconds');

  if (diff <= TimeRange.OneHour) {
    return TimeRange.OneMinute;
  }

  if (diff <= TimeRange.SixHours) {
    return TimeRange.FiveMinutes;
  }

  if (diff <= TimeRange.TwelveHours) {
    return TimeRange.TenMinutes;
  }

  if (diff <= TimeRange.OneDay) {
    return TimeRange.ThirtyMinutes;
  }

  if (diff <= TimeRange.TenDays) {
    return TimeRange.SixHours;
  }

  // Two hours extra is to account for daylight saving changes where diff can be 1 month and 1hour
  if (diff <= TimeRange.OneMonth + 7200) {
    return TimeRange.TwelveHours;
  }

  if (diff <= TimeRange.SixMonths) {
    return TimeRange.OneDay;
  }

  if (diff <= TimeRange.OneYear) {
    return TimeRange.FiveDays;
  }

  if (diff <= TimeRange.TwoYears) {
    return TimeRange.FifteenDays;
  }

  return TimeRange.FifteenDays;
}
