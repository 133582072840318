import { AngularSvgIconModule } from 'angular-svg-icon';
import { DeviceService } from 'src/app/modules/device/device.service';

import { AlertService, AlertSeverity } from '@alerts';
import { Location } from '@angular/common';
import { Component, HostListener, inject, OnInit, signal } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { JmButtonComponent } from '@app-ui';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertsListComponent } from '../alerts-list.component';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';

@UntilDestroy()
@Component({
  selector: 'app-alert-button',
  standalone: true,
  imports: [AlertsListComponent, AngularSvgIconModule, JmButtonComponent, MatMenuModule],
  templateUrl: './alert-button.component.html',
  styleUrl: './alert-button.component.scss',
})
export class AlertButtonComponent implements OnInit {
  private readonly alertService = inject(AlertService);
  private readonly deviceService = inject(DeviceService);
  private readonly location = inject(Location);
  private readonly router = inject(Router);
  private readonly snackBarService = inject(SnackBarService);

  isMobile = false;
  unreadCount = signal<number>(0);

  get routerUrl(): string {
    return this.router.url;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.routerUrl === '/notifications/alerts-list' && !this.isMobile) {
      this.router.navigate(['']);
    }
  }

  ngOnInit(): void {
    if (this.routerUrl === '/notifications/alerts-list' && !this.isMobile) {
      this.router.navigate(['/']);
    }

    this.deviceService
      .isMobile()
      .pipe(untilDestroyed(this))
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.alertService
      .unreadCount()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.unreadCount.set(value);
      });

    this.subscribeToUserAlerts();
  }

  handleNotification(): void {
    if (!this.isMobile) {
      return;
    }

    if (this.router.url !== '/notifications/alerts-list') {
      this.router.navigate(['/notifications/alerts-list']);
      return;
    }
    this.location.back();
  }

  private subscribeToUserAlerts(): void {
    this.alertService
      .alertsSubscription()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (alert) => {
          const { message, severity, id } = alert;
          this.showNewAlertSnackBar(message, severity, id);

          this.alertService.setUnreadCount(this.unreadCount() + 1);
        },
      });
  }

  private showNewAlertSnackBar(message: string, severity: AlertSeverity, messageId: number | null): void {
    const config = {
      data: {
        message,
        severity,
        messageId,
      },
    };

    this.snackBarService.open(message, config);
  }
}
