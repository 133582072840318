import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';

export const formConfig: Array<ControlConfigDTO> = [
  {
    id: 'type',
    label: 'dashboards.controls.period',
    placeholder: 'dashboards.controls.period',
    type: ControlTypeEnum.Dropdown,
    cssClass: 'dashboard-period-control__wrapper',
    value: {
      id: PeriodTypeEnum.PERIOD_CURRENT_MONTH,
      name: 'Current Month',
    },
    validators: { required: true },
    meta: {
      options: [
        {
          id: PeriodTypeEnum.PERIOD_LAST_HOUR,
          name: 'Last hour',
        },
        {
          id: PeriodTypeEnum.PERIOD_TODAY,
          name: 'Today',
        },
        {
          id: PeriodTypeEnum.PERIOD_LAST_24_HOURS,
          name: 'Last 24 hours',
        },
        {
          id: PeriodTypeEnum.PERIOD_YESTERDAY,
          name: 'Yesterday',
        },
        {
          id: PeriodTypeEnum.PERIOD_LAST_TWO_DAYS,
          name: 'Last 2 days',
        },
        {
          id: PeriodTypeEnum.PERIOD_CURRENT_MONTH,
          name: 'Current Month',
        },
        {
          id: PeriodTypeEnum.PERIOD_LAST_MONTH,
          name: 'Last Month',
        },
        {
          id: PeriodTypeEnum.CUSTOM_RANGE,
          name: 'Custom range',
        },
      ],
    },
  },
  {
    id: 'start',
    label: 'global.start-date',
    type: ControlTypeEnum.PDatePicker,
    validators: {
      required: true,
    },
    meta: {
      showTime: true,
    },
  },
  {
    id: 'end',
    label: 'global.end-date',
    type: ControlTypeEnum.PDatePicker,
    validators: {
      required: true,
    },
    meta: {
      showTime: true,
    },
  },
  {
    id: 'sensorIds',
    label: 'sensor.widget.real-time.select-sensors',
    type: ControlTypeEnum.Multiselect,
    placeholder: 'sensor.widget.real-time.select-sensors',
    value: null,
    validators: {
      required: true,
    },
    meta: {
      virtualScroll: true,
      hideSelectAll: true,
    },
  },
  {
    id: 'filename',
    label: 'global.filename',
    placeholder: 'global.filename',
    type: ControlTypeEnum.InputText,
  },
];
