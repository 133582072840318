import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { CreateOrderModel } from 'src/app/main/order/models';
import { UpdateOrderModel } from 'src/app/main/order/models/update-order.interface';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { ListResultDTO, SearchCriteriaDTO } from '@jm-table';

import { AbstractCRUDService } from '../abstract-crud.service';
import getAllOrders from './gql/get-all.graphql';
import oneQuery from './gql/one.graphql';
import { OrderQueueUpdatedDTO, OrderStatusUpdatedDTO } from './interfaces';
import { OrderDTO } from './interfaces/order.interface';
import { Order } from './models/order.model';
import orderQueueUpdated from './queries/order-queue-updated.graphql';
import orderStatusUpdated from './queries/order-status-updated.graphql';

@Injectable({ providedIn: 'root' })
export class OrderService extends AbstractCRUDService<Order, OrderDTO> {
  override modelName = 'order';
  override modelNamePlural = 'orders';

  protected override oneQuery = oneQuery;

  override model = Order;

  override readonly fields: string = `
    orderGuid
    name
    quantity
    article {
      __typename
      ... on MachineCreatedArticle {
        guid
        name
        patternName
      }
      ... on Article {
        guid
        name
        articlePatterns {
          id
          pattern {
            id
            uptFile
            company {
              id
              name
            }
          }
          repeats
          compression
          ribbonWidth
          ribbonLength
        }
        previewDocuments {
          id
          filename
          filetype
          urls {
            thumbnail
            medium
            original
          }
          createdAt
          updatedAt
        }
      }
    }
  status
  removeOrderAfterWeaving
  removePatternsAfterWeaving
  test
  productionUnit
  machine {
    guid
    name
    displayName
    location {
      id
      name
      currencyCode
    }
    platform
    currentStatus
    currentSpeedRpm
    currentShift {
      alias
      shiftId
    }
  }
  productionRuns {
    firstRunStart
    lastRunEnd
  }
  fixedCost
  variableCost
  revenue
  wastePercentage
  usedMaterial
  co2PerMaterialUnit
  isDequeueable
  progressPercent
  secondsRemaining
  test
  remainingPicks
  preselectPicks
  timeSent
  timeDequeued
  position
  isMymuenetCreated
  lineNumber
  createdAt
  updatedAt
  `;

  override getAll(searchCriteria: SearchCriteriaDTO): Observable<ListResultDTO<Order>> {
    return this.apollo.query<ListResultDTO<Array<Order>>>({ query: getAllOrders, variables: { searchCriteria } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('orders.error.get-all');
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<OrderDTO> }>) => {
        const records = response.data.orders.records.map((order) => this.loadModel(order));
        const { pageDetails } = response.data.orders;
        return { records, pageDetails };
      })
    );
  }

  addAndSendOrder(order: CreateOrderModel): Observable<any> {
    const mutation = gql`
      mutation addAndSendOrder(
        $machineGuid: GUID!
        $name: String!
        $productionUnit: ProductionUnit!
        $removeOrderAfterWeaving: Boolean!
        $removePatternsAfterWeaving: Boolean!
        $articleGuid: GUID
        $quantity: Int
        $fixedCost: Float
        $variableCost: Float
        $revenue: Float
        $wastePercentage: Float
        $usedMaterial: Float
        $co2PerMaterialUnit: Float
      ) {
        addAndSendOrder(
          machineGuid: $machineGuid
          name: $name
          productionUnit: $productionUnit
          removeOrderAfterWeaving: $removeOrderAfterWeaving
          removePatternsAfterWeaving: $removePatternsAfterWeaving
          articleGuid: $articleGuid
          quantity: $quantity
          fixedCost: $fixedCost
          variableCost: $variableCost
          revenue: $revenue
          wastePercentage: $wastePercentage
          usedMaterial: $usedMaterial
          co2PerMaterialUnit: $co2PerMaterialUnit
        ) {
          ${this.fields}
        }
      }
    `;

    if (order.productionUnit === 'UNLIMITED') {
      // TODO: find another way to remove property
      // eslint-disable-next-line
      const { quantity, ...orderWithoutQuantity } = order;
      order = orderWithoutQuantity;
    }

    return this.apollo
      .mutate({
        mutation,
        variables: {
          ...order,
        },
      })
      .pipe(
        catchAllErrors((error: ObjectLiteral) => {
          this.handleMessage(error, 'orders.error.send');
        }),
        tap(() => {
          this.snackBarService.success('orders.success.send');
        })
      );
  }

  getAvailableProductionUnits(guid: string, articleGuid: string | null | undefined) {
    const query = gql`
      query availableProductionUnits($articleGuid: GUID, $guid: GUID!) {
        availableProductionUnits(articleGuid: $articleGuid, guid: $guid)
      }
    `;
    return this.apollo
      .query<any>({
        query,
        variables: {
          guid,
          articleGuid,
        },
      })
      .pipe(
        catchAllErrors((error: Error) => {
          this.handleMessage(error, 'orders.error.available-units');
        }),
        map((response: ApolloQueryResult<any>) => response.data.availableProductionUnits)
      );
  }

  override update(order: UpdateOrderModel) {
    const mutation = gql`
      mutation updateOrder(
        $orderGuid: GUID!,
        $fixedCost: Float,
        $variableCost: Float,
        $revenue: Float,
        $wastePercentage: Float,
        $usedMaterial: Float,
        $co2PerMaterialUnit: Float
      ) {
      updateOrder(
        orderGuid: $orderGuid,
        fixedCost: $fixedCost,
        variableCost: $variableCost,
        revenue: $revenue,
        wastePercentage: $wastePercentage,
        usedMaterial: $usedMaterial,
        co2PerMaterialUnit: $co2PerMaterialUnit
      ) {
          ${this.fields}
        }
      }
    `;

    return this.apollo.mutate({ mutation, variables: { ...order } }).pipe(
      catchAllErrors((error) => {
        this.handleMessage(error, 'orders.error.update');
      }),
      tap(() => {
        this.snackBarService.success('orders.success.update');
      }),
      map((response: ApolloQueryResult<any>) => response.data.order)
    );
  }

  override getByGuid(orderGuid: string) {
    const query = gql`
      query order($orderGuid: GUID!) {
        order(orderGuid: $orderGuid) {
         ${this.fields}
        }
      }
    `;

    return this.apollo
      .query<any>({
        query,
        variables: {
          orderGuid,
        },
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'orders.error.get-one');
        }),
        map((response: ApolloQueryResult<any>) => response.data.order)
      );
  }

  override delete(id: number): Observable<Order> {
    throw new Error(`Order ${id} cannot be deleted`);
  }

  protected override get allQuery(): string {
    const query = `
    query orders($searchCriteria: OrderSearchCriteriaInput!) {
      orders(searchCriteria: $searchCriteria) {
        records {
          ${this.fields}
        }
        ${this.pageDetails}
      }
    }
  `;

    return query;
  }

  queueUpdated(machineGuid: string): Observable<Array<OrderQueueUpdatedDTO>> {
    return this.apollo
      .subscribe<{ orderQueueUpdated: Array<OrderQueueUpdatedDTO> }>({
        query: orderQueueUpdated,
        variables: {
          machineGuid,
        },
      })
      .pipe(
        map((response: FetchResult<{ orderQueueUpdated: Array<OrderQueueUpdatedDTO> }>) => {
          return response.data?.orderQueueUpdated ?? [];
        })
      );
  }

  statusUpdated(orderGuid: string): Observable<OrderStatusUpdatedDTO> {
    return this.apollo
      .subscribe<{ orderStatusUpdated: OrderStatusUpdatedDTO }>({
        query: orderStatusUpdated,
        variables: {
          orderGuid,
        },
      })
      .pipe(
        map((response: FetchResult<{ orderStatusUpdated: OrderStatusUpdatedDTO }>) => {
          return response.data?.orderStatusUpdated as OrderStatusUpdatedDTO;
        })
      );
  }
}
