import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-readonly',
  templateUrl: './readonly.component.html',
  styleUrls: ['./readonly.component.scss'],
})
export class ReadonlyComponent {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();
}
