import { Model } from 'ngx-super-model';
import { IShiftValueDTO } from 'src/app/main/admin/models';

import { LocationDTO } from '@locations';
import { OrderDTO } from '@orders';

import { CompanyDTO } from '../../company/interfaces/company.interface';
import { LicenseDTO } from '../../license/interfaces/licenses.interface';
import { MachineStatus } from '../enums/machine-status.enum';
import { MachineDTO } from '../interfaces/machine.interface.model';
import { OrderFileDTO } from '../interfaces/order-file.interface';
import { MachineTypeDTO } from '../machine-type/machine-type.model';
import { MachineCategoryDTO } from './machine-category.model';

export class Machine extends Model<MachineDTO> {
  guid?: string | null = null;
  name?: string | null = null;
  displayName?: string | null = null;
  alias?: string | null = null;
  ipAddress?: string | null = null;
  macAddress?: string | null = null;
  platform?: string | null = null;
  type?: string | null = null;
  location?: LocationDTO | null = null;
  licenses?: Array<LicenseDTO> = [];
  orders?: Array<OrderDTO> = [];
  machineType?: MachineTypeDTO | null = null;
  machineCategory?: MachineCategoryDTO | null = null;
  autofillQueue?: boolean = false;
  company?: CompanyDTO | null = null;
  lastDataReceivedAt?: string | null = null;
  currentShift?: IShiftValueDTO | null = null;
  shiftLastChangedAt?: string | null = null;
  currentStatus?: MachineStatus | null = null;
  statusLastChangedAt?: string | null = null;
  currentSpeedRpm?: number | null = null;
  currentPickCounter?: number | null = null;
  currentOrder?: OrderDTO | null = null;
  currentEfficiencyPercent?: number | null = null;
  locationName?: string | null = null;
  machineCategoryName?: string | null = null;
  createdAt?: string | null = null;
  state?: string | null = null;
  orderFiles?: Array<OrderFileDTO> = [];
  updatedAt?: string | null = null;
  deactivatedAt?: string | null = null;
  serialNumber?: string | null = null;
  anNumber?: string | null = null;
  firmwareVersion?: string | null = null;
  orderQueueLength?: number | null = null;
  camosNumber?: number | null = null;
  energyConsumptionKw?: number | null = null;
  deletedAt?: string | null = null;
}
