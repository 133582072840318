import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, OnInit {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  disabled = false;
  value = '';

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(true);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable(disabled: boolean): void {
    this.disabled = disabled;
  }

  onValueChanged(event: Event) {
    const value = (event.target as HTMLTextAreaElement).value;
    this.value = value;
    this.propagateChange(value);
  }

  onBlur() {
    this.propagateTouched();
  }

  // eslint-disable-next-line
  private propagateChange = (value: string) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
