export enum TagTypeEnum {
  'basic' = 'basic',
  'list' = 'list',
}

export interface TagDTO {
  type: TagTypeEnum;
  icon: string;
  text?: string;
  children?: Array<TagDTO>;
}
