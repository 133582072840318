import { IStepOption } from 'ngx-ui-tour-md-menu';
import { UserGroupEnum } from 'src/app/enum';

const adminRoute = 'admin';

export const locationsHowToCreateTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'admin-locations-link',
    content: 'Select the "Locations" item to manage your locations.',
    route: `${adminRoute}/locations`,
  },
  {
    stepId: '2',
    anchorId: 'select-company-location-form',
    content: 'Enter or select company from the list',
    route: `${adminRoute}/locations/new`,
  },
  {
    stepId: '3',
    anchorId: 'enter-location-name-location-form',
    content: 'Enter the name of the new location.',
    route: `${adminRoute}/locations/new`,
  },
  {
    stepId: '4',
    anchorId: 'enter-location-address-location-form',
    content: 'Enter the location address',
    route: `${adminRoute}/locations/new`,
  },
  {
    stepId: '5',
    anchorId: 'save-new-location',
    content: 'If all the data is correct, save the form.',
    route: `${adminRoute}/locations/new`,
  },
];

export const configureAliasShiftsTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'admin-locations-link',
    content: 'Each of your locations has its own set of shift aliases. To configure aliases, please navigate to Locations.',
    route: `${adminRoute}/locations`,
  },
  {
    stepId: '2',
    anchorId: 'moreOptions-0',
    content: 'Open the menu for the location you would like to configure',
  },
  {
    stepId: '3',
    anchorId: 'alias-0',
    content:
      'Configure aliases for the shifts. Depending on your requirements, you may want to call shift A "Machine Setup", shift B "Maintenance" etc."',
  },
  {
    stepId: '4',
    anchorId: 'save-shift-configuration',
    content: 'Click here to store your configuration.',
  },
];

export const companiesTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'admin-link',
    content: 'Click on Admin',
    route: 'tutorial',
  },
  {
    stepId: '2',
    anchorId: 'create-company',
    content: 'Clicking on button, you will be navigate to company form',
    route: `${adminRoute}/companies`,
  },
  {
    stepId: '3',
    anchorId: 'enter-company-name',
    content: 'Enter the company name',
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '4',
    anchorId: 'enter-location-address',
    content: "Enter the location name which you'd like to add",
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '5',
    anchorId: 'enter-location-name',
    content: 'Enter the location name',
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '6',
    anchorId: 'add-user-name',
    content: 'Enter user name which you want to assign to the company',
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '7',
    anchorId: 'add-email',
    content: 'Enter email which you want to assign to the company',
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '8',
    anchorId: 'save-new-company',
    content: 'If all the data is correct, save the form.',
    route: `${adminRoute}/companies/new`,
  },
  {
    stepId: '9',
    anchorId: 'search-company',
    content: "Enter the company name which you'd like to search.",
    route: `${adminRoute}/companies`,
  },
  {
    stepId: '10',
    anchorId: 'column-name',
    content: 'This represents the company name.',
    route: `${adminRoute}/companies`,
  },
  {
    stepId: '11',
    anchorId: 'column-noUsers',
    content: 'This represents the amount of registered users.',
    route: `${adminRoute}/companies`,
  },
  {
    stepId: '12',
    anchorId: 'column-locations',
    content: 'This represents the amount of active locations.',
    route: `${adminRoute}/companies`,
  },
  {
    stepId: '13',
    anchorId: 'company-details',
    content: 'If you want to see more details for company, click on row.',
    route: `${adminRoute}/companies`,
  },
];

export const usersTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'create-user',
    content: 'To add a new user, click on this button.',
    route: `${adminRoute}/users`,
  },
  {
    stepId: '2',
    anchorId: 'enter-user-name',
    content: "Fill in the user's name.",
    route: `${adminRoute}/users/new`,
  },
  {
    stepId: '3',
    anchorId: 'select-active-date',
    content: "Fill in the user's activation date. Before this date they will not be able to sign in.",
  },
  {
    stepId: '4',
    anchorId: 'select-end-date',
    content: "Optionally fill in the user's end date. After this date they will not be able to sign in.",
  },
  {
    stepId: '5',
    anchorId: 'select-company',
    content: 'Please select company for your new user',
  },
  {
    stepId: '6',
    anchorId: 'enter-email',
    content:
      'The new user will receive an invitation with instructions how to set their password to this email address. Please make sure this address is valid.',
  },
  {
    stepId: '7',
    anchorId: 'select-user-group',
    content: 'Please select the role for your new user.',
  },
  {
    stepId: '8',
    anchorId: 'locations',
    content:
      'Please select the location for this user. Unless the user is a Company Admin, they will only be able to access this location.',
  },
  {
    stepId: '9',
    anchorId: 'save-new-user',
    content: 'If all the data is correct, save the form.',
  },
];

export const licensesTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'admin-link',
    content: 'Click on Admin',
    route: 'tutorial',
  },
  {
    stepId: '2',
    anchorId: 'create-license',
    content: 'Clicking on button, you will be navigate to license form',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '3',
    anchorId: 'select-company',
    content: 'Select a company from the dropdown menu.',
    route: `${adminRoute}/new-license`,
  },
  {
    stepId: '4',
    anchorId: 'select-licenses',
    content: 'Select a licenses from the dropdown menu.',
    route: `${adminRoute}/new-license`,
  },
  {
    stepId: '5',
    anchorId: 'licenses-start',
    content: 'Fill licenses start date',
    route: `${adminRoute}/new-license`,
  },
  {
    stepId: '6',
    anchorId: 'licenses-end',
    content: 'Fill licenses end date',
    route: `${adminRoute}/new-license`,
  },
  {
    stepId: '7',
    anchorId: 'save-new',
    content: 'If all the data is correct, save the form.',
    route: `${adminRoute}/new-license`,
  },
  {
    stepId: '8',
    anchorId: 'filter-ALL',
    content: 'This filter displays all the licenses.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '9',
    anchorId: 'filter-ACTIVE',
    content: 'This filter displays all active licenses.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '10',
    anchorId: 'filter-UPCOMING',
    content: 'This filter displays all upcoming licenses.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '11',
    anchorId: 'filter-EXPIRED',
    content: 'This filter displays all expired licenses.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '12',
    anchorId: 'column-company',
    content: 'This represents the company name.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '13',
    anchorId: 'column-license_start',
    content: 'This represents the license start date.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '14',
    anchorId: 'column-license_end',
    content: 'This represents the license end date.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '15',
    anchorId: 'column-type',
    content: 'This represents the license type.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '16',
    anchorId: 'column-machines',
    content: 'This represents the amount of assigned/total machines.',
    route: `${adminRoute}/licenses`,
  },
  {
    stepId: '17',
    anchorId: 'column-status',
    content: 'This represents the status of the license.',
    route: `${adminRoute}/licenses`,
  },
];

export const machinesTutorialGuide: Array<IStepOption> = [
  {
    stepId: '1',
    anchorId: 'admin-link',
    content: 'Click on Admin',
    route: 'tutorial',
  },
  {
    stepId: '2',
    anchorId: 'machine-category',
    content: 'Go to machine type',
    route: `${adminRoute}/machines`,
  },
  {
    stepId: '3',
    anchorId: 'machine-category-title',
    content: "This represents machine's type name.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '4',
    anchorId: 'good-efficiency',
    content: "This represents machine's efficency with good performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '5',
    anchorId: 'good-stops',
    content: "This represents machine's stops with good performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '6',
    anchorId: 'good-speed',
    content: "This represents machine's speed with good performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '7',
    anchorId: 'poor-efficiency',
    content: "This represents machine's efficency with poor performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '8',
    anchorId: 'poor-stops',
    content: "This represents machine's stops with poor performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '9',
    anchorId: 'poor-speed',
    content: "This represents machine's speed with poor performance.",
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '10',
    anchorId: 'save',
    content: 'Click here to save the machine type.',
    route: `${adminRoute}/machine-category`,
  },
  {
    stepId: '11',
    anchorId: 'search-machine',
    content: "Enter the machine name which you'd like to search.",
    route: `${adminRoute}/machines`,
  },
];

export const tutorialGuide: {
  [params: string]: { [params: string]: { steps: Array<IStepOption>; permission: Array<UserGroupEnum> } };
} = {
  admin: {
    newLocation: {
      steps: locationsHowToCreateTutorialGuide,
      permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN],
    },
    configureAliasShifts: {
      steps: configureAliasShiftsTutorialGuide,
      permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN],
    },
    newUsers: {
      steps: usersTutorialGuide,
      permission: [UserGroupEnum.COMPANY_ADMIN, UserGroupEnum.JM_ADMIN],
    },
  },
  dashboard: {},
};
