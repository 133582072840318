import { map, Observable } from 'rxjs';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { ListResultDTO, SearchCriteria } from '@jm-table';

import { AbstractCRUDService } from '../abstract-crud.service';
import { ResponseData } from '../abstract/interface/response-data';
import controlCenterOrders from './gql/control-center-order.graphql';
import { OrderDTO } from './interfaces';
import { ControlCenterOrderDTO } from './interfaces/control-center-order.interface';
import { Order } from './models';

@Injectable({ providedIn: 'root' })
export class ControlCenterOrderService extends AbstractCRUDService<Order, OrderDTO> {
  getTableData(searchCriteria: SearchCriteria, filters: { filter: ObjectLiteral; guid: string }): Observable<ListResultDTO<Order>> {
    return this.apollo.query<ControlCenterOrderDTO>({ query: controlCenterOrders, variables: { searchCriteria, ...filters } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('orders.error.get-all');
      }),
      map((response: ApolloQueryResult<ResponseData<ControlCenterOrderDTO>>) => {
        const { records, pageDetails } = response.data.controlCenter.machineDetails.orders;
        const orders = records.map((order) => new Order().loadModel(order));
        return {
          records: orders,
          pageDetails,
        };
      })
    );
  }
}
