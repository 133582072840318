import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';

import { AlertConfiguration } from '../model';
import {
  AlertConfigurationState,
  NotificationConfigurationState,
  notificationConfigurationStateName,
} from './notification-configuration.state';

const selectNotificationConfigurationSelector = createFeatureSelector<NotificationConfigurationState>(notificationConfigurationStateName);

const selectNotificationConfigurationState: Selector<object, NotificationConfigurationState> = createSelector(
  selectNotificationConfigurationSelector,
  (state: NotificationConfigurationState) => state
);

const selectConfigurationState: Selector<object, AlertConfigurationState> = createSelector(
  selectNotificationConfigurationState,
  (state: NotificationConfigurationState) => state.alertConfiguration
);

export const selectAlertConfiguration: Selector<object, any> = createSelector(selectConfigurationState, (state: AlertConfigurationState) =>
  state.alertConfiguration ? convertAlertConfiguration(state.alertConfiguration) : null
);

export const selectAlertConfigurationLoader: Selector<object, boolean> = createSelector(
  selectConfigurationState,
  (state: AlertConfigurationState) => state.alertConfigurationLoader
);

export const selectAlertSubmittingLoading: Selector<object, boolean> = createSelector(
  selectConfigurationState,
  (state: AlertConfigurationState) => state.submittingLoading
);

function convertAlertConfiguration(alertConfiguration: AlertConfiguration): {
  [params: string]: { value: number | boolean | Array<string>; checked: boolean };
} {
  const configuration: {
    [params: string]: { value: number | boolean | Array<string>; checked: boolean };
  } = {};

  Object.keys(alertConfiguration).map((value: string) => {
    if (value === 'machineStoppedAlertReasons') {
      configuration[value] = {
        value: (alertConfiguration as any)[value],
        checked: (alertConfiguration as any)['machineStoppedAlert'],
      };

      return;
    }

    configuration[value] = {
      value: (alertConfiguration as any)[value],
      checked: Boolean((alertConfiguration as any)[value]),
    };
  });

  return configuration;
}
