<div class="support-feedback-wrapper">
  <div class="support-feedback-box">
    <ng-container *ngIf="!loader">
      <div class="title">{{ 'support-feedback.title' | translate }}</div>
      <div class="description" [innerHtml]="'support-feedback.description' | translate"></div>

      <form *ngIf="form" class="quick-form form">
        <quick-form-controls [form]="form" [config]="formConfig"> </quick-form-controls>
      </form>

      <jm-button [class]="'red-button'" (clicked)="sendFeedback()">
        {{ 'button.send-feedback' | translate }}
      </jm-button>
    </ng-container>
    <app-loader class="jm-loader" *ngIf="loader"></app-loader>
  </div>
</div>
