export enum MachineProperty {
  Speed = 'speed',
  CurrentSpeedRpm = 'machine.currentSpeedRpm',
  Efficiency = 'efficiency',
  Stops = 'stops',
  Shifts = 'shifts',
  CurrentSpeed = 'current-speed',
}

export const machineProperties: Array<MachineProperty> = [MachineProperty.Efficiency, MachineProperty.Speed, MachineProperty.Stops];
