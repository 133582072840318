<div class="widget widget--efficiency-per-shift">
  <widget-header [widget]="widget"></widget-header>

  <widget-content [error]="error" [noData]="noData">
    <main class="widget__main">
      <div *ngIf="!loading; else loader" class="graph">
        <jm-chart [chartConfig]="chartConfig" [id]="widget.id"></jm-chart>
      </div>
    </main>
  </widget-content>

  <widget-footer [tags]="tags"></widget-footer>
</div>

<ng-template #loader> <app-loader class="jm-loader" [centered]="true"></app-loader></ng-template>
