import { cloneDeep } from 'lodash-es';
import { selectUserNotificationEuids } from '@auth';
import { AbstractFormComponent } from 'src/app/modules/quick-forms/components/abstract-form.component';

import { Component, OnInit, inject } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { IAlertConfiguration } from '../../model';
import { SaveAlertConfiguration, UpdateAlertConfiguration } from '../../store/notification-configuration.actions';
import { selectAlertConfiguration } from '../../store/notification-configuration.selector';
import { AlertConfigurationValue, AlertConfigurationValues } from './types/alert-configuration-value.type';

@UntilDestroy()
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent extends AbstractFormComponent implements OnInit {
  private readonly store$ = inject(Store);

  override preventUnsavedForm = true;
  userNotificationEuids$ = this.store$.pipe(select(selectUserNotificationEuids));

  alertConfiguration: AlertConfigurationValues | null = null;
  alertConfigurationCopy: AlertConfigurationValues | null = null;
  title = 'notifications.configuration';

  ngOnInit(): void {
    this.store$.pipe(select(selectAlertConfiguration), untilDestroyed(this)).subscribe((res) => {
      this.alertConfiguration = res;
      this.alertConfigurationCopy = cloneDeep(res) as any;
    });
  }

  resetAll(): void {
    this.alertConfiguration = null;
    this.alertConfiguration = cloneDeep(this.alertConfigurationCopy);
  }

  save(): void {
    this.saveAlertConfiguration();
  }

  focusChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.title = 'notifications.configuration';
      return;
    }

    if (event.index === 1) {
      this.title = 'notifications.push-notification-configuration';
      return;
    }
  }

  setAlertConfiguration(event: AlertConfigurationValue): void {
    this.formTouched = true;

    const { value, checked, fieldName } = event;

    if (!this.alertConfiguration) {
      return;
    }

    if (!fieldName) {
      return;
    }

    this.alertConfiguration[fieldName] = {
      value,
      checked,
    };
  }

  alertConfigChanged(): void {
    this.formTouched = true;
  }

  private saveAlertConfiguration(): void {
    const alertConfiguration: any = new IAlertConfiguration(null, null, null, null, null, false, null, null, null, null, []);

    if (!this.alertConfiguration) {
      return;
    }

    Object.keys(this.alertConfiguration).map((key: any) => {
      if (!this.alertConfiguration) {
        return;
      }

      if (key === 'machineStoppedAlert') {
        alertConfiguration[key] = this.alertConfiguration[key].value as boolean;
      } else if (key === 'machineStoppedAlertReasons') {
        alertConfiguration[key] = this.alertConfiguration[key].value;
      } else {
        alertConfiguration[key] = this.alertConfiguration[key].checked ? this.alertConfiguration[key].value : null;
      }
    });

    this.store$.dispatch(new UpdateAlertConfiguration({ alertConfiguration }));
    this.store$.dispatch(new SaveAlertConfiguration({ alertConfiguration }));

    this.formTouched = false;
  }
}
