import { MutationResult } from 'apollo-angular';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { AbstractCRUDService } from 'src/app/core/abstract-crud.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { GetAllOptionsDTO, ListResultDTO, SearchCriteriaDTO } from '@jm-table';

import { ResponseData } from '../abstract/interface/response-data';
import unreadCount from './gql/alerts-unread-count.graphql';
import deleteMutation from './gql/delete.graphql';
import getAll from './gql/get-all.graphql';
import markAlertsAsRead from './gql/mark-alerts-as-read.graphql';
import markAllAsRead from './gql/mark-all-as-read.graphql';
import newAlert from './gql/new-alert.graphql';
import oneQuery from './gql/one.graphql';
import { MarkAllAsRead } from './interfaces';
import { AlertDTO } from './interfaces/alert.interface';
import { AlertsResponseDTO } from './interfaces/alerts-response.interface';
import { Alert } from './models/alert.models';

@Injectable({
  providedIn: 'root',
})
export class AlertService extends AbstractCRUDService<Alert, AlertDTO> {
  override modelName = 'alert';
  override modelNamePlural = 'alerts';
  override model = Alert;

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  private readonly unreadCount$ = new BehaviorSubject<number>(0);

  override fields = `
  id
  message
  severity
  createdAt
  readAt
  priority
  type
  `;

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<Alert>> {
    const modelName = this.modelNamePlural;

    return this.apollo.query<AlertsResponseDTO>({ query: getAll, variables: { searchCriteria, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName }));
      }),
      map((response: ApolloQueryResult<AlertsResponseDTO>) => {
        const records = response.data.alerts.records.map(this.loadModel);
        const { pageDetails } = response.data.alerts;

        return {
          pageDetails,
          records,
        };
      })
    );
  }

  getAlerts(searchCriteria: SearchCriteriaDTO): Observable<AlertsResponseDTO> {
    return this.apollo
      .query<AlertsResponseDTO>({
        query: getAll,
        variables: {
          searchCriteria,
        },
      })
      .pipe(
        catchAllErrors(),
        map((response: ApolloQueryResult<AlertsResponseDTO>) => response.data)
      );
  }

  getAlertsUnreadCount(): Observable<number> {
    return this.apollo.query<{ alertsUnreadCount: number }>({ query: unreadCount }).pipe(
      catchAllErrors(),
      map((response: ApolloQueryResult<{ alertsUnreadCount: number }>) => {
        return response.data.alertsUnreadCount;
      })
    );
  }

  markAlertAsRead(alertId: number): Observable<Array<Alert>> {
    return this.apollo
      .mutate({
        mutation: markAlertsAsRead,
        variables: {
          ids: [alertId],
        },
      })
      .pipe(
        map((response: MutationResult<any>) => {
          const alerts = response.data?.markAlertsAsRead as Array<AlertDTO>;
          return alerts?.map((alert) => new Alert().loadModel(alert));
        })
      );
  }

  markAllAlertsAsRead(): Observable<MarkAllAsRead> {
    return this.apollo.mutate<MarkAllAsRead>({ mutation: markAllAsRead }).pipe(
      catchAllErrors(),
      map((result: ApolloQueryResult<ResponseData<MarkAllAsRead>>) => result.data.markAllAlertsAsRead)
    );
  }

  alertsSubscription(): Observable<AlertDTO> {
    return this.apollo
      .subscribe<{ newAlert: AlertDTO }>({
        query: newAlert,
      })
      .pipe(
        catchAllErrors(),
        map((response: ApolloQueryResult<{ newAlert: AlertDTO }>) => {
          return response.data.newAlert;
        })
      );
  }

  unreadCount(): Observable<number> {
    return this.unreadCount$.asObservable();
  }

  setUnreadCount(value: number): void {
    this.unreadCount$.next(value);
  }
}
