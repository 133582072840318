import { Model } from 'ngx-super-model';

import { SensorType } from '../enums/type.enum';
import { SensorConfigurationDTO } from '../interfaces/sensor-configuration.interface';
import { Entry } from './entry.model';

export class SensorConfiguration extends Model<SensorConfigurationDTO> {
  sensorId: string | null = null;
  label: string | null = null;
  type: SensorType | null = null;
  unit?: string | null = null;
  data?: Array<Entry> = [];
}
