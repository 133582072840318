<div class="widget widget--realtime-sensor">
  <widget-header [widget]="widget" [isLive]="isLive"></widget-header>

  <widget-content [error]="error" [noData]="noData">
    <main class="widget__main" *ngIf="!licenseHasExpired">
      <ng-container *ngIf="!loading; else loader">
        <app-notice *ngIf="isEdit" type="warning" [description]="'sensor.widget.real-time.warning' | translate"></app-notice>

        @if(isEdit){
        <mat-grid-list
          cdkDropListGroup
          [class.edit-grid-list]="isEdit"
          cdkDropListGroup
          [cols]="getColumnConfiguration"
          rowHeight="48px"
          gutterSize="10px"
        >
          @for (sensorData of data; track sensorData.sensorId; let index = $index) {
          <mat-grid-tile>
            <cdk-drop-list [cdkDropListData]="index" style="width: 100%">
              <app-realtime-sensor-configuration
                [sensorData]="sensorData"
                [machineGuid]="machineGuid"
                [isEdit]="isEdit"
                [index]="index"
                (toggleChartWrapper)="onToggleChartWrapper(index)"
                (entered)="onEntered($event)"
                (dropped)="onDropped($event)"
              ></app-realtime-sensor-configuration>
            </cdk-drop-list>
          </mat-grid-tile>

          }
        </mat-grid-list>
        } @else {
        <div class="realtime-list" [ngClass]="configurationRowClass">
          @for (sensorData of data; track sensorData.sensorId; let index = $index) {
          <app-realtime-sensor-configuration
            [sensorData]="sensorData"
            [machineGuid]="machineGuid"
            [isEdit]="isEdit"
            (toggleChartWrapper)="onToggleChartWrapper(index)"
          ></app-realtime-sensor-configuration>
          }
        </div>
        }
      </ng-container>
    </main>
    <app-empty-block
      *ngIf="licenseHasExpired"
      [title]="'sensor.error.expired-license' | translate"
      [description]="'global.contact-jm' | translate"
    >
    </app-empty-block>
  </widget-content>

  <widget-footer [tags]="tags"></widget-footer>
</div>

<ng-template #loader> <app-loader class="jm-loader" [centered]="true"></app-loader></ng-template>
