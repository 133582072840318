import { ChartConfiguration } from 'chart.js';
import { cloneDeep } from 'lodash-es';
import { Period } from 'src/app/util/period/period.interface';

import { Component, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';

import { selectDashboardPeriod } from '../../custom-dashboard/store/dashboard.selector';
import { UNIT } from './average-efficiency.constants';
import { FooterTag } from '../core/interfaces/footer-tag.interface';
import { PeriodDTO } from '../core/interfaces/period.interface';
import { TagTypeEnum } from '../core/interfaces/tags.interface';
import { periodToBucketSize, statePeriodToStartEndDate } from '../core/mappers/period-mapper';
import { Widget } from '../core/models/widgets/widget.model';
import { AverageEfficiencyService } from './average-efficiency.service';
import { efficiencyChartConfig } from './chart-config';
import { dataToAverageEfficiencyChart } from './mappers/data-to-chart.mappers';
import { AverageEfficiencyRequest } from './interfaces/average-efficiency.request';

@UntilDestroy()
@Component({
  selector: 'widget-average-efficiency',
  templateUrl: './average-efficiency.component.html',
  styleUrls: ['./average-efficiency.component.scss'],
})
export class AverageEfficiencyComponent implements OnInit {
  private readonly store$ = inject(Store);
  private readonly widgetService = inject(AverageEfficiencyService);

  @Input() widget: Widget = new Widget();
  @Input()
  dashboardId: string | undefined | null = null;

  value?: number;
  percentage?: number;
  chartConfig: ChartConfiguration = cloneDeep(efficiencyChartConfig);
  loading = true;

  tags: Array<FooterTag> = [];
  error = false;
  errorDescription: string | null = null;
  noData = false;

  readonly unit = UNIT;

  ngOnInit() {
    if (!this.dashboardId) {
      return;
    }

    this.store$
      .pipe(select(selectDashboardPeriod(this.dashboardId)))
      .pipe(untilDestroyed(this))
      .subscribe((period: Period) => {
        this.loadData(period);
      });

    this.populateTags();
  }

  private loadData(period: Period): void {
    const { machine } = this.widget.config;

    this.loading = true;
    this.noData = false;
    this.error = false;

    const mappedPeriod: PeriodDTO = statePeriodToStartEndDate(period);
    const bucketSize = periodToBucketSize(mappedPeriod);

    const request: AverageEfficiencyRequest = {
      machineGuids: [machine.id],
      ...mappedPeriod,
      bucketSize: bucketSize,
    };

    this.widgetService
      .getData(request)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (averageEfficiency) => {
          this.loading = false;
          if (averageEfficiency.buckets.length === 0) {
            this.noData = true;
            return;
          }

          this.value = averageEfficiency.average;
          this.chartConfig.data = dataToAverageEfficiencyChart(averageEfficiency.buckets, mappedPeriod);
        },
        error: (error: Error) => {
          this.loading = false;
          this.error = true;

          if (error.message === 'Invalid location') {
            this.errorDescription = 'global.errors.location-not-found-widget';
          }
        },
      });
  }

  private populateTags(): void {
    const location = this.widget.config.location;
    const machine = this.widget.config.machine;

    this.tags = [
      {
        type: TagTypeEnum.basic,
        icon: 'locations',
        text: location.name,
      },
      {
        type: TagTypeEnum.basic,
        icon: 'machines',
        text: machine.name,
      },
    ];
  }
}
