import { UserDTO } from 'src/app/core/user/interfaces/user.interface';
import { PayloadAction } from 'src/app/shared/util';

import { Action } from '@ngrx/store';
import { User } from '@user';

export const enum LoginActionType {
  GET_USER_INFO = '[LOGIN] GET USER INFO',
  SET_USER_INFO = '[LOGIN] SET USER INFO',

  USER_LOGIN = '[LOGIN] USER LOGIN',
  LOGIN_SUCCESS = '[LOGIN] LOGIN SUCCESS',
  LOGIN_ERROR = '[LOGIN] LOGIN ERROR',

  SET_NEW_PASSWORD = '[LOGIN] SET NEW PASSWORD',
  FORGOT_PASSWORD = '[LOGIN] FORGOT PASSWORD',
  SUCCESSFULLY_FORGOT_PASSWORD = '[LOGIN] SUCCESSFULLY FORGOT PASSWORD',
  ERROR_NEW_PASSWORD = '[LOGIN] ERROR NEW PASSWORD',
  REQUEST_BACKEND_VERSION = '[LOGIN] REQUEST_BACKEND_VERSION',
  RESPONSE_BACKEND_VERSION = '[LOGIN] RESPONSE_BACKEND_VERSION',

  RESPONSE_SCHEDULED_NOTIFICATIONS = '[LOGIN] RESPONSE_SCHEDULED_NOTIFICATIONS',
  CLOSE_SCHEDULED_NOTIFICATIONS = '[LOGIN] CLOSE_SCHEDULED_NOTIFICATIONS',

  ACTIVE_LICENSES_REQUESTED = '[LOGIN] ACTIVE_LICENSES_REQUESTED',
  ACTIVE_LICENSES_UPDATED = '[LOGIN] UPDATE_ACTIVE_LICENSES',
}

export class GetUserInfo implements Action {
  readonly type: LoginActionType = LoginActionType.GET_USER_INFO;
}

export class SetUserInfo implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.SET_USER_INFO;

  constructor(public payload: { user: User }) {}
}

export class UserLogin implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.USER_LOGIN;

  constructor(public payload: { email: string; password: string }) {}
}

export class LoginSuccess {
  readonly type: LoginActionType = LoginActionType.LOGIN_SUCCESS;
}

export class LoginError {
  readonly type: LoginActionType = LoginActionType.LOGIN_ERROR;
}

export class SetNewPassword implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.SET_NEW_PASSWORD;

  constructor(
    public payload: {
      password: string;
      resetToken: string | null;
    }
  ) {}
}

export class ForgotPassword implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.FORGOT_PASSWORD;

  constructor(public payload: { email: string }) {}
}

export class SuccessfullyForgotPassword implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.SUCCESSFULLY_FORGOT_PASSWORD;

  constructor(public payload: { user: UserDTO }) {}
}

export class ErrorNewPassword implements Action {
  readonly type: LoginActionType = LoginActionType.ERROR_NEW_PASSWORD;
}
export class RequestBackendVersion implements Action {
  readonly type: LoginActionType = LoginActionType.REQUEST_BACKEND_VERSION;
}
export class ResponseBackendVersion implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.RESPONSE_BACKEND_VERSION;

  constructor(public payload: { version: string }) {}
}
export class ResponseScheduledNotifications implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.RESPONSE_SCHEDULED_NOTIFICATIONS;

  constructor(public payload: { userNotification: { id: number; message: string } | null }) {}
}

export class CloseScheduledNotifications implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.CLOSE_SCHEDULED_NOTIFICATIONS;

  constructor(public payload: { id: number }) {}
}

export class ActiveLicensesRequested implements Action {
  readonly type: LoginActionType = LoginActionType.ACTIVE_LICENSES_REQUESTED;
}

export class ActiveLicensesUpdated implements PayloadAction {
  readonly type: LoginActionType = LoginActionType.ACTIVE_LICENSES_UPDATED;

  constructor(public payload: { activeLicenses: Array<{ type: string }> }) {}
}

export type LoginAction =
  | GetUserInfo
  | SetUserInfo
  | UserLogin
  | SetNewPassword
  | ForgotPassword
  | SuccessfullyForgotPassword
  | RequestBackendVersion
  | ResponseBackendVersion
  | ResponseScheduledNotifications
  | CloseScheduledNotifications;
