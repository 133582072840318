<ng-content *ngIf="!error && !noData"></ng-content>

<app-empty-block
  *ngIf="error"
  [title]="'global.errors.error' | translate"
  [image]="'./assets/images/machines-error.svg'"
  [description]="errorDescription ?? 'global.errors.could-not-fetch-data' | translate"
>
</app-empty-block>

<app-empty-block *ngIf="noData" [title]="'global.no-data' | translate"> </app-empty-block>
