import { VersionReadyEvent } from '@angular/service-worker';
import { ObjectLiteral } from './object-literal';

export function isNewVersion(event: VersionReadyEvent) {
  const { currentVersion, latestVersion } = event as VersionReadyEvent;
  const version = (currentVersion?.appData as ObjectLiteral)?.version;
  const newVersion = (latestVersion?.appData as ObjectLiteral)?.version;

  return version !== newVersion;
}
