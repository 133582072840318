import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { AbstractTableComponent } from 'src/app/modules/jm-table/components/abstract-table/abstract-table.component';
import { JmTableModule } from 'src/app/modules/jm-table/jm-table.module';
import { TableConfig } from 'src/app/modules/jm-table/models/table.config';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { CommonModule } from '@angular/common';
import { Component, Injector, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { OeeByMachineCategoryDTO } from './interfaces';
import { OeeByMachineCategory } from './models';
import { OeeByMachineCategoryService } from './oee-by-machine-category.service';
import { oeeByMachineCategoryTableConfig } from './settings/table.config';

@Component({
  selector: 'widget-oee-by-machine-category',
  standalone: true,
  imports: [CommonModule, JmTableModule, TranslateModule, BoxModule, EmptyBlockModule],
  templateUrl: './oee-by-machine-category.component.html',
  styleUrls: ['./oee-by-machine-category.component.scss'],
})
export class OeeByMachineCategoryComponent extends AbstractTableComponent<OeeByMachineCategory, OeeByMachineCategoryDTO> {
  @Input()
  set loader(loader: boolean) {
    this.loading = loader;
  }
  @Input()
  set tableData(data: Array<OeeByMachineCategory>) {
    this.data = data;
  }
  @Input()
  error: string | null = null;

  override tableConfig: TableConfig = new TableConfig().loadModel(oeeByMachineCategoryTableConfig);

  constructor(override service: OeeByMachineCategoryService, injector: Injector) {
    super(service, injector);
  }
}
