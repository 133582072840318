import { OrderStatus } from '../enums';

export function orderStatusToCssClass(status: OrderStatus): string {
  if (status === OrderStatus.FAILED || status === OrderStatus.WAITING_FOR_DATA) {
    return 'danger';
  }

  if (status === OrderStatus.FINISHED || status === OrderStatus.IN_PRODUCTION || status === OrderStatus.SENT) {
    return 'success';
  }

  if (status === OrderStatus.MUNET_CONNECT_QUEUED || status === OrderStatus.MACHINE_QUEUED || status === OrderStatus.REMOVED) {
    return 'disable';
  }

  return '';
}
