import { ProgressBar } from 'src/app/shared/modules/progress-bar/progress-bar.interface';
import { getColorByValueForPercentage } from 'src/app/util/util';

import { Component } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-oee-cell]',
  templateUrl: './jm-oee-cell.component.html',
  styleUrls: ['./jm-oee-cell.component.scss'],
})
export class JmOeeCellComponent extends AbstractCellComponent {
  get valueInPercentage(): number {
    const value = this.cellData[(this, this.columnField)] || 0;
    return value * 100;
  }

  get progressBar(): ProgressBar {
    return {
      type: 'secondary',
      value: this.valueInPercentage,
      class: getColorByValueForPercentage(this.valueInPercentage),
      striped: false,
      animate: false,
    };
  }
}
