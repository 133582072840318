<div class="cell">
  @if(!cellData.isMymuenetCreated){
  <svg-icon
    class="hidden-mobile has-tooltip"
    tooltipPosition="top"
    [pTooltip]="'orders.machine-created' | translate"
    [src]="'./assets/images/order-created-mymunet.svg'"
  ></svg-icon>
  } @if(cellData.machine?.platform === machinePlatform.COMEZ){
  {{ cellData.article.name }}
  } @if(cellData.machine?.platform !== machinePlatform.COMEZ){
  {{ cellData.name }}
  } @if(!cellData.isMymuenetCreated){
  <svg-icon class="hidden-tablet hidden-desktop" [src]="'./assets/images/order-created-mymunet.svg'"></svg-icon>
  }
</div>
