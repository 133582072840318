import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client';
import { AverageStopsRequest } from '../interface/average-stops.request';
import { ResponseData } from '../../../abstract/interface/response-data';

import averageStopsQuery from './../gql/average-stops.graphql';
import { AverageStopsDTO } from '../interface/average-stops.interface';

@Injectable({
  providedIn: 'root',
})
export class AverageStopsService {
  private readonly apollo = inject(Apollo);
  private readonly snackbarService = inject(SnackBarService);

  getData(request: AverageStopsRequest) {
    return this.apollo
      .query({
        query: averageStopsQuery,
        variables: {
          input: request,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackbarService.error('widgets.error.average-stops');
        }),
        map((response: ApolloQueryResult<ResponseData<AverageStopsDTO>>) => {
          return response.data.averageStopsChart;
        })
      );
  }
}
