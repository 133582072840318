import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import newReleaseNotes from './queries/release-notes-subscription.graphql';
import { FetchResult } from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class ReleaseNotesSubscriptionService {
  private readonly apollo = inject(Apollo);

  newReleaseNotes() {
    return this.apollo
      .subscribe<any>({
        query: newReleaseNotes,
      })
      .pipe(
        map((response: FetchResult<any>) => {
          const notes = response.data.newReleaseNotes;
          return notes;
        })
      );
  }
}
