import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { PercentagePipe } from '@util';
import { getColorByValueForPercentage } from 'src/app/util/util';

@Component({
  selector: 'app-percentage-box',
  standalone: true,
  imports: [CommonModule, PercentagePipe],
  templateUrl: './percentage-box.component.html',
  styleUrls: ['./percentage-box.component.scss'],
})
export class PercentageBoxComponent {
  @Input() value: number | undefined | null;

  get borderColor(): string {
    if (!this.value) {
      return '';
    }
    const value = this.value * 100;

    return getColorByValueForPercentage(value);
  }
}
