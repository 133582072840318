<div class="input-wrapper string-input" (click)="clickInputWrapper()" [class.icon-left]="iconLeftPosition">
  <label class="label" *ngIf="label"
    >{{ label | translate }} <span *ngIf="required">*</span
    ><span class="error-message" *ngIf="errorMessage">{{ errorMessage | translate }}</span></label
  >
  <div class="empty-label" *ngIf="emptyLabel"></div>
  <img class="icon {{ imageClassName }} left" *ngIf="icon" src="./assets/images/{{ icon }}.svg" [alt]="icon" />
  <input
    class="{{ className }}"
    type="text"
    placeholder="{{ placeholder | translate }}"
    [ngClass]="{
      'not-editable': nonEditable,
      invalid: invalid,
      readonly: readonly
    }"
    (afterValueChanged)="valueChange($event.target.value)"
    [disabled]="disabled"
    [(ngModel)]="value"
    [readonly]="readonly"
    [maxlength]="maxLength"
    type="{{ type ? type : 'text' }}"
    #inputField
  />
</div>
<div class="help-message" *ngIf="maxLength">
  {{ ('global.max-length' | translate) + ': ' + maxLength }}
</div>
