import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageRefService } from './local-storage-ref.service';
import { isSet } from './util/util';
import { ObjectLiteral } from 'ngx-super-model/types/object-literal';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly impersonateUserTokenSubject$ = new BehaviorSubject<string | null>(null);

  readonly impersonateUserToken$ = this.impersonateUserTokenSubject$.asObservable();

  private readonly localStorage: Storage;

  constructor(private readonly localStorageRefService: LocalStorageRefService) {
    this.localStorage = this.localStorageRefService.localStorage;
    this.getImpersonateUserToken();
  }

  setImpersonateUserToken(value: { token: string }): void {
    const jsonValue = JSON.stringify(value);
    this.localStorage.setItem('muCloudUserImpersonateUser', jsonValue);
    this.impersonateUserTokenSubject$.next(value.token);
  }

  clearImpersonateUserToken(): void {
    this.localStorage.removeItem('muCloudUserImpersonateUser');
    this.impersonateUserTokenSubject$.next(null);
  }

  getImpersonateUserToken(): void {
    const impersonatedUser = this.localStorage.getItem('muCloudUserImpersonateUser');
    let token = null;

    if (isSet(impersonatedUser)) {
      token = JSON.parse(impersonatedUser)?.token;
    }

    this.impersonateUserTokenSubject$.next(token);
  }

  setSavedState(state: any, localStorageKey: string): void {
    this.localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getSavedState(localStorageKey: string): ObjectLiteral {
    const item = this.localStorage.getItem(localStorageKey);
    if (isSet(item)) {
      return JSON.parse(item);
    }

    throw new Error('Key does not exits!');
  }
}
