import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';

import { ControlConfig } from '../../../models/control.config';
import { ControlTypeEnum } from '../../../enums/control-type.enum';

@Component({
  selector: 'quick-form-control',
  templateUrl: './control.component.html',
})
export class ControlComponent {
  @Input() control: AbstractControl | null | undefined = null;
  @Input() config: ControlConfig = new ControlConfig();

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() openInfoWindow: EventEmitter<void> = new EventEmitter<void>();

  readonly controlTypeEnum = ControlTypeEnum;

  onValueChanged(value: any) {
    this.valueChanged.emit(value);
  }

  onOpenInfoWindow(): void {
    this.openInfoWindow.emit();
  }

  getControl(): FormControl {
    return this.control as FormControl;
  }

  getFormArray(): FormArray {
    return this.control as FormArray;
  }
}
