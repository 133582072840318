import { WidgetDTO, WidgetTypeEnum } from '@widgets';
import { WidgetConfigLimitsDTO } from '../interfaces/widget-config-limits.interface';

export function applyGlobalConfig(widgets: Array<WidgetDTO>, configLimits: WidgetConfigLimitsDTO): Array<WidgetDTO> {
  return widgets.map((widget: WidgetDTO) => {
    const widgetGlobalConfig = configLimits[widget.type as WidgetTypeEnum];
    const existingGridsterConfig = widget.gridsterConfig || {};

    return {
      ...widget,
      gridsterConfig: Object.assign(existingGridsterConfig, widgetGlobalConfig),
    };
  });
}
