<button
  class="{{ class }}"
  [disabled]="disabled"
  [tourAnchor]="tourAnchorId"
  [attr.data-test]="dataTestSelector"
  (click)="toggleable ? toggle() : clicked.emit()"
  [ngClass]="{ loading: loading, 'text-center': textCenter, active: active, open: isOpen }"
>
  <svg-icon
    *ngIf="prefixIcon && !loading"
    class="img-icon left-icon"
    [src]="'./assets/images/' + prefixIcon + '.svg'"
    [class.hide-text]="hideText"
    [class.hide-text-tablet]="hideTextTablet"
  ></svg-icon>

  <div *ngIf="loading && prefixIcon" class="loader prefixIcon" [ngClass]="{ 'text-visible': !textCenter }">
    <app-loader [sizing]="'button'" [invert]="loaderInvert"></app-loader>
  </div>

  <span *ngIf="!(textCenter && loading)" [class.hide-text]="hideText" [class.hide-text-tablet]="hideTextTablet">
    <ng-content></ng-content>
  </span>

  <svg-icon class="expand-arrow" *ngIf="toggleable" [src]="'./assets/images/angle.svg'"></svg-icon>

  <svg-icon
    *ngIf="suffixIcon && !loading"
    class="img-icon right-icon"
    [src]="'./assets/images/' + suffixIcon + '.svg'"
    [class.hide-text]="hideText"
    [class.hide-text-tablet]="hideTextTablet"
  ></svg-icon>

  <div *ngIf="loading && (suffixIcon || textCenter)" class="loader suffixIcon" [ngClass]="{ 'text-visible': !textCenter }">
    <app-loader [sizing]="'button'" [invert]="loaderInvert"></app-loader>
  </div>
</button>
