<div class="alert-content">
  <div class="header-alert">
    <h1 class="title">
      {{ 'global.notifications' | translate }}
    </h1>
    <div class="button-more-options" (click)="showOrHideMoreOptions(); $event.stopPropagation()">
      <svg-icon class="option-icon" [src]="'./assets/images/3-dots.svg'"></svg-icon>
    </div>
  </div>
  @if(showHideMoreOptions){
  <div class="more-options-wrapper">
    <div class="option" [ngClass]="{ disable: unreadCount() === 0 }" (click)="markAllAsRead(); $event.stopPropagation()">
      <svg-icon class="option-icon" [src]="'./assets/images/check.svg'"></svg-icon>
      <span>{{ 'notifications.mark-all' | translate }}</span>
    </div>
    <div class="option" [routerLink]="['/configuration']">
      <svg-icon class="option-icon alert-configuration" [src]="'./assets/images/setting.svg'"></svg-icon>
      <span>{{ 'notifications.configuration' | translate }}</span>
    </div>
  </div>
  } @if(loader() === false){ @if(alerts().length){
  <cdk-virtual-scroll-viewport [itemSize]="alerts().length" (scroll)="onScroll()" id="virtualScrollViewport">
    @for (alert of alerts(); track alert.id) {
    <div class="alert-detail" [ngClass]="{ unread: !alert?.readAt }" [id]="alert?.id" (click)="goToNotificationDetails(alert.id)">
      <div class="alert-icon">
        <img src="./assets/images/{{ getAlertIcon(alert) }}.svg" [alt]="alert?.severity" />
      </div>
      <div class="notification-info">
        <p class="message">
          {{ alert?.message }}
        </p>
        <p class="time-ago">
          {{ alert.createdAt | timeAgo | async }}
        </p>
      </div>
    </div>
    }
  </cdk-virtual-scroll-viewport>
  } @if(alerts().length <= 0){
  <div class="no-notifications">
    <svg-icon class="no-notifications__icon" [src]="'./assets/images/no-notifications.svg'"></svg-icon>
    <p class="text">{{ 'notifications.dropdown.empty' | translate }}</p>
  </div>
  } } @else {
  <div class="loader-wrapper">
    <app-loader sizing="button" />
  </div>
  }
</div>

<footer class="popup__footer">
  <a [class.disabled]="alerts().length <= 0" class="jm-link" (click)="navigateToNotificationList()">
    {{ 'notifications.seeAll' | translate }}
  </a>
</footer>
