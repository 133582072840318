import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-public-release-notes',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './public-release-notes.component.html',
})
export class PublicReleaseNotesComponent {}
