import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoaderComponent } from '@app-ui';

@Component({
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrls: ['./auth-card.component.scss'],
  standalone: true,
  imports: [CommonModule, LoaderComponent],
})
export class AuthCardComponent {
  @Input() loading!: boolean | null;

  @Input() titleClass = '';
}
