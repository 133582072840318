import { Observable, Subscription } from 'rxjs';

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CloseScheduledNotifications, selectNotification } from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { IUserNotification } from '../interfaces/user-notification.interface';
import { LocalStorageService } from '../local-storage.service';

const title = 'mymuenet';

@UntilDestroy()
@Component({
  selector: 'app-main',
  styleUrls: ['main.component.scss'],
  templateUrl: 'main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
  impersonateUserToken$: Observable<string | null> = this.localStorageService.impersonateUserToken$;
  userNotification$: Observable<IUserNotification | null> = this.store$.select(selectNotification);

  innerWidth = 0;
  innerHeight = 0;
  mobileMenuOpen = false;

  embedded = false;
  hideLogo = false;

  private readonly subscription = new Subscription();

  get routerUrl(): string {
    return this.router.url;
  }

  get componentName(): string {
    return this.getComponentName(this.router.routerState.snapshot.root);
  }

  get moduleClassName(): string {
    let className = '';

    if (this.routerUrl.includes('dashboard')) {
      className = 'dashboard';
    }
    if (this.routerUrl.includes('library')) {
      className = 'library';
    }
    if (this.routerUrl.includes('analytics')) {
      className = 'analytics';
    }
    if (this.routerUrl.includes('admin')) {
      className = 'admin';
    }
    if (this.routerUrl.includes('support')) {
      className = 'support';
    }
    if (this.routerUrl.includes('login')) {
      className = 'home';
    }
    if (this.routerUrl.includes('password-reset')) {
      className = 'home';
    }
    if (this.routerUrl.includes('home')) {
      className = 'home';
    }
    if (this.routerUrl.includes('library/templates') || this.routerUrl.includes('/library/articles')) {
      className = 'library library--templates';
    }
    if (this.routerUrl.includes('admin/root-templates')) {
      className = 'admin admin--root_templates';
    }

    if (this.routerUrl.includes('/order/')) {
      className = 'order';
    }

    if (this.routerUrl.includes('/auth/user-profile') || this.routerUrl.includes('/configuration')) {
      className = 'primary';
    }

    if (this.routerUrl.includes('/notifications')) {
      className = 'notifications';
    }

    if (this.routerUrl === '/') {
      className = 'home';
    }

    return className;
  }

  constructor(
    private readonly translateService: TranslateService,
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly store$: Store<any>,
    private readonly localStorageService: LocalStorageService,
    private readonly activeRoute: ActivatedRoute
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.titleService.setTitle(title);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    this.resolveLayoutFromParams();
    this.listenToLayoutParamsChanged();
  }

  resolveLayoutFromParams(): void {
    const queryParams = window.location.href.split('?')[1];

    const params = new URLSearchParams(queryParams);

    this.hideLogo = params.get('hide_logo') === '1';
    this.embedded = params.get('embedded') === '1';
  }

  listenToLayoutParamsChanged(): void {
    this.activeRoute.queryParams.pipe(untilDestroyed(this)).subscribe(() => {
      this.resolveLayoutFromParams();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isComponentLocationDashboardStatistic(): boolean {
    return (
      this.componentName === 'map' ||
      this.componentName === 'overview' ||
      this.componentName === 'orders' ||
      this.componentName === 'speed' ||
      this.componentName === 'efficiency' ||
      this.componentName === 'stops'
    );
  }

  closeNotification(id: number): void {
    this.store$.dispatch(new CloseScheduledNotifications({ id }));
  }

  onMobileMenuOpen(mobileOpen: boolean): void {
    this.mobileMenuOpen = mobileOpen;
  }

  private getComponentName(routeSnapshot: ActivatedRouteSnapshot) {
    let componentTitle: string = routeSnapshot.data && routeSnapshot.data['componentName'] ? routeSnapshot.data['componentName'] : '';
    if (routeSnapshot.firstChild) {
      componentTitle = this.getComponentName(routeSnapshot.firstChild) || componentTitle;
    }
    return componentTitle;
  }
}
