import { Apollo } from 'apollo-angular';
import { ObjectLiteral } from 'ngx-super-model/types/object-literal';
import { map, Observable } from 'rxjs';
import { isBadUserInputError, isGraphQlError, isValidationFailedError } from 'src/app/errors/helpers/error.helper';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { ListResultDTO } from '@jm-table';
import { LookupResult, LookupService } from '@quick-form';

import { ResponseData } from '../../abstract/interface/response-data';
import unconfiguredSensorIds from '../gql/unconfigured-sensors.graphql';

@Injectable({ providedIn: 'root' })
export class UnconfiguredSensorService implements LookupService {
  private readonly apollo = inject(Apollo);
  private readonly snackBarService = inject(SnackBarService);

  getSuggestions(): Observable<ListResultDTO<LookupResult>> {
    return this.apollo
      .query<Array<string>>({
        query: unconfiguredSensorIds,
      })
      .pipe(
        catchAllErrors((error) => {
          this.handleMessage(error, 'sensor.error.unconfigure-sensors');
        }),
        map((response: ApolloQueryResult<ResponseData<Array<string>>>) => {
          const sensors = response.data.unconfiguredSensorIds;
          return {
            records: sensors.map((sensor) => {
              return {
                id: sensor,
                name: sensor,
              };
            }),
            pageDetails: null,
          };
        })
      );
  }

  private handleMessage(error: ObjectLiteral, fallbackMessage: string) {
    if (isGraphQlError(error) && (isBadUserInputError(error) || isValidationFailedError(error))) {
      this.snackBarService.error(error.message);
      return;
    }

    this.snackBarService.error(fallbackMessage);
  }
}
