import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-block',
  templateUrl: './empty-block.component.html',
  styleUrls: ['./empty-block.component.scss'],
})
export class EmptyBlockComponent {
  @Input() className?: string;
  @Input() title?: string;
  @Input() image = './assets/images/no-data-icon.svg';
  @Input() description?: string;
  @Input() actionButton?: boolean;
  @Input() withBoxStyling?: boolean;
  @Input() smallSizing?: boolean;
  @Input() showIcon = true;
}
