import { TableEnum } from 'src/app/modules/jm-table/enum/table.enum';
import { loadTableCellConfig } from 'src/app/modules/jm-table/helpers/table.helper';
import { TableConfigDTO } from 'src/app/modules/jm-table/interfaces/table-config.interface';

import { oeeByMachineCellConfig } from './cells.config';

export const oeeByMachineTableConfig: TableConfigDTO = {
  id: TableEnum.oeeByMachine,
  cellsConfig: loadTableCellConfig(oeeByMachineCellConfig),
  cssClass: ['no-border'],
  footer: false,
  rowExpanded: false,
  mobileRowExpanded: false,
  dataKey: 'machineGuid',
  customSort: true,
  updateUrl: false,
  scrollHeight: '500px',
  autoLoad: false,
};
