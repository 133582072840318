import { Pipe, PipeTransform } from '@angular/core';
import { isSet } from 'src/app/util/util';

@Pipe({
  name: 'roundNumber',
  standalone: true,
})
export class RoundNumberPipe implements PipeTransform {
  transform(value: number | null, decimalPlaces: number): number | string {
    if (!isSet(value) && value !== 0) {
      return '-';
    }
    if (!decimalPlaces) {
      decimalPlaces = 0;
    }
    const factor = 10 ** decimalPlaces;
    return Math.round(value * factor) / factor;
  }
}
