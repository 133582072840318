import { AngularSvgIconModule } from 'angular-svg-icon';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';

import { Component, Inject, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JmButtonComponent } from '@app-ui';
import { SearchCriteria } from '@jm-table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractFormConfigComponent, createControls, loadFormConfig, LookupResult, QuickFormsModule } from '@quick-form';
import { SensorConfiguration, SensorService, SensorType } from '@sensor';
import { isSet } from '@util';

import { ExportSensorData } from '../../interfaces';
import { formConfig } from './form.config';

const imports = [AngularSvgIconModule, JmButtonComponent, QuickFormsModule, ReactiveFormsModule, TranslateModule];

@UntilDestroy()
@Component({
  selector: 'app-export-sensor-data',
  standalone: true,
  imports,
  templateUrl: './export-sensor-data.component.html',
  styleUrl: './export-sensor-data.component.scss',
})
export class ExportSensorDataComponent extends AbstractFormConfigComponent {
  private readonly sensorService = inject(SensorService);

  endMinDate: Date | null = null;
  startMaxDate: Date | null = null;

  sensorWidgetOptions: Array<LookupResult> = [];
  sensorDropdownLoading = false;

  availableSensors: Array<SensorConfiguration> = [];

  readonly periodTypeEnum = PeriodTypeEnum;

  constructor(
    private readonly dialogRef: MatDialogRef<ExportSensorDataComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: ExportSensorData
  ) {
    super();
    this.getSensorConfigurationOptions();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  export(): void {
    if (!isSet(this.form)) {
      return;
    }

    const { type, start, end, sensorIds, filename } = this.form.value;

    const data: ExportSensorData = {
      period: {
        type,
        customPeriod: {
          start,
          end,
        },
      },
      sensorIds: (sensorIds as Array<LookupResult>).map((value) => value.id),
      filename,
    };

    this.dialogRef.close(data);
  }

  onStartChanged(date: Date): void {
    this.endMinDate = date;
  }

  onEndChanged(date: Date): void {
    this.startMaxDate = date;
  }

  private handlePeriodChanged(value: PeriodTypeEnum): void {
    if (value === PeriodTypeEnum.CUSTOM_RANGE) {
      this.form?.get('start')?.enable();
      this.form?.get('end')?.enable();
      return;
    }

    this.form?.get('start')?.disable();
    this.form?.get('end')?.disable();
  }

  private getSensorConfigurationOptions(): void {
    const { machineGuid } = this.data;

    const searchCriteria = new SearchCriteria();

    searchCriteria.filterBy = { machineGuid, type: SensorType.CURRENT_VALUE };

    this.sensorDropdownLoading = true;

    this.sensorService
      .getAll(searchCriteria)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.sensorDropdownLoading = false;
          this.availableSensors = response.records;
          const options: Array<LookupResult> = this.mapSensors(response.records);
          this.sensorWidgetOptions = options;

          this.initForm();
        },
        error: () => (this.sensorDropdownLoading = false),
      });
  }

  private initForm(): void {
    const { period, sensorIds } = this.data;
    const periodStart = period?.customPeriod?.start;
    const periodEnd = period?.customPeriod?.end;

    const mappedSensorIds = sensorIds
      .map((sensorId) => {
        return this.availableSensors.filter((availableSensor) => availableSensor.sensorId === sensorId)[0];
      })
      .filter(isSet);

    let start: Date | null = null;
    let end: Date | null = null;

    if (isSet(periodStart)) {
      start = new Date(periodStart);
    }

    if (isSet(periodEnd)) {
      end = new Date(periodEnd);
    }

    const model = {
      type: period?.type,
      start,
      end,
      sensorIds: this.mapSensors(mappedSensorIds),
    };

    this.formConfig = loadFormConfig(formConfig, model);
    this.form = this.formBuilder.group(createControls(this.formConfig));

    if (isSet(start)) {
      this.endMinDate = new Date(start);
    }

    if (isSet(end)) {
      this.startMaxDate = new Date(end);
    }

    if (isSet(period?.type)) {
      this.handlePeriodChanged(period.type);
    }
  }

  private mapSensors(sensors: Array<SensorConfiguration>): Array<LookupResult> {
    return sensors.map((record) => {
      const option = {
        id: `${record.sensorId}`,
        name: `${record.label}`,
        additionalField: '',
      };

      if (isSet(record.unit)) {
        option.name = `${record.label} (${record.unit})`;
        option.additionalField = record.unit;
      }
      return option;
    });
  }
}
