<div class="column-settings-wrapper">
  <div cdkDropList class="column-settings-list" (cdkDropListDropped)="drop($event)">
    <div
      class="column-settings-row"
      *ngFor="let column of columns; let i = index"
      cdkDrag
      cdkDragLockAxis="y"
      [class.unselect]="column.display === false"
    >
      <div class="column-wrapper">
        <svg-icon class="drag-icon" [src]="'./assets/images/drag.svg'" cdkDragHandle></svg-icon>
        <span class="label">{{ column.title || '' | translate }}</span>
      </div>
      <quick-form-checkbox
        [config]="getConfig(column.id)"
        [checked]="column.display"
        (valueChanged)="onSelect($event, column.id)"
      ></quick-form-checkbox>
    </div>
  </div>
  <span class="error-message" *ngIf="!canUserUnselectColumns">
    {{ 'global.min-two-columns' | translate }}
  </span>
  <div class="action-wrapper">
    <div class="action-buttons-wrapper">
      <jm-button [class]="'green-button'" [suffixIcon]="'check'" (clicked)="apply()" [disabled]="!canUserUnselectColumns">
        {{ 'actions.apply' | translate }}
      </jm-button>
      <jm-button [class]="'red-border-button'" (clicked)="close()">
        {{ 'actions.close' | translate }}
      </jm-button>
    </div>
    <span class="reset" (click)="reset()">{{ 'actions.reset' | translate }}</span>
  </div>
</div>
