<div class="quick-form-control">
  <div class="input-wrapper" [ngClass]="config.wrapperCssClass" (click)="inputClick.emit()">
    <svg-icon class="edit-icon" [src]="'./assets/images/edit.svg'"></svg-icon>
    <input
      class="{{ config.cssClass }} readonly"
      [id]="config.getDisplayId()"
      [name]="config.id"
      [ngModel]="value"
      [appControlAttributes]="config.attributes"
      [type]="'text'"
      readonly
    />
  </div>

  <div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
</div>
