<app-box [className]="'without-content-scroll no-padding'">
  <ng-container header>
    {{ 'performance-dashboard.oee-by-machine-categories.title' | translate }}
  </ng-container>

  <ng-container content>
    <div class="table-wrapper">
      <jm-table
        *ngIf="!error"
        [tableConfig]="tableConfig"
        [loading]="loading"
        [data]="data"
        [tableSearchConfig]="tableSearchConfig"
        (lazyLoadChanged)="onLazyLoadChanged($event)"
        (loadMore)="loadMore($event)"
      />

      @if(error && !loading){
      <div class="error-wrapper">
        <app-empty-block [title]="'global.errors.error' | translate" [image]="'./assets/images/machines-error.svg'" [description]="error" />
      </div>
      }
    </div>
  </ng-container>
</app-box>
