import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderComponent } from '../loader/loader.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'jm-button',
  templateUrl: './jm-button.component.html',
  styleUrls: ['./jm-button.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, AngularSvgIconModule, TourMatMenuModule, LoaderComponent],
})
export class JmButtonComponent {
  @Input() class?: string;
  @Input() disabled?: boolean;
  @Input() prefixIcon = '';
  @Input() suffixIcon = '';
  @Input() hideText = false;
  @Input() hideTextTablet = false;
  @Input() tourAnchorId = '';
  @Input() dataTestSelector?: string;
  @Input() loading = false;
  @Input() active = false; // Add this line
  @Input() textCenter = false;
  @Input() toggleable = false;

  @HostBinding('class.jm-button') sbButton = true;

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  get loaderInvert(): boolean {
    return !this.class?.includes('border');
  }

  isOpen = false;

  // Modify the toggle method to return void
  toggle(): void {
    this.isOpen = !this.isOpen;
    if (!this.toggleable) {
      this.active = this.isOpen;
    }
    this.clicked.emit();
  }
}
