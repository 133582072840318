import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';
import { Period } from 'src/app/util/period/period.interface';
import { Dashboard } from '../model/dashboard.model';

export const widgetsDashboardStateName = 'customDashboard';

export const CUSTOM_DASHBOARD_STORAGE_KEY = 'customDashboard';

export type DashboardMode = 'view' | 'edit' | null;

export const initialPeriodState: Period = {
  type: PeriodTypeEnum.PERIOD_CURRENT_MONTH,
  customPeriod: null,
};

export interface DashboardFilters {
  period: Period;
}

export interface DashboardPersistentState {
  filters: {
    [id: string]: DashboardFilters;
  };
}

export interface DashboardState extends DashboardPersistentState {
  mode: DashboardMode;
  privateDashboards: Array<Dashboard>;
  sharedDashboards: Array<Dashboard>;
}

export const initialDashboardState: DashboardState = {
  mode: null,
  filters: {},
  privateDashboards: [],
  sharedDashboards: [],
};
