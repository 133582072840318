import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { LookupResult, LookupService } from 'src/app/modules/quick-forms';

import { inject, Injectable } from '@angular/core';
import { SearchCriteria } from '@jm-table';

import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import { LicenseTypesEnum } from 'src/app/main/admin/models';
import { MachineService } from './machine.service';
import { Machine } from '../models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class MachineSensorLicenseService implements LookupService {
  private readonly machineService = inject(MachineService);

  getSuggestions(searchCriteria: SearchCriteria): Observable<ListResultDTO<LookupResult>> {
    searchCriteria.filterBy = {
      ...searchCriteria.filterBy,
      licenseType: LicenseTypesEnum.SENSOR,
    };

    return this.machineService.getAll(searchCriteria).pipe(
      map((response: ListResultDTO<Machine>) => {
        return {
          records: listItemsToLookupResult(response.records, { id: 'guid', name: 'name' }),
          pageDetails: null,
        };
      })
    );
  }
}
