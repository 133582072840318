import { Component, Input } from '@angular/core';

import { CellConfig } from '../../../models/cells.config';

@Component({
  selector: 'app-abstract-cell',
  template: '',
})
export class AbstractCellComponent {
  @Input() cellData: any;
  @Input() column: CellConfig | null = new CellConfig();

  get columnField(): string {
    if (this.column?.render && typeof this.column.render === 'function') {
      return this.column?.render(this.cellData);
    }

    if (this.column?.take) {
      return this.column.take;
    }

    return this.column?.field || '';
  }
}
