import { Component, EventEmitter, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-zoom-buttons',
  templateUrl: './zoom-buttons.component.html',
  styleUrls: ['./zoom-buttons.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule],
})
export class ZoomButtonsComponent {
  @Output()
  zoomIn: EventEmitter<never> = new EventEmitter<never>();

  @Output()
  zoomOut: EventEmitter<never> = new EventEmitter<never>();

  onZoomOut() {
    this.zoomOut.emit();
  }

  onZoomIn() {
    this.zoomIn.emit();
  }
}
