import { Model } from 'ngx-super-model';
import { OrderDTO } from 'src/app/core/orders/interfaces/order.interface';
import { PreviewDocumentDTO } from 'src/app/modules/media-gallery/interfaces/preview-document.interface';
import { ArticleContentDTO } from '../../content/interfaces/article-content.interface';

import { ArticleContent } from '../../content/models/article-content.model';
import { ArticlePatternsDTO } from '../../patterns/pattern.interface';
import { ArticleTemplateDTO } from '../../templates/interfaces/template.interface';
import { ArticleStatusEnum } from '../enums/article-status.enum';
import { ArticleDTO } from '../interfaces/article.interface';

export class Article extends Model<ArticleDTO> {
  guid?: string | null = null;
  companyId? = '';
  name? = '';
  status?: ArticleStatusEnum | null = null;
  goodEfficiency?: number | null = null;
  stopsGood?: number | null = null;
  speedGood?: number | null = null;
  poorEfficiency?: number | null = null;
  stopsPoor?: number | null = null;
  speedPoor?: number | null = null;
  orders?: Array<OrderDTO> = [];
  articlePatterns: Array<ArticlePatternsDTO> = [];
  projectCategories?: Array<any> = [];
  previewDocuments?: Array<PreviewDocumentDTO> = [];
  template?: ArticleTemplateDTO | null = null;
  articleContent?: ArticleContent | null = null;
  articleContentVersions?: Array<ArticleContentDTO> = [];
  stretchFactor?: number | null;
  machineTypes?: Array<any> = [];
  machineCategories?: Array<any> = [];
  createdAt?: string | null = null;
  updatedAt?: string | null = null;
  patternName?: string | null = null;
  __typename?: string | null = null;

  override loadModel(input: ArticleDTO): this {
    super.loadModel(input);

    if (input.articleContent) {
      this.articleContent = new ArticleContent().loadModel(input.articleContent);
    }

    return this;
  }
}
