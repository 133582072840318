import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class GuestGuard {
  constructor(private readonly authService: AuthenticationService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.isLoggedIn().subscribe((data) => {
        const isLoggedIn = data;

        if (!isLoggedIn) {
          return resolve(true);
        }

        this.authService.loginRedirect(route.queryParams.redirectUrl);
        return resolve(false);
      });
    });
  }
}
