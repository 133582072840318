import { FRICK_STATUS, COMEZ_STATUS } from '../enums/machine-status.enum';

export const machineFrickStatusColorMapper: Map<FRICK_STATUS, string> = new Map([
  [FRICK_STATUS.STOP_END_OF_ORDER, '#D25051'],
  [FRICK_STATUS.STOP_OFFLINE, '#8C979E'],
  [FRICK_STATUS.STOP_REEDCOVER, '#6B59D7'],
  [FRICK_STATUS.STOP_WARP, '#D75995'],
  [FRICK_STATUS.STOP_WEFT, '#EB9D27'],
  [FRICK_STATUS.STOP_BINDER, '#0DAED3'],
  [FRICK_STATUS.STOP_FABRIC_OVERROLL, '#A0D759'],
  [FRICK_STATUS.STOP_OTHER, '#DEE6EC'],
  [FRICK_STATUS.STOP_EXTERNAL_STOP, '#FF694F'],
  [FRICK_STATUS.STOP_MANUAL, '#D25051'],
  [FRICK_STATUS.STOP_NO, '#8C979E'],
]);

export const machineComezStatusColorMapper = new Map<COMEZ_STATUS, string>([
  [COMEZ_STATUS.INIT_START, '#D25051'],
  [COMEZ_STATUS.INIT_INITIALIZATION_IN_PROGRESS, '#8C979E'],
  [COMEZ_STATUS.INIT_INIT, '#6B59D7'],
  [COMEZ_STATUS.SETUP_CONFIGURE_MACHINE, '#D75995'],
  [COMEZ_STATUS.SETUP_LOAD_CHECK_PATTERN, '#EB9D27'],
  [COMEZ_STATUS.SETUP_EXECUTE_HOME, '#0DAED3'],
  [COMEZ_STATUS.SETUP_WAITING_FOR_FIRST_LINE_RECALL, '#A0D759'],
  [COMEZ_STATUS.SETUP_OK, '#DEE6EC'],
  [COMEZ_STATUS.SETUP_INCORRECT_ANGLES, '#FF694F'],
  [COMEZ_STATUS.ACTIVATION_LOADING_PATTERN, '#FF694F'],
  [COMEZ_STATUS.ACTIVE_PATTERN_EXECUTING, '#D25051'],
  [COMEZ_STATUS.ACTIVE_CHECK_PATTERN, '#8C979E'],
  [COMEZ_STATUS.ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS, '#6B59D7'],
  [COMEZ_STATUS.ERROR_FILE, '#D75995'],
  [COMEZ_STATUS.ERROR_INTERNAL, '#EB9D27'],
  [COMEZ_STATUS.ERROR_I_O, '#0DAED3'],
  [COMEZ_STATUS.ERROR_OPERATION, '#FF694F'],
  [COMEZ_STATUS.ERROR_DEVICE, '#D25051'],
  [COMEZ_STATUS.ERROR_AUTOMATIC_CYCLE, '#FF0000'],
  [COMEZ_STATUS.ERROR_INVERTER, '#FF5733'],
  [COMEZ_STATUS.ERROR_CAN, '#FF8C33'],
  [COMEZ_STATUS.ERROR_ACTUATORS, '#FFA07A'],
]);
