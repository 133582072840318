import { Component, Input } from '@angular/core';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-assigned-cell]',
  templateUrl: './jm-assigned-cell.component.html',
})
export class JmAssignedCellComponent extends AbstractCellComponent {
  @Input() total = 0;
  @Input() assigned = 0;
}
