import { isSet } from 'src/app/util/util';

import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ControlConfig, ControlConfigDTO, createControls, loadFormConfig } from '@quick-form';

import { AbstractColumnFilterComponent } from '../abstract-column-filter/abstract-column-filter.component';

@Component({
  selector: 'jm-column-filter-dropdown',
  templateUrl: './jm-column-filter-dropdown.component.html',
})
export class JmColumnFilterDropdownComponent extends AbstractColumnFilterComponent implements OnInit {
  private readonly fb = inject(FormBuilder);

  dropdownConfig: Array<ControlConfig> = [];
  form: FormGroup | null = null;

  override ngOnInit(): void {
    if (this.column?.filter?.control) {
      this.setupForm(this.column?.filter?.control);
    }
  }

  onValueChanged(value: any): void {
    this.value = value;
  }

  getControl(id: string): FormControl {
    return this.form?.get(id) as FormControl;
  }

  isValueSet(value: any): boolean {
    return isSet(value);
  }

  onFocusToggle(focused: boolean): void {
    this.focused.emit(focused);
  }

  private setupForm(controls: Array<ControlConfigDTO>): void {
    this.dropdownConfig = loadFormConfig(controls);

    this.form = this.fb.group(createControls(this.dropdownConfig));

    if (isSet(this.value)) {
      this.form.get(this.dropdownConfig[0].id)?.setValue(this.value);
    }
  }
}
