import { LookupResult } from '@quick-form';
import { WidgetGlobalConfig } from '../interfaces/widgets-global-config.interface';

export function globalWidgetConfigToOptions(globalWidgetOptions: Array<WidgetGlobalConfig>): Array<LookupResult> {
  return globalWidgetOptions.map((widget) => {
    return {
      id: widget.type,
      name: widget.name,
    };
  });
}
