import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { LookupResult, LookupService } from 'src/app/modules/quick-forms';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable, inject } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';

import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import { MachineType, MachineTypeDTO } from './machine-type.model';
import { ResponseData } from '../../abstract/interface/response-data';

@Injectable({
  providedIn: 'root',
})
export class MachineTypeService implements LookupService {
  private readonly apollo = inject(Apollo);
  private readonly snackBarService = inject(SnackBarService);

  getAll(): Observable<Array<MachineTypeDTO>> {
    const query = gql`
      query {
        machineTypes {
          id
          name
        }
      }
    `;

    return this.apollo.query<MachineTypeDTO>({ query }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('machine-type.error.get-all');
      }),
      map((res: ApolloQueryResult<ResponseData<Array<MachineTypeDTO>>>) => res.data.machineTypes)
    );
  }

  update(machineType: MachineTypeDTO): Observable<FetchResult<MachineTypeDTO>> {
    const mutation = gql`
      mutation updateMachineType($id: Int!, $name: String!) {
        updateMachineType(id: $id, name: $name) {
          id
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          ...machineType,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('machine-type.error.update');
        }),
        tap(() => {
          this.snackBarService.success('machine-type.success.update');
        })
      );
  }

  create(machineType: MachineTypeDTO): Observable<MachineTypeDTO> {
    const mutation = gql`
      mutation addMachineType($name: String!) {
        addMachineType(name: $name) {
          id
          name
        }
      }
    `;

    return this.apollo
      .mutate<MachineTypeDTO>({
        mutation,
        variables: { name: machineType.name },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('machine-type.error.create');
        }),
        tap(() => {
          this.snackBarService.success('machine-type.success.create');
        }),
        map((result: ApolloQueryResult<ResponseData<MachineTypeDTO>>) => {
          return result.data.addMachineType;
        })
      );
  }

  delete(id: string): Observable<MachineType> {
    const mutation = gql`
      mutation deleteMachineType($id: Int!) {
        deleteMachineType(id: $id) {
          id
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          id,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('machine-type.error.delete');
        }),
        tap(() => {
          this.snackBarService.success('machine-type.success.delete');
        }),
        map((response: ApolloQueryResult<{ [key: string]: MachineTypeDTO }>) => {
          return new MachineType().loadModel(response.data['deleteMachineType']);
        })
      );
  }

  getSuggestions(): Observable<ListResultDTO<LookupResult>> {
    return this.getAll().pipe(
      map((machineTypes: Array<MachineTypeDTO>) => {
        return {
          pageDetails: null,
          records: listItemsToLookupResult(machineTypes),
        };
      })
    );
  }
}
