import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class LoaderComponent {
  @Input() sizing: 'app' | 'button' = 'app';
  @Input() invert = false;
  @Input() centered?: boolean;

  get sizingCssClass(): string {
    return 'jm-loader--' + this.sizing;
  }
}
