import { Component, HostBinding, Input } from '@angular/core';

import { AbstractResizableComponent } from '../../../ui/abstract-resizeable.component';

@Component({
  selector: 'widget-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent extends AbstractResizableComponent {
  @Input() content = '';
  @Input() error = false;
  @Input() errorDescription: string | null = null;
  @Input() noData = false;

  @HostBinding('class') class = 'widget__content';
}
