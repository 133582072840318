import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ControlConfig, createControls, loadFormConfig } from '@quick-form';

import { UserLogin } from '../../store/login.actions';
import { formConfig } from './form-config';
import { MymuellerSSOService, selectLoginLoader } from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private readonly myMullerSSOService = inject(MymuellerSSOService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly store$ = inject(Store);

  loading = false;

  form: FormGroup | null = null;
  readonly formConfig: Array<ControlConfig> = loadFormConfig(formConfig);

  ngOnInit(): void {
    this.form = this.formBuilder.group(createControls(this.formConfig));

    this.store$
      .select(selectLoginLoader)
      .pipe(untilDestroyed(this))
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  navigateTo(params: string): void {
    this.router.navigate([params]);
  }

  login(): void {
    if (this.loading) {
      return;
    }

    if (!this.form) {
      return;
    }

    this.form.markAsTouched();

    if (this.form.invalid) {
      return;
    }

    const { email, password } = this.form.value;

    this.store$.dispatch(new UserLogin({ email, password }));
  }

  loginMyMueller(): void {
    const url = this.myMullerSSOService.getMyMullerLoginURL();
    window.location.href = url.toString();
  }
}
