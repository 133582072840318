import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlConfig } from '../../../models/control.config';

@Component({
  selector: 'quick-form-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNumberComponent),
    },
  ],
})
export class InputNumberComponent implements ControlValueAccessor, OnInit {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Output()
  valueChanged = new EventEmitter<number | null>();

  value: number | null = null;

  isDisabled = false;

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(this.config.disabled);
    }
  }

  writeValue(value: any): void {
    if (value !== '') {
      this.value = value;
    }
  }

  registerOnChange(fn: (value: number | null) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChanged() {
    this.propagateChange(this.value);
    this.valueChanged.emit(this.value);
  }

  onBlur(): void {
    this.propagateTouched();
  }

  // eslint-disable-next-line
  private propagateChange = (value: number | null) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
