import { MachineStatus } from '../enums/machine-status.enum';

export const machineCustomClasses: Array<MachineStatus> = [
  MachineStatus.STOP_REEDCOVER,
  MachineStatus.STOP_FABRIC_OVERROLL,
  MachineStatus.STOP_FABRIC_ROLLOVER,
  MachineStatus.STOP_END_OF_ORDER,
  MachineStatus.STOP_MANUAL,
  MachineStatus.STOP_BINDER,
  MachineStatus.STOP_WARP,
  MachineStatus.STOP_WEFT,
  MachineStatus.STOP_OTHER,
  MachineStatus.STOP_EXTERNAL_STOP,
  MachineStatus.INIT_START,
  MachineStatus.INIT_INITIALIZATION_IN_PROGRESS,
  MachineStatus.INIT_INIT,
  MachineStatus.SETUP_CONFIGURE_MACHINE,
  MachineStatus.SETUP_LOAD_CHECK_PATTERN,
  MachineStatus.SETUP_EXECUTE_HOME,
  MachineStatus.SETUP_WAITING_FOR_FIRST_LINE_RECALL,
  MachineStatus.SETUP_OK,
  MachineStatus.SETUP_INCORRECT_ANGLES,
  MachineStatus.ACTIVATION_LOADING_PATTERN,
  MachineStatus.ACTIVE_PATTERN_EXECUTING,
  MachineStatus.ACTIVE_CHECK_PATTERN,
  MachineStatus.ACTIVE_FIRST_LINE_RECALL_IN_PROGRESS,
  MachineStatus.ERROR_FILE,
  MachineStatus.ERROR_INTERNAL,
  MachineStatus.ERROR_I_O,
  MachineStatus.ERROR_CAN,
  MachineStatus.ERROR_ACTUATORS,
  MachineStatus.ERROR_OPERATION,
  MachineStatus.ERROR_DEVICE,
  MachineStatus.ERROR_AUTOMATIC_CYCLE,
  MachineStatus.ERROR_INVERTER,
];
