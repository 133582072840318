export const stackedBarChartColors = [
  '#D25051',
  '#8C979E',
  '#6B59D7',
  '#D75995',
  '#EB9D27',
  '#0DAED3',
  '#A0D759',
  '#FF694F',
  '#DEE6EC',
  '#f9b022',
  '#929ea7',
  '#656565',
];
