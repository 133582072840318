import { Apollo, gql, QueryRef } from 'apollo-angular';
import { upperFirst } from 'lodash-es';
import { Model } from 'ngx-super-model';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult, DocumentNode } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';

import { GetAllOptionsDTO } from '../modules/jm-table/interfaces/get-all-options.interface';
import { ListResultDTO } from '../modules/jm-table/interfaces/list-result.dto';
import { SearchCriteriaDTO } from '../modules/jm-table/interfaces/search-criteria.interface';
import { ObjectLiteral } from '../util/object-literal';
import { Router } from '@angular/router';
import { EntityNotFound } from '../errors/not-found.error';
import { isBadUserInputError, isGraphQlError, isValidationFailedError } from '../errors/helpers/error.helper';
import { camelCaseToWords, isSet } from '../util/util';
import { ResponseData } from './abstract/interface/response-data';
import { CRUDError } from './abstract/errors/error';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractCRUDService<M extends Model<D>, D extends ObjectLiteral> {
  readonly translateService = inject(TranslateService);
  readonly apollo = inject(Apollo);
  readonly snackBarService = inject(SnackBarService);
  readonly router = inject(Router);

  protected modelName = '';
  protected modelNamePlural = '';
  protected fields = '';

  protected oneQuery!: DocumentNode;
  protected deleteMutation!: DocumentNode;

  readonly pageDetails: string = `
   pageDetails {
    page
    size
    isFirst
    isLast
    totalPages
    totalResults
   }
  `;

  getAllQuery!: QueryRef<any>;

  model?: new () => M;

  getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<M>> {
    const defaultQuery = gql`
      ${this.allQuery}
    `;

    const query = options?.query || defaultQuery;

    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<D>>({
      query,
      variables: { searchCriteria, ...options },
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<D> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  getOne(id: number, options?: ObjectLiteral): Observable<M> {
    const query = this.oneQuery;
    const modelName = this.modelName;

    return this.apollo.query<ListResultDTO<D>>({ query, variables: { id, ...options } }).pipe(
      catchAllErrors((error: Error) => {
        const message = this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) });
        this.handleMessage(error, message);
      }),
      map((response: ApolloQueryResult<ResponseData<D>>) => {
        return this.loadModel(response.data[modelName]);
      })
    );
  }

  getByGuid(guid: string, options?: ObjectLiteral): Observable<M> {
    const query = this.oneQuery;

    const modelName = this.modelName;

    return this.apollo.query<ListResultDTO<D>>({ query, variables: { guid, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: D }>) => {
        return this.loadModel(response.data[modelName]);
      })
    );
  }

  save(model: any, options?: any): Observable<M> {
    if (isSet(model.id) || isSet(model.guid)) {
      return this.update(model, options);
    }

    return this.create(model, options);
  }

  update(model: any, options?: any): Observable<M> {
    const mutation = gql`
      ${this.updateQuery}
    `;

    const modelName = upperFirst(this.modelName);

    return this.apollo.mutate<D>({ mutation, variables: { input: model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.update', { modelName: camelCaseToWords(modelName) }));
      }),
      tap(() => {
        this.snackBarService.success(this.translateService.instant('global.success.update', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: D }>) => {
        const mutation = `update${modelName}`;
        return this.loadModel(response.data[mutation]);
      })
    );
  }

  create(model: any, options?: any): Observable<M> {
    const mutation = gql`
      ${this.createQuery}
    `;

    const modelName = upperFirst(this.modelName);

    return this.apollo.mutate<D>({ mutation, variables: { ...model, ...options } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.create', { modelName: camelCaseToWords(modelName) }));
      }),
      tap(() => {
        this.snackBarService.success(this.translateService.instant('global.success.create', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: D }>) => {
        const mutation = `create${modelName}`;
        return this.loadModel(response.data[mutation]);
      })
    );
  }

  delete(id: number): Observable<M> {
    if (!isSet(this.deleteMutation)) {
      throw new CRUDError('Assing deleteMutions property.');
    }

    const mutation = this.deleteMutation;

    const modelName = upperFirst(this.modelName);

    return this.apollo
      .mutate({
        mutation,
        variables: {
          id,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error(this.translateService.instant('global.errors.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        tap(() => {
          this.snackBarService.success(this.translateService.instant('global.success.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        map((response: ApolloQueryResult<{ [key: string]: D }>) => {
          const mutationName = `delete${modelName}`;
          return this.loadModel(response.data[mutationName]);
        })
      );
  }

  deleteByGuid(guid: string): Observable<M> {
    if (!isSet(this.deleteMutation)) {
      throw new Error('Assing deleteMutions property.');
    }

    const mutation = this.deleteMutation;

    const modelName = upperFirst(this.modelName);

    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error(this.translateService.instant('global.errors.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        tap(() => {
          this.snackBarService.success(this.translateService.instant('global.success.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        map((response: ApolloQueryResult<{ [key: string]: D }>) => {
          const mutationName = `delete${modelName}`;
          return this.loadModel(response.data[mutationName]);
        })
      );
  }

  protected loadModel = (item: D) => {
    let model;

    try {
      if (!this.model) {
        throw new Error('Please pass model constructor in Child service for: ' + this.modelName);
      }
      model = new this.model().loadModel(item);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to load model. Check for wrong property names or missing properties!!');
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }

    return model;
  };

  protected get allQuery(): string {
    const queryName = this.modelNamePlural || `${this.modelName}s`;

    const searchCriteriaType = upperFirst(this.modelName) + 'SearchCriteriaInput';

    const query = `
    query ${queryName}($searchCriteria: ${searchCriteriaType}) {
      ${queryName}(searchCriteria: $searchCriteria) {
        records {
          ${this.fields}
        }
        ${this.pageDetails}
      }
    }
  `;

    return query;
  }

  protected get createQuery(): string {
    const mutation = `create${upperFirst(this.modelName)}`;
    const modelType = `${upperFirst(mutation)}Input!`;

    return `
      mutation ${mutation} ($input: ${modelType}) {
        ${mutation} (input: $input) {
          ${this.fields}
        }
      }
    `;
  }

  protected get updateQuery(): string {
    const mutation = `update${upperFirst(this.modelName)}`;
    const modelType = `${upperFirst(mutation)}Input!`;

    return `
      mutation ${mutation} ($input: ${modelType}) {
        ${mutation}(input: $input) {
          ${this.fields}
        }
      }
    `;
  }

  protected handleMessage(error: ObjectLiteral, fallbackMessage: string) {
    if (isGraphQlError(error) && (isBadUserInputError(error) || isValidationFailedError(error))) {
      this.snackBarService.error(error.message);
      return;
    }

    if (error instanceof EntityNotFound) {
      this.router.navigateByUrl('entity-not-found', { skipLocationChange: true, state: { modelName: this.modelName } });
      return;
    }

    this.snackBarService.error(fallbackMessage);
  }
}
