<div class="textarea-wrapper" [ngClass]="config.wrapperCssClass">
  <label class="label" *ngIf="config.label">{{ config.label | translate }} <span *ngIf="config?.validators?.required">*</span></label>
  <textarea
    [attr.class]="config.cssClass"
    type="text"
    [id]="config.getDisplayId()"
    [attr.placeholder]="config.placeholder | translate"
    [(ngModel)]="value"
    [disabled]="config.disabled"
    [appControlAttributes]="config.attributes"
    (input)="onValueChanged($event)"
    (blur)="onBlur()"
    [disabled]="disabled"
  ></textarea>
</div>
<div *ngIf="config.hasAnchorId()" [tourAnchor]="config.getAnchorId()"></div>
