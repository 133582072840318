<div
  class="sensor-configuration"
  (click)="onSensorConfigurationClick()"
  [ngStyle]="{
    'background-color':
      sensorData.getSensorDefinedState()?.status && !sensorData.chart.show
        ? hexToRgba(sensorData.getSensorDefinedState()?.color, 0.06)
        : '',
    'border-color': sensorData.getSensorDefinedState()?.status ? sensorData.getSensorDefinedState()?.color : ''
  }"
  [class.expanded-row]="sensorData.chart.show"
  [class.no-hover]="!sensorData.sensorConfiguration || sensorData.sensorConfiguration.type === sensorType.DICTIONARY || isEdit"
  [class.edit-mode]="isEdit"
  cdkDrag
  [cdkDragDisabled]="!isEdit"
  (cdkDragEntered)="entered.emit($event)"
  (cdkDragDropped)="dropped.emit($event)"
  [cdkDragData]="index"
>
  <div class="configuration-data-wrapper">
    <div class="label" [class.label-with-tag]="sensorData.hasSensorDefinedState()">
      @if (isEdit) {
      <svg-icon cdkDragHandle class="drag-icon" [src]="'./assets/images/drag.svg'"></svg-icon>
      }
      <!-- TODO refactor to be a component -->
      <div *ngIf="sensorData.getSensorDefinedState()?.status" class="color-sign" [class.animated]="sensorData.getSensorDefinedState()">
        <div
          class="big-circle"
          *ngIf="sensorData.getSensorDefinedState()?.color"
          [ngStyle]="{ 'border-color': hexToRgba(sensorData.getSensorDefinedState()?.color, 0.15) }"
        ></div>
        <div
          class="medium-circle"
          *ngIf="sensorData.getSensorDefinedState()"
          [ngStyle]="{ 'border-color': hexToRgba(sensorData.getSensorDefinedState()?.color, 0.5) }"
        ></div>
        <div
          class="small-circle"
          [ngStyle]="{
            'background-color': sensorData.getSensorDefinedState()?.color,
            'border-color': sensorData.getSensorDefinedState()?.color
          }"
        ></div>
      </div>
      <span class="sensor-label text-ellipsis">{{
        sensorData.sensorConfiguration ? (sensorData.sensorConfiguration.label | value) : '-'
      }}</span>
    </div>
    @if(sensorData.sensorConfiguration.type === sensorType.CURRENT_VALUE){
    <div class="value">
      <span class="number">{{ sensorData.value | roundNumber : 2 }}</span>
      <span class="unit">{{ sensorData.sensorConfiguration.unit ?? '' }}</span>
    </div>
    } @if(sensorData.sensorConfiguration.type === sensorType.DICTIONARY){
    <div class="value" *ngIf="!sensorData.hasSensorDefinedState()">
      {{ sensorData.value | roundNumber : 2 }} {{ sensorData.sensorConfiguration.unit ?? '' }}
    </div>
    <div
      class="tag-value"
      *ngIf="sensorData.hasSensorDefinedState()"
      [ngStyle]="{ 'background-color': sensorData.getSensorDefinedState()?.color }"
    >
      <span class="status">{{ sensorData.getSensorDefinedState()?.show | value }}</span>
    </div>
    }
  </div>

  @if(sensorData.chart.show){
  <div class="graph">
    <jm-chart
      *ngIf="sensorData.chart.hasChartData(); else noData"
      [chartConfig]="sensorData.chart.config"
      [data]="sensorData.chart.data"
      [id]="sensorData.chart.id"
    ></jm-chart>
  </div>
  }
</div>

<ng-template #noData>
  <app-empty-block [title]="'global.n/a' | translate" [description]="'global.no-data-available' | translate" [showIcon]="false">
  </app-empty-block
></ng-template>
