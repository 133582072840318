import { ChartConfiguration } from 'chart.js';
import { commonZoomConfig } from '../core/plugins/zoom.plugin';
import { UNIT } from './efficiency-per-shift.constants';

export const efficiencyPerShiftChartConfig: ChartConfiguration = {
  type: 'bar',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      zoom: {
        ...commonZoomConfig,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { formattedValue } = context;
            return `${formattedValue} ${UNIT}`;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
      Y: {
        title: {
          display: true,
          text: UNIT,
          font: {
            size: 16,
          },
        },
      },
    },
  },
};
