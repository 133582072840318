import { Action } from '@ngrx/store';
import { PayloadAction } from 'src/app/shared/util';
import { AlertConfiguration } from '../model';

export const enum NotificationConfigurationActionType {
  FETCH_ALERT_CONFIGURATION = '[ALERTS] FETCH_ALERT_CONFIGURATION',
  SAVE_ALERT_CONFIGURATION = '[ALERTS] SAVE_ALERT_CONFIGURATION',
  ERROR_ALERT_CONFIGURATION = '[ALERTS SAVE_ALERT_CONFIGURATION',
  UPDATE_ALERT_CONFIGURATION = '[ALERTS] UPDATE_ALERT_CONFIGURATION',
  SUCCESSFULLY_UPDATE_ALERT_CONFIGURATION = '[ALERTS] SUCCESSFULLY_UPDATE_ALERT_CONFIGURATION',
  ERROR_UPDATE_ALERT_CONFIGURATION = '[ALERTS] ERROR_UPDATE_ALERT_CONFIGURATION',

  LOAD_ONE_SIGNAL_GLOBAL_NOTIFICATION = '[ALERTS] LOAD_ONE_SIGNAL_GLOBAL_NOTIFICATION',
  RESPONSE_ONE_SIGNAL_GLOBAL_NOTIFICATION = '[ALERTS] RESPONSE_ONE_SIGNAL_GLOBAL_NOTIFICATION',

  UPDATE_ONE_SIGNAL_GLOBAL_NOTIFICATION = '[ALERTS] UPDATE_ONE_SIGNAL_GLOBAL_NOTIFICATION',
  DONE_UPDATE_ONE_SIGNAL_GLOBAL_NOTIFICATION = '[ALERTS] DONE_UPDATE_ONE_SIGNAL_GLOBAL_NOTIFICATION',
}
export class FetchAlertConfiguration implements Action {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.FETCH_ALERT_CONFIGURATION;
}

export class SaveAlertConfiguration implements PayloadAction {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.SAVE_ALERT_CONFIGURATION;

  constructor(public payload: { alertConfiguration: AlertConfiguration }) {}
}

export class ErrorAlertConfiguration implements Action {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.ERROR_ALERT_CONFIGURATION;
}

export class UpdateAlertConfiguration implements PayloadAction {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.UPDATE_ALERT_CONFIGURATION;

  constructor(public payload: { alertConfiguration: AlertConfiguration }) {}
}

export class SuccessfullyUpdateAlertConfiguration implements Action {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.SUCCESSFULLY_UPDATE_ALERT_CONFIGURATION;
}

export class ErrorUpdateAlertConfiguration implements Action {
  readonly type: NotificationConfigurationActionType = NotificationConfigurationActionType.ERROR_UPDATE_ALERT_CONFIGURATION;
}

export type NotificationConfigurationAction =
  | FetchAlertConfiguration
  | SaveAlertConfiguration
  | ErrorAlertConfiguration
  | UpdateAlertConfiguration
  | SuccessfullyUpdateAlertConfiguration
  | ErrorUpdateAlertConfiguration;
