@if(tableConfig.search.show || tableConfig.filtering || tableConfig.reorderColumns){
<div class="search-box" [class.search-box--sticky]="tableConfig.search.sticky">
  <div class="search-box__search">
    <app-sb-string-input
      *ngIf="tableConfig.search?.show"
      [placeholder]="tableConfig.search.placeholder || '' | translate"
      [icon]="'search'"
      [iconLeftPosition]="true"
      (onValueChange)="onGlobalSearchChanged($event)"
      [id]="'table-search'"
      [value]="globalSearch"
    ></app-sb-string-input>
  </div>
  <div class="search-box__actions">
    @if(tableConfig.globalFilter.icon && tableConfig.globalFilter.label){
    <jm-button
      [class]="'gray-white-border-button'"
      [suffixIcon]="tableConfig.globalFilter.icon"
      [hideText]="true"
      [matMenuTriggerFor]="globalFilter"
      #globalFilterTrigger="matMenuTrigger"
    >
      {{ tableConfig.globalFilter.label | translate }}
    </jm-button>
    } @if(tableConfig.filtering){
    <jm-button
      [class]="'green-border-button'"
      [disabled]="!areFiltersActiveInTable()"
      [suffixIcon]="'clear-filters'"
      [hideText]="true"
      (clicked)="onResetAllFilters()"
      [dataTestSelector]="'table-clear-filters'"
    >
      {{ 'actions.clear-filters' | translate }}
    </jm-button>
    } @if(tableConfig.reorderColumns){
    <jm-button
      [class]="'gray-special'"
      [suffixIcon]="'setting'"
      [hideText]="true"
      [matMenuTriggerFor]="columnSettings"
      #columnSettingsTrigger="matMenuTrigger"
    >
      {{ 'actions.column-settings' | translate }}
    </jm-button>
    }
  </div>
</div>
}

<p-table
  #dt
  class="primary"
  [class.overflow-table]="tableConfig.reorderColumns"
  [ngClass]="tableConfig.cssClass"
  [dataKey]="tableConfig.dataKey"
  [columns]="cellsConfig"
  [value]="data"
  [loading]="loading"
  [scrollable]="true"
  [rows]="rows"
  [first]="first"
  [filters]="filters"
  responsiveLayout="scroll"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="onLazyLoad($event)"
  [sortField]="selectedSortField"
  [sortOrder]="sortOrder"
  [customSort]="true"
  sortMode="single"
  scrollHeight=""
  [selectionMode]="selectionMode"
  [virtualScroll]="tableConfig.virtualScroll"
  scrollHeight="{{ tableConfig.scrollHeight }}"
>
  <ng-template pTemplate="header" let-columns let-rowData>
    <tr
      jm-row-header
      [attr.id]="rowData.id"
      [columns]="cellsConfig"
      [rowExpanded]="tableConfig.rowExpanded"
      [sortOrder]="sortOrder"
      [sortField]="selectedSortField"
      [unit]="tableConfig.unit"
      [tableConfig]="tableConfig"
    ></tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns" let-expanded="expanded" let-i="rowIndex">
    <tr
      jm-row-body
      [attr.id]="rowData.id || rowData.guid"
      [columns]="columns"
      [class.expanded-row]="!!expanded"
      [class.row-has-menu-options]="tableConfig.moreOptions.length > 0"
      [ngClass]="getConditionalClasses(rowData)"
      [rowData]="rowData"
      [expanded]="expanded"
      [rowExpanded]="tableConfig.rowExpanded"
      [moreOptions]="tableConfig.moreOptions"
      [deleteButton]="tableConfig.deleteButton"
      [dequeueButton]="tableConfig.dequeueButton"
      [matMenuTemplate]="matMenuTemplate"
      [rowIndex]="i"
      [tableConfig]="tableConfig"
      (click)="onItemClick(rowData)"
      (optionsSelected)="optionsSelected.emit($event)"
      (recordMoreOptions)="onRecordMoreOptions($event)"
    ></tr>
  </ng-template>

  @if(tableConfig.rowExpanded || tableConfig.mobileRowExpanded){
  <ng-template pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr class="row-expansion default-mobile-columns hidden-tablet hidden-desktop" *ngIf="tableConfig.mobileRowExpanded">
      <td [attr.colspan]="colspan">
        <ng-template ngFor let-column [ngForOf]="columns">
          <jm-row-expansion *ngIf="column.meta.hiddenMobile" [column]="column" [rowData]="rowData" />
        </ng-template>
      </td>
    </tr>
    <tr class="row-expansion" *ngIf="tableConfig.rowExpanded">
      <td [attr.colspan]="colspan">
        <ng-template #defaultTemplate>
          <td [attr.colspan]="colspan" *ngFor="let column of columns">
            <jm-row-expansion [column]="column" />
          </td>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="rowExpandTemplate || defaultTemplate"
          [ngTemplateOutletContext]="{ $implicit: rowData, index: rowIndex }"
        >
        </ng-container>
      </td>
    </tr>
  </ng-template>
  }

  <ng-template pTemplate="loadingbody" let-columns="columns">
    <tr style="height: 46px">
      <td [attr.colspan]="colspan">
        <app-loader class="jm-loader" />
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr class="no-data-table-holder">
      <td [attr.colspan]="colspan">
        <app-empty-block [title]="'global.no-data' | translate" />
      </td>
    </tr>
  </ng-template>

  <ng-container *ngIf="tableConfig.footer">
    <ng-template pTemplate="footer" let-columns>
      <!-- TBD -->
    </ng-template>
  </ng-container>
</p-table>

<mat-menu #columnSettings="matMenu" class="column-settings" xPosition="before">
  <jm-column-settings
    [columns]="cellsConfig"
    (click)="$event.stopPropagation()"
    (applyChanges)="onApplyColumnSettings($event)"
    (closeMenu)="onCloseColumnSettings()"
  />
</mat-menu>

<mat-menu #globalFilter="matMenu" class="column-settings" xPosition="before">
  <jm-global-filter
    *ngIf="tableConfig.globalFilter"
    [controls]="tableConfig.globalFilter.controls"
    [filters]="filters"
    (click)="$event.stopPropagation()"
    (valueChanged)="onGlobalFilterChange($event)"
    (cancel)="onGlobalFilterCancel()"
  />
</mat-menu>
