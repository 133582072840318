import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { snackbarConfig } from 'src/app/util/notification.util';
import { SnackBarComponent } from './component/snack-bar.component';
import { SeverityEnum } from './enums/severity.enums';
import { ObjectLiteral } from 'src/app/util/object-literal';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private readonly snackBar: MatSnackBar) {}

  open(message: string, config?: MatSnackBarConfig) {
    const mergedConfig = Object.assign({}, snackbarConfig, config);
    mergedConfig.data.message = message;
    this.snackBar.openFromComponent(SnackBarComponent, mergedConfig);
  }

  error(message: string, config?: MatSnackBarConfig) {
    const mergedConfig = Object.assign({}, snackbarConfig, config);
    mergedConfig.data.message = message;
    mergedConfig.data.severity = SeverityEnum.ERROR;
    this.open(message, mergedConfig);
  }

  info(message: string, config?: MatSnackBarConfig) {
    const mergedConfig = Object.assign({}, snackbarConfig, config);
    mergedConfig.data.message = message;
    mergedConfig.data.severity = SeverityEnum.INFO;
    this.open(message, mergedConfig);
  }

  success(message: string, config?: MatSnackBarConfig, link?: ObjectLiteral) {
    const mergedConfig = Object.assign(snackbarConfig, config);
    mergedConfig.data.message = message;
    mergedConfig.data.severity = SeverityEnum.SUCCESS;
    mergedConfig.data.link = link;
    this.open(message, mergedConfig);
  }

  warning(message: string, config?: MatSnackBarConfig) {
    const mergedConfig = Object.assign({}, snackbarConfig, config);
    mergedConfig.data.message = message;
    mergedConfig.data.severity = SeverityEnum.WARNING;
    this.open(message, mergedConfig);
  }
}
