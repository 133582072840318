import { SortOrder } from './sort-order.enum';

export interface SortBy {
  columnName: any;
  direction?: SortOrder;
}

export const initialSortBy: SortBy = {
  columnName: 'createdAt',
  direction: SortOrder.DESC,
};
