<header class="snack-bar__header {{ data.severity.toLowerCase() }}">
  <svg-icon [ngClass]="data.severity.toLowerCase()" [src]="getIconUrl()"></svg-icon>
  <span class="header__text">{{ data.severity }}</span>
</header>
<main class="snack-bar__body">
  {{ data.message | translate }}
  @if(data?.link?.text){
  <br />
  <span class="link" (click)="navigateTo()">
    {{ data?.link?.text | translate }}
    <svg-icon [src]="'./assets/images/arrow-right-line.svg'"></svg-icon>
  </span>
  }
</main>
<footer>
  <div class="snack-bar__actions">
    <jm-button [class]="'dismiss'" (clicked)="onDismiss()" [dataTestSelector]="'dismiss'">
      {{ 'notifications.dismiss' | translate }}
    </jm-button>
    @if(data.messageId){
    <jm-button [class]="'readmore'" (clicked)="onReadMore()"> {{ 'notifications.readmore' | translate }} </jm-button>}
  </div>
</footer>
