import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { COMEZ_ALERTS, FRICK_STATUS, MACHINE_PLATFORM } from '@machines';
import * as FormHelper from '@quick-form';
import { enumToLookupResultsArray } from '@util';
import { ControlConfig, ControlTypeEnum } from '@quick-form';
import { UserService } from '@user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-machine-stopped-control',
  templateUrl: './machine-stopped-control.component.html',
  styleUrls: ['./machine-stopped-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineStoppedControlComponent implements OnInit {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly fb = inject(FormBuilder);
  private readonly userService = inject(UserService);

  @Input()
  control: any;

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  formConfigFrickStatus: Array<ControlConfig> = [];
  formFrickStatus: FormGroup | null = null;

  formConfigComezStatus: Array<ControlConfig> = [];
  formComezStatus: FormGroup | null = null;

  showComez = false;
  showFrick = false;

  ngOnInit(): void {
    this.getUserPermissionToPlatform();
  }

  onStatusToggle(control: any, event: MatCheckboxChange, type: 'COMEZ' | 'FRICK') {
    if (type === 'FRICK') {
      this.formFrickStatus?.get(control)?.setValue(event.checked);
    }

    if (type === 'COMEZ') {
      this.formComezStatus?.get(control)?.setValue(event.checked);
    }

    const object = {
      ...this.formFrickStatus?.value,
      ...this.formComezStatus?.value,
    };

    this.valueChanged.emit(object);
  }

  onStopAlertToggle(control: any, value: any): void {
    this.formFrickStatus?.get(control)?.setValue(value);
    this.valueChanged.emit(this.formFrickStatus?.value);
  }

  private initFormFrick() {
    this.formConfigFrickStatus = enumToLookupResultsArray(FRICK_STATUS).map((item) => {
      const { id } = item;
      const exists = this.control.value.value.includes(id);
      return new ControlConfig().loadModel({
        id,
        value: exists,
        type: ControlTypeEnum.Checkbox,
        disabled: false,
        label: 'machines.status.' + id,
      });
    });

    const stopControls = FormHelper.createControls(this.formConfigFrickStatus);

    this.formFrickStatus = this.fb.group({
      machineStoppedAlert: new FormControl(false),
      ...stopControls,
    });
  }

  private initFormComez() {
    this.formConfigComezStatus = COMEZ_ALERTS.map((status) => {
      const exists = this.control.value.value.includes(status);
      return new ControlConfig().loadModel({
        id: status,
        value: exists,
        type: ControlTypeEnum.Checkbox,
        disabled: false,
        label: 'machines.status.' + status,
      });
    });

    const stopControls = FormHelper.createControls(this.formConfigComezStatus);

    this.formComezStatus = this.fb.group({
      ...stopControls,
    });
  }

  private getUserPermissionToPlatform(): void {
    this.userService
      .getUserPermissionToPlatform()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.showComez = res.includes(MACHINE_PLATFORM.COMEZ);
        this.showFrick = res.filter((platform) => platform !== MACHINE_PLATFORM.COMEZ).length > 0;

        if (this.showComez) {
          this.initFormComez();
        }

        if (this.showFrick) {
          this.initFormFrick();
        }

        this.cdr.detectChanges();
      });
  }
}
