<app-auth-card [loading]="loader$ | async">
  <ng-container title>
    {{ 'auth.activate.title' | translate }}
  </ng-container>

  <form *ngIf="form" class="quick-form form">
    <quick-form-controls [form]="form" [config]="formConfig"> </quick-form-controls>
  </form>

  <app-password-rules *ngIf="token !== null && form.invalid && form.touched"></app-password-rules>

  <jm-button [class]="'red-button'" (clicked)="submit()" [disabled]="form.invalid && form.touched">
    {{ 'auth.set-password' | translate }}
  </jm-button>
</app-auth-card>
