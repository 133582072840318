import {
  Component,
  forwardRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlConfig } from '../../../models/control.config';

declare let google: any;

@Component({
  selector: 'quick-form-google-places',
  templateUrl: './google-places.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QuickGooglePlacesComponent),
    },
  ],
})
export class QuickGooglePlacesComponent implements OnInit, AfterViewInit {
  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Output()
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addressRef') addressRef: any;

  @Input() label = '';
  @Input() required = false;
  @Input() invalid = false;

  isDisabled = false;

  adressType = 'address';

  autoCompleteValue = '';

  constructor(private readonly cdr: ChangeDetectorRef, private readonly ngZone: NgZone) {}

  ngOnInit(): void {
    if (this.config.disabled) {
      this.setDisable(true);
    }

    const addresType = this.config.meta?.addressType;
    if (addresType) {
      this.adressType = addresType;
    }
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete(): void {
    const autocomplete = new google.maps.places.Autocomplete(this.addressRef.nativeElement, {
      types: [this.adressType], // 'establishment' / 'address' / 'geocode'
    });

    this.ngZone.runOutsideAngular(() => {
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        this.ngZone.run(() => {
          autocomplete.getx;
          const place = autocomplete.getPlace();
          this.onValueChanged(place);
        });
      });
    });
  }

  writeValue(value: any): void {
    this.autoCompleteValue = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisable(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onValueChanged(event: any) {
    if (event.geometry) {
      this.propagateChange(event);
      this.valueChanged.emit(event);
      this.autoCompleteValue = event.formatted_address;
      return;
    }

    this.autoCompleteValue = '';
    this.propagateChange('');
    this.valueChanged.emit('');
  }

  onBlur(): void {
    this.propagateTouched();
  }

  // eslint-disable-next-line
  private propagateChange = (value: any) => {};

  // eslint-disable-next-line
  private propagateTouched = () => {};
}
