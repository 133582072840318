<div class="dashboard-menu">
  <header class="dashboard-menu__header">
    <jm-button
      [class]="'gray-white-border-button full-width'"
      [suffixIcon]="'circle'"
      (click)="goTo('/dashboard')"
      [dataTestSelector]="'go-to--dashboard'"
    >
      {{ 'dashboards.actions.control-center' | translate }}
    </jm-button>
    <jm-button
      [class]="'gray-white-border-button full-width'"
      *appHasLicense="[licenses.PerformanceDashboard]"
      [suffixIcon]="'performance'"
      (click)="goTo('/performance-dashboard')"
      [dataTestSelector]="'go-to-performance-dashboard'"
    >
      {{ 'dashboards.actions.performance' | translate }}
    </jm-button>
  </header>
  <ul class="dashboard-menu-list">
    <ng-container *ngIf="privateDashboards$ | async as privateDashboards">
      @if(privateDashboards.length > 0){
      <li class="dashboard-menu-list__item widget-type-title no-hover" (click)="$event.stopPropagation()">
        <svg-icon [src]="'./assets/images/lock.svg'"></svg-icon> {{ 'dashboard.menu.private' | translate }}
      </li>
      <li
        class="dashboard-menu-list__item"
        *ngFor="let dashboard of privateDashboards"
        [class.active]="dashboard.guid === activeDashboardId"
        (click)="goTo('/dashboard/view/' + dashboard.guid)"
      >
        <svg-icon class="check" [src]="'./assets/images/check.svg'"></svg-icon>
        <span>{{ dashboard.name }}</span>
      </li>
      }
    </ng-container>
    <ng-container *ngIf="sharedDashboards$ | async as sharedDashboards">
      <li class="dashboard-menu-list__item widget-type-title no-hover" (click)="$event.stopPropagation()">
        <svg-icon [src]="'./assets/images/link.svg'"></svg-icon> {{ 'dashboard.menu.shared' | translate }}
      </li>
      <li
        class="dashboard-menu-list__item"
        *ngFor="let dashboard of sharedDashboards"
        [class.active]="dashboard.guid === activeDashboardId"
        (click)="goTo('/dashboard/view/' + dashboard.guid)"
      >
        <svg-icon class="check" [src]="'./assets/images/check.svg'"></svg-icon>
        <span>{{ dashboard.name }}</span>
      </li>
    </ng-container>
  </ul>

  <footer class="dashboard-menu__footer">
    <jm-button
      [class]="'green-button btn-create full-width'"
      [suffixIcon]="'plus'"
      (clicked)="createNewDashboard()"
      [dataTestSelector]="'create-new-dasboard'"
    >
      {{ 'dashboards.actions.create-new' | translate }}
    </jm-button>
  </footer>
</div>
