
    
    var doc = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"subscription","name":{"kind":"Name","value":"OrderStatusUpdated"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"orderGuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}},"directives":[]}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"orderStatusUpdated"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orderGuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"orderGuid"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"orderGuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"orderManagementMovable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"orderManagementRemovable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"preselectPicks"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"remainingPicks"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastKnownSpeed"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"statusLastChangedAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"progressPercent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"secondsRemaining"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lineNumber"},"arguments":[],"directives":[]}]}}]}}]};
  

    
  var names = {};
  function unique(defs) {
    return defs.filter(function (def) {
      if (def.kind !== 'FragmentDefinition') return true;
      var name = def.name.value;
      if (names[name]) {
        return false;
      } else {
        names[name] = true;
        return true;
      }
    });
  };

  

    module.exports = doc
  
