import { ObjectLiteral } from 'src/app/util/object-literal';

import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { ListResultDTO } from '../../jm-table/interfaces/list-result.dto';
import { ControlConfigDTO } from '../interfaces/control-config.interface';
import { FormValidators } from '../interfaces/validators.interace';
import { ControlConfig } from '../models/control.config';
import { Validators } from '../validators/validators';
import { get, cloneDeep } from 'lodash-es';
import { LookupResult } from '../interfaces/lookup-result.interface';
import { QuickFormError } from '../errors/forms.error';

/**
 * Creates form controls object ready to be used by formBuilder.group()
 * @param config array holding controls configuaration
 * @returns object literal with controlls used by formBuilder.group({...})
 */
export function createControls(controlsConfig: Array<ControlConfig>, prefix?: string) {
  const controls: ObjectLiteral = {};

  controlsConfig.forEach((config: ControlConfig) => {
    if (config.type === 'group') {
      const groupControls = config.children as Array<ControlConfig>;

      groupControls.forEach((childConfig: ControlConfig) => {
        let controlId = childConfig.id;

        if (prefix) {
          controlId = `${prefix}-${controlId}`;
        }

        controls[controlId] = createControl(childConfig, groupControls);
      });

      return;
    }

    let controlId = config.id;

    if (prefix) {
      controlId = `${prefix}-${controlId}`;
    }

    controls[controlId] = createControl(config, controlsConfig);
  });

  return controls;
}

/**
 * Creates single control from provided config
 * @param control single control config
 * @param controlsConfig all controls config required by child functions
 * @returns FormControl
 */
export function createControl(control: ControlConfig, controlsConfig: Array<ControlConfig>): AbstractControl {
  if (control.type === 'repeater') {
    return createRepeaterControl(control, controlsConfig);
  }

  let validators: Array<ValidatorFn> = [];
  if (control.validators) {
    validators = createValidators(control.validators, control, controlsConfig);
  }
  return new FormControl({ value: control.value, disabled: control.disabled }, validators);
}

/**
 * Uses controlConfig to build angular form array control with its children
 * @param control
 * @param controlsConfig
 * @returns FormArray
 */
function createRepeaterControl(control: ControlConfig, controlsConfig: Array<ControlConfig>): FormArray {
  const forms = (control.children as Array<Array<ControlConfig>>).map((formConfig: Array<ControlConfig>) => {
    return createControls(formConfig);
  });

  const formGroups = forms.map((item) => new FormGroup(item));

  let validators: Array<ValidatorFn> = [];
  if (control.validators) {
    validators = createValidators(control.validators, control, controlsConfig);
  }
  return new FormArray([...formGroups], { validators });
}

/**
 * Creates Angular validator array used by form control
 * @param validators object containing list of validators
 * @param currentControl control for validtors to be applied to
 * @param controls access to all controls configuaration
 * @returns ValidatorFn[]
 */
export function createValidators(
  validators: FormValidators,
  currentControl: ControlConfig,
  controlsConfig: Array<ControlConfig>
): Array<ValidatorFn> {
  return Object.keys(validators).map((key: string) => {
    return (Validators as ObjectLiteral)[key](validators[key], currentControl, controlsConfig);
  });
}

/**
 * Loads config neccessary for quick Form controls to work.
 * User defined config is merged with default config defined in ControlConfig class
 * @param config Array of control configs
 * @param model when available form values are populated from model
 * @returns Array of control config
 */
export function loadFormConfig(config: Array<ControlConfigDTO>, model?: ObjectLiteral | null): Array<ControlConfig> {
  const clonedConfig = cloneDeep(config);
  return clonedConfig.map((item) => loadConfig(item, model));
}

/**
 * Loads single control config
 * User defined config is merged with default config defined in ControlConfig class
 * @param config Array of control configs
 * @param model when available form values are populated from model
 * @returns Control
 */
export function loadConfig(config: ControlConfigDTO, model?: ObjectLiteral | null): ControlConfig {
  if (model) {
    config.value = get(model, config.take || config.id);
  }

  const conf: ControlConfig = new ControlConfig().loadModel(config);

  if (config.type === 'repeater') {
    return loadRepeater(conf, model);
  }

  if (config.type === 'group') {
    return loadGroup(conf, model);
  }

  return conf;
}

/**
 * Loads config for repeater control
 * @param config Control Configuration
 * @param model when available form values are populated from model
 * @returns ControlConfig
 */
function loadRepeater(config: ControlConfig, model?: ObjectLiteral | null): ControlConfig {
  const records: Array<ObjectLiteral> = get(model, config.take || config.id);

  if (!config.children || !config.children[0]) {
    throw new QuickFormError('When using repeater first child has to be of type ControlConfigDTO');
  }

  const conf = config.children[0] as Array<ControlConfigDTO>;
  const mappedConf = conf.map((confItem) => ({ ...confItem, index: 0 }));
  config.children[0] = loadFormConfig(mappedConf);

  config.meta = {
    ...config.meta,
    initialConfig: cloneDeep(config.children[0]),
  };

  if (config.meta?.initialEmptyRow && !records) {
    config.children = [];
    return config;
  }

  if (!records) {
    return config;
  }

  config.children = records.map((item, index) => {
    const mappedConf = conf.map((confItem) => {
      return {
        ...confItem,
        index,
      };
    });

    return loadFormConfig(mappedConf, item);
  });
  return config;
}

export function updateRepeaterDropdownOptions(config: ControlConfig, options: ListResultDTO<any>, disabled = false): ControlConfig {
  const clonedConfig = cloneDeep(config);
  clonedConfig.meta = {};

  clonedConfig.meta.initialConfig = clonedConfig.meta.initialConfig?.map((controlConfig: ControlConfig) => {
    controlConfig.meta = {
      options: options.records,
    };
    controlConfig.disabled = disabled;
    return controlConfig;
  });

  clonedConfig.children = (config.children as Array<Array<ControlConfig>>).map((repeaterChild) => {
    return repeaterChild.map((controlConfig) => {
      controlConfig.meta = {
        options: options.records,
      };
      controlConfig.disabled = disabled;
      return controlConfig;
    });
  });

  return clonedConfig;
}

/**
 * Loads config for group control
 * @param config
 * @param model
 * @returns ControlConfig
 */
function loadGroup(config: ControlConfig, model?: ObjectLiteral | null): ControlConfig {
  const conf = config.children as Array<ControlConfigDTO>;
  config.children = loadFormConfig(conf, model);
  return config;
}

/**
 * Executes provided callback if control is leaf
 * @param configs
 * @param callback
 */
export function whenLeaf(
  configs: Array<ControlConfig>,
  callback: (item: ControlConfig, config?: Array<ControlConfig>, index?: number) => void
) {
  configs.forEach((item, index: number) => {
    if (!item.isLeaf()) {
      if (item.type === 'repeater') {
        (item.children as Array<Array<ControlConfig>>).forEach((formConfig: Array<ControlConfig>) => {
          whenLeaf(formConfig, callback);
        });
        return;
      }
      const children = item.children as Array<ControlConfig>;
      whenLeaf(children, callback);
      return;
    }

    callback(item, configs, index);
  });
}

/**
 * Finds a control in formConfig tree
 * @param configs
 * @param id
 * @returns ControlConfig
 */
export function findConfig(configs: Array<ControlConfig>, id: string): ControlConfig | null {
  let formConfig: ControlConfig | null = null;

  configs.forEach((item) => {
    if (!item.isLeaf()) {
      const children = item.getChildren();
      const foundChild = findConfig(children, id);
      if (foundChild) {
        formConfig = foundChild;
      }
      return;
    }

    if (item.id === id) {
      formConfig = item;
    }
  });

  return formConfig;
}

/**
 *
 * @param enumOject enum type object
 * @param translationPath root path for the options translation
 * @returns Array of LookupResult
 */
export function enumToOptions(enumObject: ObjectLiteral, translationPath?: string): Array<LookupResult> {
  return Object.keys(enumObject).map((key) => {
    const value = enumObject[key];
    const name = translationPath ? translationPath + '.' + key : key;

    return {
      id: value,
      name,
    };
  });
}
