import { ITableColumnsDTO } from 'src/app/modules/legacy-table/table-columns.model';

export const alertConfigurationSettingsRows: Array<ITableColumnsDTO> = [
  {
    label: 'machineSpeedAlertDelay',
    dtoValue: 'machineSpeedAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'machineEfficiencyAlertDelay',
    dtoValue: 'machineEfficiencyAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'articleSpeedAlertDelay',
    dtoValue: 'articleSpeedAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'articleEfficiencyAlertDelay',
    dtoValue: 'articleEfficiencyAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'locationEfficiencyAlertDelay',
    dtoValue: 'locationEfficiencyAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'orderRunoutAlert',
    dtoValue: 'orderRunoutAlert',
    disabled: false,
    type: 'number',
  },
  {
    label: 'muconAlertDelay',
    dtoValue: 'muconAlertDelay',
    disabled: false,
    type: 'number',
  },
  {
    label: 'machineStoppedAlertReasons',
    dtoValue: 'machineStoppedAlertReasons',
    disabled: false,
    type: 'Array',
  },
];
