import { Article } from '../../articles/article/models/article.model';
import { OrderQueueUpdatedDTO } from '../interfaces/order-queeue-updated.interface';
import { OrderDTO } from '../interfaces/order.interface';

export function mapFromQueueUpdated(orders: Array<OrderQueueUpdatedDTO>): Array<Partial<OrderDTO>> {
  const mappedOrders = orders.map((order: OrderQueueUpdatedDTO) => {
    const { articleGuid, articleName, machineGuid, ...orderUpdate } = order;
    const article = new Article().loadModel({
      guid: articleGuid,
      name: articleName,
    });

    return {
      ...orderUpdate,
      article,
      machine: { guid: machineGuid },
    };
  });

  return mappedOrders;
}
