<app-progress-bar [progressBar]="progressBar">
  <ng-container *ngIf="!showOnlyBar">
    <ng-container *ngIf="!showOnlyName">
      <span class="bar-text" *ngIf="progressBar.translation">{{ progressBar.translation | translate }}</span>
      <div class="remaining-time" *ngIf="progressBar?.secondsRemaining || progressBar?.secondsRemaining?.length === 0">
        <span class="bar-text">{{ progressBar.secondsRemaining }}</span>
      </div>
    </ng-container>
    <span class="bar-text" *ngIf="showOnlyName">{{ progressBar.name }}</span>
  </ng-container>
</app-progress-bar>
