<div *ngIf="!embedded">
  <app-tour-step></app-tour-step>
  <app-notification-message
    [userNotification]="userNotification$ | async"
    (closeNotification)="closeNotification($event)"
    *ngIf="userNotification$ | async"
  ></app-notification-message>
  <div class="navbar-selector" [class.mobileMenuOpen]="mobileMenuOpen">
    <app-navbar [hideLogo]="hideLogo" (mobileMenuOpen)="onMobileMenuOpen($event)"></app-navbar>
  </div>
</div>
<div
  class="app-router {{ moduleClassName }}"
  [ngClass]="{
    'mobile-notifications': innerWidth < 768 && routerUrl === '/notifications/alerts-list',
    embedded: embedded
  }"
>
  <div>
    <router-outlet></router-outlet>
  </div>
  <div
    class="footer-selector"
    *ngIf="isComponentLocationDashboardStatistic() ? innerWidth > 320 || innerHeight > 500 : true"
    [ngClass]="{
      'footer-selector--tablet': innerHeight <= 767 && innerWidth >= 768
    }"
  >
    <app-footer *ngIf="!embedded"></app-footer>
  </div>
</div>
