import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModuleSidebarService {
  navOpen = true;
  rightNavOpen = true;

  private showLeftSideMenu = true;
  private readonly showLeftSideMenu$ = new BehaviorSubject<boolean>(true);

  openHideLeftSideMenu(): void {
    this.showLeftSideMenu = !this.showLeftSideMenu;
    this.showLeftSideMenu$.next(this.showLeftSideMenu);
  }

  isLeftSideMenuOpen(): Observable<boolean> {
    return this.showLeftSideMenu$.asObservable();
  }
}
