import { Component } from '@angular/core';
import { machineCustomClasses, MachineStatus } from '@machines';
import { isSet } from '@util';

import { AbstractCellComponent } from '../abstract-cell/abstract-cell.component';

@Component({
  selector: 'td[jm-machine-status]',
  templateUrl: './jm-machine-status-cell.component.html',
})
export class JmMachineStatusCellComponent extends AbstractCellComponent {
  shouldDisplayAnotherStatus(status: MachineStatus | undefined): boolean {
    if (!isSet(status)) {
      return false;
    }

    return machineCustomClasses.includes(status);
  }
}
