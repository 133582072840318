import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';

import { DashboardLocalStorageAction } from './store/dashboard.actions';
import { DashboardPersistentState } from './store/dashboard.state';

export const DASHBOARD_STORAGE_KEYS = new InjectionToken<keyof DashboardPersistentState>('DashboardWidgetsStorageKeys');
export const DASHBOARD_LOCAL_STORAGE_KEY = new InjectionToken<Array<string>>('DashboardWidgetsStorage');
export const DASHBOARD_CONFIG_TOKEN = new InjectionToken<StoreConfig<DashboardPersistentState, DashboardLocalStorageAction>>(
  'DashboardConfigToken'
);
