import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isSet } from 'src/app/util/util';

@Component({
  selector: 'app-sb-dash-number-input',
  templateUrl: './sb-dash-number-input.component.html',
  styleUrls: ['./sb-dash-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbDashNumberInputComponent implements OnChanges {
  @Input() value: number | null = null;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() unit = '';

  @Input() disabled = false;

  @Output() valueChanged: EventEmitter<number | null | undefined> = new EventEmitter<number | null | undefined>();

  @ViewChild('numberInput') numberInput?: ElementRef<HTMLInputElement>;

  checkedValue: number | null | undefined;
  targetValue: number | null | undefined | string;
  isInputFocused = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.checkedValue = this.checkValue(this.value);
      this.targetValue = this.value;

      if (this.numberInput?.nativeElement) {
        this.numberInput.nativeElement.value = this.targetValue?.toString() || '';
      }
    }
  }

  valueChange(event: any): void {
    let parsedValue: number | null | undefined | string = event.target.value;

    if (!isSet(parsedValue)) {
      return;
    }

    if (parsedValue.toString().charAt(0) === '0' && parsedValue.toString().length >= 2) {
      parsedValue = parsedValue.toString().replace(/^0+/, '');
    }
    this.targetValue = Number(parsedValue);
    const valueChecked: number | null | undefined = this.checkValue(Number(parsedValue));
    this.valueChanged.emit(valueChecked);
  }

  focusOnNumberInput(): void {
    this.numberInput?.nativeElement.focus();
  }

  private checkValue(value: any): number | null | undefined {
    let valueChecked: number | null;

    if (this.min && value < this.min) {
      valueChecked = this.min;
    } else if (this.max && value > this.max) {
      valueChecked = this.max;
    } else {
      valueChecked = value;
    }

    if (!isSet(valueChecked)) {
      return;
    }

    return Number(valueChecked);
  }

  get styleWidth(): string {
    const currentValue = this.targetValue?.toString() ?? '';
    const widthInPixels = Math.max(20, (currentValue.length + 2) * 8);
    return `${widthInPixels}px`;
  }
}
