import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthCardComponent } from 'src/app/layout/partials/auth-card/auth-card.component';
import { BoxModule } from 'src/app/layout/partials/box/box.module';
import { QuickFormsModule } from 'src/app/modules/quick-forms';
import { HeaderBarComponent } from 'src/app/layout/header-bar/header-bar.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LegacyComponentsModule } from '@legacy-components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LoginComponent, PasswordResetComponent, UserProfileComponent } from './components';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { LoginEffects } from './store/login.effect';
import { loginReducer } from './store/login.reducer';
import { loginStateName } from './store/login.state';

import { SSOGuard } from './mymueller-sso/guard/sso.guard.service';
import { LoaderComponent } from '@app-ui';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    UserProfileComponent,
    ActivateAccountComponent,
    PasswordRulesComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    LegacyComponentsModule,
    SharedModule,
    QuickFormsModule,
    AngularSvgIconModule,
    StoreModule.forFeature(loginStateName, loginReducer),
    EffectsModule.forFeature([LoginEffects]),
    HeaderBarComponent,
    BoxModule,
    LoaderComponent,
    AuthCardComponent,
  ],
  providers: [SSOGuard],
})
export class AuthModule {}
