import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { selectActiveLicenses } from '@auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyBlockModule } from 'src/app/shared/modules/empty-block/empty-block.module';

import { filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { LoaderComponent } from '../loader/loader.component';
import { LicenseEnum, checkLicenses } from '@licenses';
import { ParamsTranslatePipe } from 'src/app/util/pipes/params-translate.pipe';
import { notNull } from 'src/app/util/rxjs/rxjs.hellper';

@UntilDestroy()
@Component({
  selector: 'app-view-wrapper',
  templateUrl: './view-wrapper.component.html',
  styleUrls: ['./view-wrapper.component.scss'],
  imports: [NgIf, AsyncPipe, EmptyBlockModule, TranslateModule, LoaderComponent, ParamsTranslatePipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewWrapperComponent {
  private readonly store$ = inject(Store);

  @Input()
  error = false;

  @Input()
  noData = false;

  @Input()
  loading = false;

  @Input()
  requiredLicenses: Array<LicenseEnum> = [];

  get canLoadContent() {
    return !this.loading && !this.error && !this.noData;
  }

  get hasLicence$(): Observable<boolean> {
    if (isEmpty(this.requiredLicenses)) {
      return of(true);
    }

    return this.store$.select(selectActiveLicenses).pipe(
      filter(notNull),
      map((userLicenses: Array<string>) => checkLicenses(userLicenses, this.requiredLicenses)),
      untilDestroyed(this)
    );
  }
}
