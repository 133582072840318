import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavBarService {
  private readonly showMobileMenu$ = new BehaviorSubject<boolean>(false);

  openHideLeftSideMenu(flag: boolean): void {
    this.showMobileMenu$.next(flag);
  }

  showOrHideMobileMenu(): Observable<boolean> {
    return this.showMobileMenu$.asObservable();
  }
}
