import { ControlConfigDTO, ControlTypeEnum } from '@quick-form';

export const userInfoConfig: Array<ControlConfigDTO> = [
  {
    id: 'name',
    label: 'user-profile.username',
    type: ControlTypeEnum.InputText,
    disabled: true,
  },
  {
    id: 'email',
    label: 'user-profile.email',
    type: ControlTypeEnum.InputText,
    disabled: true,
  },
];

export const passwordResetConfig: Array<ControlConfigDTO> = [
  {
    id: 'currentPassword',
    label: 'user-profile.current-password',
    type: ControlTypeEnum.InputText,
    attributes: {
      type: 'password',
    },
    validators: {
      required: true,
      password: true,
    },
  },
  {
    id: 'newPassword',
    label: 'user-profile.new-password',
    type: ControlTypeEnum.InputText,
    attributes: {
      type: 'password',
    },
    validators: {
      required: true,
      equalTo: {
        field: 'confirmNewPassword',
        message: 'Passwords must match',
      },
      password: true,
    },
  },
  {
    id: 'confirmNewPassword',
    label: 'user-profile.confirm-new-password',
    type: ControlTypeEnum.InputText,
    placeholder: 'user-profile.confirm-new-password',
    attributes: {
      type: 'password',
    },
    validators: {
      required: true,
      equalTo: {
        field: 'newPassword',
        message: 'Passwords must match',
      },
      password: true,
    },
  },
];
