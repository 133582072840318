import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { inject, Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { ResponseData } from '../../../abstract/interface/response-data';
import currentSensorReadings from '../gql/current-sensor-readings.graphql';
import rawSensorReadingsChart from '../gql/raw-sensor-readings-chart.graphql';
import { RawSensorChartInput } from '../interfaces/raw-sensor-chart-input.interface';
import { RawSensorReadingsChartDTO } from '../interfaces/raw-sensor-readings-chart.interface';
import { RealtimeSensorDTO } from '../interfaces/real-time-sensor.interface';
import { RequestRealtimeSensorInputDTO } from '../interfaces/request-input.interface';
import { RealtimeSensorChart } from '../model/real-time-sensor-chart.model';
import { RealtimeSensor } from '../model/real-time-sensor.model';

@Injectable({ providedIn: 'root' })
export class RealtimeSensorService {
  private readonly apollo = inject(Apollo);

  getData(input: RequestRealtimeSensorInputDTO): Observable<Array<RealtimeSensor>> {
    return this.apollo
      .query<Array<RealtimeSensorDTO>>({
        query: currentSensorReadings,
        variables: {
          input,
        },
      })
      .pipe(
        catchAllErrors(),
        map((response: ApolloQueryResult<ResponseData<Array<RealtimeSensorDTO>>>) => {
          return response.data.currentSensorReadings.map((sensorData) => new RealtimeSensor().loadModel(sensorData));
        })
      );
  }

  getChartData(input: RawSensorChartInput): Observable<Array<RealtimeSensorChart>> {
    return this.apollo
      .query<RawSensorReadingsChartDTO>({
        query: rawSensorReadingsChart,
        variables: {
          input,
        },
      })
      .pipe(
        catchAllErrors(),
        map((response: ApolloQueryResult<ResponseData<RawSensorReadingsChartDTO>>) => {
          return response.data.rawSensorReadingsChart.readings.values.map((value) => new RealtimeSensorChart().loadModel(value));
        })
      );
  }
}
