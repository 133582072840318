export enum CellType {
  ARRAY = 'array',
  ASSIGNED = 'assigned',
  DATA_TRANSLATION = 'data-translation',
  DATE_TIME = 'date-time',
  LABEL = 'label',
  MACHINE_STATUS = 'machine-status',
  NUMBER = 'number',
  OEE = 'oee',
  ORDER_NAME = 'order-name',
  ORDER_PROGRESS = 'order-progress',
  RELATION = 'relation',
  STRING = 'string',
  TAG = 'tag',
  TIME = 'time',
}
