import { MachineTypeService } from 'src/app/core/machines/machine-type/machine-type.service';
import { MachineService } from 'src/app/core/machines/services/machine.service';
import { CellFilterType } from 'src/app/modules/jm-table/enum/cell-filter-type.enum';
import { CellType } from 'src/app/modules/jm-table/enum/cell-type.enum';
import { CellConfigDTO } from 'src/app/modules/jm-table/interfaces/cell-config.interface';

import { ControlTypeEnum, enumToOptions } from '@quick-form';

import { ProductionDetailsDTO } from '../interfaces';
import { DateTimeFormatStyle } from 'src/app/util/datetime/format-style.enum';
import { MachineStatus } from '@machines';

export const productionDetailsCellConfig: Array<CellConfigDTO> = [
  {
    type: CellType.STRING,
    field: 'articleGuid',
    title: 'performance-dashboard.production-details.table.columns.articleName',
    cssClass: ['small'],
    sortingField: 'articleName',
    sortable: true,
    render: () => {
      return 'articleName';
    },
  },
  {
    type: CellType.DATE_TIME,
    field: 'lastFinished',
    title: 'performance-dashboard.production-details.table.columns.date',
    cssClass: ['small'],
    sortingField: 'lastFinished',
    sortable: true,
    meta: {
      dateFormat: DateTimeFormatStyle.date,
      hiddenMobile: true,
    },
  },
  {
    type: CellType.STRING,
    field: 'machineTypeId',
    title: 'performance-dashboard.production-details.table.columns.machineType',
    cssClass: ['small'],
    sortingField: 'machineTypeName',
    sortable: true,
    render: () => {
      return 'machineTypeName';
    },
    filter: {
      type: CellFilterType.DROPDOWN,
      control: [
        {
          id: 'machineTypeId',
          label: 'actions.select-machine-type',
          placeholder: 'actions.choose-machine-type',
          type: ControlTypeEnum.Dropdown,
          meta: {
            appendTo: '.filter-dropdown',
            valueAsObject: false,
            virtualScroll: true,
            lazyLoad: true,
            lookupService: MachineTypeService,
          },
        },
      ],
    },
  },
  {
    type: CellType.STRING,
    field: 'machineGuid',
    title: 'performance-dashboard.production-details.table.columns.machineName',
    cssClass: ['small'],
    sortingField: 'machineName',
    sortable: true,
    render: (details: ProductionDetailsDTO) => {
      if (details.machineAlias) {
        return 'machineAlias';
      }
      return 'machineName';
    },
    filter: {
      type: CellFilterType.DROPDOWN,
      control: [
        {
          id: 'machineGuid',
          label: 'actions.select-machine',
          placeholder: 'actions.choose-machine',
          type: ControlTypeEnum.Dropdown,
          meta: {
            appendTo: '.filter-dropdown',
            valueAsObject: false,
            virtualScroll: true,
            lazyLoad: true,
            lookupService: MachineService,
          },
        },
      ],
    },
  },
  {
    type: CellType.DATA_TRANSLATION,
    field: 'stopStatus',
    title: 'performance-dashboard.production-details.table.columns.event',
    cssClass: ['small'],
    sortingField: 'stopStatus',
    sortable: true,
    filter: {
      type: CellFilterType.DROPDOWN,
      control: [
        {
          id: 'stopStatus',
          label: 'actions.select-event',
          placeholder: 'actions.choose-event',
          type: ControlTypeEnum.Dropdown,
          meta: {
            appendTo: '.filter-dropdown',
            options: enumToOptions(MachineStatus, 'machines.status'),
          },
        },
      ],
    },
    meta: {
      dataTranslation: 'machines.status',
      hiddenMobile: true,
    },
  },
  {
    type: CellType.TIME,
    field: 'downtime',
    title: 'performance-dashboard.production-details.table.columns.downtime',
    cssClass: ['small'],
    sortingField: 'downtime',
    sortable: true,
  },
];
