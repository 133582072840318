import { ChartData, ChartDataset } from 'chart.js';
import { CHART_COLORS } from 'src/app/layout/chart/chart.enum';

import { Profitability } from '../models';
import { arrayPadEnd } from 'src/app/util/util';

export function dataToChartMapper(articles: Array<Profitability>): ChartData {
  const maxLength = 10;
  const revenueValues = articles.map((value) => value.revenue || 0);
  const costValues = articles.map((value) => value.cost || 0);
  const profitValues = articles.map((value) => value.profit || 0);
  const labelsValues = articles.map((value) => value.articleName);

  const datasets: Array<ChartDataset> = [
    {
      label: 'Revenue',
      backgroundColor: CHART_COLORS.grey,
      borderColor: CHART_COLORS.grey,
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: arrayPadEnd(revenueValues, maxLength, null),
      maxBarThickness: 40,
      stack: 'revenue',
    },
    {
      label: 'Cost',
      backgroundColor: CHART_COLORS.blue,
      borderColor: CHART_COLORS.blue,
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: arrayPadEnd(costValues, maxLength, null),
      maxBarThickness: 40,
      stack: 'cost',
    },
    {
      label: 'Profit',
      borderColor: CHART_COLORS.orange,
      backgroundColor: CHART_COLORS.orange,
      data: arrayPadEnd(profitValues, maxLength, null),
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      maxBarThickness: 40,
      stack: 'profit',
    },
  ];

  const labels = arrayPadEnd(labelsValues, maxLength, '');
  return {
    labels,
    datasets,
  };
}
