import { Injectable, inject } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { SignInDTO } from '../../intefaces/sign-in';
import { ApolloQueryResult } from '@apollo/client';
import { ResponseData } from 'src/app/core/abstract/interface/response-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MymuellerSSOService {
  private readonly apollo = inject(Apollo);

  signIn(code: string): Observable<SignInDTO> {
    const mutation = gql`
      mutation signInWithOAuth($code: String!) {
        signInWithOAuth(code: $code) {
          token
          user {
            name
            email
          }
        }
      }
    `;

    return this.apollo.mutate<SignInDTO>({ mutation, variables: { code } }).pipe(
      catchAllErrors(),
      map((result: ApolloQueryResult<ResponseData<SignInDTO>>) => result.data.signInWithOAuth)
    );
  }

  getMyMullerLoginURL(): URL {
    const ssoConfig = environment.myMueller.sso;

    const url = new URL(ssoConfig.url);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', ssoConfig.clientId);
    url.searchParams.set('redirect_uri', environment.rootUrl + '/auth/mymueller-sso/callback');
    return url;
  }
}
