import { AngularSvgIconModule } from 'angular-svg-icon';

import { Component } from '@angular/core';

@Component({
  selector: 'app-impersonation-icon',
  templateUrl: './impersonation-icon.component.html',
  styleUrls: ['./impersonation-icon.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule],
})
export class ImpersonationIconComponent {}
