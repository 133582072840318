import { Apollo } from 'apollo-angular';
import { tap } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { DashboardUpdateMachineStatus } from '../../../main/dashboard/store/dashboard.action';
import machineStatusUpdated from '../gql/machine-status-updated.graphql';
import { IMachineStatusDto } from '../interfaces/machine-status.model';

@Injectable({
  providedIn: 'root',
})
export class MachineSubscriptionsService {
  private readonly apollo = inject(Apollo);
  private readonly store$ = inject(Store);

  machineStatusUpdated() {
    return this.apollo
      .subscribe({
        query: machineStatusUpdated,
      })
      .pipe(
        tap((result: any) => {
          const machineStatusData: IMachineStatusDto = result.data.machineStatusUpdated;
          this.store$.dispatch(new DashboardUpdateMachineStatus({ machineData: machineStatusData }));
        })
      );
  }
}
