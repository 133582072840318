import { gql } from 'apollo-angular';
import { upperFirst } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/modules/jm-table/interfaces/list-result.dto';
import { SearchCriteria } from 'src/app/modules/jm-table/models/search-criteria.config';
import { LookupResult, LookupService } from 'src/app/modules/quick-forms';
import { ObjectLiteral } from 'src/app/util/object-literal';
import { camelCaseToWords, isSet } from 'src/app/util/util';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { GetAllOptionsDTO, SearchCriteriaDTO } from '@jm-table';

import { catchAllErrors } from '../../../errors/pipes/catch-all-errors';
import { AbstractCRUDService } from '../../abstract-crud.service';
import { ResponseData } from '../../abstract/interface/response-data';
import { listItemsToLookupResult } from '../../helpers/lookup-mapper.helper';
import deleteMutation from '../gql/delete.graphql';
import getAll from '../gql/get-all.graphql';
import basicQuery from '../gql/machines-basic-query.graphql';
import oneQuery from '../gql/one.graphql';
import orderFiles from '../gql/order-files.graphql';
import restoreMachine from '../gql/restore-machine.graphql';
import { MachineDTO } from '../interfaces/machine.interface.model';
import { OrderFileDTO } from '../interfaces/order-file.interface';
import { Machine } from '../models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends AbstractCRUDService<Machine, MachineDTO> implements LookupService {
  override modelName = 'machine';
  override modelNamePlural = 'machines';

  protected override oneQuery = oneQuery;
  protected override deleteMutation = deleteMutation;

  override model = Machine;

  override fields = `
    guid
    name
    displayName
    alias
    ipAddress
    macAddress
    platform
    type
    location {
      id
      name
      company {
        id
        name
      }
    }
    licenses {
      id
      status
    }
    orders {
      orderGuid
      name
    }
    machineType {
      id
      name
    }
    machineCategory {
      id
      name
    }
    autofillQueue
    lastDataReceivedAt
    currentShift {
      shiftId
      alias
    }
    shiftLastChangedAt
    currentStatus
    statusLastChangedAt
    currentSpeedRpm
    currentPickCounter
    createdAt
    updatedAt
    decks
    spaces
    deactivatedAt
    serialNumber
    anNumber
    firmwareVersion
    orderQueueLength
    camosNumber
    energyConsumptionKw
    deletedAt
  `;

  getMachineCategories() {
    const query = gql`
      query {
        machineCategories {
          id
          name
          goodEfficiency
          stopsGood
          speedGood
          poorEfficiency
          stopsPoor
          speedPoor
        }
      }
    `;
    return this.apollo.query<any>({ query }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('machine-category.error.get-all');
      })
    );
  }

  reactivateMachine(guid: string): Observable<MachineDTO> {
    const mutation = gql`
      mutation reactivateMachine($guid: GUID!) {
        reactivateMachine(guid: $guid) {
          guid
          name
          createdAt
          location {
            name
            company {
              id
              name
            }
          }
          alias
          machineCategory {
            name
          }
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid,
        },
      })
      .pipe(map((response) => (response.data as ObjectLiteral)['reactivateMachine']));
  }

  deactivateMachine(guid: string): Observable<MachineDTO> {
    const mutation = gql`
      mutation deactivateMachine($guid: GUID!) {
        deactivateMachine(guid: $guid) {
          guid
          name
          createdAt
          location {
            name
            company {
              id
              name
            }
          }
          alias
          machineCategory {
            name
          }
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation,
        variables: {
          guid,
        },
      })
      .pipe(map((response) => (response.data as ObjectLiteral)['deactivateMachine']));
  }

  getOrderFiles(guid: string): Observable<Array<OrderFileDTO>> {
    const modelName = this.modelName;

    return this.apollo.query<MachineDTO>({ query: orderFiles, variables: { guid } }).pipe(
      catchAllErrors(() => {
        this.snackBarService.error('machines.error.get-one');
      }),
      map((response: ApolloQueryResult<ResponseData<MachineDTO>>) => {
        return response.data[modelName].orderFiles || [];
      })
    );
  }
  getSuggestions(searchCriteria: SearchCriteria): Observable<ListResultDTO<LookupResult>> {
    let locationId: number;
    if (isSet(searchCriteria.filterBy?.locationId)) {
      locationId = Number(searchCriteria.filterBy?.locationId);

      searchCriteria.filterBy = {
        ...searchCriteria.filterBy,
        locationId,
      };
    }

    searchCriteria.filterBy = {
      ...searchCriteria.filterBy,
      isActive: true,
    };
    return this.getAll(searchCriteria, { query: basicQuery }).pipe(
      map((value) => {
        return {
          records: listItemsToLookupResult(value.records, { id: 'guid', name: 'name' }),
          pageDetails: value.pageDetails,
        };
      })
    );
  }

  clearMachineState(guid: string | null | undefined) {
    const mutation = gql`
      mutation clearMachineState($guid: GUID!) {
        clearMachineState(guid: $guid) {
          guid
        }
      }
    `;
    return this.apollo.mutate<any>({ mutation, variables: { guid } }).pipe(
      catchAllErrors((error: ObjectLiteral) => {
        this.handleMessage(error, 'machines.error.clear-state');
      }),
      tap(() => {
        this.snackBarService.success('machines.success.clear-state');
      })
    );
  }

  sendMessage(machineGuid: string | null | undefined, message: string) {
    const mutation = gql`
      mutation sendMachineMessage($machineGuid: GUID!, $message: String!) {
        sendMachineMessage(machineGuid: $machineGuid, message: $message) {
          machineGuid
          message
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          machineGuid,
          message,
        },
      })
      .pipe(map((response) => response));
  }

  dequeueOrder(orderGuid: string | undefined) {
    const mutation = gql`
      mutation dequeueOrder($orderGuid: GUID!) {
        dequeueOrder(orderGuid: $orderGuid) {
          orderGuid
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          orderGuid,
        },
      })
      .pipe(
        catchAllErrors((error: ObjectLiteral) => {
          this.handleMessage(error, 'orders.error.dequeued');
        }),
        tap(() => {
          this.snackBarService.success('orders.success.dequeued');
        })
      );
  }

  moveOrder(orderGuid: string, precedingOrderGuid: string) {
    const mutation = gql`
      mutation moveOrder($orderGuid: GUID!, $precedingOrderGuid: GUID) {
        moveOrder(orderGuid: $orderGuid, precedingOrderGuid: $precedingOrderGuid) {
          orderGuid
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          orderGuid,
          precedingOrderGuid,
        },
      })
      .pipe(
        catchAllErrors((error: ObjectLiteral) => {
          this.handleMessage(error, 'orders.error.move');
        })
      );
  }

  saveShift(machineGuid: string | null | undefined, shiftId: string) {
    const mutation = gql`
      mutation setShift($machineGuid: GUID!, $shiftId: ValidShiftIds!) {
        setShift(machineGuid: $machineGuid, shiftId: $shiftId) {
          guid
          currentShift {
            shiftId
            alias
          }
        }
      }
    `;

    return this.apollo
      .mutate({
        mutation,
        variables: {
          machineGuid,
          shiftId,
        },
      })
      .pipe(map((res) => (res.data as ObjectLiteral)['setShift']));
  }

  restoreMachine(guid: string, name: string): Observable<Machine> {
    return this.apollo
      .mutate({
        mutation: restoreMachine,
        variables: {
          guid,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error(this.translateService.instant('machines.error.restore'));
        }),
        tap(() => {
          const link = {
            text: 'global.show-machine',
            url: `/admin/machines/${guid}`,
          };
          this.snackBarService.success(this.translateService.instant('machines.success.restore', { name }), {}, link);
        }),
        map((response: ApolloQueryResult<{ [key: string]: MachineDTO }>) => {
          return this.loadModel(response.data.restoreMachine);
        })
      );
  }

  override getAll(searchCriteria?: SearchCriteriaDTO, options?: GetAllOptionsDTO): Observable<ListResultDTO<Machine>> {
    const modelName = this.modelNamePlural;

    this.getAllQuery = this.apollo.watchQuery<ListResultDTO<MachineDTO>>({
      query: getAll,
      variables: { searchCriteria, ...options },
      fetchPolicy: 'cache-and-network',
    });

    return this.getAllQuery.valueChanges.pipe(
      catchAllErrors(() => {
        this.snackBarService.error(this.translateService.instant('global.errors.get', { modelName: camelCaseToWords(modelName) }));
      }),
      map((response: ApolloQueryResult<{ [key: string]: ListResultDTO<MachineDTO> }>) => {
        const records = response.data[modelName].records.map(this.loadModel);

        return {
          ...response.data[modelName],
          records,
        };
      })
    );
  }

  override getOne(guid: number, options?: ObjectLiteral): Observable<Machine> {
    const query = gql`
      ${this.oneQuery}
    `;

    const modelName = this.modelName;

    return this.apollo.query<MachineDTO>({ query, variables: { guid, ...options } }).pipe(
      catchAllErrors((error: Error) => {
        this.handleMessage(error, 'machines.error.get-one');
      }),
      map((response: ApolloQueryResult<MachineDTO>) => {
        return (response.data as ObjectLiteral)[modelName];
      })
    );
  }

  override delete(guid: number): Observable<Machine> {
    const modelName = upperFirst(this.modelName);

    return this.apollo
      .mutate({
        mutation: deleteMutation,
        variables: {
          guid,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error(this.translateService.instant('global.errors.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        tap(() => {
          this.snackBarService.success(this.translateService.instant('global.success.delete', { modelName: camelCaseToWords(modelName) }));
        }),
        map((response: ApolloQueryResult<{ [key: string]: MachineDTO }>) => {
          const mutation = `delete${modelName}`;
          return this.loadModel(response.data[mutation]);
        })
      );
  }
}
