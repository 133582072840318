import { GridsterItem } from 'angular-gridster2';
import { Model } from 'ngx-super-model';

export class GridsterConfig extends Model<GridsterItem> {
  id = '';
  x = 0;
  y = 0;
  rows = 2;
  cols = 3;
  layerIndex? = 0;
  dragEnabled?: boolean = true;
  resizeEnabled?: boolean = true;
  compactEnabled?: boolean;
  maxItemRows?: number = 2;
  minItemRows?: number = 2;
  maxItemCols?: number = 6;
  minItemCols?: number = 3;
  minItemArea?: number = 1;
  maxItemArea?: number = 12;

  getSearchDefault(): GridsterItem {
    return {
      x: this.x,
      y: this.y,
      rows: this.rows,
      cols: this.cols,
      maxItemCols: this.maxItemCols,
      maxItemRows: this.maxItemRows,
      maxItemArea: this.maxItemArea,
      minItemArea: this.minItemArea,
    };
  }

  getChartDefault(): GridsterItem {
    return {
      x: this.x,
      y: this.y,
      rows: 2,
      cols: 3,
      maxItemCols: this.maxItemCols,
      minItemCols: 3,
      maxItemRows: this.maxItemRows,
      minItemRows: 2,
      maxItemArea: this.maxItemArea,
      minItemArea: this.minItemArea,
    };
  }
}
