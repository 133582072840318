import { Component, Inject } from '@angular/core';
import { JmButtonComponent } from '@app-ui';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReloadDialogInput } from './interfaces/reload-dialog-input.interface';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './reload-dialog.component.html',
  styleUrls: ['./reload-dialog.component.scss'],
  standalone: true,
  imports: [TranslateModule, JmButtonComponent, AngularSvgIconModule],
})
export class ReloadDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) readonly data: ReloadDialogInput) {}

  reload(): void {
    window.location.reload();
  }
}
