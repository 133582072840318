import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SeverityEnum } from '../shared/components/notification/enums/severity.enums';
import { cloneDeep } from 'lodash-es';

export const snackbarConfig: MatSnackBarConfig = {
  panelClass: [],
  verticalPosition: 'top',
  horizontalPosition: 'end',
  duration: 5000,
  data: {
    message: '',
    messageId: '',
    severity: '',
  },
};

/**
 * @deprecated
 * Use snackbareService functions instead: "error(message, config), success(message, config)"
 * They already have desired severity config and can be tweaked with additional config
 */
export class NotificationUtils {
  static snackBarSuccessConfig(): MatSnackBarConfig {
    const config = cloneDeep(snackbarConfig);
    config.data.severity = SeverityEnum.SUCCESS;
    return Object.assign(new MatSnackBarConfig(), config);
  }

  static snackBarErrorConfig(): MatSnackBarConfig {
    const config = cloneDeep(snackbarConfig);
    config.data.severity = SeverityEnum.ERROR;
    return Object.assign(new MatSnackBarConfig(), config);
  }

  static snackBarInfoConfig(): MatSnackBarConfig {
    const config = cloneDeep(snackbarConfig);
    config.data.severity = SeverityEnum.INFO;
    return Object.assign(new MatSnackBarConfig(), config);
  }

  static snackBarWarningConfig(): MatSnackBarConfig {
    const config = cloneDeep(snackbarConfig);
    config.data.severity = SeverityEnum.WARNING;
    return Object.assign(new MatSnackBarConfig(), config);
  }
}
