import { Injectable, inject } from '@angular/core';

import queryOne from './../gql/one.graphql';
import updateMutation from './../gql/update.graphql';

import { PushNotificationConfigurationDTO } from './interface/push-notification-configuration';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { ResponseData } from 'src/app/core/abstract/interface/response-data';
import { SnackBarService } from 'src/app/shared/components/notification/snack-bar.service';
import { PushNotificationConfiguration } from './model/push-notification-configuration';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationConfigurationService {
  private readonly apollo = inject(Apollo);
  private readonly snackBarService = inject(SnackBarService);

  get(): Observable<PushNotificationConfiguration> {
    return this.apollo
      .query<ResponseData<PushNotificationConfigurationDTO>>({
        query: queryOne,
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('push-notification.configuration.errors.get');
        }),
        map((result) => new PushNotificationConfiguration().loadModel(result.data.pushNotificationConfiguration))
      );
  }

  update(defaultNotification: boolean): Observable<PushNotificationConfiguration> {
    return this.apollo
      .mutate<ResponseData<PushNotificationConfigurationDTO>>({
        mutation: updateMutation,
        variables: {
          defaultNotification,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('push-notification.configuration.errors.get');
        }),
        map((result) => new PushNotificationConfiguration().loadModel(result.data.updatePushNotificationConfiguration))
      );
  }
}
