import { Observable } from 'rxjs';
import { ITableColumnsDTO } from 'src/app/modules/legacy-table/table-columns.model';
import { ObjectLiteral } from 'src/app/util/object-literal';

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { alertConfigurationSettingsRows } from '../../../settings';
import { FetchAlertConfiguration } from '../../../store/notification-configuration.actions';
import { selectAlertConfigurationLoader, selectAlertSubmittingLoading } from '../../../store/notification-configuration.selector';

@Component({
  selector: 'app-alert-configuration',
  templateUrl: './alert-configuration.component.html',
  styleUrls: ['./alert-configuration.component.scss'],
})
export class AlertConfigurationComponent implements OnInit {
  @Input() alertConfigurationCopy: ObjectLiteral | null = null;
  @Input() alertConfiguration: ObjectLiteral | null = null;

  @Output() alertConfigurationChanged: EventEmitter<{
    value: number | boolean | Array<string>;
    checked: boolean;
    fieldName: string;
  }> = new EventEmitter<{ value: number | boolean | Array<string>; checked: boolean; fieldName: string }>();

  alertConfigurationLoader$?: Observable<boolean>;
  alertSubmittingLoading$?: Observable<boolean>;

  alertConfigurationSettingsRows: Array<ITableColumnsDTO> = alertConfigurationSettingsRows;
  innerWidth = 0;

  constructor(private readonly store$: Store<any>) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this.store$.dispatch(new FetchAlertConfiguration());

    this.alertConfigurationLoader$ = this.store$.pipe(select(selectAlertConfigurationLoader));
    this.alertSubmittingLoading$ = this.store$.pipe(select(selectAlertSubmittingLoading));
  }

  emitAlertConf(value: any) {
    this.alertConfigurationChanged.emit(value);
  }
}
