import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';

import { ControlConfig } from '../../../models/control.config';
import { isSet } from '@util';
import { isBoolean } from 'lodash-es';

@Component({
  selector: 'quick-form-readonly-checkbox',
  templateUrl: './readonly-checkbox.component.html',
  styleUrls: ['../checkbox/checkbox.component.scss'],
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class ReadOnlyCheckboxComponent implements OnInit {
  private readonly cdRef = inject(ChangeDetectorRef);

  @Input()
  config: ControlConfig = new ControlConfig();

  @Input()
  control: FormControl = new FormControl();

  @Input()
  checked = false;

  @Input()
  disabled = false;

  ngOnInit(): void {
    if (isSet(this.config.value) && isBoolean(this.config.value)) {
      this.checked = this.config.value;
    }

    if (isSet(this.config.disabled)) {
      this.disabled = this.config.disabled;
    }
  }
}
