import { Model } from 'ngx-super-model';
import { isSet } from 'src/app/util/util';

import { Entry, SensorConfiguration, SensorType } from '@sensor';

import { RealtimeSensorDTO } from '../interfaces/real-time-sensor.interface';
import { RealtimeSensorChartConfig } from './chart-config.model';

export class RealtimeSensor extends Model<RealtimeSensorDTO> {
  value: number | null = null;
  timestamp: string | null = null;
  sensorId: string | null = null;
  sensorName: string | null = null;
  sensorConfiguration = new SensorConfiguration();
  chart = new RealtimeSensorChartConfig();

  getSensorDefinedState(): Entry | null {
    const data = this.sensorConfiguration?.data || [];
    const type = this.sensorConfiguration?.type;
    if (type === SensorType.DICTIONARY) {
      return data?.find((value) => Number(value.for) === this.value) ?? null;
    }
    return null;
  }

  hasSensorDefinedState(): boolean {
    return isSet(this.getSensorDefinedState());
  }
}
