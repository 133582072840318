import { Routes } from '@angular/router';
import { MainComponent } from 'src/app/main/main.component';
import { AuthGuard } from '@auth';
import { EntityNotFoundComponent } from '../errors/components/entity-not-found/entity-not-found.component';

export const mainRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      // old order form had route 'orders' I changed but I put redirect
      {
        path: 'orders',
        redirectTo: 'order',
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/control-center.module').then((m) => m.ControlCenterModule),
        data: { preload: true },
      },
      {
        path: 'library',
        loadChildren: () => import('./library/library.module').then((m) => m.LibraryModule),
      },
      {
        path: 'performance-dashboard',
        loadChildren: () =>
          import('../core/performance-dashboard/performance-dashboard.routing').then((m) => m.PERFORMANCE_DASHBOARD_ROUTES),
      },
      {
        path: 'release-notes',
        loadChildren: () => import('@release-notes').then((m) => m.PUBLIC_RELEASE_NOTES_ROUTES),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./alerts/alerts.routing').then((m) => m.ALERTS_ROUTES),
      },
      { path: 'entity-not-found', component: EntityNotFoundComponent },
    ],
  },
];
