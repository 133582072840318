import { AngularSvgIconModule } from 'angular-svg-icon';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';

import { Component, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tour-step',
  templateUrl: './tour-step.component.html',
  styleUrls: ['./tour-step.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, MatCardModule, TourMatMenuModule, TranslateModule],
})
export class TourStepComponent {
  tourService = inject(TourService);

  end(): void {
    this.tourService.pause();
  }
}
