<div class="add-widget__sidebar hidden-mobile">
  <div
    class="widget-option"
    [class.active]="widget.type === selectedWidget"
    [class.disabled]="isDisabled(widget, selectedWidget)"
    *ngFor="let widget of widgetsConfig"
    (click)="onMenuSelect(widget)"
  >
    <span class="widget-option__title">{{ widget.name | translate }}</span>
    <svg-icon *ngIf="widget.type === selectedWidget" class="widget-option__arrow" [src]="'./assets/images/arrow-right.svg'"></svg-icon>
  </div>
</div>
<div class="add-widget__dropdown hidden-tablet hidden-desktop" *ngIf="form">
  <quick-form-dropdown
    [options]="widgetsConfig"
    [config]="formConfig[0]"
    [control]="form.get('selectedWidget')"
    [formControl]="form.get('selectedWidget')"
    (valueChanged)="onDropdownSelect($event)"
  ></quick-form-dropdown>
</div>
