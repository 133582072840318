import { Model } from 'ngx-super-model';
import { MediaGalleryDTO } from 'src/app/modules/media-gallery/interfaces/gallery-image.interface';
import { ComponentDTO } from '../interfaces/component.interface';
import { AttributesComponentType, TemplatesNotes } from '../../templates/interfaces/article-template.model';

export class Component extends Model<ComponentDTO> {
  label? = '';
  mandatory? = false;
  type?: AttributesComponentType | null = null;
  index? = 0;
  tooltip?: {
    title?: string;
    description?: string;
  } = {};
  default? = '';
  unit? = '';
  min?: number | null = null;
  max?: number | null = null;
  integer?: boolean = false;
  decimal?: boolean = false;
  foreignKey? = '';
  range?: boolean = false;
  maxCharacter?: number | null = null;
  options?: Array<{ id: string, name: string }> = [];
  columns?: Array<string> = [];
  images: Array<MediaGalleryDTO> = [];
  value?: any = '';
  visible?: boolean = true;
  notes?: Array<TemplatesNotes> = [];
  activeImagePreview?: MediaGalleryDTO | null = null;

  toggleImage(media: MediaGalleryDTO) {
    const mediaExists = this.images?.find((image) => media.id === image.id);

    if (mediaExists) {
      this.images = this.images?.filter((image) => {
        return image.id !== mediaExists.id;
      });

      return;
    }

    this.images = [...this.images, media];
  }

  override loadModel(input: ComponentDTO): this {
    super.loadModel(input);
    if (!input.value && input.default) {
      this.value = input.default;
    }
    return this;
  }
}
