import { ChartData } from 'chart.js';
import { CHART_COLORS } from 'src/app/layout/chart/chart.enum';

import { Revenue } from '../models';
import { arrayPadEnd } from 'src/app/util/util';

export function dataToChartMapper(articles: Array<Revenue>): ChartData {
  const maxLength = 10;
  const revenueValues = articles.map((value) => value.revenue || 0);
  const labelsValues = articles.map((value) => value.articleName);

  const datasets = [
    {
      id: 'volumeByArticle',
      backgroundColor: CHART_COLORS.grey,
      borderColor: CHART_COLORS.grey,
      borderWidth: 1,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
      data: arrayPadEnd(revenueValues, maxLength),
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      maxBarThickness: 40,
    },
  ];

  const labels = arrayPadEnd(labelsValues, maxLength);

  return {
    labels,
    datasets,
  };
}
