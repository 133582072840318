import { AverageEfficiencyWidget } from '../../average-efficiency/average-efficiency.model';
import { AverageSpeedWidget } from '../../average-speed/average-speed.model';
import { AverageStopsWidget } from '../../average-stop/average-stops.model';
import { EfficiencyPerShiftWidget } from '../../efficiency-per-shift/efficency-per-shift.model';
import { HistoricalDataWidget } from '../../historical-sensor/historical-sensor.model';
import { RealtimeSensorWidget } from '../../realtime-sensor/realtime-sensor.model';
import { WidgetTypeEnum } from '../enums/widget-type.enum';
import { WidgetsError } from '../errors/widget.error';
import { WidgetDTO } from '../interfaces/widget.interface';
import { CustomWidget } from '../models/widgets/custom.model';

export class WidgetFactory {
  static fromObjectLiteral(widget: WidgetDTO) {
    switch (widget.type) {
      case WidgetTypeEnum.AverageSpeed:
        return new AverageSpeedWidget().loadModel(widget);
      case WidgetTypeEnum.AverageEfficiency:
        return new AverageEfficiencyWidget().loadModel(widget);
      case WidgetTypeEnum.AverageStops:
        return new AverageStopsWidget().loadModel(widget);
      case WidgetTypeEnum.EfficiencyPerShift:
        return new EfficiencyPerShiftWidget().loadModel(widget);
      case WidgetTypeEnum.RealtimeSensor:
        return new RealtimeSensorWidget().loadModel(widget);
      case WidgetTypeEnum.HistoricalSensor:
        return new HistoricalDataWidget().loadModel(widget);
      case WidgetTypeEnum.Custom:
        return new CustomWidget().loadModel(widget);
      default:
        throw new WidgetsError(`Widget ${widget.type} does not exist!`);
    }
  }
}
