<div class="release-notes-dialog">
  <div class="image-wrapper">
    <svg-icon class="logo" [src]="'./assets/images/jm-logo-white.svg'"></svg-icon>
  </div>
  <div class="info-wrapper">
    <div class="action-wrapper">
      <svg-icon class="close-icon" alt="close-icon" (click)="closeDialog()" [src]="'./assets/images/close.svg'"></svg-icon>
    </div>
    <div class="title">{{ title }}</div>
    <div class="message">{{ note.message }}</div>
    <div class="button-wrapper">
      <jm-button [class]="'red-button'" (click)="download()"> {{ 'actions.download' | translate }}</jm-button>
      <jm-button [class]="'red-border-button'" (click)="goToVersionHistory()"> {{ 'release-notes.version-history' | translate }}</jm-button>
    </div>
  </div>
</div>
