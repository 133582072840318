export enum PeriodTypeEnum {
  PERIOD_CURRENT_SHIFT = 'PERIOD_CURRENT_SHIFT',
  PERIOD_LAST_SHIFT = 'PERIOD_LAST_SHIFT',
  PERIOD_LAST_HOUR = 'PERIOD_LAST_HOUR',
  PERIOD_TODAY = 'PERIOD_TODAY',
  PERIOD_LAST_24_HOURS = 'PERIOD_LAST_24_HOURS',
  PERIOD_YESTERDAY = 'PERIOD_YESTERDAY',
  PERIOD_LAST_TWO_DAYS = 'PERIOD_LAST_TWO_DAYS',
  PERIOD_CURRENT_MONTH = 'PERIOD_CURRENT_MONTH',
  PERIOD_LAST_MONTH = 'PERIOD_LAST_MONTH',
  PERIOD_LAST_HALF_HOUR = 'PERIOD_LAST_HALF_HOUR',
  CUSTOM_RANGE = 'CUSTOM_RANGE',
}
