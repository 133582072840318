import { Period } from 'src/app/util/period/period.interface';

import { createAction, props } from '@ngrx/store';

import { Widget } from '../../widgets/core/models/widgets/widget.model';
import { Dashboard } from '../model/dashboard.model';
import { DashboardMode } from './dashboard.state';

export const openAddNewWidgetDialog = createAction('[WIDGETS] OPEN_ADD_NEW_WIDGET_DIALOG');

export const deleteWidget = createAction('[WIDGETS] DELETE_WIDGET', props<{ id: string }>());

export const dashboardLoaded = createAction('[WIDGETS] SET DASHBOARD LOADED', props<{ mode: DashboardMode }>());

export const setDashboardPeriod = createAction('[WIDGETS] SET_DASHBOARD_PERIOD', props<{ period: Period; id: string }>());

export const updateWidget = createAction('[WIDGETS] UPDATE WIDGET', props<{ widget: Widget }>());

export const loadPublicDashboards = createAction('[WIDGETS] LOAD PUBLIC DASHBOARDS');

export const loadPrivateDashboards = createAction('[WIDGETS] LOAD PRIVATE DASHBOARDS');

export const setPublicDashboards = createAction('[WIDGETS] SET PUBLIC DASHBOARDS', props<{ dashboards: Array<Dashboard> }>());

export const setPrivateDashboards = createAction('[WIDGETS] SET PRIVATE DASHBOARDS', props<{ dashboards: Array<Dashboard> }>());

export type DashboardLocalStorageAction = typeof setDashboardPeriod;
