import { Directive, Input, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { selectActiveLicenses } from '@auth';
import { filter } from 'rxjs/operators';
import { notNull } from 'src/app/util/rxjs/rxjs.hellper';

@UntilDestroy()
@Directive({
  selector: '[appHasLicense]',
  standalone: true,
})
export class HasLicenseDirective {
  private readonly templateRef = inject(TemplateRef);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly store$ = inject(Store);

  embededViewCreated = false;

  @Input()
  set appHasLicense(requiredLicenses: Array<string>) {
    this.store$
      .select(selectActiveLicenses)
      .pipe(filter(notNull), untilDestroyed(this))
      .subscribe((activeLicenses: Array<string>) => {
        this.updateView(activeLicenses, requiredLicenses);
      });
  }

  private updateView(activeLicenses: Array<string>, requiredLicenses: Array<string>): void {
    const hasLicense = this.checkLicenses(activeLicenses, requiredLicenses);

    if (!hasLicense) {
      this.viewContainer.clear();
      this.embededViewCreated = false;
      return;
    }

    if (!this.embededViewCreated) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.embededViewCreated = true;
    }
  }

  private checkLicenses(activeLicenses: Array<string>, requiredLicenses: Array<string>) {
    return requiredLicenses.every((permission) => activeLicenses?.includes(permission));
  }
}
