import { createReducer, on } from '@ngrx/store';
import * as ApplicationActions from './application.actions';
import { initialApplicationState } from './application.state';

export const applicationStateReducer = createReducer(
  initialApplicationState,
  on(ApplicationActions.toggleSettingsSidebar, (state) => ({ ...state, isSettingsSideBarOpened: !state.isSettingsSideBarOpened })),
  on(ApplicationActions.showSettingsSidebar, (state) => ({ ...state, isSettingsSideBarOpened: true })),
  on(ApplicationActions.closeSettingsSidebar, (state) => ({ ...state, isSettingsSideBarOpened: false })),
  on(ApplicationActions.setActiveDashboard, (state, { id }) => ({ ...state, activeDashboardId: id })),
  on(ApplicationActions.tableFilterChanged, (state, { id, searchCriteria }) => {
    const tableFilters = { ...state.tableFilters };
    tableFilters[id] = searchCriteria;

    return {
      ...state,
      tableFilters,
    };
  })
);
