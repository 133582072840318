<div class="main-holder main-holder--primary">
  <div class="form user-profile__wrapper">
    <app-header-bar [title]="'user-profile.title' | translate">
      <ng-container actions>
        <jm-button [class]="'red-button'" [suffixIcon]="'save'" (clicked)="save()" [disabled]="passwordResetForm?.invalid">
          {{ 'actions.save' | translate }}
        </jm-button>
      </ng-container>
    </app-header-bar>
    <div class="boxes">
      <app-box>
        <ng-container header>
          {{ 'user-profile.basic-info' | translate }}
        </ng-container>

        <ng-container content>
          <quick-form [id]="'user-info-form'" [form]="infoForm" [config]="infoConfig"></quick-form>
        </ng-container>
      </app-box>

      <app-box>
        <ng-container header>
          {{ 'user-profile.update-password' | translate }}
        </ng-container>

        <ng-container content>
          <quick-form [id]="'password-reset-form'" [form]="passwordResetForm" [config]="passwordConfig"></quick-form>
        </ng-container>
      </app-box>
    </div>
  </div>
</div>
