<div class="widget widget--historical-sensor">
  <widget-header [widget]="widget" [isLive]="isRealTimePeriodSelected" (download)="onDownload()"></widget-header>

  <widget-content [error]="error" [noData]="noData">
    <main class="widget__main" *ngIf="!licenseHasExpired">
      <ng-container *ngIf="!loading; else loader">
        <div class="graph" *ngIf="!isPeriodLargerThanTwoDays">
          <jm-chart [chartConfig]="chartConfig" [data]="data" [id]="widget.id"></jm-chart>
        </div>
        <app-empty-block
          *ngIf="isPeriodLargerThanTwoDays"
          [title]="'global.errors.error' | translate"
          [image]="'./assets/images/machines-error.svg'"
          [description]="'sensor.widget.historical.period-warning' | translate"
        >
        </app-empty-block>
      </ng-container>
    </main>
    <app-empty-block
      *ngIf="licenseHasExpired"
      [title]="'sensor.error.expired-license' | translate"
      [description]="'global.contact-jm' | translate"
    >
    </app-empty-block>
  </widget-content>

  <widget-footer [tags]="tags"></widget-footer>
</div>

<ng-template #loader> <app-loader class="jm-loader" [centered]="true"></app-loader></ng-template>
