import { ChartData } from 'chart.js';
import moment from 'moment';

import { PeriodDTO } from '../../core/interfaces/period.interface';
import { getDateFormatFromPeriod } from '../../core/helpers/chart.helper';

export function dataToAverageSpeedChart(backendData: Array<any>, period: PeriodDTO): ChartData {
  const format = getDateFormatFromPeriod(period);
  const data: Array<any> = backendData.map((value) => {
    return { ...value, bucket: moment(value.bucket).format(format) };
  });

  return {
    datasets: [{ data, borderColor: '#80C953', backgroundColor: '#80C953' }],
  };
}
