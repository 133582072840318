<quick-form-input
  *ngIf="config?.type === controlTypeEnum.InputText"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-input>
<quick-form-input-number
  *ngIf="config?.type === controlTypeEnum.InputNumber"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-input-number>
<quick-form-dropdown
  *ngIf="config?.type === controlTypeEnum.Dropdown"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
  (openInfoWindow)="onOpenInfoWindow()"
></quick-form-dropdown>
<quick-form-checkbox
  *ngIf="config?.type === controlTypeEnum.Checkbox"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-checkbox>
<quick-form-readonly-checkbox
  *ngIf="config?.type === controlTypeEnum.ReadOnlyCheckbox"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-readonly-checkbox>
<quick-form-textarea
  *ngIf="config?.type === controlTypeEnum.TextArea"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-textarea>
<quick-form-radio-button
  *ngIf="config?.type === controlTypeEnum.RadioButton"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-radio-button>
<quick-form-google-places
  *ngIf="config?.type === controlTypeEnum.GooglePlaces"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-google-places>
<quick-form-repeater
  *ngIf="config?.type === controlTypeEnum.Repeater"
  [config]="config"
  [control]="getFormArray()"
  (valueChanged)="onValueChanged($event)"
></quick-form-repeater>
<quick-form-upload
  *ngIf="config?.type === controlTypeEnum.Upload"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-upload>
<quick-form-p-datepicker
  *ngIf="config?.type === controlTypeEnum.PDatePicker"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-p-datepicker>
<quick-form-multiselect
  *ngIf="config?.type === controlTypeEnum.Multiselect"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
></quick-form-multiselect>

<quick-form-pattern-file
  *ngIf="config?.type === controlTypeEnum.PatternFile"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
></quick-form-pattern-file>

<quick-form-upload-multipart
  *ngIf="config?.type === controlTypeEnum.UploadMultipart"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
></quick-form-upload-multipart>

<quick-form-color-picker
  *ngIf="config?.type === controlTypeEnum.ColorPicker"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
  (valueChanged)="onValueChanged($event)"
>
</quick-form-color-picker>
<quick-form-slide-toggle
  *ngIf="config?.type === controlTypeEnum.SlideToggle"
  [config]="config"
  [control]="getControl()"
  [formControl]="getControl()"
></quick-form-slide-toggle>

<quick-form-readonly *ngIf="config?.type === controlTypeEnum.ReadOnly" [config]="config"></quick-form-readonly>

<div *ngIf="config.type !== controlTypeEnum.ReadOnly && config.type !== controlTypeEnum.Hidden" class="validation-wrapper">
  <quick-form-validation-message [control]="control" [controlId]="config.id"></quick-form-validation-message>
</div>
