import { Apollo, gql } from 'apollo-angular';
import { Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private userPermissions?: Array<string> | null;
  private readonly authenticationState = new Subject<any>();

  constructor(private readonly apollo: Apollo) {}

  fetch() {
    const query = gql`
      query {
        me {
          permissions
        }
      }
    `;
    return this.apollo
      .query<any>({
        query,
      })
      .pipe(
        map((res) => res.data.me),
        catchError((err) => {
          return err;
        })
      );
  }

  identity(force?: boolean): Promise<Array<string> | null | undefined> {
    if (force) {
      this.userPermissions = undefined;
    }

    if (this.userPermissions) {
      return Promise.resolve(this.userPermissions);
    }

    return this.fetch()
      .toPromise()
      .then((response) => {
        const permissions = response.permissions;
        if (permissions) {
          this.userPermissions = permissions;
        } else {
          this.userPermissions = null;
        }
        this.authenticationState.next(this.userPermissions);
        return this.userPermissions;
      })
      .catch((err) => {
        this.userPermissions = null;
        this.authenticationState.next(this.userPermissions);
        return null;
      });
  }
}
