import { ChartConfiguration } from 'chart.js';
import 'chartjs-adapter-moment';

export const realtimeSensorChartConfig: ChartConfiguration = {
  type: 'line',
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.1,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { dataIndex } = context;
            const { prettyDuration } = (context.dataset as any).myCustomData;

            return prettyDuration[dataIndex];
          },
        },
      },
    },
    animation: {
      duration: 0,
    },
  },
};
