@if (isMobile) {
<jm-button [class]="'white-border-button button--small notification'" (click)="handleNotification()" data-test="notifications">
  @if(unreadCount() > 0){
  <span class="alerts">{{ unreadCount() > 99 ? '99+' : unreadCount() }} </span>
  }
  <svg-icon class="link-icon" src="./assets/images/alert.svg"></svg-icon>
</jm-button>
} @if (routerUrl !== '/notifications/alerts-list' && isMobile === false) {
<jm-button
  [class]="'white-border-button button--small notification'"
  #alertMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="alertMenu"
  data-test="notifications"
>
  @if(unreadCount() > 0){
  <span class="alerts">{{ unreadCount() > 99 ? '99+' : unreadCount() }} </span>
  }
  <svg-icon class="link-icon" src="./assets/images/alert.svg"></svg-icon>
</jm-button>
<mat-menu #alertMenu="matMenu" class="alert-menu">
  <ng-template matMenuContent>
    <app-alerts-list />
  </ng-template>
</mat-menu>
}
