import { ChartData } from 'chart.js';
import moment from 'moment';

import { PeriodDTO } from '../../core/interfaces/period.interface';
import { getDateFormatFromPeriod } from '../../core/helpers/chart.helper';
import { ChartResponseItemDTO } from '../../core';

export function dataToAverageEfficiencyChart(backendData: Array<ChartResponseItemDTO>, period: PeriodDTO): ChartData {
  const format = getDateFormatFromPeriod(period);

  const data: Array<any> = backendData.map((data) => {
    const { value, offline, bucket } = data;
    return { value: value * 100, offline, bucket: moment(bucket).format(format) };
  });

  return {
    datasets: [{ data, borderColor: '#80C953', backgroundColor: '#80C953' }],
  };
}
