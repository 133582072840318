import { selectActiveDashboard } from 'src/app/store/application.selector';

import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseEnum } from '@licenses';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { selectPrivateDashboards, selectSharedDashboards } from 'src/app/core/custom-dashboard/store/dashboard.selector';

@UntilDestroy()
@Component({
  selector: 'app-dashboards-menu-dialog',
  templateUrl: './dashboards-menu-dialog.component.html',
  styleUrls: ['./dashboards-menu-dialog.component.scss'],
})
export class DashboardsMenuDialogComponent implements OnInit {
  private readonly router = inject(Router);
  private readonly store$ = inject(Store);

  @Output() create: EventEmitter<never> = new EventEmitter<never>();

  readonly privateDashboards$ = this.store$.pipe(select(selectPrivateDashboards));
  readonly sharedDashboards$ = this.store$.pipe(select(selectSharedDashboards));

  activeDashboardId: string | null = '';

  get licenses(): typeof LicenseEnum {
    return LicenseEnum;
  }

  ngOnInit(): void {
    this.watchActiveDashboardChange();
  }

  createNewDashboard(): void {
    this.create.emit();
  }

  goTo(route: string): void {
    this.router.navigateByUrl(route);
  }

  private watchActiveDashboardChange(): void {
    this.store$
      .select(selectActiveDashboard)
      .pipe(untilDestroyed(this))
      .subscribe((activeDashboardId: string | null) => {
        this.activeDashboardId = activeDashboardId;
      });
  }
}
