import { ParamMap } from '@angular/router';
import { Period } from '../period/period.interface';
import { UrlPeriodDTO } from '../period/url-period.interface';
import { isSet } from '../util';
import { DateTimeFormatStyle } from './format-style.enum';
import { dateStyleToOptionsMaper } from './style-to-options.mapper';
import { PeriodTypeEnum } from 'src/app/enum/period-filter.enum';

export function browserLocaleFormat(value: string, formatStyle: DateTimeFormatStyle = DateTimeFormatStyle.date, defaultLang = 'en') {
  const format = dateStyleToOptionsMaper.get(formatStyle);
  const language = navigator.language || defaultLang;
  return Intl.DateTimeFormat(language, format).format(new Date(value));
}

export function periodToQueryParams(period: Period): UrlPeriodDTO {
  const params: UrlPeriodDTO = {
    period: period.type,
  };

  const customPeriod = period.customPeriod;

  if (!isSet(customPeriod)) {
    return params;
  }

  if (!isSet(customPeriod.start) || !isSet(customPeriod.end)) {
    return params;
  }

  return {
    ...params,
    start: encodeURIComponent(customPeriod.start),
    end: encodeURIComponent(customPeriod.end),
  };
}

export function queryParamsToPeriod(paramMap: ParamMap): Period {
  const type = paramMap.get('period') as PeriodTypeEnum;
  const start = paramMap.get('start');
  const end = paramMap.get('end');

  if (!Object.values(PeriodTypeEnum).includes(type)) {
    throw new Error('custom-dashboard.errors.invalid-period-in-url');
  }

  if (!start || !end) {
    return {
      type,
      customPeriod: null,
    };
  }

  return {
    type,
    customPeriod: {
      start: decodeURIComponent(start),
      end: decodeURIComponent(end),
    },
  };
}
