import { Apollo } from 'apollo-angular';
import { BehaviorSubject, EMPTY, NEVER, Observable, from } from 'rxjs';
import { concatMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { isSet } from 'src/app/util/util';
import { pushNotificationConfig } from './one-signal.config';

import registerDeviceMutation from './gql/register-device.graphql';
import unregisterDeviceMutation from './gql/unregister-device.graphql';
import { PushNotificationDeviceDTO } from './interfaces/push-notification-device.interface';

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
  private readonly apollo = inject(Apollo);
  private readonly oneSignalService = inject(OneSignal);

  private readonly loadedSub$ = new BehaviorSubject(false);

  init(): Observable<PushNotificationDeviceDTO> {
    const initPromise = this.oneSignalService.init(pushNotificationConfig);

    return from(initPromise).pipe(
      tap(() => {
        this.loadedSub$.next(true);
      }),
      switchMap(() => this.permissionChanged()),
      concatMap((isSubscribed) => {
        const userId = this.getUserId();

        if (isSubscribed) {
          localStorage.setItem('osUserId', userId as string);

          if (!userId) {
            return NEVER;
          }

          return this.registerDevice(userId);
        }

        if (!userId) {
          return NEVER;
        }

        return this.unregisterDevice(userId);
      })
    );
  }

  isLoaded(): Observable<boolean> {
    return this.loadedSub$.asObservable().pipe(filter((isLoaded) => isLoaded));
  }

  permissionChanged(): Observable<boolean> {
    return new Observable((observer) => {
      const permFunction = (permission: boolean) => {
        observer.next(permission);
      };

      this.oneSignalService.Notifications.addEventListener('permissionChange', permFunction);

      return () => {
        this.oneSignalService.Notifications.removeEventListener('permissionChange', permFunction);
      };
    });
  }

  getUserId() {
    return this.oneSignalService.User.PushSubscription.id;
  }

  showNativePrompt(): Observable<void> {
    const requestPermissionPromise = this.oneSignalService.Notifications.requestPermission();
    return from(requestPermissionPromise);
  }

  hasPermission(): boolean {
    return this.oneSignalService.Notifications.permission;
  }

  getNativePermission(): string {
    return this.oneSignalService.Notifications.permissionNative;
  }

  checkAndSendToApiIfUserIdExist(): Observable<PushNotificationDeviceDTO> {
    const userId = this.getUserId() as string;

    localStorage.setItem('osUserId', userId);

    if (!isSet(userId)) {
      return EMPTY;
    }

    const permission = this.hasPermission();

    if (!isSet(permission)) {
      return EMPTY;
    }

    return this.registerDevice(userId);
  }

  registerDevice(osUserId: string): Observable<PushNotificationDeviceDTO> {
    return this.apollo
      .mutate<PushNotificationDeviceDTO>({
        mutation: registerDeviceMutation,
        variables: {
          euid: osUserId,
        },
      })
      .pipe(
        map((response) => response.data),
        filter((device): device is PushNotificationDeviceDTO => isSet(device))
      );
  }

  unregisterDevice(osUserId: string): Observable<PushNotificationDeviceDTO> {
    return this.apollo
      .mutate<PushNotificationDeviceDTO>({
        mutation: unregisterDeviceMutation,
        variables: {
          euid: osUserId,
        },
      })
      .pipe(
        map((response) => response.data),
        filter((device): device is PushNotificationDeviceDTO => isSet(device))
      );
  }
}
