import { GridsterConfig } from '../core/models/gridster-config.model';
import { Widget } from '../core/models/widgets/widget.model';

export class RealtimeSensorWidget extends Widget {
  override gridsterConfig: GridsterConfig = new GridsterConfig().loadModel({
    x: 0,
    y: 0,
    rows: 2,
    cols: 3,
    minItemCols: 3,
    minItemRows: 1,
    maxItemRows: 20,
    maxItemArea: 20,
  });
}
