import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LookupResult } from '../../../interfaces/lookup-result.interface';
import { AbstractDropdownComponent } from '../abstract/abstract-dropdown.component';
import { hasProperty } from 'src/app/util/object.helper';
import { isSet } from '@util';

@Component({
  selector: 'quick-form-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DropdownComponent),
    },
  ],
})
export class DropdownComponent extends AbstractDropdownComponent implements ControlValueAccessor, OnInit {
  value: LookupResult | null = null;
  bindValueProperty = 'id';
  valueAsObject = false;

  @Output() valueChanged = new EventEmitter<LookupResult | string | Array<string> | null>();

  writeValue(value: any): void {
    if (typeof value !== 'undefined' && value !== '') {
      this.value = value;
      return;
    }

    this.value = null;
  }

  override setupConfiguration(): void {
    super.setupConfiguration();
    const meta = this.config.meta;

    if (isSet(meta) && hasProperty(meta, 'valueAsObject')) {
      this.valueAsObject = this.config.meta?.valueAsObject;
      this.bindValueProperty = '';
    }
  }

  onChange(selectedValue: LookupResult | null): void {
    const selectedId = selectedValue?.id ?? null;
    const valueToEmit = this.valueAsObject ? selectedValue : selectedId;

    this.propagateChange(valueToEmit);
    this.valueChanged.emit(valueToEmit);
  }

  clearValue(): void {
    this.value = null;
  }

  compareFn = (item: LookupResult, value: LookupResult | null): boolean => {
    if (value === null) {
      return false;
    }

    if (this.valueAsObject) {
      return item.id === (value as LookupResult).id;
    }

    // TODO: value can be boolean | number | string this will be sorted out when we start using angular Typed forms
    return item.id === (value as any);
  };

  close(): void {
    this.focused.emit(false);
  }
}
