import { map, Observable, tap } from 'rxjs';
import { catchAllErrors } from 'src/app/errors/pipes/catch-all-errors';

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';

import { AbstractCRUDService } from '../../abstract-crud.service';
import { SensorConfigurationDTO, SensorExport } from '../interfaces';
import { SensorConfiguration } from '../models';
import deleteSensorConfiguration from '../gql/delete-configuration.graphql';
import sensorConfiguration from '../gql/sensor-configuration.graphql';
import sensorExport from '../gql/sensor-export.graphql';
import { ResponseData } from '../../abstract/interface/response-data';

@Injectable({ providedIn: 'root' })
export class SensorService extends AbstractCRUDService<SensorConfiguration, SensorConfigurationDTO> {
  override modelName = 'sensorConfiguration';
  override modelNamePlural = 'sensorConfigurations';

  override model = SensorConfiguration;

  override readonly fields: string = `
      sensorId
      label
      type
      unit
      data
      updatedAt
  `;

  protected override get allQuery(): string {
    const searchCriteriaType = 'SensorConfigurationSearchCriteriaInput!';

    const query = `
    query ${this.modelNamePlural}($searchCriteria: ${searchCriteriaType}) {
      ${this.modelNamePlural}(searchCriteria: $searchCriteria) {
        records {
          ${this.fields}
        }
        ${this.pageDetails}
      }
    }
  `;

    return query;
  }

  getOneBySensorId(sensorId: string): Observable<SensorConfiguration> {
    return this.apollo
      .query<SensorConfigurationDTO>({
        query: sensorConfiguration,
        variables: {
          sensorId,
        },
      })
      .pipe(
        catchAllErrors((error: Error) => {
          this.handleMessage(error, 'sensor.error.get-one');
        }),
        map((response: ApolloQueryResult<ResponseData<SensorConfigurationDTO>>) => {
          return new SensorConfiguration().loadModel(response.data.sensorConfiguration);
        })
      );
  }

  deleteBySensorId(sensorId: string): Observable<SensorConfiguration> {
    return this.apollo
      .mutate({
        mutation: deleteSensorConfiguration,
        variables: {
          sensorId,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('sensor.error.delete');
        }),
        tap(() => {
          this.snackBarService.success('sensor.success.delete');
        }),
        map((response: ApolloQueryResult<ResponseData<SensorConfiguration>>) => {
          return response.data.deleteSensorConfiguration;
        })
      );
  }

  exportData(input: SensorExport): Observable<{ url: string; filename: string }> {
    return this.apollo
      .mutate({
        mutation: sensorExport,
        variables: {
          input,
        },
      })
      .pipe(
        catchAllErrors(() => {
          this.snackBarService.error('sensor.error.export');
        }),
        map((response: ApolloQueryResult<ResponseData<{ url: string }>>) => {
          return {
            url: response.data.sensorReadingExport.url,
            filename: input.filename,
          };
        })
      );
  }
}
