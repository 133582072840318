import { MainComponent } from 'src/app/main/main.component';
import { mainRoutes } from 'src/app/main/main.routing';
import { MaterialSharedModule } from 'src/app/shared/material-shared.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImpersonationIconComponent, LoaderComponent } from '@app-ui';
import { LegacyComponentsModule } from '@legacy-components';
import { NotificationConfigurationModule } from '@notification-configuration';

import { AuthModule } from '../auth/auth.module';
import { HasPermissionDirective } from '../auth/permissions/directives/permission.directive';
import { CreateDashboardModule } from '../core/custom-dashboard/create-dashboard/create-dashboard.module';
import { DashboardModule } from '../core/custom-dashboard/dashboard.module';
import { HasLicenseDirective } from '../core/license/directives/has-license.directive';
import { ReleaseNotesInfoDialogComponent } from '../core/release-notes/components/release-notes-info-dialog/release-notes-info-dialog.component';
import { FooterComponent, NavbarComponent, NotFoundComponent, NotificationMessageComponent, TutorialComponent } from '../layout';
import { BasicLayoutComponent } from '../layout/basic-layout/basic-layout.component';
import { DashboardsMenuDialogComponent } from '../layout/dashboards-menu-dialog/dashboards-menu-dialog.component';
import { HeaderBarComponent } from '../layout/header-bar/header-bar.component';
import { TourStepComponent } from '../layout/tour-step/tour-step.component';
import { JmTableModule } from '../modules/jm-table/jm-table.module';
import { QuickFormsModule } from '../modules/quick-forms';
import { SnackBarComponent } from '../shared/components/notification/component/snack-bar.component';
import { I18nModule } from '../shared/i18n.module';
import { EmptyBlockModule } from '../shared/modules/empty-block/empty-block.module';
import { AlertButtonComponent } from './alerts/components/alerts-list/alert-button/alert-button.component';

@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    NotificationMessageComponent,
    TutorialComponent,
    SnackBarComponent,
    BasicLayoutComponent,
    DashboardsMenuDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(mainRoutes),
    MaterialSharedModule,
    I18nModule,
    LegacyComponentsModule,
    SharedModule,
    AuthModule,
    NotificationConfigurationModule,
    QuickFormsModule,
    JmTableModule,
    HeaderBarComponent,
    EmptyBlockModule,
    CreateDashboardModule,
    LoaderComponent,
    ImpersonationIconComponent,
    HasLicenseDirective,
    ReleaseNotesInfoDialogComponent,
    HasPermissionDirective,
    TourStepComponent,
    DashboardModule,
    AlertButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NotificationConfigurationModule],
})
export class MainModule {}
