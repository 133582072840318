/* eslint-disable */
/* legacy components will be removed*/

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNumber } from 'lodash-es';

@Component({
  selector: 'app-sb-number-input',
  templateUrl: './sb-number-input.component.html',
  styleUrls: ['../input.scss'],
})
export class SbNumberInputComponent {
  @Input() value: number | null | undefined = null;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() nonEditable = false;
  @Input() label = '';
  @Input() required = false;
  @Input() invalid = false;
  @Input() readonly = false;
  @Input() hideArrows = false;
  @Input() validationMessage = '';
  @Input() onlyInteger = false;
  @Input() description = '';

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onValueChange: EventEmitter<number | null | undefined> = new EventEmitter<number | null | undefined>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() setActive: EventEmitter<void> = new EventEmitter<void>();

  isInputFocused = false;

  valueChange(value: number | null | undefined): void {
    if (!value) {
      return;
    }

    let valueChecked: any = value.toString()?.length > 0 ? Number(this.onlyInteger ? Math.round(value) : value) : null;

    valueChecked = this.checkMin(valueChecked);
    valueChecked = this.checkMax(valueChecked);
    this.value = valueChecked;
    this.onValueChange.emit(valueChecked);
  }

  onFocus(event?: any): void {
    this.isInputFocused = Boolean(event);
    this.setActive.emit();
  }

  decrease(): void {
    if (!isNumber(this.value)) {
      return;
    }

    // eslint-disable-next-line
    const value: number | null =
      Boolean(this.value) || Number(this.value) === 0
        ? this.min
          ? Number(this.value) - 1 >= this.min
            ? Number(this.value) - 1
            : this.min
          : Number(this.value) - 1
        : this.min;
    this.value = value;
    this.onValueChange.emit(value);
  }

  increase(): void {
    const value =
      Boolean(this.value) || this.value === 0
        ? this.max
          ? Number(this.value) + 1 <= this.max
            ? Number(this.value) + 1
            : this.max
          : Number(this.value) + 1
        : this.min;
    this.value = value;
    this.onValueChange.emit(value);
  }

  clickInputWrapper(): void {
    if (this.readonly) {
      this.onClick.emit();
    }
    this.setActive.emit();
  }

  private checkMax(valueChecked: number): number | null {
    if (Boolean(valueChecked) || valueChecked === 0) {
      if (this.max) {
        if (Number(valueChecked) <= this.max) {
          return Number(valueChecked);
        } else {
          return null;
        }
      } else {
        return Number(valueChecked);
      }
    } else {
      return null;
    }
  }

  private checkMin(valueChecked: any): number | null {
    if (Boolean(valueChecked) || Number(valueChecked) === 0) {
      if (this.min) {
        if (Number(valueChecked) >= this.min) {
          return Number(valueChecked);
        } else {
          return null;
        }
      } else {
        return Number(valueChecked);
      }
    } else {
      return null;
    }
  }
}
