import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ControlConfig, createControls, loadFormConfig, LookupResult } from '@quick-form';
import { FormModeEnum } from 'src/app/modules/quick-forms/enums/form-mode';

import { WidgetTypeEnum } from '../../../enums/widget-type.enum';
import { WidgetsError } from '../../../errors/widget.error';
import { WidgetGlobalConfig } from '../../../interfaces/widgets-global-config.interface';
import { formConfig } from './form-config';
import { isSet } from '../../../../../../util/util';

// TODO: this component needs to be simplified in a way to separate mobile and desktop menu in their own components outputin only selected widget
@Component({
  selector: 'app-widget-select',
  templateUrl: './widget-select.component.html',
  styleUrls: ['./widget-select.component.scss'],
})
export class WidgetSelectComponent implements OnInit {
  @Input() widgetsConfig: Array<WidgetGlobalConfig> = [];
  @Input() formMode: FormModeEnum = FormModeEnum.create;

  @Output() widgetSelect: EventEmitter<WidgetTypeEnum | string> = new EventEmitter<WidgetTypeEnum | string>();

  formConfig: Array<ControlConfig> = [];
  form: FormGroup | any = null;

  @Input() set selectedWidget(widgetType: WidgetTypeEnum | null) {
    this.setSelectedWidgetFromInput(widgetType);
  }

  setSelectedWidgetFromInput(widgetType: WidgetTypeEnum | null): void {
    const selectedWidget: WidgetGlobalConfig | undefined = this.widgetsConfig.find((widget) => widget.type === widgetType);

    if (!selectedWidget) {
      return;
    }

    if (!this.form) {
      this.setupForm();
    }

    const { type, name } = selectedWidget;
    this.form?.get('selectedWidget').setValue({ id: type, name });
  }

  get selectedWidget(): WidgetTypeEnum {
    const { id } = this.form?.get('selectedWidget')?.value;
    return id;
  }

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    if (!this.form) {
      this.setupForm();
    }

    if (this.isEdit()) {
      this.form.get('selectedWidget').disable();
    }
  }

  onMenuSelect(widget: WidgetGlobalConfig): void {
    if (this.formMode === FormModeEnum.edit) {
      return;
    }

    const { type, name } = widget;
    this.form.get('selectedWidget').setValue({ id: type, name });
    this.selectWidget(type);
  }

  // the amount of stupid checks are there because of incorrect Typescript types, or well, lack of inferring correct types from the form config
  onDropdownSelect(value: Array<LookupResult> | LookupResult | string | Array<string> | null): void {
    if (!isSet(value) || typeof value === 'string' || Array.isArray(value)) {
      return;
    }

    this.selectWidget(value.id);
  }

  selectWidget(widgetType: WidgetTypeEnum | string): void {
    const selectedWidget: WidgetGlobalConfig | undefined = this.widgetsConfig.find((widget) => widget.type === widgetType);

    if (!selectedWidget) {
      throw new WidgetsError('Selected widget not found!');
    }
    this.widgetSelect.emit(widgetType);
  }

  private setupForm(): void {
    const { type, name } = this.widgetsConfig[0];
    this.formConfig = loadFormConfig(formConfig, {
      selectedWidget: {
        id: type,
        name,
      },
    });
    this.form = this.fb.group(createControls(this.formConfig));
  }

  isDisabled(widget: WidgetGlobalConfig, selectedWidget: WidgetTypeEnum): boolean {
    return this.formMode === FormModeEnum.edit && widget.type !== selectedWidget;
  }

  isEdit(): boolean {
    return this.formMode === FormModeEnum.edit;
  }
}
