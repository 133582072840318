import { Component, Input } from '@angular/core';
import { FooterTag } from '../../../interfaces/footer-tag.interface';
import { AbstractResizableComponent } from '../../../ui/abstract-resizeable.component';

@Component({
  selector: 'widget-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends AbstractResizableComponent {
  @Input() tags: Array<FooterTag> = [];
}
