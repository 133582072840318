import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { isSet } from '@util';

import { MymuellerSSOService } from '../mymueller-sso/services/mymueller-sso.service';
import { SSOAutoLoginConfig } from '../mymueller-sso/sso-autologin-config';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthenticationService);
  private readonly mymullerSSOService = inject(MymuellerSSOService);

  canActivate(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.isLoggedIn().pipe(
      map((isLogged) => {
        if (!isLogged) {
          return this.handleNotLoggedIn(route);
        }
        return this.handleLoggedIn();
      })
    );
  }

  private handleNotLoggedIn(route: ActivatedRouteSnapshot): boolean {
    const myMullerAutoLogin = route.queryParamMap.get(SSOAutoLoginConfig.param) === '1';
    if (myMullerAutoLogin) {
      this.authService.setSessionRedirectUrl(location.href);
      const url = this.mymullerSSOService.getMyMullerLoginURL();
      window.location.href = url.toString();
      return false;
    }

    this.router.navigate(['/auth/login']);
    return false;
  }

  private handleLoggedIn(): boolean {
    const hasSessionRedirect = this.authService.getSessionRedirectUrl();
    if (isSet(hasSessionRedirect)) {
      this.authService.loginSessionRedirect();
      return false;
    }

    return true;
  }
}
