import { cloneDeep } from 'lodash-es';
import { ControlConfigDTO } from '../interfaces/control-config.interface';
import { ControlConfig } from '../models/control.config';
import { isSet } from 'src/app/util/util';
import { whenLeaf } from './form.helper';

export function nextConfig(config: ControlConfig, disabled = false): Array<ControlConfig> {
  const newConfig = cloneDeep(config.meta?.initialConfig);

  const nextIndex = getLargestIndex([config]) + 1;
  newConfig.forEach((element: ControlConfigDTO) => {
    element.value = '';
    element.disabled = disabled;
    element.index = nextIndex;
  });

  return newConfig;
}

export function getLargestIndex(config: Array<ControlConfig>) {
  let largestIndex = 0;

  whenLeaf(config, (control: ControlConfig) => {
    if (!isSet(control.index)) {
      return;
    }

    if (control.index > largestIndex) {
      largestIndex = control.index;
    }
  });

  return largestIndex;
}
