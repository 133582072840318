import { ITableColumnsDTO } from 'src/app/modules/legacy-table/table-columns.model';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfigurationDialogComponent } from '../configuration-dialog/configuration-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertTableComponent implements OnInit {
  private readonly dialog = inject(MatDialog);

  @Input() tableTitle = '';
  @Input() alertConfiguration: any = {};
  @Input() alertConfigurationLoader: boolean | null = false;
  @Input() alertSubmittingLoading: boolean | null = false;
  @Input() rows: Array<ITableColumnsDTO> = [];

  @Output() emitAlertConfiguration: EventEmitter<{
    value: number | boolean | Array<string>;
    checked: boolean;
    fieldName: string;
  }> = new EventEmitter<{ value: number | boolean | Array<string>; checked: boolean; fieldName: string }>();

  generalOptions: Array<ITableColumnsDTO> = [];

  ngOnInit() {
    this.generalOptions = this.rows.filter((item) => item.dtoValue !== 'machineStoppedAlertReasons');
  }

  toggleAlertConfiguration(event: boolean, fieldName: string): void {
    this.alertConfiguration[fieldName].checked = event;
    this.alertConfiguration = { ...this.alertConfiguration };
    const { value, checked } = this.alertConfiguration[fieldName];
    this.emitAlertConfiguration.emit({ value, checked, fieldName });
  }

  onDashInputValueChange(currentValue: number | null | undefined | string, fieldName: string): void {
    this.alertConfiguration[fieldName].value = Number(currentValue);
    const { value, checked } = this.alertConfiguration[fieldName];
    this.emitAlertConfiguration.emit({ value, checked, fieldName });
  }

  openConfigurationOption(row: ITableColumnsDTO): void {
    if (!row.disabled) {
      const fieldName: string = row.dtoValue;
      const data = {
        value: this.alertConfiguration[fieldName].value,
        checked: this.alertConfiguration[fieldName].checked,
        type: row.type,
        fieldName,
      };

      const dialogRef = this.dialog.open(ConfigurationDialogComponent, {
        data,
      });

      dialogRef
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((res: any) => {
          if (res?.changeData) {
            if (fieldName !== 'machineStoppedAlert') {
              this.alertConfiguration[fieldName].value = res.value;
            } else if (fieldName === 'machineStoppedAlert') {
              this.alertConfiguration['machineStoppedAlertReasons'].value = res.value;
            }
            this.alertConfiguration[fieldName].checked = res.checked;
            const { value, checked } = this.alertConfiguration[fieldName];
            this.emitAlertConfiguration.emit({ value, checked, fieldName });
          }
        });
    }
  }

  getConfig(property: string): ITableColumnsDTO | undefined {
    const found: any = this.rows.find((item) => item.dtoValue === property);
    found['value'] = this.alertConfiguration[found.dtoValue];
    return found;
  }

  onAlertReasonValueChanged(alertReasonValue: any) {
    const { machineStoppedAlert, ...reasons } = alertReasonValue;
    const selectedReasons = Object.keys(reasons).filter((reason) => Boolean(reasons[reason]));
    this.emitValueStopReason('machineStoppedAlertReasons', selectedReasons);
    this.emitValueStopReason('machineStoppedAlert', machineStoppedAlert);
  }

  emitValueStopReason(fieldName: any, currentValue: any) {
    this.alertConfiguration[fieldName].value = currentValue;
    let { checked } = this.alertConfiguration[fieldName];
    const { value } = this.alertConfiguration[fieldName];

    if (fieldName === 'machineStoppedAlert') {
      checked = value as boolean;
    }

    this.emitAlertConfiguration.emit({ value, checked, fieldName });
  }
}
