export enum TimeRange {
  OneMinute = 60,
  FiveMinutes = 300,
  TenMinutes = 600,
  FifteenMinutes = 900,
  ThirtyMinutes = 1800,
  OneHour = 3600,
  SixHours = 21600,
  TwelveHours = 43200,
  OneDay = 86400,
  FiveDays = 432000,
  TenDays = 864000,
  FifteenDays = 432000,
  OneMonth = 2678400,
  SixMonths = 15552000,
  OneYear = 31104000,
  TwoYears = 62208000,
}
