import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupResult } from '@quick-form';
import { CellConfig } from 'src/app/modules/jm-table/models/cells.config';

@Component({
  selector: 'jm-abstract-column-filter',
  template: '',
})
export class AbstractColumnFilterComponent implements OnInit {
  @Input() column: CellConfig = new CellConfig();
  @Input() value?: any;
  @Output() valueChanged = new EventEmitter<Array<any> | null>();
  @Output() focused: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  options: Array<LookupResult> | Array<string> = [];

  ngOnInit(): void {
    this.options = this.column?.filter?.options || [];
  }

  clear(): void {
    this.value = null;
    this.valueChanged.emit(null);
  }

  apply(): void {
    this.valueChanged.emit(this.value);
  }
}
