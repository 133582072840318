<div class="not-found-wrapper">
  <div class="not-found-wrapper__content">
    <img src="./assets/images/404.svg" alt="not-found" />
    <h1 class="title">{{ 'not-found.title' | translate }}</h1>
    <p class="description">{{ 'not-found.description' | translate }}</p>
    <div class="go-back">
      <jm-button [class]="'red-border-button'" [prefixIcon]="'arrow-left'" [hideText]="true" (clicked)="back()">
        {{ 'actions.back' | translate }}
      </jm-button>
    </div>
  </div>
</div>
