import { Component } from '@angular/core';

import { AbstractColumnFilterComponent } from '../abstract-column-filter/abstract-column-filter.component';

@Component({
  selector: 'jm-colum-filter-multiselect',
  templateUrl: './jm-colum-filter-multiselect.component.html',
})
export class JmColumFilterMultiselectComponent extends AbstractColumnFilterComponent {
  onChange(event: any) {
    this.value = event.value;
  }
}
